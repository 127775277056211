import React, { FC, useContext } from 'react';

import { LayoutContext } from 'contexts';


export const Cell: FC<any> = (props) => {
  const {
    as: Tag, cell, row, col, columns, attributesRenderer,
    selected, editing, updated, style,
    children,
    ...rest
  } = props;
  const { isLightMode } = useContext(LayoutContext);
  const attributes = cell.attributes || {};

  return (
    <td
      style={{
        ...(style || {}),
        color: isLightMode ? 'black' : 'white',
        padding: '10px',
        border: '1px #e4e4e4 solid',
        borderRight: `2px ${isLightMode ? '#fafafa' : undefined} solid`,
        background: isLightMode ? undefined : 'transparent',
        textAlign: 'left',
        height: 38,
      }}
      {...rest}
      {...attributes}
    >
      {children}
    </td>
  );
};
