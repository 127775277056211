import { FC, useCallback, useContext, useState } from 'react';
import { useDebounce } from 'react-use';
import { TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Add } from '@mui/icons-material';

import { LayoutContext, LocalizationContext } from 'contexts';
import { DocumentItemType } from 'clients/documents/documentsClient.types';
import { useOriginalItemSearchResults } from 'shared/hooks/useOriginalItemSearchResults';
import { useQueryState } from 'shared/hooks/useQueryState';
import { ButtonDropdown } from 'shared/components/interactive/ButtonDropdown/ButtonDropdown';

interface Props {
    onSelect: (item: any) => void;
    itemType: DocumentItemType.Article | DocumentItemType.Block;
};

export const DuplicateButton: FC<Props> = ({ onSelect = () => {}, itemType }) => {
  const { layoutTempState, isLightMode } = useContext(LayoutContext);
  const [ slug = '' ] = useQueryState('slug');
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');
  const { dictionary } = useContext(LocalizationContext);

  const { results = [] } = useOriginalItemSearchResults(
    {
      cla_slug: slug,
      article: itemType === DocumentItemType.Article,
      search: debouncedSearchQuery,
      section_id: layoutTempState?.parentSectionId,
    },
    { enabled: debouncedSearchQuery.length > 2 },
  );

  useDebounce(() => {
    setDebouncedSearchQuery(searchQuery);
  },
  500,
  [searchQuery],
  );

  const handleSelectOption = useCallback((index: number) => {
    const selectedResult = results[index];
    const title = selectedResult.title_draft || '';
    const body = itemType === DocumentItemType.Article ? selectedResult.blocks_draft?.[0]?.text_content_draft || '' : selectedResult.text_content_draft || '';

    const payload = {
      title,
      body,
      id: selectedResult.id,
      ...(itemType === DocumentItemType.Block && {
        list_formatting_draft: selectedResult.list_formatting_draft,
        variant_draft: selectedResult.variant_draft,
        is_important_draft: selectedResult.is_important_draft,
        is_renvoi_draft: selectedResult.is_renvoi_draft,
      }),
    };

    onSelect(payload);
  },[itemType, onSelect, results]);

  return (
    <ButtonDropdown
      label={dictionary.editableContent.addDuplicateButton}
      placement="bottom-end"
      buttonProps={{
        sx: {
          ml: 2,
        },
        variant: 'contained',
        size: 'large',
        startIcon: <Add/>,
      }}
      dropDownStyle={{
        minWidth: '246px',
        padding: '16px 8px',
        marginTop: '8px',
        borderColor: isLightMode ? '#2B3E6D' : undefined,
      }}
      content={({ closeDropdown }) => (
        <Box
          minHeight={200}
        >
          <TextField
            sx={{ my: 0.5 }}
            label={dictionary.search}
            onChange={(event) => setSearchQuery(event.target.value)}
          />
          <Box
            sx={{
              maxHeight: 200,
              overflowY: 'auto',
            }}
          >
            {results.map((result, index) => (
              <Box
                mb={1}
                sx={{
                  borderTop: index !== 0 ? '1px solid' : undefined,
                  paddingTop: '12px',
                  borderColor: '#E7E7E7',
                }}
                onClick={() => {
                  handleSelectOption(index);
                  closeDropdown();
                }}
              >
                <Typography color="primary" fontSize={12} fontWeight={400}>
                  {result.numbering_draft || ''} {result.stripped_title}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    />
  );
};
