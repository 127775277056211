import { FC, useContext } from 'react';
import { QuestionAnswerOutlined } from '@mui/icons-material';
import { Badge, Box } from '@mui/material';
import { SxProps } from '@mui/system';

import { setFaqOrNotesTab, setShowFilesOverview, setShowRightContainer } from 'store/DocumentTemplate';
import { FaqOrNotesTab } from 'store/types';
import { LayoutContext, UserContext } from 'contexts';
import { DocumentPrintContext } from 'contexts/DocumentPrintContext/DocumentPrintContext';
import { documentsClient } from 'clients/documents/documentsClient';
import { mapQuestion } from 'clients/documents/documentsClient.formatter';
import { useQueryState } from 'shared/hooks/useQueryState';
import { useNotes } from 'shared/hooks/useNotes';

interface Props {
  itemType?: 'block' | 'article';
  item: any;
  itemQuestions?: any[];
  sx?: SxProps;
}

export const QuestionAnswerButton: FC<Props> = ({
  // document entity
  item,
  // entity can have multiple questions
  itemQuestions = [],
  // document entity type
  itemType,
  sx,
}) => {
  const { setShowFAQ, showFAQ, setLayoutTempState, layoutTempState, reviewMode } = useContext(LayoutContext);
  const { isPrintMode, isPrintPreview } = useContext(DocumentPrintContext);
  const { userPermissions } = useContext(UserContext);
  const [slug] = useQueryState('slug');

  const { groupByBlock, groupByArticle } = useNotes();

  let notes: any;
  switch (itemType) {
    case 'block':
      notes = groupByBlock[item?.id] || [];
      break;
    case 'article':
      notes = groupByArticle[item?.id] || [];
      break;
    default:
      notes = [];
      break;
  }

  const onClick = async () => {
    setShowFilesOverview(false);
    const previousOpenFAQ = `${layoutTempState?.FAQItemType}-${layoutTempState?.FAQItem?.id}`;
    const clickedFAQ = `${itemType}-${item.id}`;

    const fetchQuestions = itemQuestions.map(async (question: any) => {
      const response = await documentsClient.getQuestion(
        question.id as number,
        slug as string,
      );

      return mapQuestion(response.data);
    });

    const questions = await Promise.all(fetchQuestions);

    setFaqOrNotesTab(questions.length ? FaqOrNotesTab.FAQ : FaqOrNotesTab.NOTES );

    setLayoutTempState({
      ...(layoutTempState || {}),
      selectedFAQ: questions,
      FAQItemType: itemType,
      FAQItem: item,
    });

    if (showFAQ && previousOpenFAQ === clickedFAQ) {
      setLayoutTempState({
        ...(layoutTempState || {}),
        selectedFAQ: [],
        FAQItemType: undefined,
        FAQItem: undefined,
      });
      setShowFAQ(!showFAQ);
      setShowRightContainer(false);
    } else {
      setShowFAQ(true);
      setShowRightContainer(true);
    }
    // if (showFAQ) {
    //   setShowFAQ(false);
    //   setShowRightContainer(false);
    // } else {
    //   setShowFAQ(true);
    //   setShowRightContainer(true);
    // }
  };

  if (isPrintMode || isPrintPreview || reviewMode || !userPermissions.view_questionanswer) {
    return null;
  }

  const innerContent = (
    // @ts-ignore
    <Badge variant="outlined"
      color="secondary"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      badgeContent={notes.length}
      onClick={onClick}
    >
      <QuestionAnswerOutlined/>
    </Badge>
  );

  if (!itemQuestions.length) {
    return (
      <Box sx={{ ...sx }} className="no-print">
        <Box position="relative">
          {innerContent}
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ ...sx, cursor: 'pointer !important' }} className="no-print">
      <Box position="relative">
        <Badge
          color="secondary"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          badgeContent={itemQuestions.length}
          sx={{ cursor: 'pointer !important', wordBreak: 'keep-all' }}
          onClick={onClick}
        >
          {innerContent}
        </Badge>
      </Box>
    </Box>
  );
};
