import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Box, Button, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import { ImportExport, Save } from '@mui/icons-material';
import { arrayMoveImmutable } from 'array-move';

import { LayoutContext, LocalizationContext } from 'contexts';

const SortableItem = SortableElement(({ value }: any) => (
  <Typography variant="body1" style={{ cursor: 'move', padding: 16, margin: 4, border: '1px solid #000' }}>
    <Grid container alignItems="center" justifyContent="space-between" wrap="nowrap">
      <Typography noWrap width={350} dangerouslySetInnerHTML={{ __html: value }}/>
      <ImportExport/>
    </Grid>
  </Typography>
));

const SortableContentContainer = SortableContainer(({ items }: any) => (
  <Grid container direction="column">
    {items.map((content: any, i: number) => <SortableItem key={content.id} index={i} value={content.title || content.text_content}/> )}
  </Grid>
));

export const ContentSort: FC<{ onSave?: any, items?: any[] }> = ({ onSave, items }) => {

  const { dictionary } = useContext(LocalizationContext);
  const { changesSaved, genericError, setSharedPopupOpen, isMobile } = useContext(LayoutContext);
  const [contents, setContents] = useState<any>(items);
  const [isSaving, setIsSaving] = useState<any>();

  useEffect(() => {
    if(items) {
      setContents(items);
    }
  }, [items]);

  const onSortEnd = useCallback(({ oldIndex, newIndex }: any) => {
    setContents(arrayMoveImmutable(contents, oldIndex, newIndex));
  }, [contents]);

  const save = useCallback(async () => {
    setIsSaving(true);
    try {
      if(onSave) {
        await onSave(contents);
        changesSaved();
        setSharedPopupOpen(false);
      }
    } catch(e) {
      console.error(e);
      genericError();
    }
    setIsSaving(false);
  }, [genericError, contents, changesSaved, setSharedPopupOpen, onSave]);


  return (
    <Box component={Paper} sx={{ p: 2, minWidth: isMobile ? 'calc(100vw - 100)' : 500 }}>
      <Grid container direction="column" spacing={2}>
        <Grid item xs={12}><Typography variant="h5">{dictionary.editableContent.sort}</Typography></Grid>
        <Grid item xs={12}>
          <SortableContentContainer
            items={contents}
            onSortEnd={onSortEnd}
            updateBeforeSortStart={(node: any) => { node.node.style.zIndex=9999; }}
            lockAxis="y"
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={save}
            disabled={isSaving}
            endIcon={isSaving ? <CircularProgress style={{ width: 24, height: 24, color: '#fff' }}/> : <Save style={{ fill: '#fff' }}/>}
          >
            {dictionary.save}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
