import React, { FC, useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  Paper,
  TextField, Typography,
} from '@mui/material';
import { AxiosResponse } from 'axios';
import { cacheKeys } from 'config';
import { LayoutContext, LocalizationContext, UserContext } from 'contexts';
import { documentsClient } from 'clients/documents/documentsClient';
import { Note } from 'modules/Document/Notes';
import { useMe } from 'shared/hooks/useMe';
import { useNotes } from 'shared/hooks/useNotes';
import { FormData, NoteDialogProps } from 'shared/components/dialog/NoteDialog/types';
import { HTMLEditor } from 'shared/components/form/HTMLEditor/HTMLEditor';
import AttachmentEditableList from 'shared/components/form/AttachmentEditableList/AttachmentEditableList';

const NoteDialog: FC<NoteDialogProps> = (
  {
    open,
    onClose,
    editNoteId,
    item,
    itemType,
  },
) => {

  const { dictionary } = useContext(LocalizationContext);
  const { sendNotification, genericError } = useContext(LayoutContext);
  const queryClient = useQueryClient();
  const { user } = useMe();
  const { groupById } = useNotes();

  const [newFiles, setNewFiles] = useState<any>([]);
  const [fileIdsToDelete, setFileIdsToDelete] = useState<any>([]);
  const { userPermissions } = useContext(UserContext);

  const editNote = editNoteId ? groupById[editNoteId][0] : {};

  const defaultValues: FormData = {
    title: editNote.title,
    text: editNote.text,
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: defaultValues,
  });

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: FormData) => {
    setIsLoading(true);
    try {
      let response: AxiosResponse<Note>;
      if (editNoteId) {
        response = await documentsClient.patchNote(editNoteId, data);
        sendNotification(dictionary.notes.modal.notifications.edited);
      } else {
        response = await documentsClient.createNote({
          ...data,
          created_by: user?.id,
          [itemType]: item.id,
        });
        sendNotification(dictionary.notes.modal.notifications.created);
      }
      const createNewFiles = newFiles.map(async (file: any) => {
        await documentsClient.createFile(
          {
            file,
            note: response.data.id,
          },
        );
      });

      await Promise.all(createNewFiles);

      const deleteFiles = fileIdsToDelete.map(async (fileId: number) => {
        await documentsClient.deleteFile(fileId);
      });

      await Promise.all(deleteFiles);

    } catch(error: any) {
      console.error(error);
      genericError();
    } finally {
      setIsLoading(false);
      await queryClient.invalidateQueries(cacheKeys.documents.getNotes);
    }

    innerOnClose();
  };

  const innerOnClose = () => {
    setNewFiles([]);
    setFileIdsToDelete([]);
    reset();
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={innerOnClose}
      maxWidth="sm"
      fullWidth
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper>
          <DialogTitle>
            {editNoteId ? dictionary.notes.modal.editTitle : dictionary.notes.modal.addTitle}
          </DialogTitle>
          <DialogContent>
            <Grid container direction="column" spacing={3} mt={1}>
              <Grid item>
                <Controller
                  name="title"
                  control={control}
                  rules={{
                    required: dictionary.forms.validations.required,
                    maxLength: {
                      value: 255,
                      message: dictionary.forms.validations.maxLength(255),
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={dictionary.notes.title}
                      error={!!errors.title}
                      helperText={errors.title?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Controller
                  name="text"
                  control={control}
                  render={({ field }) => (
                    <HTMLEditor
                      {...field}
                      wrapperStyle={{
                        minHeight: 200,
                        maxWidth: 500,
                      }}
                      label={dictionary.notes.text}
                      error={!!errors.text}
                      helperText={errors.text?.message}
                      tooltipsEnabled={false}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
              >
                <Typography
                  sx={{
                    fontSize: '0.75em',
                  }}
                  pl="12px"
                >
                  {dictionary.document.files}
                </Typography>
                <Grid container direction="column" spacing={2}>
                  <AttachmentEditableList
                    files={editNote.file_set?.filter(({ id }: any) => (
                      !fileIdsToDelete.includes(id)),
                    )}
                    allowDelete={userPermissions?.delete_file}
                    onDelete={(id) => {
                      setFileIdsToDelete([
                        ...fileIdsToDelete,
                        id,
                      ]);
                    }}
                  />
                  <AttachmentEditableList
                    files={newFiles.map((file: any) => ({ file, isNew: true }))}
                    allowDelete={userPermissions?.delete_file}
                    onDelete={(id, file) => {
                      setNewFiles(
                        newFiles.filter((currentFile: any) => file !== currentFile),
                      );
                    }}
                  />
                  <Grid item xs={12}>
                    <TextField
                      style={{ border: '1px solid #999' }}
                      type="file"
                      value={undefined}
                      onChange={e => {
                        // @ts-ignore
                        const file = e?.target?.files?.[0];
                        setNewFiles(
                          [
                            ...newFiles,
                            file,
                          ],
                        );
                        e.target.value = '';
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={innerOnClose}
              disabled={isLoading}
            >
              {dictionary.dialogs.close}
            </Button>
            <Button
              type="submit"
              disabled={isLoading}
            >
              {dictionary.saveAndClose}
            </Button>
          </DialogActions>
          {isLoading && (
            <LinearProgress/>
          )}
        </Paper>
      </form>
    </Dialog>

  );
};

export default NoteDialog;
