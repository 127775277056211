import { useEffect, useState } from 'react';

export const useLazyHashInitScrollEffect = () => {
  const hash = window.location.hash.substring(1);
  const [scrollToTheAnchorOnce, setScrollToTheAnchorOnce] = useState(false);

  useEffect(() => {
    // todo need to find a way to do it after the document load and don't use any timers

    let counter = 0;
    const timer = setInterval(() => {
      if (!scrollToTheAnchorOnce) {
        const target = window.document.getElementById(hash);
        if (counter > 5) {
          if (!target) {
            console.warn(`Cannot find the element with the hash ${hash}`);
          }
          clearInterval(timer);
          return;
        }

        counter++;

        if (!target) {
          return;
        }

        target.scrollIntoView();
        setScrollToTheAnchorOnce(true);

        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [hash, scrollToTheAnchorOnce]);
};