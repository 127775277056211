import React, { FC, useCallback, useContext, useMemo } from 'react';
import { Avatar, Box, Chip, Grid, Paper, Tooltip, Typography } from '@mui/material';
import { format } from 'date-fns';

import { LayoutContext, LocalizationContext } from 'contexts';

export const ContentHistoryRecord: FC<any> = ({
  history_user: user,
  history_date: date,
  records,
  cla,
  index,
  currentRecord,
  ...record
}) => {

  const { dictionary } = useContext(LocalizationContext);
  const { mode } = useContext(LayoutContext);

  const changesLabel = useMemo(() => ({
    title: dictionary.editableContent.title,
    order: dictionary.editableContent.order,
    numbering: dictionary.editableContent.numbering,
    text_content: dictionary.editableContent.text,
    list_formatting: dictionary.editableContent.childrenFormat,
    json_content: dictionary.editableContent.spreadsheet,
    variant: dictionary.editableContent.variant,
    updated_at: dictionary.lastUpdated,
    published_at: dictionary.publishedDate,
  }), [dictionary]);

  const getChangesLabel = useCallback((key) => {
    const strippedKey = key.replace('_draft', '').replace('_en', '').replace('_nl', '');
    let label = changesLabel?.[strippedKey as keyof typeof changesLabel] || dictionary.editableContent.unknown;
    if(key.includes('_draft')) {
      label = `${dictionary.draft} ${label}`;
    }
    if(key.includes('_nl')) {
      label = `${label} - NL`;
    }
    if(key.includes('_en')) {
      label = `${label} - EN`;
    }
    return label;
  }, [changesLabel, dictionary]);

  const newerRecord = useMemo(() => records?.[index + 1] || currentRecord, [currentRecord, records, index]);

  const fields = useMemo(() => {
    let fields: string[] = [];
    if(!cla) {
      fields = [
        ...fields,
        'order',
        'list_formatting',
        'variant',
      ];
      fields = [...fields, ...fields.map(field => `${field}_draft`)];
    }
    if(cla) {
      fields = [...fields, 'published_at', 'published_at_draft'];
    }
    let multilingualFields = [
      'title',
    ];
    if(!cla) {
      multilingualFields = [...multilingualFields, 'text_content'];
    }
    multilingualFields = [
      ...multilingualFields,
      ...multilingualFields.map(field => `${field}_draft`),
    ];
    multilingualFields = [
      ...multilingualFields,
      ...multilingualFields.map(field => `${field}_en`),
      ...multilingualFields.map(field => `${field}_nl`),
    ];
    return [...fields, ...multilingualFields];
  }, [cla]);

  const changes = useMemo(() => fields.filter(key => {
    return record?.[key] !== newerRecord?.[key];
  }).map(key => ({
    key,
    next: newerRecord?.[key],
    previous: record?.[key],
  })), [newerRecord, record, fields]);

  if(changes.length === 0) {
    return null;
  }

  return (
    <Box component={Paper} p={1} mt={1} key={record.history_id}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Avatar src={user.profile_picture} sx={{ mr: -1, width: 32, height: 32 }}/>
        </Grid>
        <Grid item>
          <Typography variant="body1" sx={{ mb: -0.5 }}>{user.first_name} {user.last_name}</Typography>
          <Typography variant="subtitle2">
            {format(new Date(date), 'dd/MM/yyy HH:mm:ss')}
          </Typography>
        </Grid>
        <Grid item sx={{ ml: 'auto', flexGrow: 1, textAlign: 'right' }}>
          {changes.map(({ key, previous, next }, i) => (
            <Tooltip
              title={(
                <Box style={{ minWidth: 100 }}>
                  <Typography>{dictionary.previous}:</Typography>
                  <Box component="pre" sx={{ background: mode === 'light' ? '#fc9d9d' : '#6e0000', p: 1, m: 0, mb: 1, maxHeight: 150, overflow: 'auto' }}>
                    {key === 'json_content' ? JSON.stringify(next, null, 2) : next}
                  </Box>
                  <Typography>{dictionary.next}:</Typography>
                  <Box component="pre" sx={{ background: mode === 'light' ? '#b5fc9d' : '#1b6c00', p: 1, m: 0, maxHeight: 150, overflow: 'auto' }}>
                    {key === 'json_content' ? JSON.stringify(previous, null, 2) : previous}
                  </Box>
                </Box>
              )}
            >
              <Chip
                size="small"
                sx={{ m: 0.5, background: key.includes('_draft') ? '#ffaf0050' : '#0057ff50', ...key === 'updated_at' && { background: undefined } }}
                key={i}
                label={getChangesLabel(key)}
              />
            </Tooltip>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};
