import React, { FC } from 'react';
import {
  Grid,
  Typography,
} from '@mui/material';

import { EditableContent } from 'shared/components/form/EditableContent/EditableContent';

export const SectionList: FC<any> = ({ document }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        {document?.section_set.map((section: any) => (
          <Typography variant="h5" key={section.slug}>
            <EditableContent section={section} siblings={document?.section_set} disableEnterKey>
              {section.title}
            </EditableContent>
          </Typography>
        ))}
      </Grid>
    </Grid>
  );
};
