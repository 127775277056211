import { BlockVariant } from 'clients/documents/documentsClient.types';
import { isOnlyItemInRow } from 'modules/Document/Article/helpers';

export const hasSubBlocksWithContent = (block: any) => {
  const subBlocksHaveContent = block.sub_block.some((subBlock: any) => {
    const subBlockHasContent = subBlock.title
                              || subBlock.text_content
                              || subBlock.variant === BlockVariant.Spreadsheet;
    return subBlockHasContent || hasSubBlocksWithContent(subBlock);
  });

  return subBlocksHaveContent;
};

export const hasContent = (block: any) => {
  const blockHasContent = block.title
                        || block.text_content
                        || block.variant === BlockVariant.Spreadsheet
                        || hasSubBlocksWithContent(block);

  return blockHasContent;
};

export const getBlockSm = (blocks: any, blockIndex: number) => {
  const blockPosition = blockIndex + 1;
  const blockCount = blocks.filter((block: any) => block.hasContent).length;
  
  if (blockCount === 1) {
    return undefined; 
  }
    
  if (!isOnlyItemInRow(blockCount, blockPosition, 2)) {
    return 6;
  }
      
  return undefined;
};
    
export const getBlockMd = (blocks: any, blockIndex: number) => {
  const blockPosition = blockIndex + 1;
  const blockCount = blocks.filter((block: any) => block.hasContent).length;
    
  if (blockCount <= 2) {
    return undefined;
  }
    
  if (!isOnlyItemInRow(blockCount, blockPosition, 3)) {
    return 6;
  }
    
  return undefined;
};

export const blockHasTables = (block: any) => {
  return block.variant === BlockVariant.Spreadsheet || block.sub_block.some((subBlock: any) => {
    return blockHasTables(subBlock);
  });
};
