import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { cacheKeys } from 'config';
import { LayoutContext } from 'contexts';
import { documentsClient } from 'clients/documents/documentsClient';

export const useQuestionCrud = () => {
  const { changesSaved, genericError } = useContext(LayoutContext);
  const queryClient = useQueryClient();

  const createQuestion = useMutation(documentsClient.createQuestion, {
    mutationKey: cacheKeys.documents.createQuestion,
    onSuccess: (result, request) => {
      queryClient.invalidateQueries(cacheKeys.documents.getDocument);
      queryClient.invalidateQueries(cacheKeys.documents.getDraftDocument);
      queryClient.invalidateQueries(cacheKeys.documents.searchQuestions);
      changesSaved();
    },
    onError: (result, request) => {
      genericError();
    },
  });

  const patchQuestion = useMutation(documentsClient.patchQuestion, {
    mutationKey: cacheKeys.documents.patchQuestion,
    onSuccess: (result, request) => {
      queryClient.invalidateQueries(cacheKeys.documents.getDocument);
      queryClient.invalidateQueries(cacheKeys.documents.getDraftDocument);
      queryClient.invalidateQueries(cacheKeys.documents.searchQuestions);
      changesSaved();
    },
    onError: (result, request) => {
      genericError();
    },
  });

  const deleteQuestion = useMutation(documentsClient.deleteQuestion, {
    mutationKey: cacheKeys.documents.deleteQuestion,
    onSuccess: (result, request) => {
      queryClient.invalidateQueries(cacheKeys.documents.getDocument);
      queryClient.invalidateQueries(cacheKeys.documents.getDraftDocument);
      queryClient.invalidateQueries(cacheKeys.documents.searchQuestions);

      changesSaved();
    },
    onError: (result, request) => {
      genericError();
    },
  });

  return {
    createQuestion,
    patchQuestion,
    deleteQuestion,
  };
};
