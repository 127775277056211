export enum ListFormattingType {
  Dot = 'dot',
  Dash = 'dash',
  ArabicNumbers = 'arabic_numbers',
  RomanNumerals = 'roman_numerals',
  RomanNumeralsCapital = 'roman_numerals_capital',
  Alphabet = 'alphabet',
  AlphabetCapital = 'alphabet_capital',
};

export type UnorderedListFormattingType =
  | typeof ListFormattingType.Dash
  | typeof ListFormattingType.Dot;

export type OrderedListFormattingType =
  | typeof ListFormattingType.Alphabet
  | typeof ListFormattingType.AlphabetCapital
  | typeof ListFormattingType.ArabicNumbers
  | typeof ListFormattingType.RomanNumerals
  | typeof ListFormattingType.RomanNumeralsCapital;

export enum BlockVariant {
  HtmlField = 'html_field',
  Text = 'text',
  Spreadsheet = 'spreadsheet',
  Explanation = 'explanation',
  Recommendation = 'recommendation',
}

export enum SearchResultType {
  ArticleTitle = 'article_title',
  BlockTitle = 'block_title',
  BlockTextContent = 'block_text_content',
}

export enum DocumentItemType {
  Cla = 'cla',
  Section = 'section',
  Article = 'article',
  Block = 'block',
}

export enum RenvoiMode {
  Normal = 'normal',
  Strikethrough = 'strikethrough',
  Removed = 'removed',
}

export type SearchOriginalItemsParams = {
  cla_slug: string;
  article?: boolean;
  search: string;
  section_id?: number;
}

export type SearchQuestionsParams = {
  cla_id: string;
  article?: boolean;
  search: string;
  limit?: number;
}

export type SearchOriginalResult = {
  id: number;
  title_draft: string;
  numbering_draft?: string;
  text_content_draft?: string;
  list_formatting_draft?: string;
  variant_draft?: string;
  is_important_draft?: string;
  is_renvoi_draft?: string;
  blocks_draft?: [{
    id: number;
    title_draft: string;
    text_content_draft: string;
  }];
}

export type MappedSearchOriginalResult = SearchOriginalResult & {
  stripped_title?: string;
}

export type QuestionLinkedItem = {
  title: string;
  title_draft: string;
  numbering?: string;
  numbering_draft?: string;
  slug?: string;
}

export type SearchQuestionResult = {
  id: number;
  question: string;
  answer: string;
  linked_articles: QuestionLinkedItem[],
  linked_blocks: QuestionLinkedItem[],
}

export type MappedQuestionLinkedItem = QuestionLinkedItem & {
  stripped_title?: string;
  stripped_title_draft?: string;
}

export type MappedQuestionResult = SearchQuestionResult & {
  linked_articles: MappedQuestionLinkedItem[],
  linked_blocks: MappedQuestionLinkedItem[],
}

export type CreateNoteData = {
  title: string,
  text?: string,
  created_by?: number,
  block?: number,
  article?: number,
}

export type UpdateNoteData = CreateNoteData

export type NotesParams = {
  cla_id?: string,
  limit?: number,
}

export type GetFilesParams = {
  section__in?: string,
  block__in?: string,
  article__in?: string,
  article?: number,
  block?: number,
  section?: number,
}
