import React, { FC, forwardRef, useMemo } from 'react';

import classnames from 'classnames';
import { ListFormattingType } from 'clients/documents/documentsClient.types';

type Props = {
  type: ListFormattingType;
  style: any;
  value: number;
  children: any;
  displayListFormatting?: boolean,
  showMarker?: boolean,
}

type ListItemProps = React.HTMLAttributes<HTMLLIElement | HTMLDivElement> & {
  value: number;
};

const Li: FC<ListItemProps> = forwardRef(({ children, style, ...props }, ref: any) => (
  <li
    ref={ref}
    style={{ paddingLeft: 10, ...style }}
    className="block-item"
    {...props}
  >
    {children}
  </li>
));

const Div: FC<ListItemProps> = forwardRef(({ children, style, ...props }, ref: any) => (
  <div
    ref={ref}
    style={{ ...style }}
    className="block-item"
    {...props}
  >
    {children}
  </div>
));

export const ListItem: FC<any> = forwardRef(({
  type,
  children,
  style,
  value,
  displayListFormatting,
  // avoid empty list items with indicator only for is_important
  showMarker=true,
}: Props, ref: any) => {
  const Element: any = useMemo(() => {
    if (!type) {
      return Div;
    }

    return Li;
  }, [type]);

  if (!showMarker) {
    return children;
  }

  return (
    <Element
      ref={ref}
      style={style}
      value={value}
      className={classnames({
        'non-list-item': !displayListFormatting,
      })}
    >
      {children}
    </Element>
  );
});
