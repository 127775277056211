import React, { FC, useContext } from 'react';
import { useQueryClient } from 'react-query';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { AddCircle, Delete, Edit } from '@mui/icons-material';
import { isEmpty } from 'lodash';
import { cacheKeys } from 'config';
import { DialogContext, LayoutContext, LocalizationContext } from 'contexts';
import { Dialog } from 'contexts/DialogContext/types';
import { documentsClient } from 'clients/documents/documentsClient';
import { NotesProps } from 'modules/Document/Notes/index';
import MultiAccordion from 'shared/components/MultiAccordion/MultiAccordion';
import FileList from 'shared/components/FileList/FileList';

const Notes: FC<NotesProps> = ({
  notes,
  disableAddButton = false,
}) => {
  const { dictionary } = useContext(LocalizationContext);
  const { asyncConfirmation } = useContext(DialogContext);

  const { writeMode, sendNotification, genericError, layoutTempState } = useContext(LayoutContext);

  const queryClient = useQueryClient();
  const { openDialog } = useContext(DialogContext);

  const onCreate = () => {
    openDialog(Dialog.NoteDialog, {
      key: layoutTempState?.FAQItem?.id,
      item: layoutTempState?.FAQItem,
      itemType: layoutTempState?.FAQItemType,
    });
  };

  const onEdit = (id: number) => {
    openDialog(Dialog.NoteDialog, {
      key: id,
      editNoteId: id,
      item: layoutTempState?.FAQItem,
      itemType: layoutTempState?.FAQItemType,
    });
  };

  const onDelete = async (id: number) => {
    const userConfirmed = await asyncConfirmation({
      title: dictionary.confirmation.defaultTitle,
      content: (
        <React.Fragment>
          {dictionary.confirmation.defaultContent}
        </React.Fragment>
      ),
    });

    if (userConfirmed) {
      try {
        await documentsClient.deleteNote(id);
        sendNotification(dictionary.notes.modal.notifications.deleted);
      } catch (error: any) {
        console.error(error);
        genericError();
      } finally {
        queryClient.invalidateQueries(cacheKeys.documents.getNotes);
      }
    }
  };

  return (
    <>
      <Box
        mb={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item ml="auto"/>
        {writeMode && (
          <Button
            sx={{
              'ml': '12px',
            }}
            variant="contained"
            endIcon={<AddCircle/>}
            disabled={disableAddButton}
            onClick={onCreate}
          >
            {dictionary.add}
          </Button>
        )}
      </Box>
      <Box maxHeight="calc(100vh - 440px)" overflow="auto">
        <MultiAccordion
          data={notes}
          emptyLabel={dictionary.notes.empty}
          renderSummary={(note: any, index: number, expandIndex: number) => (
            <>
              <Typography fontWeight={500}>{note.title}</Typography>
              <Box ml="auto"/>
              {expandIndex === index && writeMode && (
                <IconButton onClick={() => onEdit(note.id)}>
                  <Edit/>
                </IconButton>
              )}
              {expandIndex === index && writeMode && (
                <IconButton onClick={() => onDelete(note.id)}>
                  <Delete/>
                </IconButton>
              )}
            </>
          )}
          renderDetails={(note: any, index: number) => (
            <>
              {note.created_by_data && (
                <Typography
                  variant="subtitle2"
                  color="#878787"
                >
                  {note.created_by_data?.first_name} {note.created_by_data?.last_name}
                </Typography>
              )}
              <Typography
                variant="body1"
                sx={{ wordBreak: 'break-word' }}
                dangerouslySetInnerHTML={{ __html: note.text }}
              />

              {!isEmpty(note.file_set) && (
                <>
                  <Typography mt={3} fontWeight={400}>
                    {dictionary.document.files}:
                  </Typography>
                  <Box mt={1}>
                    <FileList
                      files={note.file_set}
                    />
                  </Box>
                </>
              )}
            </>
          )}
        />
      </Box>
      {/* the key is necessary to trigger the rerendering and pick up the new defaultValues*/}
      {/*<NoteDialog*/}
      {/*  key={editNoteId}*/}
      {/*  open={openDialog}*/}
      {/*  editNoteId={editNoteId}*/}
      {/*  onClose={() => {*/}
      {/*    setOpenDialog(false);*/}
      {/*    // state rerendering and cleanup*/}
      {/*    setEditNoteId(undefined);*/}
      {/*  }}*/}
      {/*/>*/}
    </>
  );
};

export default Notes;
