import React, { FC } from 'react';
import { Parser, ProcessNodeDefinitions } from 'html-to-react';

import isURL from 'validator/es/lib/isURL';
import ErrorBoundary from 'shared/components/layout/ErrorBoundary/ErrorBoundary';
import { ReferenceTooltip } from './ReferenceTooltip';
import { HighlightedText } from './HighlightedText';


const htmlParser = new Parser(React);
const processNodeDefinitions = new ProcessNodeDefinitions(React);

const isValidNode = () => {
  return true;
};


export const TransformedText: FC<any> = ({
  text = '',
  is_renvoi,
  is_full_renvoi,
  is_parent_full_renvoi,
}) => {

  // Custom instructions for processing nodes
  const processingInstructions = [
  // Create instruction for custom elements
    {
      shouldProcessNode: (node: any) => {
        // Process the node if it matches a custom element
        if (node.name !== 'a') {
          return false;
        }

        const href = node.attribs?.href || '';
        const title = node.attribs?.title || '';
        const hasTitleOrHref = href || title;

        return hasTitleOrHref && !isURL(href) && href !== '#' && !href.startsWith('#');
      },
      processNode: (node: any, children: any) => {
        const tooltipTitle = node?.attribs?.title || node?.attribs?.href;
        return (
          <ReferenceTooltip
            key={tooltipTitle}
            title={tooltipTitle}
            is_renvoi={is_renvoi}
            is_full_renvoi={is_full_renvoi}
            is_parent_full_renvoi={is_parent_full_renvoi}
          >
            <span>{children}</span>
          </ReferenceTooltip>
        );
      },
    },
    {
      shouldProcessNode: (node: any) => {
        // Process the node if it matches a custom element
        return node.name === 'span' && node?.attribs?.class && node?.attribs?.id;
      },
      processNode: (node: any, children: any) => {
        const title = node?.attribs?.id;
        return <HighlightedText slug={title}>{children[0]}</HighlightedText>;
      },
    },
    // Default processing
    {
      shouldProcessNode: () => true,
      processNode: processNodeDefinitions.processDefaultNode,
    },
  ];

  return (
    <ErrorBoundary passive>
      {
        htmlParser.parseWithInstructions(
          text,
          isValidNode,
          processingInstructions,
        ) || null
      }
    </ErrorBoundary>
  );
};
