import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { Button, Dialog, DialogContent, Grid, LinearProgress, Paper, TextField, Typography } from '@mui/material';

import { isEmpty } from 'lodash';
import { cacheKeys } from 'config';
import { DialogContext, LayoutContext, LocalizationContext, UserContext } from 'contexts';
import { Dialog as DialogType } from 'contexts/DialogContext/types';
import { DocumentItemType, QuestionLinkedItem } from 'clients/documents/documentsClient.types';
import { documentsClient } from 'clients/documents/documentsClient';
import { mapQuestion } from 'clients/documents/documentsClient.formatter';
import { useQuestionCrud } from 'shared/hooks/useQuestionCrud';
import { useQueryState } from 'shared/hooks/useQueryState';
import { HTMLEditor } from 'shared/components/form/HTMLEditor/HTMLEditor';
import { ArticleSearchAutocomplete } from 'shared/components/form/ArticleSearchAutocomplete/ArticleSearchAutocomplete';
import { BlockSearchAutocomplete } from 'shared/components/form/BlockSearchAutocomplete/BlockSearchAutocomplete';
import AttachmentEditableList from 'shared/components/form/AttachmentEditableList/AttachmentEditableList';

type FormData = {
  id?: number;
  question: string;
  answer: string;
  cla_id: string;
  linked_articles: QuestionLinkedItem[];
  linked_blocks: QuestionLinkedItem[];
}

export const QuestionAnswerDialog: FC<any> = ({
  open = false,
  onClose = () => null,
}) => {
  const queryClient = useQueryClient();
  const { dialogOptions } = useContext(DialogContext);
  const { language, dictionary } = useContext(LocalizationContext);
  const { setLayoutTempState, layoutTempState, writeMode } = useContext(LayoutContext);
  const { createQuestion, patchQuestion } = useQuestionCrud();
  const [slug = ''] = useQueryState('slug');

  const [newFiles, setNewFiles] = useState<any>([]);
  const [fileIdsToDelete, setFileIdsToDelete] = useState<any>([]);
  const [isSaving, setIsSaving] = useState(false);

  const { userPermissions } = useContext(UserContext);

  useEffect(() => {
    if (!writeMode) {
      onClose();
    }
  }, [onClose, writeMode]);

  const dialogData = useMemo(() => {
    const FAQDialogOptions = dialogOptions?.[DialogType.QuestionAnswerDialog];
    return {
      question: FAQDialogOptions?.question || {},
      questionIndex: FAQDialogOptions?.index ?? -1,
      itemType: FAQDialogOptions?.itemType,
      linkedItem: FAQDialogOptions?.linkedItem,
    };
  }, [dialogOptions]);

  const { handleSubmit, control, watch, formState: { errors } } = useForm<FormData>({
    defaultValues: {
      id: dialogData.question?.id,
      question: dialogData.question?.question || '',
      answer: dialogData.question?.answer || '',
      linked_articles: dialogData.itemType === DocumentItemType.Article ? [dialogData.linkedItem] : dialogData.question?.linked_articles || [],
      linked_blocks: dialogData.itemType === DocumentItemType.Block ? [dialogData.linkedItem] : dialogData.question?.linked_blocks || [],
      cla_id: slug,
    },
  });

  const id = watch('id');
  const linkedArticles = watch('linked_articles');
  const linkedBlocks = watch('linked_blocks');

  const validateLinkedItems = useCallback(() => {
    if (isEmpty(linkedArticles) && isEmpty(linkedBlocks)) {
      return dictionary.faq.linkedItemValidation;
    }
    return true;
  }, [dictionary, linkedArticles, linkedBlocks]);

  const onSubmit = useCallback(async (data: FormData) => {
    setIsSaving(true);
    try {
      const dataWithLanguage = {
        cla_id: data.cla_id,
        id: data.id,
        [`question_${language}`]: data.question,
        [`answer_${language}`]: data.answer,
        linked_articles: data.linked_articles,
        linked_blocks: data.linked_blocks,
      };

      let questionId: number | undefined = data.id;

      if (data.id) {
        await patchQuestion.mutateAsync(dataWithLanguage);
      } else {
        const newQuestion = await createQuestion.mutateAsync(dataWithLanguage);
        questionId = newQuestion.data.id;
      }

      const createNewFiles = newFiles.map(async (file: any) => {
        await documentsClient.createFile(
          {
            file,
            question_answer: questionId,
          },
        );
      });

      await Promise.all(createNewFiles);

      const deleteFiles = fileIdsToDelete.map(async (fileId: number) => {
        await documentsClient.deleteFile(fileId);
      });

      await Promise.all(deleteFiles);

      await queryClient.invalidateQueries(cacheKeys.documents.searchQuestions);

      let selectedFAQ = layoutTempState?.selectedFAQ || [];
      const responseQuestion = await documentsClient.getQuestion(
        questionId as number,
        slug,
      );

      // appends question to the current list whatever it is
      // makes assumption that it related to the current entity that isn't always a case
      const mappedQuestion = mapQuestion(responseQuestion.data);
      if (dialogData.questionIndex < 0) {
        selectedFAQ = [mappedQuestion, ...selectedFAQ];
      } else {
        selectedFAQ[dialogData.questionIndex] = mappedQuestion;
      }

      setLayoutTempState({
        ...layoutTempState,
        selectedFAQ: selectedFAQ,
      });

      onClose();
    } finally {
      setIsSaving(false);
    }
  }, [
    createQuestion, dialogData.questionIndex,
    fileIdsToDelete, language,
    layoutTempState, newFiles,
    onClose, patchQuestion,
    queryClient, setLayoutTempState,
    slug,
  ]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Paper>
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            {id ? dictionary.faq.editQA : dictionary.faq.addQA}
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction="column" spacing={3} mt={1}>
              <Grid item>
                <Controller
                  name="question"
                  control={control}
                  rules={{
                    required: dictionary.forms.validations.required,
                    maxLength: {
                      value: 255,
                      message: dictionary.forms.validations.maxLength(255),
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={dictionary.forms.fieldQuestion}
                      error={!!errors.question}
                      helperText={errors.question?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Controller
                  name="answer"
                  control={control}
                  render={({ field }) => (
                    <HTMLEditor
                      {...field}
                      wrapperStyle={{
                        minHeight: 200,
                        maxWidth: 500,
                      }}
                      label={dictionary.forms.fieldAnswer}
                      error={!!errors.answer}
                      helperText={errors.answer?.message}
                      tooltipsEnabled={false}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Controller
                  name="linked_articles"
                  control={control}
                  rules={{
                    validate: {
                      required: validateLinkedItems,
                    },
                  }}
                  render={({ field }) => (
                    <ArticleSearchAutocomplete
                      label={dictionary.faq.linkedArticles}
                      multiple
                      sx={{ width: 532 }}
                      TextFieldProps={{
                        error: !!errors.linked_articles,
                        helperText: (errors.linked_articles as any)?.message,
                      }}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Controller
                  name="linked_blocks"
                  control={control}
                  rules={{
                    validate: {
                      required: validateLinkedItems,
                    },
                  }}
                  render={({ field }) => (
                    <BlockSearchAutocomplete
                      label={dictionary.faq.linkedBlocks}
                      multiple
                      sx={{ width: 532 }}
                      TextFieldProps={{
                        error: !!errors.linked_blocks,
                        helperText: (errors.linked_articles as any)?.message,
                      }}
                      {...field}
                    />
                  )}
                />
              </Grid>

              <Grid
                item
              >
                <Typography
                  sx={{
                    fontSize: '0.75em',
                  }}
                  pl="12px"
                >
                  {dictionary.document.files}
                </Typography>
                <Grid container direction="column" spacing={2}>
                  <AttachmentEditableList
                    files={dialogData.question.file_set?.filter(({ id }: any) => (
                      !fileIdsToDelete.includes(id)),
                    )}
                    allowDelete={userPermissions?.delete_file}
                    onDelete={(id) => {
                      setFileIdsToDelete([
                        ...fileIdsToDelete,
                        id,
                      ]);
                    }}
                  />
                  <AttachmentEditableList
                    files={newFiles.map((file: any) => ({ file, isNew: true }))}
                    allowDelete={userPermissions?.delete_file}
                    onDelete={(id, file) => {
                      setNewFiles(
                        newFiles.filter((currentFile: any) => file !== currentFile),
                      );
                    }}
                  />
                  <Grid item xs={12}>
                    <TextField
                      style={{ border: '1px solid #999' }}
                      type="file"
                      value={undefined}
                      onChange={e => {
                        // @ts-ignore
                        const file = e?.target?.files?.[0];
                        setNewFiles(
                          [
                            ...newFiles,
                            file,
                          ],
                        );
                        e.target.value = '';
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  container
                  display="flex"
                  justifyContent="end"
                  spacing={2}
                >
                  <Grid item>
                    <Button
                      disabled={isSaving}
                      onClick={onClose}
                    >
                      {dictionary.dialogs.close}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      color="primary"
                      disabled={isSaving}
                    >
                      {dictionary.saveAndClose}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              {isSaving && (
                <LinearProgress/>
              )}
            </Grid>
          </form>
        </DialogContent>
      </Paper>
    </Dialog>
  );
};
