import { useQuery } from 'react-query';
import { cacheKeys } from 'config';
import { groupClient } from 'clients/groups/groupClient';

export const useGetAuthGroups = () => {
  const { data, status, error } = useQuery(
    cacheKeys.groups.getAuthGroups,
    groupClient.getAuthGroups,
  );

  return {
    data,
    status,
    error,
  };
};