import { Country } from 'types/Country';

export const dictionary = {
  home: 'Home',
  loading: 'Loading',
  results: 'Results',
  myProfile: 'My Profile',
  myAccount: 'My Account',
  subscriptions: 'Subscriptions',
  login: 'Log In',
  logOut: 'Log Out',
  signUp: 'Signup',
  fullName: 'Full Name',
  name: 'Name',
  surname: 'Surname',
  email: 'Email',
  phone: 'Phone',
  password: 'Password',
  thePasswordMustBeAtLeast8CharactersLong: 'The password must be at least 8 characters long',
  repeatPassword: 'Repeat Password',
  userWithThisEmailAlreadyExists: 'User with this email already exists',
  accountAlreadyExists: 'An account is already registered with this email.',
  please: 'Please',
  orUseAnotherEmail: 'or use another email.',
  passwordsDoNotMatch: 'Passwords do not match',
  signUpWithGoogle: 'Signup with Google',
  signUpWithMicrosoft: 'Signup with Microsoft',
  loginWithGoogle: 'Login with Google',
  loginWithMicrosoft: 'Login with Microsoft',
  lastUpdated: 'Last Updated',
  publishedDate: 'Published Date',
  publishDate: 'Publish Date',
  tableOfContents: 'Table of Contents',
  insertText: 'Insert text',
  share: 'Share',

  auth: {
    or: 'or',
    dontHaveAccount: 'Don\'t have an account?',
    alreadyHaveAccount: 'Already have an account?',
    buttonSignIn: 'Sign in',
    buttonCreateAccount: 'Create account',

    validation: {
      emailOrPasswordWrong: 'Email or password wrong',
    },
  },

  impersonate: {
    noToken: 'Token is not present',
    invalidToken: 'Token is invalid or expired',
  },

  somethingWentWrong: 'Something went wrong!',
  oneOrMoreFieldsAreIncorrect: 'One or more fields are incorrect.',
  edit: 'Edit',
  open: 'Open',
  delete: 'Delete',
  save: 'Save',
  saveAndClose: 'Save and close',
  view: 'View',
  date: 'Date',
  dueDate: 'Due Date',
  dates: 'Dates',
  time: 'Time',
  duration: 'Duration',
  comingSoon: 'Coming Soon',
  toggleLighDarkMode: 'Toggle Light/Dark Mode',
  previous: 'Previous',
  next: 'Next',

  hour: 'Hour',
  hours: 'Hours',
  minutes: 'Minutes',

  resetPassword: 'Reset Password',
  reset: 'Reset',
  passwordResetEmailSent: 'Password Reset Email Sent',

  notFound: 'Not Found',

  progress: 'Progress',
  complete: 'Complete',
  downloads: 'Downloads',

  join: 'Join',

  darkMode: 'Dark Mode',
  lightMode: 'Light Mode',

  switchToDarkMode: 'Switch to Dark Mode',
  switchToLightMode: 'Switch to Light Mode',

  draft: 'Draft',
  in_review: 'In Review',
  approved: 'Approved',

  download: 'Download',

  search: 'Search',
  notifications: 'Notifications',
  seeNotifications: 'See Notifications',

  hello: 'Hello',
  assignee: 'Assignee',
  status: 'Status',

  cancel: 'Cancel',
  all: 'All',
  submit: 'Submit',

  description: 'Description',
  attachments: 'Attachments',

  resetFilters: 'Reset Filters',
  type: 'Type',

  no: 'No',
  yes: 'Yes',

  none: 'None',

  noResults: 'There are no results matching your selection',

  changesSavedSuccessfully: 'Changes saved successfully!',

  export: 'Export as',
  toggleFilters: 'Toggle Filters',
  section: 'Section',
  partial: 'Partial',
  full: 'Full',

  add: 'Add',

  confirmation: {
    defaultTitle: 'Are you sure you want to do this?',
    defaultContent: 'This action is irreversible.',
    cannotEditTitle: 'Cannot edit item',
    cannotEditDuplicateContent: 'This item is a duplicate, to edit this item you have to edit the original item.',
    cannotPublishTitle: 'Cannot publish item',
    cannotPublishDuplicateContent: 'This item is a duplicate, to publish this item you have to publish the original item.',
    cannotDeleteOriginalTitle: 'Cannot delete item',
    cannotDeleteOriginalContent: 'This item has duplicates, you cannot delete it until you delete all its duplicates.',
    no: 'No',
    yes: 'Yes',
    ok: 'Ok',
  },

  welcome: {
    title: 'Thank you for signing up.',
    subtitle: 'You should receive a confirmation email shortly.',
    buttonStart: 'Get started',
  },

  company: {
    accountManagerLabel: 'Account Manager',
    primaryContactLabel: 'Primary Contact',
    selectCompanyLabel: 'View Projects',
    title: 'Company',

    create: {
      title: 'Create Company',
      buttonCreate: 'Create Company',
    },

    edit: {
      addUserTitle: (company: string) => `Add user to ${company}`,
      buttonEdit: 'Edit Company',
      buttonDelete: 'Delete Company',
      buttonAddUser: 'Add User',
      deleteConfirmation: 'Are you sure you want to delete this Company?',
      editTheCompanyName: 'Edit the company name',
      placeholderCompanyName: 'Company name',
      feedbackCompanyNameUpdated: 'The company name has been updated successfully.',
    },
  },

  companies: {
    title: 'Companies',
  },

  user: {
    title: 'User',
    typeCustomerLabel: 'Customer',
    typeAdminLabel: 'Admin',

    create: {
      title: 'Create User',
      buttonCreate: 'Create User',
    },

    edit: {
      buttonEdit: 'Edit User',
      buttonDelete: 'Delete User',
      deleteConfirmation: 'Are you sure you want to delete this User?',
      deleteOwnAccountConfirmation: 'Are you sure you want to delete your account?',
    },

    companyLabel: 'Company',
    jobTitleLabel: 'Job Title',
    adminLabel: 'Admin',
  },

  users: {
    title: 'Users',
  },

  document: {
    form: {
      fieldSearch: 'Search',
      noMatchingArticles: 'There are no articles matching your search',
      typeToSearch: 'Type to search',
    },
    files: 'Files',
    footnotesTitle: 'Footnotes',
    duplicateTag: 'duplicate of',
  },

  faq: {
    faq: 'FAQ and Notes',
    linkedArticles: 'Linked Articles',
    linkedBlocks: 'Linked Blocks',
    searchArticles: 'Search Articles',
    searchBlocks: 'Search Blocks',
    frequentlyAskedQuestions: 'Frequently Asked Questions',
    relatedBlocks: 'Related Blocks',
    relatedArticles: 'Related Articles',
    addQA: 'Add FAQ',
    editQA: 'Edit FAQ',
    linkedItemValidation: 'You have to provide at least 1 linked item',
    empty: 'There are no questions',
  },

  notes: {
    notes: 'Notes',
    empty: 'There are no notes',
    title: 'Title',
    text: 'Text',

    modal: {
      addTitle: 'Add note',
      editTitle: 'Edit note',

      notifications: {
        created: 'The note was successfully created.',
        edited: 'The note was successfully edited.',
        deleted: 'The note was successfully deleted.',
        error: 'Something went wrong during the request.',
      },
    },
  },

  filesOverview: {
    title: 'Files Overview',
    empty: 'There are no files',
  },

  printDialog: {
    title: 'Export as',
    form: {
      entireDocument: 'Entire document',
      version: 'Version',
      buttonLabels: {
        printFull: 'Print',
        printPartial: 'Print Partially',
        docxFull: 'Export',
        docxPartial: 'Export Partially',
      },
    },
    format: {
      print: 'Print / PDF',
    },
  },

  team: {
    title: 'Team members',
    detailsLabel: 'Details',
    jobTitleLabel: 'Job title',
    openQueriesLabel: 'Open queries',
    notificationsLabel: 'Email notifications',
    unknownLabel: 'Unknown job title',
    buttonInvite: 'Invite team members',
  },

  invite: {
    dialog: {
      title: 'Invite team members',
      placeholderEmail: 'Email, comma separated',
      buttonInvite: 'Send invite',
      invitationSentInfo: (email: string) => `Invitation to ${email} sent`,
      userType: 'User Type',
    },

    title: 'Team invitation',
    subtitle: (company?: string) => company ? `You've been invited to join ${company}'s team` : 'You\'ve been invited to join a team',
    buttonAcceptInvitation: 'Create account',
  },

  menu: {
    home: 'Home',
    documents: 'Documents',
    help: 'Help',
    faq: 'FAQ',
    dashboard: 'Dashboard',
    companies: 'Companies',
    users: 'Users',
    team: 'Team',
    manual: 'Manual',
  },

  filters: {
    search: 'Search',
    label: 'Filters',
    fieldStatus: 'Status',
    fieldDateFrom: 'Date from',
    fieldDateTo: 'Date to',
    fieldImportantOnly: 'Only A-provisions',
    buttonSort: 'Sort',
    buttonFilter: 'Filter',

    transaction: {
      fieldScope: 'Scope',
    },
  },

  dialogs: {
    defaultTitle: 'Are you sure you want to do this?',
    defaultContent: 'This action is irreversible.',
    no: 'No',
    yes: 'Yes',
    cancel: 'Cancel',
    close: 'Close',
    buttonCancel: 'Cancel',
  },

  forms: {
    fieldEmail: 'Email',
    fieldPassword: 'Password',
    fieldPhone: 'Phone',
    fieldCountry: 'Country',
    fieldCompany: 'Company',
    fieldTitle: 'Title',
    fieldSubtitle: 'Subtitle',
    fieldDescription: 'Description',
    fieldAuthGroup: 'User Group',
    fieldTooltip: 'Tooltip',
    fieldQuestion: 'Question',
    fieldAnswer: 'Answer',
    copyUrlToShare: 'Copy Url to Share',
    colorPickerInvalidError: 'Must be valid hex color that starts with "#"',

    signup: {
      fieldRepeatPassword: 'Repeat Password',
    },

    company: {
      fieldName: 'Name',
      fieldAccountManager: 'Account Manager',
      fieldPrimaryContact: 'Primary Contact',
      fieldPreferredLanguage: 'Preferred Language',
    },

    user: {
      fieldFirstName: 'Name',
      fieldLastName: 'Surname',
      fieldType: 'Type',
      fieldJobTitle: 'Job title',
      fieldAdmin: 'Admin',
      fieldPicture: 'Upload picture',

      fieldEmailDisabledHelper: 'Please contact support to change the email.',
    },

    validations: {
      required: 'This Field is Required',
      titleOrDescriptionRequired: 'Please enter the title or description',
      invalidDate: 'Date is Invalid',
      invalidEmail: 'Email is Invalid',
      invalidPhone: 'Phone number is Invalid',
      invalidPassword: 'Password is Invalid. At least 1 number is required.',
      invalidFileSize: (max: string) => `File is too big. Maximum is ${max}`,
      invalidFileType: 'File type is not valid',
      minLength: (length: number | string) => `Need to have at least ${length} characters`,
      maxLength: (length: number | string) => `Can't have more than ${length} characters`,

      memberInvitationAllEmailsValid: 'One or more emails is invalid.',
      companyContactRequired: 'Company requires an account manager and primary contact',
    },
    delete: 'Delete',
    save: 'Save',
    attach: 'Attach',
    upload: 'Upload',
    saveChanges: 'Save changes',
  },

  routes: {
    home: 'Home',
    login: 'Login',
    signup: 'Signup',
    documents: 'Documents',
    document: 'Document',
    publicDocument: 'Document',
    documentPrintPreview: 'Document',
    team: 'Team',
    myAccount: 'My Account',
    invite: 'Invite',
    resetPassword: 'Reset Password',
  },

  editableContent: {
    title: 'Title',
    copyLink: 'Copy Link',
    scrollOppositeWindow: 'Scroll to in Opposite Window',
    linkCopiedSuccessfully: 'Link Copied to Clipboard',
    order: 'Order',
    numbering: 'Numbering',
    body: 'Body',
    type: 'Type',
    sibling: 'Sibling',
    child: 'Child',
    variant: 'Variant',
    descendants: 'Descendants',
    childrenFormat: 'Children Format',
    text: 'Text',
    html: 'HTML',
    explanation: 'Explanation',
    recommendation: 'Recommendation',
    spreadsheet: 'Spreadsheet',
    none: 'None',
    dot: 'Dot',
    dash: 'Dash',
    arabicNumbers: 'Numbers',
    romanNumerals: 'Roman Numerals',
    alphabetLowercase: 'Alphabet (Lowercase)',
    alphabetUppercase: 'Alphabet (Uppercase)',
    add: 'Add',
    addCla: 'Add CLA',
    addSection: 'Add Section',
    addArticle: 'Add Article',
    addContent: 'Add Content',
    addSiblingSectionOrChildArticle: 'Add Sibling Section or Child Article',
    addTooltip: 'Add tooltip',
    editTooltip: 'Edit tooltip',
    addDuplicate: (title: string) => `Add Duplicate: ${title}`,
    addDuplicateButton: 'Add Duplicate',
    edit: 'Edit',
    editCla: 'Edit CLA',
    editSection: 'Edit Section',
    editArticle: 'Edit Article',
    editContent: 'Edit Content',
    delete: 'Delete',
    deleteCla: 'Delete CLA',
    deleteSection: 'Delete Section',
    deleteArticle: 'Delete Article',
    deleteContent: 'Delete Content',
    removeTooltip: 'Remove tooltip',
    sort: 'Sort',
    nest: 'Nest',
    unNest: 'Un-Nest',
    files: 'Files',
    saveChanges: 'Save Changes',
    viewHistory: 'View History',
    isImportant: 'A-Provision', //FIXME: Is specific to metalektro
    remove: 'Remove',
    renvoi: 'Renvoi partially',
    renvoi_helptext: 'In this mode, you can select what texts you want to renvoi by striking it through with your hands.',
    full_renvoi: 'Renvoi everything',
    full_renvoi_helptext: 'In this mode, you will renvoi all texts including texts of all descendants.',
    renvoi_disclaimer: 'You can\'t edit its renvoi fields if the parent is renvoi. You should make parent not renvoi, and renvoi each descendant article or block manually.',
    sectionTitlesColor: 'Titles color',
    is_toc_include_children: 'Include children in the ToC',
    is_toc_include_children_helptext: 'If checked will display all 1-level children titles as Table of Contents items.',
    displayListFormatting: 'List format',
    showMore: 'Show More',
    publish: 'Publish',
    unknown: 'Unknown',
    questionAnswer: 'Add FAQ',
    note: 'Add Note',
    editInDifferentLanguage: 'Edit content in a different language',
    errorUnsavedChangesOnTranslationsSwitch: 'This field was modified, save the form before editing other translations',
  },

  editableTable: {
    columnActions: 'Column Actions',
    table: 'Table',
    noLabel: 'No Label',
    writeMode: 'Write Mode',
    addColumnBefore: 'Add Column Before',
    addColumnAfter: 'Add Column After',
    duplicateColumn: 'Duplicate Column',
    deleteColumn: 'Delete Column',
    sortRows: 'Sort Rows',
    sortColumns: 'Sort Columns',
    addRowBefore: 'Add Row Before',
    addRowAfter: 'Add Row After',
    deleteRow: 'Delete Row',
    duplicateRow: 'Duplicate Row',
  },

  renvoiMode: {
    normal: 'Renvoi text not strikethrough',
    strikethrough: 'Renvoi visible as strikethrough',
    removed: 'Renvoi text removed',
  },

  documentStatuses: {
    read: 'Published',
    write: 'Draft',
    review: 'Review',
  },

  languages: {
    language: 'Language',
    english: 'English',
    dutch: 'Dutch',
    switchToEnglish: 'Switch to English',
    switchToDutch: 'Overschakelen naar Nederlands',
  },

  documentShare: {
    modal: {
      title: 'Public Document share',
      mode: 'Mode',
      modes: {
        normal: 'Normal',
        draft: 'Draft',
      },
      link: 'URL link',
      textCopied: 'URL link copied to the clipboard',
      copyLink: 'Copy link',
    },
  },

  publicDocument: {
    error: {
      title: 'You are not allowed to view this draft document',
      description: 'Seems that your link is expired or invalid. Ask your supervisor to share the new link again or log in and generate link by yourself.',
    },
  },

  authGroups: {
    moderator: 'Moderator',
    customer: 'Customer',
    admin: 'Admin',
    controller: 'Controller',
  },

  countries: {
    [Country.Andorra]: 'Andorra',
    [Country.UnitedArabEmirates]: 'United Arab Emirates',
    [Country.Afghanistan]: 'Afghanistan',
    [Country.AntiguaAndBarbuda]: 'Antigua and Barbuda',
    [Country.Anguilla]: 'Anguilla',
    [Country.Albania]: 'Albania',
    [Country.Armenia]: 'Armenia',
    [Country.Angola]: 'Angola',
    [Country.Antarctica]: 'Antarctica',
    [Country.Argentina]: 'Argentina',
    [Country.AmericanSamoa]: 'American Samoa',
    [Country.Austria]: 'Austria',
    [Country.Australia]: 'Australia',
    [Country.Aruba]: 'Aruba',
    [Country.AlandIslands]: 'Alland Islands',
    [Country.Azerbaijan]: 'Azerbaijan',
    [Country.BosniaAndHerzegovina]: 'Bosnia and Herzegovina',
    [Country.Barbados]: 'Barbados',
    [Country.Bangladesh]: 'Bangladesh',
    [Country.Belgium]: 'Belgium',
    [Country.BurkinaFaso]: 'Burkina Faso',
    [Country.Bulgaria]: 'Bulgaria',
    [Country.Bahrain]: 'Bahrain',
    [Country.Burundi]: 'Burundi',
    [Country.Benin]: 'Benin',
    [Country.BonaireSintEustatiusSaba]: 'Bonaire, Sint Eustatius and Saba',
    [Country.SaintBarthelemy]: 'Saint Barthelemy',
    [Country.Bermuda]: 'Bermuda',
    [Country.BruneiDarussalam]: 'Brunei Darussalam',
    [Country.Bolivia]: 'Bolivia',
    [Country.Brazil]: 'Brazil',
    [Country.Bahamas]: 'Bahamas',
    [Country.Bhutan]: 'Bhutan',
    [Country.BouvetIsland]: 'Bouvet Island',
    [Country.Botswana]: 'Botswana',
    [Country.Belarus]: 'Belarus',
    [Country.Belize]: 'Belize',
    [Country.Canada]: 'Canada',
    [Country.CocosKeelingIslands]: 'Cocos (Keeling) Islands',
    [Country.CentralAfricanRepublic]: 'Central African Republic',
    [Country.Congo]: 'Congo',
    [Country.CongoDemocraticRepublic]: 'Congo, Democratic Republic of the',
    [Country.Switzerland]: 'Switzerland',
    [Country.CoteDIvoire]: 'Cote d\'Ivoire',
    [Country.CookIslands]: 'Cook Islands',
    [Country.Chile]: 'Chile',
    [Country.Cameroon]: 'Cameroon',
    [Country.China]: 'China',
    [Country.Colombia]: 'Colombia',
    [Country.CostaRica]: 'Costa Rica',
    [Country.Cuba]: 'Cuba',
    [Country.CapeVerde]: 'Cape Verde',
    [Country.Curacao]: 'Curacao',
    [Country.ChristmasIsland]: 'Christmas Island',
    [Country.Cyprus]: 'Cyprus',
    [Country.CzechRepublic]: 'Czech Republic',
    [Country.Germany]: 'Germany',
    [Country.Djibouti]: 'Djibouti',
    [Country.Denmark]: 'Denmark',
    [Country.Dominica]: 'Dominica',
    [Country.DominicanRepublic]: 'Dominican Republic',
    [Country.Algeria]: 'Algeria',
    [Country.Ecuador]: 'Ecuador',
    [Country.Estonia]: 'Estonia',
    [Country.Egypt]: 'Egypt',
    [Country.WesternSahara]: 'Western Sahara',
    [Country.Eritrea]: 'Eritrea',
    [Country.Spain]: 'Spain',
    [Country.Ethiopia]: 'Ethiopia',
    [Country.Finland]: 'Finland',
    [Country.Fiji]: 'Fiji',
    [Country.FalklandIslands]: 'Falkland Islands (Malvinas)',
    [Country.Micronesia]: 'Micronesia, Federated States of',
    [Country.FaroeIslands]: 'Faroe Islands',
    [Country.France]: 'France',
    [Country.Gabon]: 'Gabon',
    [Country.UnitedKingdom]: 'United Kingdom',
    [Country.Grenada]: 'Grenada',
    [Country.Georgia]: 'Georgia',
    [Country.FrenchGuiana]: 'French Guiana',
    [Country.Guernsey]: 'Guernsey',
    [Country.Ghana]: 'Ghana',
    [Country.Gibraltar]: 'Gibraltar',
    [Country.Greenland]: 'Greenland',
    [Country.Gambia]: 'Gambia',
    [Country.Guinea]: 'Guinea',
    [Country.Guadeloupe]: 'Guadeloupe',
    [Country.EquatorialGuinea]: 'Equatorial Guinea',
    [Country.Greece]: 'Greece',
    [Country.SouthGeorgiaAndSandwichIsl]: 'South Georgia and the South Sandwich Islands',
    [Country.Guatemala]: 'Guatemala',
    [Country.Guam]: 'Guam',
    [Country.GuineaBissau]: 'Guinea-Bissau',
    [Country.Guyana]: 'Guyana',
    [Country.HongKong]: 'Hong Kong',
    [Country.HeardIslandMcdonaldIslands]: 'Heard Island and McDonald Islands',
    [Country.Honduras]: 'Honduras',
    [Country.Croatia]: 'Croatia',
    [Country.Haiti]: 'Haiti',
    [Country.Hungary]: 'Hungary',
    [Country.Indonesia]: 'Indonesia',
    [Country.Ireland]: 'Ireland',
    [Country.Israel]: 'Israel',
    [Country.IsleOfMan]: 'Isle of Man',
    [Country.India]: 'India',
    [Country.BritishIndianOceanTerritory]: 'British Indian Ocean Territory',
    [Country.Iraq]: 'Iraq',
    [Country.Iran]: 'Iran, Islamic Republic of',
    [Country.Iceland]: 'Iceland',
    [Country.Italy]: 'Italy',
    [Country.Jersey]: 'Jersey',
    [Country.Jamaica]: 'Jamaica',
    [Country.Jordan]: 'Jordan',
    [Country.Japan]: 'Japan',
    [Country.Kenya]: 'Kenya',
    [Country.Kyrgyzstan]: 'Kyrgyzstan',
    [Country.Cambodia]: 'Cambodia',
    [Country.Kiribati]: 'Kiribati',
    [Country.Comoros]: 'Comoros',
    [Country.SaintKittsAndNevis]: 'Saint Kitts and Nevis',
    [Country.KoreaDemocraticPeoplesRepublic]: 'Korea, Democratic People\'s Republic of',
    [Country.Korea]: 'Korea, Republic of',
    [Country.Kuwait]: 'Kuwait',
    [Country.CaymanIslands]: 'Cayman Islands',
    [Country.Kazakhstan]: 'Kazakhstan',
    [Country.LaoPeoplesDemocraticRepublic]: 'Lao People\'s Democratic Republic',
    [Country.Lebanon]: 'Lebanon',
    [Country.SaintLucia]: 'Saint Lucia',
    [Country.Liechtenstein]: 'Liechtenstein',
    [Country.SriLanka]: 'Sri Lanka',
    [Country.Liberia]: 'Liberia',
    [Country.Lesotho]: 'Lesotho',
    [Country.Lithuania]: 'Lithuania',
    [Country.Luxembourg]: 'Luxembourg',
    [Country.Latvia]: 'Latvia',
    [Country.LibyanArabJamahiriya]: 'Libya',
    [Country.Morocco]: 'Morocco',
    [Country.Monaco]: 'Monaco',
    [Country.Moldova]: 'Moldova, Republic of',
    [Country.Montenegro]: 'Montenegro',
    [Country.SaintMartin]: 'Saint Martin (French part)',
    [Country.Madagascar]: 'Madagascar',
    [Country.MarshallIslands]: 'Marshall Islands',
    [Country.Macedonia]: 'Macedonia, the Former Yugoslav Republic of',
    [Country.Mali]: 'Mali',
    [Country.Myanmar]: 'Myanmar',
    [Country.Mongolia]: 'Mongolia',
    [Country.Macao]: 'Macao',
    [Country.NorthernMarianaIslands]: 'Northern Mariana Islands',
    [Country.Martinique]: 'Martinique',
    [Country.Mauritania]: 'Mauritania',
    [Country.Montserrat]: 'Montserrat',
    [Country.Malta]: 'Malta',
    [Country.Mauritius]: 'Mauritius',
    [Country.Maldives]: 'Maldives',
    [Country.Malawi]: 'Malawi',
    [Country.Mexico]: 'Mexico',
    [Country.Malaysia]: 'Malaysia',
    [Country.Mozambique]: 'Mozambique',
    [Country.Namibia]: 'Namibia',
    [Country.NewCaledonia]: 'New Caledonia',
    [Country.Niger]: 'Niger',
    [Country.NorfolkIsland]: 'Norfolk Island',
    [Country.Nigeria]: 'Nigeria',
    [Country.Nicaragua]: 'Nicaragua',
    [Country.Netherlands]: 'Netherlands',
    [Country.Norway]: 'Norway',
    [Country.Nepal]: 'Nepal',
    [Country.Nauru]: 'Nauru',
    [Country.Niue]: 'Niue',
    [Country.NewZealand]: 'New Zealand',
    [Country.Oman]: 'Oman',
    [Country.Panama]: 'Panama',
    [Country.Peru]: 'Peru',
    [Country.FrenchPolynesia]: 'French Polynesia',
    [Country.PapuaNewGuinea]: 'Papua New Guinea',
    [Country.Philippines]: 'Philippines',
    [Country.Pakistan]: 'Pakistan',
    [Country.Poland]: 'Poland',
    [Country.SaintPierreAndMiquelon]: 'Saint Pierre and Miquelon',
    [Country.Pitcairn]: 'Pitcairn',
    [Country.PuertoRico]: 'Puerto Rico',
    [Country.PalestinianTerritory]: 'Palestine, State of',
    [Country.Portugal]: 'Portugal',
    [Country.Palau]: 'Palau',
    [Country.Paraguay]: 'Paraguay',
    [Country.Qatar]: 'Qatar',
    [Country.Reunion]: 'Reunion',
    [Country.Romania]: 'Romania',
    [Country.Serbia]: 'Serbia',
    [Country.RussianFederation]: 'Russian Federation',
    [Country.Rwanda]: 'Rwanda',
    [Country.SaudiArabia]: 'Saudi Arabia',
    [Country.SolomonIslands]: 'Solomon Islands',
    [Country.Seychelles]: 'Seychelles',
    [Country.Sudan]: 'Sudan',
    [Country.Sweden]: 'Sweden',
    [Country.Singapore]: 'Singapore',
    [Country.SaintHelena]: 'Saint Helena',
    [Country.Slovenia]: 'Slovenia',
    [Country.SvalbardAndJanMayen]: 'Svalbard and Jan Mayen',
    [Country.Slovakia]: 'Slovakia',
    [Country.SierraLeone]: 'Sierra Leone',
    [Country.SanMarino]: 'San Marino',
    [Country.Senegal]: 'Senegal',
    [Country.Somalia]: 'Somalia',
    [Country.Suriname]: 'Suriname',
    [Country.SouthSudan]: 'South Sudan',
    [Country.SaoTomeAndPrincipe]: 'Sao Tome and Principe',
    [Country.ElSalvador]: 'El Salvador',
    [Country.SintMaarten]: 'Sint Maarten (Dutch part)',
    [Country.SyrianArabRepublic]: 'Syrian Arab Republic',
    [Country.Swaziland]: 'Swaziland',
    [Country.TurksAndCaicosIslands]: 'Turks and Caicos Islands',
    [Country.Chad]: 'Chad',
    [Country.FrenchSouthernTerritories]: 'French Southern Territories',
    [Country.Togo]: 'Togo',
    [Country.Thailand]: 'Thailand',
    [Country.Tajikistan]: 'Tajikistan',
    [Country.Tokelau]: 'Tokelau',
    [Country.TimorLeste]: 'Timor-Leste',
    [Country.Turkmenistan]: 'Turkmenistan',
    [Country.Tunisia]: 'Tunisia',
    [Country.Tonga]: 'Tonga',
    [Country.Turkey]: 'Turkey',
    [Country.TrinidadAndTobago]: 'Trinidad and Tobago',
    [Country.Tuvalu]: 'Tuvalu',
    [Country.Taiwan]: 'Taiwan, Province of China',
    [Country.Tanzania]: 'United Republic of Tanzania',
    [Country.Ukraine]: 'Ukraine',
    [Country.Uganda]: 'Uganda',
    [Country.UnitedStates]: 'United States',
    [Country.UnitedStatesOutlyingIslands]: 'United States Minor Outlying Islands',
    [Country.Uruguay]: 'Uruguay',
    [Country.Uzbekistan]: 'Uzbekistan',
    [Country.HolySeeVaticanCityState]: 'Holy See (Vatican City State)',
    [Country.SaintVincentAndGrenadines]: 'Saint Vincent and the Grenadines',
    [Country.Venezuela]: 'Venezuela',
    [Country.VirginIslandsBritish]: 'British Virgin Islands',
    [Country.VirginIslandsUS]: 'US Virgin Islands',
    [Country.Vietnam]: 'Vietnam',
    [Country.Vanuatu]: 'Vanuatu',
    [Country.WallisAndFutuna]: 'Wallis and Futuna',
    [Country.Samoa]: 'Samoa',
    [Country.Kosovo]: 'Kosovo',
    [Country.Yemen]: 'Yemen',
    [Country.Mayotte]: 'Mayotte',
    [Country.SouthAfrica]: 'South Africa',
    [Country.Zambia]: 'Zambia',
    [Country.Zimbabwe]: 'Zimbabwe',
  },
};
