import React, { Fragment, useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { routes } from 'config';
import { UserContext } from 'contexts/UserContext/UserContext';
import { isPublicWebsite } from 'helpers/html';
import { useScrollTop } from 'shared/hooks/useScrollTop';
import { useQueryState } from 'shared/hooks/useQueryState';
import { Authenticated } from 'shared/components/Authenticated/Authenticated';
import { NotAuthenticated } from 'shared/components/Authenticated/NotAuthenticated';
import { Nav } from 'shared/components/layout/Nav/Nav';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';
import { SharedLayoutComponent } from 'shared/components/layout/SharedLayoutComponent/SharedLayoutComponent';

import { Login } from 'views/LoggedOut/Login';
import { Home } from 'views/LoggedIn/Home/Home';
import { Documents } from './LoggedIn/Documents/Documents';
import { DocumentRouteView } from './LoggedIn/Document/Document';
import { Team } from './LoggedIn/Team/Team';
import { Profile } from './LoggedIn/User/Profile';
import { Invite } from './LoggedOut/Invite';
import { ResetPassword } from './LoggedOut/ResetPassword';
import { DocumentPrintPreview } from './LoggedIn/DocumentPrintPreview/DocumentPrintPreview';
import { DocumentPublicView } from './LoggedOut/Document';
import { PublicDocumentPrintPreview } from './LoggedOut/PublicDocumentPrintPreview';


const Public404 = () => (
  <div
    style={{
      fontFamily: 'Arial, sans-serif',
      backgroundColor: '#f8f8f8',
      color: '#333',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      margin: 0,
    }}
  >
    <div
      style={{
        textAlign: 'center',
        maxWidth: '600px',
        padding: '20px',
        border: '1px solid #ddd',
        backgroundColor: '#fff',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
      }}
    >
      <h1 style={{ fontSize: '48px', margin: 0 }}>404 Not Found</h1>
      <p style={{ fontSize: '18px', margin: '10px 0' }}>The page you are looking for does not exist.</p>
      <p style={{ fontSize: '18px', margin: '10px 0' }}>
        Consider visiting{' '}
        <a href="https://www.cao-metalektro.nl" style={{ color: '#007bff', textDecoration: 'none' }}>
          https://www.cao-metalektro.nl
        </a>
      </p>
    </div>
  </div>
);


export const Root = () => {
  useScrollTop();
  const { loading } = useContext(UserContext);
  const [hideNav] = useQueryState('hide-nav');

  const publicDocumentElement = (
    <>
      {!hideNav && (
        <Nav
          showUserMenu={false}
          logoRoute={null}
        />
      )}
      <DocumentPublicView/>
    </>
  );

  if (loading) {
    return <BodyLoading height="100vh"/>;
  }

  return (
    <Fragment>
      <NotAuthenticated>
        <Routes>
          <Route path={routes.login} element={<Login/>}/>
          <Route path={routes.invite} element={<Invite/>}/>
          <Route path={routes.resetPassword} element={<ResetPassword/>}/>
          {/* TODO if you need to do the same again please make a reusable solution */}
          {/*  or even introduce the json settings, because now it becomes hard to manage */}
          <Route
            path={routes.publicDocument}
            element={publicDocumentElement}
          />
          <Route path={routes.documentPrintPreview} element={<PublicDocumentPrintPreview/>}/>
          <Route
            path="/"
            element={isPublicWebsite() ? publicDocumentElement : <Navigate to={routes.login}/>}
          />
          <Route path="*" element={isPublicWebsite() ? (<Public404/>) : <Navigate to={routes.login}/>}/>
        </Routes>
      </NotAuthenticated>
      <Authenticated>
        {!hideNav && <Nav/>}
        <Routes>
          <Route path={routes.team} element={<Team/>}/>
          <Route path={routes.documents} element={<Documents/>}/>
          <Route path={routes.document} element={<DocumentRouteView/>}/>
          <Route path={routes.documentPrintPreview} element={<DocumentPrintPreview/>}/>
          <Route path={routes.publicDocument} element={<DocumentPublicView/>}/>
          <Route path={routes.myAccount} element={<Profile/>}/>
          <Route path={routes.home} element={<Home/>}/>
          <Route path="*" element={<Navigate to={routes.home}/>}/>
        </Routes>
      </Authenticated>
      <SharedLayoutComponent/>
    </Fragment>
  );
};
