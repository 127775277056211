import React, { FC } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import { SupportedLanguage } from 'contexts/LocalizationContext/types';
import { BlockVariant } from 'clients/documents/documentsClient.types';
import { documentsClient } from 'clients/documents/documentsClient';
import {
  DefaultDocumentEntityFormValuesParsersItems,
  DocumentContentEntityType,
  DocumentEntityType,
  FetchDocumentContentEntityMethodItem,
} from './types';


export const shouldUseHtmlEditor = (variant: BlockVariant, isAddingArticle: boolean, titleFieldValue: boolean) => {
  const htmlVariants = [
    BlockVariant.HtmlField,
    BlockVariant.Explanation,
    BlockVariant.Recommendation,
    BlockVariant.Spreadsheet,
  ];

  return htmlVariants.includes(variant) || isAddingArticle || titleFieldValue;
};

const fetchMethods: FetchDocumentContentEntityMethodItem = {
  // 'cla': documentsClient.getBlock,
  'article': documentsClient.getArticle,
  'section': documentsClient.getSection,
  'block': documentsClient.getBlock,
};

export const fetchDocumentEntity: any = (documentContentEntityType: DocumentContentEntityType, documentEntityId: number) => {
  const fetchMethod = fetchMethods[documentContentEntityType];
  return fetchMethod(documentEntityId);
};

const fieldNameWithSuffix = (fieldName: string, language: SupportedLanguage) => {
  return `${fieldName}_draft_${language}`;
};

export const getDocumentEntityFieldValuesInLanguageParser = (
  documentEntityType: DocumentEntityType,
) => {
  const parsers: DefaultDocumentEntityFormValuesParsersItems = {
    cla: (language: SupportedLanguage, data: any) => {
      return {
        title: data[fieldNameWithSuffix('title', language)],
        description: data[fieldNameWithSuffix('subtitle', language)],
      };
    },
    section: (language: SupportedLanguage, data: any) => {
      return {
        title: data[fieldNameWithSuffix('title', language)] || '',
      };
    },
    article: (language: SupportedLanguage, data: any) => {
      return {
        title: data[fieldNameWithSuffix('title', language)] || '',
      };
    },
    block: (language: SupportedLanguage, data: any) => {
      return {
        title: data[fieldNameWithSuffix('title', language)] || '',
        description: data[fieldNameWithSuffix('text_content', language)],
      };
    },
  };

  return parsers[documentEntityType];
};


export const FieldLabelWithTooltip: FC<any> = ({
  label,
  tooltipTitle,
  tooltipDisclaimer,
}) => (
  <>
    {label}
    <Tooltip
      title={(
        <>
          <p>
            {tooltipTitle}
          </p>
          <p
            style={{
              color: 'rgba(0, 0, 0, 0.6)',
            }}
          >
            {tooltipDisclaimer}
          </p>
        </>
      )}>
      <IconButton>
        <HelpOutlineRoundedIcon/>
      </IconButton>
    </Tooltip>
  </>
);
