import React, { FC } from 'react';
import { Box, Grid, Paper } from '@mui/material';

import { Logo } from 'shared/components/layout/Logo/Logo';
import { LanguageSwitch } from 'shared/components/form/LanguageSwitch/LanguageSwitch';


export const LoggedOutActionsLayout: FC = ({ children }) => (
  <Grid
    container
    direction="column"
    spacing={2}
    sx={theme => ({
      position: 'relative',
      overflow: 'visible',
      padding: theme.spacing(20),
      minHeight: '100vh',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(2),
      },
    })}
  >
    <Grid item>
      <Logo style={{ width: 300, maxWidth: '100%', height: 'auto' }}/>
    </Grid>
    <Grid
      item
      sx={theme => ({
        minWidth: 500,
        [theme.breakpoints.down('sm')]: {
          minWidth: '100%',
        },
      })}
    >
      <Paper variant="elevation" elevation={5}>
        <Box p={4}>
          {children}
        </Box>
      </Paper>
      <Box mt={2}/>
    </Grid>
    <Grid item>
      <LanguageSwitch/>
    </Grid>
  </Grid>
);
