import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useOnLeavePage = (onLeavePage: () => void) => {
  const location = useLocation();

  useEffect(() => {
    return () => {
      onLeavePage();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
};
