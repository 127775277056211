import React, { FC, useContext, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { Controller } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import { Box, IconButton, TextField } from '@mui/material';
import { green } from '@mui/material/colors';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { LocalizationContext } from 'contexts';


const BorderlessTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }}/>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: 0,
    margin: 0,
    borderRadius: '8px',
  },
}));

export const ColorPicker: FC<any> = ({
  value,
  onChange,
  label,
  ...props
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const toggleOpenModal = () => {
    setOpenModal(!openModal);
  };

  const picker = (
    <HexColorPicker
      color={value || ''}
      onChange={onChange}
    />
  );

  return (
    <>
      <Box
        display="inline-flex"
        width={160}
      >
        <BorderlessTooltip
          title={picker}
          open={openModal}
          style={{
            margin: 0,
            padding: 0,
          }}
        >
          <TextField
            {...props}
            label={label}
            value={value}
            variant="standard"
            onClick={toggleOpenModal}
            onChange={e => onChange(e.target.value)}
            InputProps={{
              startAdornment: (
                <span
                  style={{
                    background: value,
                    display: 'inline-block',
                    width: '21px',
                    height: '17px',
                    marginRight: '6px',
                  }}
                />
              ),
              endAdornment: (
                <IconButton
                  style={{
                    padding: 0,
                  }}
                >
                  <CheckBoxIcon
                    sx={{
                      fill: green[500],
                      width: '21px',
                      height: openModal ? '21px' : '0px',
                    }}
                  />
                </IconButton>
              ),
            }}
          />
        </BorderlessTooltip>
      </Box>
    </>
  );

};


export const ColorPickerController: FC<any> = (props) => {
  const { dictionary } = useContext(LocalizationContext);

  return (
    <Controller
      rules={{
        validate: v => {
          if (!v) {
            return true;
          }
          const validated = /^#[0-9A-F]{6}$/i.test(v);
          if (!validated) {
            return dictionary.forms.colorPickerInvalidError;
          }
          return true;
        },
      }}
      {...props}
    />
  );
};
