import React, { FC, Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import StickyBox from 'react-sticky-box';
import { useQueryClient } from 'react-query';
import {
  Grid,
  Typography,
  Box,
  Hidden,
  Paper,
  FormGroup,
  FormControlLabel,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Button,
  Tooltip,
  TextField,
  MenuItem,
  Switch,
  Alert,
  AlertTitle,
} from '@mui/material';
import {
  FilterAlt,
  Fullscreen,
  Publish,
  ViewCompact,
  Article,
  Compare,
  Edit,
  QuestionAnswerOutlined,
  FilePresent,
} from '@mui/icons-material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { useBoundStore } from 'store';
import {
  setShowFilesOverview,
  toggleShowDocumentSidebar,
  toggleShowFilters,
  setShowRightContainer,
} from 'store/DocumentTemplate';
import { setDraftPreviewMode, setMode } from 'store/DocumentMode';
import { find } from 'lodash';
import { setRenvoiMode } from 'store/DocumentSearch';
import { primaryLight } from 'shared/styles/muiTheme';
import { setHistoryData } from 'store/Document';
import { cacheKeys } from 'config';
import {
  DialogContext,
  DocumentPublishContext,
  DocumentPublishContextProvider,
  LayoutContext,
  LocalizationContext,
  UserContext,
} from 'contexts';
import { DocumentMode } from 'types/Document';
import { Dialog } from 'contexts/DialogContext/types';
import { DocumentPrintContextProvider } from 'contexts/DocumentPrintContext/DocumentPrintContext';
import { documentsClient } from 'clients/documents/documentsClient';

import { RenvoiMode } from 'clients/documents/documentsClient.types';
import { SectionMenu } from 'modules/Document/SectionMenu/SectionMenu';
import { MobileDocumentNav } from 'modules/Document/SectionMenu/MobileDocumentNav';
import { SearchResultsNav } from 'modules/Document/SearchResultsNav/SearchResultsNav';
import { SearchInput } from 'modules/Document/SearchInput/SearchInput';
import { RenvoiModeDropDown } from 'modules/Document/RenvoiModeDropdown/RenvoiModeDropDown';
import { ImportantOnlyDropDown } from 'modules/Document/ImportantOnlyDropDown/ImportantOnlyDropDown';
import FAQAndNotesContainer from 'modules/Document/FAQAndNotesContainer/FAQAndNotesContainer';
import { FilesOverviewContainer } from 'modules/Document/FilesOverviewContainer';
import { DocumentRightContainer } from 'modules/Document/DocumentRightContainer/DocumentRightContainer';
import { useLazyHashInitScrollEffect } from 'shared/hooks/useLazyHashInitScrollEffect';

import { useQueryState } from 'shared/hooks/useQueryState';
import { useDocument, useDocumentHistoryV2, usePrefetchDocument } from 'shared/hooks/useDocument';
import { useOnLeavePage } from 'shared/hooks/useOnLeavePage';
import { useNotes } from 'shared/hooks/useNotes';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';
import { DocumentShareButton } from './components/DocumentShareButton';
import { RenderDocumentContainer, RenderedDocument } from './components/RenderedDocument';

const prefetch = false;
const showSectionList = false;

type DocumentProps = {
  mode?: boolean;
  isPrintMode?: boolean;
  isPrintPreview?: boolean;
};

export const DocumentWithContexts: FC<DocumentProps> = ({
  mode: documentModeProp,
  isPrintMode,
  isPrintPreview,
}) => {

  const queryClient = useQueryClient();
  const {
    mode,
    writeMode,
    reviewMode,
    readMode,
    documentMode,
    setDocumentMode,
    isMobile,
    isLightMode,
    changesSaved,
    genericError,
    showFAQ,
    setShowFAQ,
    setLayoutTempState,
    layoutTempState,
  } = useContext(LayoutContext);
  const { asyncConfirmation, openDialog } = useContext(DialogContext);
  const { userPermissions } = useContext(UserContext);
  const { dictionary } = useContext(LocalizationContext);
  const {
    articlesToPublish,
    sectionsToPublish,
    partialPublish,
    setPartialPublish,
    reset,
  } = useContext(DocumentPublishContext);
  const [slug] = useQueryState('slug');
  const [importantOnly] = useQueryState('important-only');
  const [hideNav] = useQueryState('hide-nav');
  const [sectionId, setSectionId] = useQueryState('section-id');
  const showFilesOverview = useBoundStore((state) => state.showFilesOverview);

  const [isPublishing, setIsPublishing] = useState(false);

  const showDocumentSidebar = useBoundStore((state) => state.showDocumentSidebar);

  const showFilters = useBoundStore((state) => state.showFilters);

  const showRightContainer = useBoundStore((state) => state.showRightContainer);

  const { document, draftDocument, error, status } = useDocument({
    slug,
    importantOnly: !!importantOnly,
  });

  useNotes();

  const renvoiMode = useBoundStore((state) => state.renvoiMode);

  const toggleFAQ = useCallback(() => {
    setShowFAQ((previousShowFAQ: boolean) => {
      if (!previousShowFAQ) {
        setLayoutTempState({
          ...(layoutTempState || {}),
          selectedFAQ: [],
          FAQItemType: undefined,
          FAQItem: undefined,
        });
        setShowFilesOverview(false);
      }

      setShowRightContainer(!previousShowFAQ);

      return !previousShowFAQ;
    });
  }, [layoutTempState, setLayoutTempState, setShowFAQ]);

  const publishDocument = useCallback(async () => {
    if (slug) {
      try {
        const userConfirmed = await asyncConfirmation({
          title: dictionary.confirmation.defaultTitle,
          content: dictionary.confirmation.defaultContent,
          confirmLabel: dictionary.yes,
        });
        setIsPublishing(true);
        if (userConfirmed) {
          await documentsClient.publishDocument(slug, {
            ...partialPublish ? {
              sections: sectionsToPublish,
              articles: articlesToPublish,
              recursive_block_publish: true,
              recursive_article_publish: false,
              partial_publish: true,
              publish_cla_metadata: false,
            } : {
              recursive_block_publish: true,
              recursive_article_publish: true,
              partial_publish: false,
              publish_cla_metadata: true,
            },
          });
          await Promise.all([
            queryClient.invalidateQueries(cacheKeys.history.getHistory),
            queryClient.invalidateQueries(cacheKeys.documents.getDocument),
            queryClient.invalidateQueries(cacheKeys.documents.getDocuments),
            queryClient.invalidateQueries(cacheKeys.documents.getDraftDocument),
            queryClient.invalidateQueries(cacheKeys.documents.getSearchDropdownData),
          ]);
          changesSaved();
          reset();
        }
      } catch (e) {
        genericError();
        console.error(e);
      }
      setIsPublishing(false);
    }
  }, [genericError, reset, partialPublish, sectionsToPublish, articlesToPublish, dictionary, slug, asyncConfirmation, queryClient, changesSaved]);

  const draftSections = useMemo(() => {
    if (!reviewMode && sectionId !== undefined && draftDocument?.section_set && draftDocument?.section_set?.[sectionId]) {
      return [draftDocument?.section_set?.[sectionId]];
    }
    return draftDocument?.section_set;
  }, [reviewMode, sectionId, draftDocument]);

  const sections = useMemo(() => {
    const section = find(document?.section_set, { 'id': parseInt(sectionId as string) });
    if (!reviewMode && sectionId !== undefined && document?.section_set && section) {
      setSectionId(section.id);
      return [section];
    }
    return document?.section_set;
  }, [reviewMode, sectionId, setSectionId, document]);

  usePrefetchDocument({ slug, importantOnly: !importantOnly }, { enabled: prefetch && status === 'success' });

  useLazyHashInitScrollEffect();

  useEffect(() => {
    const newDocumentMode = documentModeProp || documentMode;
    setMode(newDocumentMode);
    if (!isPrintPreview && !isPrintMode) {
      setRenvoiMode(
        newDocumentMode === (DocumentMode.Read || DocumentMode.Review)
          ? RenvoiMode.Normal
          : RenvoiMode.Strikethrough,
      );
    }
  }, [documentModeProp, documentMode, isPrintPreview, isPrintMode]);

  useEffect(() => {
    setDraftPreviewMode(!!isPrintMode);
  }, [isPrintMode]);

  const {
    data: historyData,
    refetch: refetchHistory,
    status: historyStatus,
  } = useDocumentHistoryV2({ slug }, { enabled: false });

  useEffect(() => {
    if (reviewMode) {
      refetchHistory();
    }
  }, [reviewMode, refetchHistory]);

  useEffect(() => {
    if (historyStatus === 'success') {
      setHistoryData(historyData);
    }
  }, [historyData, historyStatus]);

  const onPrintStart = useCallback(() => {
    openDialog(Dialog.PrintDocument, { showVersionFilters: true });
  }, [openDialog]);

  if (status === 'error') {
    return (
      <Alert
        severity="error"
        sx={{
          m: 4,
        }}
      >
        <AlertTitle>
          {dictionary.publicDocument.error.title}
        </AlertTitle>
        {dictionary.publicDocument.error.description}
      </Alert>
    );
  }

  if (error) {
    genericError();
    return null;
  }

  if (status !== 'success' || isPublishing) {
    return <BodyLoading/>;
  }

  return (
    <Box bgcolor={mode === 'light' ? '#ececec' : '#111'}>
      {!isPrintMode && (
        <StickyBox
          key={isMobile}
          offsetTop={hideNav ? 0 : (isMobile ? 56 : 100)}
          style={{ zIndex: 100 }}
          className="no-print"
        >
          <Paper
            sx={{
              p: 2,
              background: primaryLight,
            }}
          >

            {/* todo make a shared header */}

            <Grid
              container
              alignItems="center"
              wrap={!isMobile ? 'nowrap' : undefined}
              spacing={2}
            >
              <Fragment>
                {!reviewMode && (
                  <Fragment>
                    <Grid item>
                      <IconButton onClick={toggleShowDocumentSidebar}>
                        {showDocumentSidebar ? <Fullscreen/> : <ViewCompact/>}
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <Tooltip title={dictionary.export}>
                        <IconButton onClick={onPrintStart} edge="start">
                          <CloudDownloadIcon/>
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Fragment>
                )}
                <Grid item>
                  <Tooltip title={dictionary.toggleFilters}>
                    <IconButton onClick={toggleShowFilters} edge="start">
                      <FilterAlt/>
                    </IconButton>
                  </Tooltip>
                </Grid>
                {showFilters && (
                  <Fragment>
                    {(!reviewMode && (document || draftDocument)) && (
                      <Grid item xs={12} sm="auto">
                        <TextField
                          label={dictionary.section}
                          size="small"
                          sx={{ width: 200 }}
                          defaultValue={sectionId}
                          onChange={e => setSectionId(e.target.value === 'all' ? null : e.target.value.toString())}
                          select
                        >
                          <MenuItem value="all">
                            {dictionary.all}
                          </MenuItem>
                          {(writeMode ? draftDocument : document)?.section_set?.map((section: any, i: number) => (
                            <MenuItem
                              key={section.slug}
                              value={section.id}
                            >
                              {section?.stripped_title}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    )}
                    {!reviewMode && (
                      <Grid item xs={12}>
                        <SearchInput document={document}/>
                      </Grid>
                    )}
                    <Grid item xs={12} sm="auto">
                      <SearchResultsNav/>
                    </Grid>
                    <Grid item xs={12} sm="auto" textAlign="right" sx={{ ml: -2 }}>
                      <ImportantOnlyDropDown/>
                    </Grid>
                    <Grid item xs={12} sm="auto" textAlign="right" sx={{ ml: -2 }}>
                      <RenvoiModeDropDown/>
                    </Grid>
                  </Fragment>
                )}
              </Fragment>

              {reviewMode && !!userPermissions?.publish_cla && (
                <Fragment>
                  <Grid item xs={12} sm="auto">
                    <Button
                      onClick={publishDocument}
                      disabled={isPublishing}
                      endIcon={<Publish style={{ fill: '#ececec' }}/>}
                    >
                      {dictionary.editableContent.publish}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm="auto" sx={{ mr: -2 }}>
                    <FormGroup>
                      <FormControlLabel
                        control={(
                          <Switch
                            checked={partialPublish}
                            onChange={e => setPartialPublish(e.target.checked)}
                          />
                        )}
                        label={<Typography
                          variant="subtitle2">{partialPublish ? dictionary.partial : dictionary.full}</Typography>}
                      />
                    </FormGroup>
                  </Grid>
                </Fragment>
              )}
              <Box ml="auto"/>

              {!!userPermissions?.share_cla && (
                <Grid item>
                  <Tooltip title={dictionary.share}>
                    <DocumentShareButton
                      slug={slug}
                    />
                  </Tooltip>
                </Grid>
              )}

              <Grid item>
                <ToggleButtonGroup
                  size="small"
                  value={[!!showFAQ, showFilesOverview]}
                  onChange={(e, value) => {
                  }}
                  exclusive
                >
                  {!!userPermissions?.view_questionanswer && !reviewMode && (
                    <ToggleButton value={[true, false]} selected={!!showFAQ && showRightContainer}
                      onMouseDown={toggleFAQ}>
                      <Tooltip title={dictionary.faq.faq}>
                        <QuestionAnswerOutlined/>
                      </Tooltip>
                    </ToggleButton>
                  )}
                  {!!userPermissions?.view_file && !reviewMode && (
                    <ToggleButton
                      value={[false, true]}
                      selected={showFilesOverview && showRightContainer}
                      onMouseDown={() => {
                        if (showRightContainer && showFAQ) {
                          setShowFilesOverview(true);
                          setShowRightContainer(true);
                          setShowFAQ(false);
                        } else if (showRightContainer && showFilesOverview) {
                          setShowFilesOverview(false);
                          setShowRightContainer(false);
                        } else {
                          setShowFilesOverview(true);
                          setShowRightContainer(true);
                        }
                      }}
                    >
                      <Tooltip title={dictionary.document.files}>
                        <FilePresent/>
                      </Tooltip>
                    </ToggleButton>
                  )}
                </ToggleButtonGroup>
              </Grid>

              {/*old faq*/}
              {/*{!!userPermissions?.view_questionanswer && !reviewMode && (*/}
              {/*  <Grid item>*/}
              {/*    <Tooltip title={dictionary.faq.faq}>*/}
              {/*      <IconButton onMouseDown={toggleFAQ} size="small">*/}
              {/*        <QuestionAnswerOutlined/>*/}
              {/*      </IconButton>*/}
              {/*    </Tooltip>*/}
              {/*  </Grid>*/}
              {/*)}*/}

              {/*old files*/}
              {/*{!!userPermissions?.view_file && !reviewMode && (*/}
              {/*  <Grid item>*/}
              {/*    <Tooltip title={dictionary.document.files}>*/}
              {/*      <IconButton*/}
              {/*        onMouseDown={() => {*/}
              {/*          if (showRightContainer && showFAQ) {*/}
              {/*            setShowFilesOverview(true);*/}
              {/*            setShowRightContainer(true);*/}
              {/*            setShowFAQ(false);*/}
              {/*          } else if (showRightContainer && showFilesOverview) {*/}
              {/*            setShowFilesOverview(false);*/}
              {/*            setShowRightContainer(false);*/}
              {/*          } else {*/}
              {/*            setShowFilesOverview(true);*/}
              {/*            setShowRightContainer(true);*/}
              {/*          }*/}
              {/*        }}*/}
              {/*        size="small"*/}
              {/*      >*/}
              {/*        <FilePresent/>*/}
              {/*      </IconButton>*/}
              {/*    </Tooltip>*/}
              {/*  </Grid>*/}
              {/*)}*/}

              {!!userPermissions?.change_cla && (
                <Grid item xs={12} sm="auto">
                  <ToggleButtonGroup
                    size="small"
                    value={documentModeProp || documentMode}
                    onChange={(e, value) => {
                      window.scrollTo({ top: 0 });
                      setDocumentMode(value || DocumentMode.Read);
                    }}
                    exclusive
                  >
                    <ToggleButton value={DocumentMode.Read}>
                      <Tooltip title={dictionary.documentStatuses.read}>
                        <Article/>
                      </Tooltip>
                    </ToggleButton>
                    <ToggleButton value={DocumentMode.Write}>
                      <Tooltip title={dictionary.documentStatuses.write}>
                        <Edit/>
                      </Tooltip>
                    </ToggleButton>
                    <ToggleButton value={DocumentMode.Review}>
                      <Tooltip title={dictionary.documentStatuses.review}>
                        <Compare/>
                      </Tooltip>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              )}
            </Grid>
          </Paper>
        </StickyBox>
      )}
      <Box
        p={isPrintMode ? undefined : 2}
        pt={isPrintMode ? undefined : 4}
        className={`renvoi-mode-${renvoiMode} print-document-container`}
        id="document"
      >
        <Box
          sx={{
            paddingLeft: isPrintMode ? 0 : undefined,
            paddingRight: isPrintMode ? 0 : undefined,
          }}
        >
          <Grid
            container
            alignItems="top"
            wrap="nowrap"
            spacing={isPrintMode ? undefined : 1}
            display="flex"
            justifyContent="center"
          >
            {(showDocumentSidebar && !reviewMode && !isPrintMode) && (
              <Fragment>
                <Hidden mdDown>
                  <Grid
                    item
                    className="no-print"
                    sx={{
                      mr: 2,
                      maxWidth: '480px',
                      minWidth: '300px',
                    }}
                  >
                    <StickyBox
                      offsetTop={190}
                      style={{ zIndex: 50 }}
                    >
                      <Box textAlign="center" sx={{ mb: 2 }}>
                        <Typography variant="h6">{dictionary.tableOfContents}</Typography>
                      </Box>
                      <Box maxHeight="calc(100vh - 280px)" overflow="auto">
                        <SectionMenu document={writeMode ? draftDocument : document}/>
                      </Box>
                    </StickyBox>
                  </Grid>
                </Hidden>
                <Hidden mdUp>
                  <MobileDocumentNav document={writeMode ? draftDocument : document}/>
                </Hidden>
              </Fragment>
            )}
            {((reviewMode || readMode) && document) && (
              <RenderDocumentContainer
                reviewMode={reviewMode}
                isPrintMode={isPrintMode}
                showDocumentSidebar={showDocumentSidebar}
                showRightContainer={showRightContainer}
                id="document-published"
              >
                {/* to avoid props overloading let's keep grid element at this level */}
                <RenderedDocument
                  document={document}
                  sections={sections}
                  reviewMode={reviewMode}
                  reviewModeTitle={dictionary.documentStatuses.read}
                  showSectionList={showSectionList}
                  isLightMode={isLightMode}
                  isCanEditTitle={userPermissions?.rename_cla || false}
                  isPrintPreview={isPrintPreview}
                />
              </RenderDocumentContainer>
            )}
            {((reviewMode || writeMode) && draftDocument) && (
              <RenderDocumentContainer
                reviewMode={reviewMode}
                isPrintMode={isPrintMode}
                showDocumentSidebar={showDocumentSidebar}
                showRightContainer={showRightContainer}
                id="document-draft"
              >
                <RenderedDocument
                  document={draftDocument}
                  sections={draftSections}
                  reviewMode={reviewMode}
                  reviewModeTitle={dictionary.documentStatuses.write}
                  showSectionList={showSectionList}
                  isLightMode={isLightMode}
                  isCanEditTitle={userPermissions?.rename_cla || false}
                  isPrintPreview={isPrintPreview}
                />
              </RenderDocumentContainer>
            )}

            <DocumentRightContainer
              show={showRightContainer}
              onClose={() => {
                setShowRightContainer(false);
                setShowFAQ(false);
                setShowFilesOverview(false);
              }}
            >
              {showFAQ && !reviewMode && (
                <FAQAndNotesContainer/>
              )}

              {showFilesOverview && !reviewMode && (
                <FilesOverviewContainer
                  document={writeMode ? draftDocument : document}
                />
              )}
            </DocumentRightContainer>

          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export const Document: FC<DocumentProps> = (props) => {
  return (
    <DocumentPublishContextProvider>
      <DocumentPrintContextProvider isPrintMode={!!props.isPrintMode} isPrintPreview={!!props.isPrintPreview}>
        <DocumentWithContexts {...props}/>
      </DocumentPrintContextProvider>
    </DocumentPublishContextProvider>
  );
};

export const DocumentRouteView: FC = () => {
  const { layoutTempState, setLayoutTempState, setShowFAQ } = useContext(LayoutContext);

  const onLeavePage = useCallback(() => {
    setLayoutTempState({
      ...layoutTempState,
      itemsToPrint: {},
      selectedFAQ: [],
      FAQItem: undefined,
      FAQItemType: undefined,
    });
    setShowFAQ(false);
  }, [layoutTempState, setLayoutTempState, setShowFAQ]);

  useOnLeavePage(onLeavePage);

  return (
    <Document/>
  );
};
