import React, { forwardRef, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ReactDataSheet from 'react-datasheet';
import { Box, TextField } from '@mui/material';
import { isArray } from 'lodash';

import { useBoundStore } from 'store';
import { LayoutContext, LocalizationContext, UserContext } from 'contexts';
import { documentsClient } from 'clients/documents/documentsClient';
import { getAddOrDeleteEntityColorForHistory, getModifiedEntityColorForHistory } from 'modules/Document/helpers';
import { EditableContent } from 'shared/components/form/EditableContent/EditableContent';

import { Row } from './Row';
import { Cell } from './Cell';
import { SheetRenderer } from './SheetRenderer';
import { CellValueViewer } from './CellValueViewer';


export const SpreadSheet: FC<any> = forwardRef(({ block, parent, siblings, style = {} }, ref: any) => {

  const { language } = useContext(LocalizationContext);
  const { userPermissions } = useContext(UserContext);
  const { writeMode, setSharedPopupOpen, setLayoutTempState } = useContext(LayoutContext);

  const draftPreviewMode = useBoundStore((state) => state.draftPreviewMode);

  //FIXME: This is temporary to fix broken tables we had previously inserted
  const mappedData = useMemo(() => {
    const mappedData = block?.json_content;
    if (isArray(mappedData?.style?.[0]) && isArray(mappedData?.style?.[1])) {
      mappedData.style = mappedData.style[1];
    }
    return mappedData;
  }, [block]);

  const [data, setData] = useState(mappedData);
  const [edits, setEdits] = useState(0);
  const [autoSave, setAutoSave] = useState(false);

  useEffect(() => {
    if (mappedData) {
      setData(mappedData);
    }
  }, [mappedData]);

  const onCellsChanged = useCallback((cells: any[]) => {
    for (const cell of cells) {
      const { col, row, value } = cell;
      setData((data: any) => {
        data.values[row][col].value = value;
        setEdits(p => p + 1);
        setAutoSave(true);
        return data;
      });
    }
    return cells;
  }, []);

  const onAddRow = useCallback((index = undefined, duplicate = false) => {
    setData((data: any) => {
      const newRow = data.values[duplicate ? index : 0].map((value: any) => ({ value: '', ...duplicate && value }));
      const values = [...data.values];
      if (index !== undefined) {
        values.splice(index, 0, newRow);
      }
      const newValues = index ? values : [...values, newRow];
      setEdits(p => p++);
      setAutoSave(true);
      return {
        ...data,
        values: newValues,
      };
    });
  }, []);

  const onDeleteRow = useCallback((index) => {
    setData((data: any) => {
      const columns = data.values?.[0]?.length || 1;
      const values = [...data.values];
      values.splice(index, 1);
      if (values.length === 0) {
        values.push(new Array(columns).fill('').map(value => ({ value })));
      }
      setEdits(p => p + 1);
      setAutoSave(true);
      return {
        ...data,
        values,
      };
    });
  }, []);

  const onAddColumn = useCallback((index = undefined, duplicate = false) => {
    setData((data: any) => {
      const columns = [...data.style];
      const newColumn = { label: '' };
      if (duplicate) {
        newColumn.label = columns[index].label;
      }
      columns.splice(index, 0, newColumn);
      setEdits(p => p + 1);
      setAutoSave(true);
      return {
        ...data,
        values: data.values.map((rows: any) => {
          const newColumn = { value: '' };
          if (duplicate) {
            newColumn.value = rows[index].value;
          }
          rows.splice(index, 0, newColumn);
          return rows;
        }),
        style: columns.map((column: any) => ({ ...column, width: undefined })),
      };
    });
  }, []);

  const onDeleteColumn = useCallback((index) => {
    setData((data: any) => {
      const columns = [...data.style];
      columns.splice(index, 1);
      let newValues = data.values.map((rows: any) => {
        rows.splice(index, 1);
        return rows;
      });
      if (columns.length === 0) {
        columns.push({ label: '' });
        newValues = [[{ value: '' }]];
      }
      setEdits(p => p + 1);
      setAutoSave(true);
      return {
        ...data,
        values: newValues,
        style: columns,
      };
    });
  }, []);

  const onHeaderChange = useCallback((index, label) => {
    setData((data: any) => {
      const columns = [...data.style];
      columns[index].label = label;
      setEdits(p => p + 1);
      setAutoSave(true);
      return {
        ...data,
        style: columns,
      };
    });
  }, []);

  const onSort = useCallback((columnIndex: number, type: 'columns' | 'rows') => {
    const items = type === 'rows' ? (
      data.values.map((columns: any) => ({ title: columns[columnIndex].value, columns }))
    ) : (
      data.style.map((column: any, i: number) => ({ ...column, title: column.label, previousIndex: i }))
    );
    setSharedPopupOpen(true);
    setLayoutTempState({
      sortContent: true,
      items,
      onSave: (sorted: any) => {
        setData((data: any) => {
          setEdits(p => p + 1);
          setAutoSave(true);
          if (type === 'rows') {
            return {
              ...data,
              values: sorted.map(({ columns }: any) => columns),
            };
          }
          if (type === 'columns') {
            const changes: any = [];
            const newColumns = sorted.map(({ title, previousIndex, ...column }: any, newIndex: number) => {
              changes.push({ from: previousIndex, to: newIndex });
              return column;
            });
            return {
              ...data,
              style: newColumns,
              values: data.values.map((columns: any) => changes.map(({ from }: any) => columns[from])),
            };
          }
          return data;
        });
      },
    });
  }, [data, setSharedPopupOpen, setLayoutTempState]);

  const grid = useMemo(() => {
    return data?.values?.map((a: any) => {
      return a?.map((cell: any) => Object.assign(cell, {
        readOnly: draftPreviewMode || !writeMode || !userPermissions?.change_block,
      }));
    });
  }, [data?.values, draftPreviewMode, writeMode, userPermissions]);

  useEffect(() => {
    if (autoSave) {
      documentsClient.patchBlock(block.id, { [`json_content_draft_${language}`]: data });
      setAutoSave(false);
    }
  }, [autoSave, language, edits, data, block]);

  if (!data) {
    return null;
  }

  const modifiedColor = getModifiedEntityColorForHistory(block.id, 'block', 'json_content');
  const newOrDeletedColor = getAddOrDeleteEntityColorForHistory(block.id, 'block');

  return (
    <Box
      ref={ref}
      sx={{
        overflowX: 'auto',
        width: '100%',
        mt: 1,
        mb: 1,
        ml: '4px',
        border: (!!modifiedColor || !!newOrDeletedColor) ? `8px solid ${newOrDeletedColor || modifiedColor}` : undefined,
        ...style,
      }}
    >
      <ReactDataSheet
        key={`${block.id}-${edits}-${grid.length}-${language}`}
        data={grid}
        valueRenderer={(cell: any) => cell.expr || cell.value}
        sheetRenderer={(props) => (
          <SheetRenderer
            key={`${block.id}-${edits}-${grid.length}-${language}`}
            block={block}
            parent={parent}
            siblings={siblings}
            columns={data.style}
            onAddColumn={onAddColumn}
            onDeleteColumn={onDeleteColumn}
            onHeaderChange={onHeaderChange}
            onSort={onSort}
            {...props}
          />
        )}
        valueViewer={CellValueViewer}
        rowRenderer={({ row, cells, ...rest }) => (
          <Row rowIndex={row} onAddRow={onAddRow} onDeleteRow={onDeleteRow} {...rest} />
        )}
        cellRenderer={(props) => (
          <Cell {...props}/>
        )}
        dataEditor={({ value, onChange, ...props }) => (
          <TextField
            {...props}
            style={{ margin: -8 }}
            InputProps={{ style: { background: 'none', border: 'none', outline: 'none' } }}
            autoFocus
            fullWidth
            size="small"
            value={value}
            onChange={(e) => onChange(e.target.value)}
          />
        )}
        onCellsChanged={onCellsChanged}
      />
      <EditableContent block={block} parent={parent} siblings={siblings} field="text_content" disableEnterKey>
        <span dangerouslySetInnerHTML={{ __html: block?.text_content }}/>
      </EditableContent>
    </Box>
  );
});
