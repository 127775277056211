import React, { Fragment, useCallback, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { Container, Grid, Typography, Box, TextField, MenuItem, Paper, Button, useTheme } from '@mui/material';
import { InsertDriveFile } from '@mui/icons-material';
import { format } from 'date-fns';

import { cacheKeys, routes } from 'config';
import { LayoutContext, LocalizationContext, UserContext } from 'contexts';
import { addQuery } from 'helpers/query';
import { stripHtml } from 'helpers/html';
import { documentsClient } from 'clients/documents/documentsClient';
import { useBreadcrumbs } from 'shared/hooks/useBreadcrumbs';
import { useDocuments } from 'shared/hooks/useDocuments';
import { Breadcrumbs } from 'shared/components/layout/Breadcrumbs/Breadcrumbs';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';


export const Documents = () => {

  const queryClient = useQueryClient();
  const breadcrumbs = useBreadcrumbs('documents');
  const theme = useTheme();
  const { documents, status } = useDocuments();
  const { isMobile, isLightMode, setSharedPopupOpen, setLayoutTempState } = useContext(LayoutContext);
  const { dictionary } = useContext(LocalizationContext);
  const { userPermissions } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();

  const onCreate = useCallback(() => {
    setSharedPopupOpen(true);
    setLayoutTempState({
      title: dictionary.editableContent.addCla,
      contentForm: true,
      fields: ['title', 'description'],
      onSave: async (data: any) => {
        const { data: createdDocument } = await documentsClient.createDocument({
          title: data?.title,
          title_draft: data?.title,
          subtitle: data?.description,
          subtitle_draft: data?.description,
          published_at: new Date().toISOString().substr(0, 10),
          published_at_draft: new Date().toISOString().substr(0, 10),
        });
        navigate({
          pathname: routes.document,
          search: addQuery(location.search, { slug: createdDocument?.slug }),
        });
        queryClient.invalidateQueries(cacheKeys.documents.getDocuments);
      },
    });
  }, [dictionary, location?.search, navigate, setSharedPopupOpen, setLayoutTempState, queryClient]);

  return (
    <Container maxWidth="xl" sx={{ p: isMobile ? 2 : 4, mt: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h3"
            align="center"
            color={isLightMode ? theme.palette.primary.main : 'white'}
          >
            {dictionary.menu.documents}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ textAlign: 'center' }}>
            <Breadcrumbs list={breadcrumbs}/>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Grid container alignItems="center" wrap={!isMobile ? 'nowrap' : undefined} spacing={2}>
              <Grid item xs={12} sm="auto" style={{ flexGrow: 1 }}>
                <TextField size="small" label={dictionary.search} fullWidth/>
              </Grid>
              <Grid item xs={12} sm="auto">
                <TextField
                  style={{ minWidth: 200 }}
                  size="small"
                  label={dictionary.status}
                  defaultValue="All"
                  fullWidth
                  select
                >
                  {['All', 'Approved', 'Published', 'Draft', 'Deleted'].map(value => (
                    <MenuItem sx={{ p: 2 }} value={value} key={value}>{value}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              {!!userPermissions?.add_cla && (
                <Grid item sm="auto">
                  <Button onClick={onCreate}>
                    {dictionary.editableContent.addCla}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
        {(status === 'loading' || !documents) && (
          <BodyLoading height="calc(100vh - 300px)"/>
        )}
        {documents?.map(({ slug, title, updated_at, published_at, subtitle }: any, i: number) => (
          <Grid item xs={12} key={i}>
            <Box
              onClick={() => navigate({
                pathname: routes.document,
                search: addQuery(location.search, { slug }),
              })}
              p={3}
              component={Paper}
              sx={{
                overflow: 'hidden',
                position: 'relative',
                height: '100%',
                cursor: 'pointer',
                '&:hover': {
                  background: 'none',
                  bgcolor: isLightMode ? '#ececec': '#111111',
                },
              }}
            >
              <InsertDriveFile
                sx={{
                  position: 'absolute',
                  bottom: -40,
                  right: -10,
                  width: 100,
                  height: 100,
                  opacity: 0.2,
                }}
              />
              <Typography variant="h5" fontWeight={300} sx={{ textTransform: 'capitalize' }}>
                {stripHtml(title)}
              </Typography>
              {subtitle && <Typography variant="body1" sx={{ mb: 1 }}>
                {stripHtml(subtitle)}
              </Typography>}
              {(updated_at && userPermissions.change_cla) && (
                <Fragment>
                  <Typography variant="caption" align="center" sx={{ mb: 2 }} noWrap>
                    {dictionary.lastUpdated} {format(new Date(updated_at), 'dd/MM/yyy HH:mm')}
                  </Typography>
                  <br/>
                </Fragment>
              )}
              {published_at && (
                <Fragment>
                  <Typography variant="caption" align="center" sx={{ mb: 2 }} noWrap>
                    {dictionary.documentStatuses.read} {format(new Date(published_at), 'dd/MM/yyy')}
                  </Typography>
                  <br/>
                </Fragment>
              )}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
