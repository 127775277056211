import React, { FC } from 'react';
import StickyBox from 'react-sticky-box';
import { Drawer, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { DocumentRightContainerProps } from 'modules/Document/DocumentRightContainer/types';

export const DocumentRightContainer: FC<DocumentRightContainerProps> = (
  {
    show,
    onClose,
    children,
  },
) => {
  const container = (
    <Grid
      item
      sx={{
        maxWidth: '480px',
        minWidth: '350px',
      }}
      className="no-print"
    >
      <StickyBox offsetTop={190} style={{ zIndex: 50 }}>
        <Box
          sx={{
            background: 'white',
            padding: '8px',
          }}
        >
          {children}
        </Box>
      </StickyBox>
    </Grid>
  );
  
  if (!show) {
    return <></>;
  }

  return (
    <Drawer
      anchor="right"
      open={show}
      onClose={onClose}
    >
      {container}
    </Drawer>
  );
};
