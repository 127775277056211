import { useQuery } from 'react-query';
import { cacheKeys } from 'config';
import { documentsClient } from 'clients/documents/documentsClient';

type Options = {
  enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useDocuments = (params: any = {}, options: Partial<Options> = defaultOptions) => {
  const { data: { data } = {}, status, error } = useQuery(
    [cacheKeys.documents.getDocuments, params],
    () => documentsClient.getDocuments(params),
    {
      enabled: options.enabled,
    },
  );

  return {
    status,
    error,
    count: data?.count || 0,
    documents: data?.results || [],
  };
};
