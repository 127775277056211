import React, { FC, useContext } from 'react';
import { Box, Tooltip } from '@mui/material';

import classnames from 'classnames';
import { secondaryBrandGrey } from 'shared/styles/muiTheme';
import { useBoundStore } from 'store';
import { LayoutContext } from 'contexts';
import { sanitizeHtml } from 'helpers/html';
import { InfoIcon } from 'shared/components/layout/CustomIcons/InfoIcon';


export const ReferenceTooltip: FC<any> = ({
  title,
  is_renvoi,
  is_full_renvoi,
  is_parent_full_renvoi,
  children,
}) => {
  const { isLightMode } = useContext(LayoutContext);

  const renvoiMode = useBoundStore((state) => state.renvoiMode);

  return (
    <Tooltip
      title={(
        <div
          dangerouslySetInnerHTML={{ __html: sanitizeHtml(title) }}
          className={
            classnames(
              `renvoi-mode-${renvoiMode}`,
              {
                'is-renvoi': is_renvoi,
              },
            )
          }
        />
      )}
      placement="top"
      enterTouchDelay={50}
      leaveTouchDelay={5000}
      arrow
    >
      <Box
        display="inline"
        className={
          classnames(
            'reference-container',
            {
              'is-renvoi': is_renvoi,
            },
          )
        }
        sx={{ cursor: 'pointer' }}
      >
        <Box
          sx={{
            display: 'inline',
            backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, 0) 50%, lightgray 50%)',
            backgroundSize: '4px 1px',
            backgroundRepeat: 'repeat-x',
            backgroundPosition: '0 100%',
            textDecoration: 'none',

            '&:hover': {
              backgroundColor: '#ffe3b9',
            },
          }}
        >
          {children}
        </Box>
        <Box display="inline" className="no-print">
          <InfoIcon
            backgroundColor={isLightMode ? secondaryBrandGrey : '#333333'}
            color={isLightMode ? '#273e71' : 'white'}
            style={{
              marginLeft: 2,
              marginRight: 4,
              width: 16,
              height: 16,
            }}
          />
        </Box>
        <Box display="inline" className="print-only">
          <sup
            data-reference-text={title}
            data-is-renvoi={is_renvoi}
            data-is-full-renvoi={is_full_renvoi}
            data-is-parent-full-renvoi={is_parent_full_renvoi}
          />
        </Box>
      </Box>
    </Tooltip>
  );
};
