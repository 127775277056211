import React, { FC, useCallback, useState } from 'react';
import { Drawer, Grid, useTheme } from '@mui/material';
import { SectionMenu } from './SectionMenu';
import { MobileDocumentNavHandle } from './MobileDocumentNavHandle';


type Props = {
    document: any;
};

export const MobileDocumentNav: FC<Props> = ({
  document,
}) => {
  const [navOpen, setNavOpen] = useState(false);
  const toggleNav = useCallback(() => {
    setNavOpen(!navOpen);
  },[navOpen]);
  const theme = useTheme();
  return (
    <div>
      <MobileDocumentNavHandle navOpen={navOpen} onClick={toggleNav}/>
      <Drawer
        anchor="right"
        open={navOpen}
        onClose={toggleNav}
        sx={{
          '.MuiDrawer-paper': {
            [theme.breakpoints.up('sm')]: {
              height: 'calc(100% - 100px)',
              top: '100px',
            },
            height: 'calc(100% - 56px)',
            top: '56px',
            boxShadow: 3,
            padding: '10px',
          },
        }}
        ModalProps={{ BackdropProps: { sx: { background: 'transparent' } } }}
      >
        <Grid container direction="column" sx={{ maxWidth: 280 }}>
          <SectionMenu
            document={document}
            onNavigate={toggleNav}/>
        </Grid>
      </Drawer>
    </div>
  );
};
