import React, { FC, useContext } from 'react';
import { Box } from '@mui/material';
import { ArrowBackIos, AssignmentOutlined } from '@mui/icons-material';

import { LayoutContext } from 'contexts';


type Props = {
    onClick: () => void;
    navOpen: boolean;
};

export const MobileDocumentNavHandle: FC<Props> = ({
  onClick,
  navOpen=false,
}) => {
  const { isLightMode } = useContext(LayoutContext);

  return (
    <Box
      onClick={onClick}
      className="no-print"
      sx={{
        position: 'fixed',
        bottom: '50px',
        right: navOpen ? '299px': '0px',
        zIndex: 9999,
        transition: 'right 50ms cubic-bezier(0, 0, 0.2, 1) 0ms',
        height: '80px',
        width: '50px',
        padding: '10px',
        borderTopLeftRadius: '10px',
        borderBottomLeftRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: 3,
        clipPath: 'inset(-15px 0px -15px -15px)',
        ...(isLightMode ? {
          background: navOpen ? '#f8f8f8' : '#ee782f',
        } : {
          background: '#2c2e33',
        }),
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          right: '50px',
          width: '14px',
          height: '28px',
          padding: '6px',
          display: 'flex',
          alignItems: 'center',
          borderTopLeftRadius: '6px',
          borderBottomLeftRadius: '6px',
          boxShadow: 3,
          clipPath: 'inset(-15px 0px -15px -15px)',
          ...(isLightMode ? {
            background: navOpen ? '#f8f8f8' : '#ee782f',
          } : {
            background: '#2c2e33',
          }),
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          right: '50px',
          width: '14px',
          height: '28px',
          padding: '6px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <ArrowBackIos
          sx={{
            position: 'relative',
            fontSize: '14px',
            right: navOpen ? '8px' : '2px',
            transform: navOpen ? 'rotate(.5turn)' : 'none',
            ...(isLightMode ? {
              fill: navOpen ? '#ee782f' : '#f8f8f8',
            } : {
              fill: navOpen ? '#f8f8f8' : '#ee782f',
            }),
          }}
        />
        <ArrowBackIos
          sx={{
            position: 'relative',
            fontSize: '14px',
            right: navOpen ? '16px' : '10px',
            opacity: 0.7,
            transform: navOpen ? 'rotate(.5turn)' : 'none',
            ...(isLightMode ? {
              fill: navOpen ? '#ee782f' : '#f8f8f8',
            } : {
              fill: navOpen ? '#f8f8f8' : '#ee782f',
            }),
          }}
        />
        <ArrowBackIos
          sx={{
            position: 'relative',
            fontSize: '14px',
            right: navOpen ? '24px': '18px',
            opacity: 0.5,
            transform: navOpen ? 'rotate(.5turn)' : 'none',
            ...(isLightMode ? {
              fill: navOpen ? '#ee782f' : '#f8f8f8',
            } : {
              fill: navOpen ? '#f8f8f8' : '#ee782f',
            }),
          }}
        />
      </Box>
      <AssignmentOutlined
        sx={{
          fontSize: '36px',
          position: 'relative',
          left: '4px',
          ...(isLightMode ? {
            fill: navOpen ? '#ee782f' : '#f8f8f8',
          } : {
            fill: navOpen ? '#f8f8f8' : '#ee782f',
          }),
        }}
      />
    </Box>
  );
};
