import React, { useCallback, useContext, FC } from 'react';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import { MenuList, MenuItem } from '@mui/material';

import { useBoundStore } from 'store';
import { setRenvoiMode } from 'store/DocumentSearch';
import { LocalizationContext } from 'contexts';
import { RenvoiMode } from 'clients/documents/documentsClient.types';
import { getRenvoiModeLabel } from 'modules/Document/helpers';
import { ButtonDropdown } from 'shared/components/interactive/ButtonDropdown/ButtonDropdown';

export const RenvoiModeDropDown: FC = () => {
  const { dictionary } = useContext(LocalizationContext);

  const renvoiMode = useBoundStore((state) => state.renvoiMode);

  const onSelect = useCallback((mode: RenvoiMode, closeDropdown: () => void) => {
    setRenvoiMode(mode);
    closeDropdown();
  }, []);

  // artem suspects that it doesn't consume the default value properly

  return (
    <ButtonDropdown
      label={getRenvoiModeLabel(renvoiMode, dictionary)}
      placement="bottom"
      buttonProps={{
        sx: (theme) => ({
          minWidth: '246px',
          ml: 2,
          color: theme.palette.primary.main,
          borderColor: theme.palette.background.default,
        }),
        variant: 'outlined',
        size: 'large',
        endIcon: open => open ? <KeyboardArrowUp/> : <KeyboardArrowDown/>,
      }}
      dropDownStyle={{
        minWidth: '246px',
      }}
      content={({ closeDropdown }) => (
        <MenuList>
          {Object.values(RenvoiMode).map((mode) => (
            <MenuItem onClick={() => onSelect(mode as RenvoiMode, closeDropdown)}>
              {getRenvoiModeLabel(mode as RenvoiMode, dictionary)}
            </MenuItem>
          ))}
        </MenuList>
      )}
    />
  );
};
