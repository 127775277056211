import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  Grid,
  MenuItem,
  Stack,
  TextField, ToggleButton, ToggleButtonGroup,
  Typography,
} from '@mui/material';

import { isEmpty, pickBy } from 'lodash';
import { setDraftPreviewMode, setMode } from 'store/DocumentMode';
import { routes } from 'config';
import { LayoutContext, LocalizationContext, UserContext } from 'contexts';
import { DocumentMode } from 'types/Document';
import { addQuery, parseQuery } from 'helpers/query';
import { checkAllRelatedPrintCheckboxes } from 'modules/Document/helpers';
import { Document } from 'views/LoggedIn/Document/Document';
import { Document as PublicDocument } from 'views/LoggedOut/Document';


type Props = {
  open: boolean,
  onClose: () => void,
  options?: {
    showVersionFilters?: boolean;
  };
}

export const EXPORT_FORMATS = {
  PRINT: 'print',
  DOCX: 'docx',
};

export const PrintDialog: FC<Props> = ({ open, onClose, options }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn } = useContext(UserContext);
  const { dictionary } = useContext(LocalizationContext);
  const { documentMode, setDocumentMode, layoutTempState, setLayoutTempState } = useContext(LayoutContext);

  const [format, setFormat] = useState(EXPORT_FORMATS.DOCX);

  const selectedItems = useMemo(() => {
    return pickBy(layoutTempState?.itemsToPrint);
  }, [layoutTempState?.itemsToPrint]);

  const onPrintAllChange = useCallback((shouldPrintAll: boolean) => {
    let itemsToUpdate = layoutTempState?.itemsToPrint || {};

    itemsToUpdate = checkAllRelatedPrintCheckboxes('', shouldPrintAll);

    setLayoutTempState({
      ...layoutTempState,
      shouldPrintAll,
      itemsToPrint: {
        ...itemsToUpdate,
      },
    });
  }, [layoutTempState, setLayoutTempState]);

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={() => {
        setDocumentMode(DocumentMode.Read);
        setMode(DocumentMode.Read);
        setDraftPreviewMode(false);
        onClose();
      }}
      className="print-dialog"
    >
      <Grid
        container
        display="flex"
        sx={{
          bgcolor: '#E5E5E5',
        }}
      >
        <Grid item xs={8}>
          <Box
            sx={{
              overflowY: 'auto',
              height: '90vh',
            }}
          >
            {isLoggedIn ? (
              <Document isPrintMode mode={documentMode}/>
            ) : (
              <PublicDocument isPrintMode mode={documentMode}/>
            )}
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            sx={{
              height: '100%',
              p: 2,
            }}
          >
            <Stack justifyContent="space-between" height="100%">
              <Box>
                <Typography variant="h6">{dictionary.printDialog.title}</Typography>
                <FormControlLabel
                  label={dictionary.printDialog.form.entireDocument}
                  control={
                    <Checkbox
                      key={layoutTempState?.shouldPrintAll}
                      defaultChecked={layoutTempState?.shouldPrintAll}
                      onChange={(event) => {
                        onPrintAllChange(event.target.checked);
                      }}
                    />
                  }
                />
                {options?.showVersionFilters && (
                  <TextField
                    style={{ minWidth: 200, marginTop: 32, marginBottom: '12px' }}
                    size="small"
                    label={dictionary.printDialog.form.version}
                    defaultValue={documentMode}
                    fullWidth
                    onChange={(event) => setDocumentMode(event.target.value)}
                    select
                  >
                    <MenuItem sx={{ p: 2 }} value={DocumentMode.Read}
                      key="read">{dictionary.documentStatuses.read}</MenuItem>
                    <MenuItem sx={{ p: 2 }} value={DocumentMode.Write}
                      key="write">{dictionary.documentStatuses.write}</MenuItem>
                  </TextField>
                )}
                {location.pathname === routes.document && (
                  <Box>
                    <ToggleButtonGroup
                      size="small"
                      value={format}
                      exclusive
                      onChange={(event, newFormat) => {
                        setFormat(newFormat);
                      }}
                    >
                      <ToggleButton
                        value={EXPORT_FORMATS.DOCX}
                        key={EXPORT_FORMATS.DOCX}
                        sx={{
                          padding: '6px 24px',
                        }}
                      >
                        DOCX
                      </ToggleButton>,
                      <ToggleButton
                        value={EXPORT_FORMATS.PRINT}
                        key={EXPORT_FORMATS.PRINT}
                        sx={{
                          padding: '6px 24px',
                        }}
                      >
                        {dictionary.printDialog.format.print}
                      </ToggleButton>,
                    </ToggleButtonGroup>
                  </Box>
                )}
              </Box>

              <Box display="flex" justifyContent="right">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isEmpty(selectedItems)}
                  onClick={() => {
                    navigate({
                      pathname: routes.documentPrintPreview,
                      search: addQuery('', {
                        slug: parseQuery(location.search).slug,
                        'draft-secret': parseQuery(location.search)['draft-secret'],
                        'important-only': parseQuery(location.search)['important-only'],
                        'section-id': parseQuery(location.search)['section-id'],
                        'format': format,
                      }),
                    });
                  }}
                >
                  {isEmpty(selectedItems) ? (
                    <>
                      {dictionary.printDialog.form.buttonLabels.docxFull}
                    </>
                  ) : (
                    <>
                      {layoutTempState?.shouldPrintAll && format === EXPORT_FORMATS.DOCX && dictionary.printDialog.form.buttonLabels.docxFull}
                      {!layoutTempState?.shouldPrintAll && format === EXPORT_FORMATS.DOCX && dictionary.printDialog.form.buttonLabels.docxPartial}
                      {layoutTempState?.shouldPrintAll && format === EXPORT_FORMATS.PRINT && dictionary.printDialog.form.buttonLabels.printFull}
                      {!layoutTempState?.shouldPrintAll && format === EXPORT_FORMATS.PRINT && dictionary.printDialog.form.buttonLabels.printPartial}
                    </>
                  )}
                </Button>
              </Box>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
};
