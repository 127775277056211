
export const cacheKeys = {
  history: {
    getHistory: 'getHistory',
  },
  section: {
    getSection: 'getSection',
  },
  article: {
    getArticle: 'getArticle',
  },
  block: {
    getBlock: 'getBlock',
  },
  files: {
    getFiles: 'getFiles',
  },
  documents: {
    getDocument: 'getDocument',
    getPublicDocument: 'getPublicDocument',
    getDraftDocument: 'getDraftDocument',
    getDocumentHistoryV2: 'getDocumentHistoryV2',
    searchDocument: 'searchDocument',
    searchOriginalItems: 'searchOriginalItems',
    searchQuestions: 'searchQuestions',
    createQuestion: 'createQuestion',
    deleteQuestion: 'deleteQuestion',
    patchQuestion: 'patchQuestion',
    getSearchDropdownData: 'getSearchDropdownData',
    getDocuments: 'getDocuments',
    getNotes: 'getNotes',
  },
  users: {
    getUsers: 'getUsers',
    getUser: 'getUser',
    editUser: 'editUser',
    deleteUser: 'deleteUser',
    changeUserGroup: 'changeUserGroup',
    getMe: 'getMe',
    inviteUser: 'inviteUser',
    getInvitation: 'getInvitation',
  },
  groups: {
    getAuthGroups: 'getAuthGroups',
  },
};

export const routes = {
  documents: '/documents',
  document: '/document/open',
  publicDocument: '/document/public',
  documentPrintPreview: '/document/print',
  home: '/',
  login: '/login',
  signup: '/signup',
  team: '/team',
  myAccount: '/my-account',
  invite: '/invitation',
  resetPassword: '/resetPassword',
};


export type Routes = keyof typeof routes;

export const routeBreadcrumbs: Record<Routes, Routes[]> = {
  home: ['home'],
  login: ['login'],
  resetPassword: ['resetPassword'],
  signup: ['signup'],
  documents: ['home', 'documents'],
  document: ['home', 'document'],
  publicDocument: ['home', 'documents', 'document'],
  documentPrintPreview: ['home', 'documents', 'document'],
  team: ['home', 'team'],
  myAccount: ['home', 'myAccount'],
  invite: ['invite'],
};

export const featureFlags = {
  microsoftSSO: false,
  translations: false,
  team: false,
  darkMode: false,
  teamFilter: false,
  teamSort: false,
};
