import React, { FC, useContext } from 'react';
import { Box } from '@mui/material';

import { useBoundStore } from 'store';
import { LayoutContext } from 'contexts';
import { DocumentMode } from 'types/Document';


export const HighlightedText: FC<any> = ({ slug, children }) => {
  const { isLightMode, documentMode } = useContext(LayoutContext);
  const isHighlighted = useBoundStore(
    (state) => state.documentSearchCurrentHighlightedId === slug,
  );

  if(documentMode === DocumentMode.Write) {
    return children;
  }

  // todo use callback or some optimization
  return (
    <Box
      component="span"
      sx={{
        background: isLightMode ? (isHighlighted ? '#ff9900' : '#b7ffba') : 'transparent',
      }}
    >
      {children}
    </Box>
  );
};
