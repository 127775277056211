import React, { FC, ReactElement, useContext, useState, useCallback } from 'react';
import {
  Box,
  Button,
  Grid,
  Paper,
  MenuItem,
  TextField,
} from '@mui/material';
import { FilterAlt, Sort } from '@mui/icons-material';

import { featureFlags } from 'config';
import { LocalizationContext } from 'contexts';
import { UserType } from 'clients/users/userClient.types';
import { useQueryState } from 'shared/hooks/useQueryState';
import { useDebouncedQueryState } from 'shared/hooks/useDebouncedQueryState';

export type Filters = {
  search?: string;
  type?: UserType;
}

type Props = {
  children(props: {
    filters: Filters,
  }): ReactElement;
}

export const TeamHeader: FC<Props> = ({ children }) => {

  const { dictionary } = useContext(LocalizationContext);

  const [showFilters, setShowFilters] = useState(false);
  const [newSearch, currSearch, setSearch] = useDebouncedQueryState('search');
  const [type, setType] = useQueryState<UserType>('type');

  const toggleFiltersVisibility = useCallback(() => {
    setShowFilters(show => !show);
  }, []);

  return (
    <Grid container>
      {featureFlags.teamFilter && (
        <Grid item xs={12}>
          <Box p={4}>
            <Grid container spacing={2}>
              <Grid item xs={7} textAlign="right">
                {featureFlags.teamSort && (
                  <Button color="secondary" variant="text" size="small" startIcon={<Sort />} sx={{ marginLeft: 3 }}>
                    {dictionary.filters.buttonSort}
                  </Button>
                )}
                {featureFlags.teamFilter && (
                  <Button
                    color="secondary"
                    variant={showFilters ? 'outlined' : 'text'}
                    size="small"
                    startIcon={<FilterAlt />} sx={{ marginLeft: 3 }}
                    onClick={toggleFiltersVisibility}
                  >
                    {dictionary.filters.buttonFilter}
                  </Button>
                )}
              </Grid>
              {showFilters && (
                <Grid
                  item
                  xs={12}
                >
                  <Paper
                    square
                    variant="contained"
                    sx={theme => ({
                      marginLeft: theme.spacing(-4),
                      marginRight: theme.spacing(-4),
                      paddingTop: theme.spacing(2),
                      paddingBottom: theme.spacing(2),
                      paddingLeft: theme.spacing(4),
                      paddingRight: theme.spacing(4),
                    })}
                  >
                    <Grid container spacing={2}>
                      <Grid item>
                        <TextField
                          size="small"
                          style={{ minWidth: 200 }}
                          label={dictionary.search}
                          value={newSearch || null}
                          onChange={({ target: { value } }) => setSearch(value.trim() === '' ? undefined : value)}
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          select
                          size="small"
                          style={{ minWidth: 200 }}
                          label={dictionary.forms.user.fieldType}
                          defaultValue="all"
                          value={type || 'all'}
                          onChange={({ target: { value } }) => setType(value === 'all' ? undefined : value as UserType)}
                        >
                          <MenuItem value="all">{dictionary.all}</MenuItem>
                          <MenuItem value={UserType.Customer}>{dictionary.user.typeCustomerLabel}</MenuItem>
                          <MenuItem value={UserType.Admin}>{dictionary.user.typeAdminLabel}</MenuItem>
                        </TextField>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>
      )}
      <Grid item xs={12}>
        {children({
          filters: {
            search: currSearch,
            type,
          },
        })}
      </Grid>
    </Grid>
  );
};
