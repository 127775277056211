import { ContentBlock, ContentState } from 'draft-js';
import isURL from 'validator/es/lib/isURL';

export const findLinkEntities = (contentBlock: ContentBlock, callback: any, contentState: ContentState) => {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();

      if (!entityKey) {
        return false;
      }
      const entity = contentState.getEntity(entityKey);

      if (entity.getType() === 'LINK') {
        const { url } = entity.getData();

        return url !== '#' && !isURL(url) && !(url as string || '').startsWith('#');
      }

      return false;
    },
    callback,
  );
};

export const preProcessHtmlForTooltips = (html: string) => {
  const element = document.createElement('div');
  element.innerHTML = html;

  element.querySelectorAll('a').forEach((linkElement) => {
    const title = linkElement.getAttribute('title');

    if (!title) {
      return;
    }

    linkElement.setAttribute('href', title);
  });

  const updatedHtml = element.innerHTML;
  return updatedHtml;
};

export const postProcessEditorHtmlForTooltips = (html: string) => {
  const element = document.createElement('div');
  element.innerHTML = html;

  element.querySelectorAll('a').forEach((linkElement) => {
    const href = linkElement.getAttribute('href') || '';
    if (isURL(href) || (href !== '#' && href.startsWith('#'))) {
      return;
    }

    linkElement.setAttribute('title', href);
  });

  const updatedHtml = element.innerHTML;
  return updatedHtml;
};
