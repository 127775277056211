import { isEmpty, uniqBy } from 'lodash';

import { DocumentMode } from 'types/Document';
import { SearchResultType } from 'clients/documents/documentsClient.types';

export const getHeaderVariant = (level: number) => {
  switch (level) {
    case 1:
      return 'h4';
    case 2:
      return 'h5';
    case 3:
      return 'h6';
    default:
      return;
  }
};

export const isOnlyItemInRow = (articleCount:number, articlePosition: number, itemsPerRow: number) => {
  return articleCount === articlePosition && articlePosition % itemsPerRow === 1;
};

export const getArticleSm = (articles: any, articleIndex: number, mode?: string) => {

  if (mode === DocumentMode.Review) {
    return undefined;
  }

  const articlePosition = articleIndex + 1;
  const articleCount = articles.filter((article: any) => article.title).length;

  if (articleCount === 1) {
    return undefined;
  }

  if (!isOnlyItemInRow(articleCount, articlePosition, 2)) {
    return 6;
  }

  return undefined;
};

export const getArticleMd = (articles: any, articleIndex: number, mode?: string) => {

  if (mode === DocumentMode.Review) {
    return undefined;
  }

  const articlePosition = articleIndex + 1;
  const articleCount = articles.filter((article: any) => article.title).length;

  if (articleCount <= 2) {
    return undefined;
  }

  if (!isOnlyItemInRow(articleCount, articlePosition, 3)) {
    return 6;
  }

  return undefined;
};

export const getArticleNumbering = (parentArticleNumber: string = '', articleIndex: number, level: number) => {
  const articleNumber = articleIndex + 1;
  if (parentArticleNumber) {
    return level === 2 ? `${parentArticleNumber}${articleNumber}` : `${parentArticleNumber}.${articleNumber}`;
  }
  return `${articleNumber}.`;
};

export const getArticleResults = (document: any, dropDownData: any, searchResults: any) => {
  if (!searchResults?.highlighted_results?.length) {
    return [];
  }
  const resultTree: any = {};

  searchResults.highlighted_results.forEach((result:any) => {
    if (result.type === SearchResultType.ArticleTitle) {
      if (dropDownData.root_articles[result.id]) { // result is root
        const rootArticle = dropDownData.root_articles[result.id];

        if (!rootArticle) {
          return;
        }

        resultTree[rootArticle.id] = {
          ...rootArticle,
          subArticles: [],
        };
      } else { // result is subarticle
        if (!dropDownData.articles_with_root_articles[result.id]) {
          return;
        }
        const subArticle = dropDownData.articles_with_root_articles[result.id].root_subarticle;
        const rootArticle = dropDownData.articles_with_root_articles[result.id].root_article;

        if (resultTree[rootArticle.id]) {
          resultTree[rootArticle.id].subArticles.push(subArticle);
        } else {
          resultTree[rootArticle.id] = {
            ...rootArticle,
            subArticles: [subArticle],
          };
        }
      }
    } else {
      if (!dropDownData.blocks_with_root_articles[result.id]) {
        return;
      }
      const subArticle = dropDownData.blocks_with_root_articles[result.id].root_subarticle;
      const rootArticle = dropDownData.blocks_with_root_articles[result.id].root_article;

      if (resultTree[rootArticle.id]) {
        resultTree[rootArticle.id].subArticles.push(subArticle);
      } else {
        resultTree[rootArticle.id] = {
          ...rootArticle,
          subArticles: [subArticle],
        };
      }
    }
  });

  let results = Object.values(resultTree);
  results = results.slice(0, 5);
  results = results.map((rootArticle: any) => ({
    ...rootArticle,
    subArticles: uniqBy(rootArticle.subArticles, 'id'),
  }));

  return results;
};

export const hasBlocksWithContent = (blocks: any[]) => {
  if (isEmpty(blocks)) {
    return false;
  }

  return blocks.some((block) => {
    return block.title || block.title_draft || block.text_content || block.text_content_draft;
  });
};
