import React, { FC, useContext, useRef } from 'react';
import {
  Grid,
  Box,
  Typography,
  Checkbox,
} from '@mui/material';
import { isEmpty } from 'lodash';

import { useBoundStore } from 'store';
import { DocumentPublishContext } from 'contexts';
import { DocumentPrintContext } from 'contexts/DocumentPrintContext/DocumentPrintContext';
import { SectionContextProvider } from 'contexts/SectionContext/SectionContext';
import { Article } from 'modules/Document/Article/Article';
import { getHeaderVariant } from 'modules/Document/Article/helpers';
import { BlockWrapper as Block } from 'modules/Document/Block/Block';
import { getAddOrDeleteEntityColorForHistory, getModifiedEntityColorForHistory } from 'modules/Document/helpers';
import FileButton from 'shared/components/form/FileButton/FileButton';
import { EditableContent } from 'shared/components/form/EditableContent/EditableContent';


export const Section: FC<any> = ({ section, siblings, showComments = true }) => {

  const ref = useRef<HTMLElement>();
  const { addSection, removeSection, partialPublish } = useContext(DocumentPublishContext);
  const { isPrintMode, isPrintPreview, itemsToPrint, toggleItemToPrint } = useContext(DocumentPrintContext);

  const showDraftVersion = useBoundStore((state) => state.showDraftVersion);

  const newOrDeletedColor = getAddOrDeleteEntityColorForHistory(section.id, 'section');
  const modifiedColorTitle = getModifiedEntityColorForHistory(section.id, 'section', 'title');
  const modifiedColorOther = getModifiedEntityColorForHistory(section.id, 'section', 'other');


  return (
    <SectionContextProvider
      sectionId={section.id}
      sectionTitle={section.title}
    >
      <Box
        ref={ref}
        className="document-child-item"
        id={section.slug}
        sx={{
          background: newOrDeletedColor,
        }}
      >
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              id={section.slug}
              sx={{
                wordBreak: 'break-word',
                color: section.titles_color,
                background: modifiedColorTitle,
                border: `4px dashed ${modifiedColorOther}`,
              }}
            >
              {(!showDraftVersion && partialPublish) && (
                <Checkbox
                  sx={{ ml: -3, mt: -0.5 }}
                  onChange={e => e.target.checked ? addSection(section.id) : removeSection(section.id)}
                />
              )}
              {(isPrintMode && !isPrintPreview) && (
                <span data-slug={section.slug}>
                  <Checkbox
                    key={`${itemsToPrint[section.slug]}`}
                    defaultChecked={itemsToPrint[section.slug]}
                    size="small"
                    className="print-selection-checkbox"
                    sx={{ ml: -2, mt: -0.5 }}
                    onChange={event => toggleItemToPrint(section.slug, event.target.checked)}
                  />
                </span>
              )}
              <EditableContent
                section={section}
                siblings={siblings}
                disableEnterKey
              >
                {section.stripped_title}
                {showComments && (
                  <FileButton
                    section={section}
                  />
                )}
              </EditableContent>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {!isEmpty(section.blocks) && section.blocks.map((block: any) => (
              (block.is_before_article && block.hasContent) && (
                <Box key={block.slug}>
                  <Block
                    parent={section}
                    block={block}
                    titleVariant={getHeaderVariant(1)}
                    siblings={section.blocks}
                    allowPrintSelection
                    showComments={showComments}
                  />
                </Box>
              )
            ))}

            {!isEmpty(section.article_set) && section.article_set.map((article: any, index: number) => (
              article.title && (
                <Article
                  parent={section}
                  siblings={section.article_set}
                  article={article}
                  key={article.slug}
                  level={1}
                  position={index}
                  showComments={showComments}
                />
              )
            ))}

            {!isEmpty(section.blocks) && section.blocks.map((block: any) => (
              (!block.is_before_article && block.hasContent) && (
                <Box key={block.slug}>
                  <Block
                    block={block}
                    titleVariant={getHeaderVariant(1)}
                    siblings={section.blocks}
                    parent={section}
                    allowPrintSelection
                    showComments={showComments}
                  />
                </Box>
              )
            ))}
          </Grid>
        </Grid>
      </Box>
    </SectionContextProvider>
  );
};
