import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { filter, map } from 'lodash';

import { routes } from 'config';
import { LayoutContext } from 'contexts';
import { addQuery, parseQuery } from 'helpers/query';
import { sanitizeFootnote } from 'helpers/html';
import { EXPORT_FORMATS } from 'shared/components/dialog/PrintDialog/PrintDialog';
import { Document } from 'views/LoggedIn/Document/Document';
import { downloadDocx } from 'views/LoggedIn/DocumentPrintPreview/downloadDocx';

export const DocumentPrintPreview = () => {
  const [isPrinting, setIsPrinting] = useState(false);
  const { layoutTempState, setLayoutTempState } = useContext(LayoutContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isPrinting) {
      if (parseQuery(location.search)['format'] === EXPORT_FORMATS.DOCX) {
        downloadDocx();
      } else {
        window.print();
      }
      setLayoutTempState({
        ...layoutTempState,
        footnotes: [],
      });
      navigate({
        pathname: routes.document,
        search: addQuery('', {
          slug: parseQuery(location.search).slug,
          'draft-secret': parseQuery(location.search)['draft-secret'],
          'important-only': parseQuery(location.search)['important-only'],
          'section-id': parseQuery(location.search)['section-id'],
        }),
      });
    }
  }, [isPrinting, layoutTempState, location, navigate, setIsPrinting, setLayoutTempState]);

  const showOnlyItemsToPrint = useCallback(() => {
    const documentChildItems = window.document.querySelectorAll('.print-preview-container .document-child-item');

    documentChildItems.forEach((childItem) => childItem.classList.add('hidden'));

    Object.keys(layoutTempState?.itemsToPrint || {})?.forEach((key) => {
      if (layoutTempState?.itemsToPrint?.[key]) {
        document.getElementById(key)?.classList.remove('hidden');
      }
    });
  }, [layoutTempState]);

  useEffect(() => {
    setTimeout(() => {
      if (!layoutTempState?.shouldPrintAll) {
        showOnlyItemsToPrint();
      }

      const allReferences = window.document.querySelectorAll('.print-document-container  .reference-container sup');
      const visibleReferences = filter(allReferences, (referenceElement) => {
        return !referenceElement.closest('.document-child-item.hidden');
      });

      (visibleReferences as Element[]).forEach((referenceElement, index) => {
        referenceElement.textContent = index + 1 + '';
      });

      setLayoutTempState({
        ...layoutTempState,
        footnotes: map(visibleReferences as Element[], (element, index) => ({
          number: index + 1,
          text: sanitizeFootnote(element.getAttribute('data-reference-text') || ''),
          is_renvoi: element.getAttribute('data-is-renvoi') === 'true',
          is_full_renvoi: element.getAttribute('data-is-full-renvoi') === 'true',
          is_parent_full_renvoi: element.getAttribute('data-is-parent-full-renvoi') === 'true',
        })),
      });

      setIsPrinting(true);

    }, 1000);
  }, [layoutTempState, setLayoutTempState, showOnlyItemsToPrint]);

  return (
    <Box className="print-preview-container">
      <Document isPrintMode isPrintPreview/>
    </Box>
  );
};
