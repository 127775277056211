import { dictionary as rootDictionary } from 'contexts/LocalizationContext/languages/en';

export const warnOnMissingTranslations = (dictionary: Partial<typeof rootDictionary>, language: string) => {
  let key: keyof typeof rootDictionary | keyof typeof dictionary;
  for (key in rootDictionary) {
    if (!dictionary.hasOwnProperty(key)) {
      console.warn(`${key} is not translated in ${language}. Will default to English.`);
    }
  }
};


export const getAuthGroupTranslation = (dictionary: any, name: string) => {
  const key = name?.toLowerCase();
  // @ts-ignore
  const label = dictionary.authGroups[key];
  if (!label) {
    console.warn(`Cannot find translation for ${label} user group`);
  }
  return label || name;
};