import React, { FC } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { isEqual } from 'lodash';
import { format } from 'date-fns';
import { SectionList } from 'modules/Document/Section/SectionList';
import { Footnotes } from 'modules/Document/Footnotes/Footnotes';
import { Section } from 'modules/Document/Section/Section';
import { EditableContent } from 'shared/components/form/EditableContent/EditableContent';
import { RenderedDocumentProps, RenderedDocumentSectionsProps } from './types';

export const RenderDocumentContainer: FC<any> = (
  {
    reviewMode,
    isPrintMode,
    showDocumentSidebar,
    showRightContainer,
    children,
    id,
  },
) => {
  return (
    <Grid
      id={id}
      item
      sm={reviewMode ? 6 : undefined}
      sx={theme => {
        if (isPrintMode) {
          return {
            p: 0,
            width: '100%',
          };
        } else {
          return {
            py: '8px !important',
            px: '16px !important',
            maxWidth: '1200px !important',
            display: reviewMode ? 'grid' : undefined,
            width: '100%',
            [theme.breakpoints.between('md', 'xl')]: {
              maxWidth: `calc(min(100vw, 1200px) ${showDocumentSidebar ? '- 330px' : ''}  ${showRightContainer ? '- 370px' : ''}) !important`,
            },
          };
        }
      }}
    >
      {children}
    </Grid>
  );
};

const RenderedDocumentSections = React.memo<RenderedDocumentSectionsProps>(({
  sections,
  sectionsRaw,
  showComments,
}) => (
  <>
    {sections.map((section: any) => (
      <Section
        key={section.slug}
        section={section}
        siblings={sectionsRaw}
        showComments={showComments}
      />
    ))}
  </>
), (oldProps, newProps) => {
  return (
    isEqual(oldProps.sections, newProps.sections)
    && isEqual(oldProps.sectionsRaw, newProps.sectionsRaw)
  );
});

export const RenderedDocument = React.memo<RenderedDocumentProps>((
  {
    document,
    sections,
    reviewMode,
    reviewModeTitle,
    showSectionList,
    isLightMode,
    isPrintPreview,
    isCanEditTitle,
    showComments,
  },
) => {
  return (
    <>
      {reviewMode && reviewModeTitle && (
        <Typography
          variant="h6"
          align="center"
          color={isLightMode ? 'rgba(0,0,0,0.5)' : 'rgba(255, 255, 255, 0.5)'} sx={{ pb: 1 }}
        >
          {reviewModeTitle}
        </Typography>
      )}
      <Box
        sx={{
          bgcolor: isLightMode ? '#fff' : '#222',
          boxShadow: '0px 0px 10px 10px rgba(0,0,0,0.03)',
          p: 4,
          minHeight: '80vh',
          overflowY: reviewMode ? 'scroll': undefined,
          maxHeight: reviewMode ? 'calc(100vh - 250px)': undefined,
        }}
        className="print-document-page"
      >
        <Typography
          variant="h3"
          color="primary"
          align="center"
          sx={{ wordBreak: 'break-word', mt: 4 }}
          id="documentTitle"
        >
          <EditableContent
            cla={{ ...document, id: document.id }}
            disableEnterKey
            canEdit={isCanEditTitle}
          >
            {document?.stripped_title}
          </EditableContent>
        </Typography>
        {document?.published_at && (
          <Typography
            align="center"
            color="secondary"
            variant="h5"
            sx={{ mt: 2 }}
            id="documentPublishedAt"
          >
            {format(new Date(document.published_at), 'dd/MM/yyyy')}
          </Typography>
        )}
        {(!reviewMode && showSectionList) && <SectionList document={document}/>}
        <RenderedDocumentSections
          sections={sections}
          sectionsRaw={document?.section_set}
          showComments={showComments}
        />
        {isPrintPreview && (
          <Footnotes/>
        )}
      </Box>
    </>
  );
}, (oldProps, newProps) => {
  return (
    isEqual(oldProps.document, newProps.document)
    && isEqual(oldProps.sections, newProps.sections)
    && oldProps.reviewMode === newProps.reviewMode
    && oldProps.reviewModeTitle === newProps.reviewModeTitle
    && oldProps.showSectionList === newProps.showSectionList
    && oldProps.isLightMode === newProps.isLightMode
    && oldProps.isPrintPreview === newProps.isPrintPreview
    && oldProps.showComments === newProps.showComments
    && oldProps.isCanEditTitle === newProps.isCanEditTitle
  );
});
