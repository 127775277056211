import React, { useState } from 'react';
import { useQueryClient } from 'react-query';


import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import { Delete, ExpandMore } from '@mui/icons-material';
import { isEqual, find } from 'lodash';

import { cacheKeys } from 'config';
import { DialogContext, LayoutContext, LocalizationContext } from 'contexts';
import { BlockVariant, DocumentItemType } from 'clients/documents/documentsClient.types';

import { getArticleNumbering } from 'modules/Document/Article/helpers';
import { deleteItemWithDescendents } from 'shared/components/form/EditableContent/helpers/requests';

interface Props {
  item: any;
  articleNumber?: string,
  showDelete?: boolean;
}

export const ContentDescendants: React.FC<Props> = ({ item, articleNumber = '', showDelete = false }) => {

  const queryClient = useQueryClient();
  const { dictionary } = React.useContext(LocalizationContext);
  const { genericError } = React.useContext(LayoutContext);
  const { asyncConfirmation } = React.useContext(DialogContext);
  const [showInfo, setShowInfo] = React.useState(false);

  const [deletedEntities, setDeletedEntities] = useState<any>([]);

  const descendants = React.useMemo(() => {
    const articles = item?.articles?.map((it: any) => ({ ...it, type: DocumentItemType.Article })) || [];
    const articleSets = item?.article_set?.map((it: any) => ({ ...it, type: DocumentItemType.Article })) || [];
    const blocks = item?.blocks?.map((it: any) => ({ ...it, type: DocumentItemType.Block })) || [];
    const subBlocks = item?.sub_block?.map((it: any) => ({ ...it, type: DocumentItemType.Block })) || [];
    const entities = [...articles, ...articleSets, ...blocks, ...subBlocks];

    const entitiesAfterDeletion = entities.filter(el => (
      !find(deletedEntities, deletedEntity => isEqual(el, deletedEntity))
    ));

    return entitiesAfterDeletion;
  }, [item, deletedEntities]);

  const onDelete = React.useCallback(async (item: any) => {
    const userConfirmed = await asyncConfirmation({
      title: dictionary.confirmation.defaultTitle,
      content: (
        <React.Fragment>
          {dictionary.confirmation.defaultContent}
          <ContentDescendants item={item}/>
        </React.Fragment>
      ),
    });

    if (userConfirmed) {
      try {
        await deleteItemWithDescendents(item, item.type);
        setDeletedEntities([...deletedEntities, item]);
      } catch (e) {
        genericError();
        console.error(e);
      }
      await queryClient.invalidateQueries(cacheKeys.documents.getDraftDocument);
    }
  }, [genericError, dictionary, asyncConfirmation, queryClient, deletedEntities]);

  if (descendants.length === 0) {
    return null;
  }

  return (
    <Box component={Paper} variant="outlined" mt={2} minWidth={400}>
      <Accordion expanded={showInfo} onChange={() => setShowInfo(!showInfo)}>
        <AccordionSummary
          expandIcon={<ExpandMore/>}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
            {dictionary.editableContent.descendants}
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            {descendants.length} descendants
            {item.hasTable && ', Includes tables'}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List dense>
            {descendants.map((d: any, idx: number) => (
              <React.Fragment key={d.id}>
                <Divider component="li"/>
                <ListItem
                  secondaryAction={showDelete && (
                    <IconButton edge="end" aria-label="delete" onClick={() => onDelete(d)}>
                      <Delete/>
                    </IconButton>
                  )}
                >
                  <ListItemText
                    secondary={
                      <React.Fragment>
                        {d.type === DocumentItemType.Article && (
                          <Typography variant="body2" component="span" fontWeight={500} mr={1}>
                            {getArticleNumbering(articleNumber, idx, item.level)}
                          </Typography>
                        )}
                        <span dangerouslySetInnerHTML={{ __html: d.title || d.text_content }}/>
                      </React.Fragment>
                    }
                    primary={({
                      [BlockVariant.Text]: dictionary.editableContent.text,
                      [BlockVariant.HtmlField]: dictionary.editableContent.html,
                      [BlockVariant.Explanation]: dictionary.editableContent.explanation,
                      [BlockVariant.Recommendation]: dictionary.editableContent.recommendation,
                      [BlockVariant.Spreadsheet]: dictionary.editableContent.spreadsheet,
                    }[d.variant as BlockVariant])}
                  />
                </ListItem>
              </React.Fragment>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
