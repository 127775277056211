import sanitize from 'sanitize-html';
import * as _ from 'lodash';

export const sanitizeHtml = (html: string = '') => {
  if (!html) {
    return '';
  }

  return _.unescape(
    sanitize(html, {
      allowedTags: ['a', 'span', 'b', 'strong', 'i', 'ins', 'em', 'sub', 'sup', 'br', 'del', 'p'],
      allowedAttributes: { a: ['title', 'href', 'target'], span: ['class', 'id'] },
    }),
  );
};

export const stripHtml = (html: string = '') => {
  if (!html) {
    return '';
  }

  return _.unescape(
    sanitize(html, {
      allowedTags: [],
      allowedAttributes: {},
    }),
  );
};

export const sanitizeFootnote = (html: string = '') => {
  if (!html) {
    return '';
  }

  return _.unescape(
    sanitize(html, {
      allowedTags: ['del'],
      allowedAttributes: {},
    }),
  );
};


export const sanitizeHtmlAndHideTable = (html: string = '') => {
  if (!html || html.indexOf('<table') > -1) {
    return '';
  }

  return sanitizeHtml(html);
};

export const sanitizeTableContent = (html: string = '') => (
  _.unescape(
    sanitize(html, {
      allowedTags: [
        'p', 'b', 'em', 'i', 'del',
        'span', 'strong', 'u',
      ],
      allowedAttributes: {
        p: ['style'],
        span: ['style'],
      },
    }),
  )
);

export const isPublicWebsite = () => 'www.cao-metalektro.nl' === window.location.hostname;
// export const isPublicWebsite = () => 'localhost' === window.location.hostname;
