import { useBoundStore } from 'store';
import { Maybe, MaybeNull } from 'types';
import { Dictionary } from 'contexts/LocalizationContext/types';
import { RenvoiMode } from 'clients/documents/documentsClient.types';

export const getRenvoiModeLabel = (mode: Maybe<RenvoiMode>, dictionary: Dictionary) => {
  const map = {
    [RenvoiMode.Normal]: dictionary.renvoiMode.normal,
    [RenvoiMode.Strikethrough]: dictionary.renvoiMode.strikethrough,
    [RenvoiMode.Removed]: dictionary.renvoiMode.removed,
  };

  return map[mode || RenvoiMode.Normal];
};

export const getParentPrintCheckboxes = (itemSlug: string) => {
  const element = window.document.querySelector(`.print-dialog ${itemSlug ? `#${itemSlug}` : ''}`);

  if (!element || !itemSlug) {
    return [];
  }

  const parentCheckboxes: HTMLInputElement[] = [];
  let parentElement = element.closest(`.print-dialog .document-child-item:not(#${itemSlug})`);

  while (parentElement) {
    const checkbox = parentElement.querySelector('.print-selection-checkbox > input');
    const parentElementId = (parentElement.getAttribute('id') || '').replaceAll('.', '\\.');

    if (checkbox) {
      parentCheckboxes.push(checkbox as HTMLInputElement);
    }

    if (!parentElementId) {
      break;
    }

    parentElement = parentElement.closest(`.print-dialog .document-child-item:not(#${parentElementId})`);
  }

  return parentCheckboxes;
};

export const getChildPrintCheckboxes = (itemSlug?: Maybe<string>) => {
  const parentItem = window.document.querySelector(`.print-dialog ${itemSlug ? `#${itemSlug}` : ''}`);
  const childCheckBoxes = parentItem?.querySelectorAll('.print-selection-checkbox > input') || [];
  return childCheckBoxes;
};

export const checkAllRelatedPrintCheckboxes = (itemSlug: string, shouldPrint: boolean = false) => {
  const escapedSlug = itemSlug.replaceAll('.', '\\.');
  const childCheckBoxes = getChildPrintCheckboxes(escapedSlug);
  const parentCheckboxes = shouldPrint && itemSlug ? getParentPrintCheckboxes(escapedSlug) : [];

  const relatedCheckboxes = [
    ...childCheckBoxes,
    ...parentCheckboxes,
  ];

  const slugsToUpdate: Maybe<MaybeNull<string>>[] = [];

  (relatedCheckboxes as HTMLInputElement[]).forEach((checkbox) => {
    const childItemSlug = checkbox.parentElement?.parentElement?.getAttribute('data-slug');
    slugsToUpdate.push(childItemSlug);
    checkbox.checked = shouldPrint;
  });

  const itemsToUpdate = slugsToUpdate.reduce((accumulator, slug) => {
    return slug ? {
      ...accumulator,
      [slug]: shouldPrint,
    } : accumulator;
  }, {});

  return itemsToUpdate;
};

export const getAddOrDeleteEntityColorForHistory = (id: number, type: 'block' | 'article' | 'section') => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const reviewMode = useBoundStore((state) => state.reviewMode);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const historyData = useBoundStore((state) => state.historyData);

  if (!reviewMode) {
    return;
  }

  const newColor = '#cee9cc';
  const deletedColor = '#ffc1c4';

  if (
    (type === 'block' && historyData.new_blocks.includes(id)) ||
    (type === 'article' && historyData.new_articles.includes(id)) ||
    (type === 'section' && historyData.new_sections.includes(id))
  ) {
    return newColor;
  } else if (
    (type === 'block' && historyData.deleted_blocks.includes(id)) ||
    (type === 'article' && historyData.deleted_articles.includes(id)) ||
    (type === 'section' && historyData.deleted_sections.includes(id))
  ) {
    return deletedColor;
  }
};

export const getModifiedEntityColorForHistory = (
  id: number,
  type: 'block' | 'article' | 'section',
  change: 'title' | 'text_content' | 'json_content' | 'other',
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const reviewMode = useBoundStore((state) => state.reviewMode);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const historyData = useBoundStore((state) => state.historyData);

  if (!reviewMode) {
    return;
  }
  const dataPointsMapping = {
    'block': 'modified_blocks',
    'article': 'modified_articles',
    'section': 'modified_sections',
  };

  // @ts-ignore
  if (historyData[dataPointsMapping[type]][change].includes(id)) {
    return '#ffe6c5';
  }
};
