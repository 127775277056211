import React, { FC, useCallback, useContext, useState, useEffect } from 'react';
import {
  Box,
  IconButton,
} from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

import { useBoundStore } from 'store';
import { setDocumentSearchCurrentHighlightedId } from 'store/DocumentSearch';
import { LayoutContext } from 'contexts';

type Props = {
  disabled?: boolean,
};

export const SearchResultsNav: FC<Props> = ({ disabled }) => {
  const { isLightMode } = useContext(LayoutContext);
  const [currentResult, setCurrentResult] = useState(1);
  const documentSearchHighlightedResults = useBoundStore(
    (state) => state.documentSearchHighlightedResults,
  );

  const scrollTo = useCallback((index: number) => {
    // currentResult starts from 1
    const selectedSearchId = documentSearchHighlightedResults?.[index - 1]?.slug;
    window.document.getElementById(
      selectedSearchId,
    )?.scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'center',
    });
    setDocumentSearchCurrentHighlightedId(selectedSearchId);
  }, [documentSearchHighlightedResults]);

  const onNextResult = useCallback(() => {
    const nextResult = currentResult + 1;
    setCurrentResult(nextResult);
    scrollTo(nextResult);
  }, [scrollTo, currentResult]);

  const onPreviousResult = useCallback(() => {
    const nextResult = currentResult - 1;
    setCurrentResult(nextResult);
    scrollTo(nextResult);
  }, [scrollTo, currentResult]);

  useEffect(() => {
    setCurrentResult(1);

    if (documentSearchHighlightedResults?.length) {
      scrollTo(1);
    }
  }, [scrollTo, documentSearchHighlightedResults]);


  return !!documentSearchHighlightedResults?.length ? (
    <Box
      display="flex"
      sx={{
        width: 'auto',
        background: isLightMode ? '#fafafa' : 'transparent',
        height: '36.7px',
        border: '1px solid #ececec',
        cursor: 'pointer',
        mr: 2,
      }}
    >
      <IconButton
        sx={{
          borderRadius: 0,
          borderRight: '1px #ececec solid',
        }}
        disabled={disabled || !documentSearchHighlightedResults?.length || currentResult === 1}
        onClick={onPreviousResult}
      >
        <ChevronLeft/>
      </IconButton>
      <Box
        sx={{
          borderRight: '1px #ececec solid',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingLeft: '4px',
          paddingRight: '4px',
          width: 50,
        }}
      >
        <span>
          {currentResult}/{documentSearchHighlightedResults?.length}
        </span>
      </Box>
      <IconButton
        sx={{
          borderRadius: 0,
        }}
        disabled={disabled || !documentSearchHighlightedResults?.length || currentResult === documentSearchHighlightedResults?.length}
        onClick={onNextResult}
      >
        <ChevronRight/>
      </IconButton>
    </Box>
  ) : null;
};
