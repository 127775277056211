import React, { useContext } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { LocalizationContext } from 'contexts';
import { useQueryState } from 'shared/hooks/useQueryState';

export const ImportantOnlyDropDown = () => {
  const { dictionary } = useContext(LocalizationContext);
  const [importantOnly, setImportantOnly] = useQueryState('important-only');

  return (
    <FormControlLabel
      label={dictionary.filters.fieldImportantOnly}
      control={
        <Checkbox
          defaultChecked={!!importantOnly}
          onChange={(event, checked) => {
            if (checked) {
              //@ts-ignore
              setImportantOnly(true);
            } else {
              setImportantOnly(undefined);
            }
          }}
        />
      }
    />
  );
};