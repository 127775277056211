import React from 'react';

const ENTER_KEYCODE = 13;

export const preventEnterKey = (event: React.KeyboardEvent<HTMLSpanElement>) => {
  if (event.key === 'Enter' || event.keyCode === ENTER_KEYCODE) {
    event.preventDefault();
    event.stopPropagation();
    return false;
  }
};
