import { Maybe, MaybeNull } from 'types';

export enum Dialog {
  InviteTeamMember = 'inviteTeamMember',
  QuestionAnswerDialog = 'questionAnswerDialog',
  PrintDocument = 'printDocument',
  NoteDialog = 'noteDialog',
};

export type DialogOptions = Maybe<any>;

export type ContextProps = {
  openDialog: (dialog: Dialog, options?: DialogOptions) => any;
  closeDialog: (dialog: Dialog) => any;
  asyncConfirmation: AsyncConfirmation;
  dialogOptions?: DialogOptions;
};

export type AsyncDialogProps = {
  show?: boolean;
  title?: MaybeNull<React.ReactNode>;
  content?: MaybeNull<React.ReactNode>;
  confirmLabel?: MaybeNull<React.ReactNode>;
  cancelLabel?: MaybeNull<React.ReactNode>;
}

export type AsyncConfirmation = (props: AsyncDialogProps) => Promise<boolean>;
