import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { Typography, Grid, Tooltip, Button } from '@mui/material';
import { Box } from '@mui/system';
import draftToHtml from 'draftjs-to-html';
import { EditorState, ContentState, convertToRaw } from 'draft-js';

import htmlToDraft from 'html-to-draftjs';
import { LayoutContext, LocalizationContext } from 'contexts';

interface Props {
  value?: string;
  onChange: (value: string) => void;
  onClose: () => void;
}

export const TooltipEditorInput: FC<Props> = ({ value, onChange, onClose }) => {
  const { mode } = useContext(LayoutContext);
  const { dictionary } = useContext(LocalizationContext);
  const ref: any = useRef();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (value) {
      const contentBlock = htmlToDraft(value);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
  }, [value]);

  // const onBlur = useCallback((e: any, state: EditorState) => {
  //   const html = draftToHtml(convertToRaw(state.getCurrentContent()));
  //   onChange(html);
  // }, [onChange]);

  return (
    <Box p={2} mt={2} sx={{ background: mode === 'light' ? '#fafafa' : '#000' }}>
      <Typography
        variant="body1"
        sx={{
          fontSize: '10px',
          marginTop: '-28px',
          marginBottom: '8px',
          background: mode === 'light' ? '#fafafa' : '#000',
          width: 'max-content',
          padding: '4px',
        }}
      >
        {dictionary.forms.fieldTooltip}
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Editor
            ref={ref}
            editorState={editorState}
            stripPastedStyles={true}
            wrapperStyle={{
              width: '100%',
              //  minHeight: 200,
            }}
            toolbar={{
              options: ['inline', 'link'],
              inline: {
                options: ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript'],
              },
              link: {
                linkCallback: (props: any) => {
                  setTimeout(() => {
                    ref.current.focusEditor();
                  }, 300);
                  return { ...props };
                },
              },
            }}
            onEditorStateChange={setEditorState}
            //@ts-ignore
            // onBlur={onBlur}
          />
        </Grid>

        <Grid item>
          <Tooltip title={dictionary.editableContent.editTooltip}>
            <Button variant="outlined" onClick={() => {
              const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
              onChange(html);
            }} type="button">
              {dictionary.editableContent.edit}
            </Button>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title={dictionary.editableContent.editTooltip}>
            <Button variant="outlined" onClick={onClose} type="button">
              {dictionary.dialogs.close}
            </Button>
          </Tooltip>
        </Grid>

      </Grid>
    </Box>
  );
};
