import { useCallback, useContext } from 'react';
import { LayoutContext, UserContext } from 'contexts';
import { usersClient } from 'clients/users/usersClient';


export const useSignup = () => {

  const { login } = useContext(UserContext);
  const { genericError } = useContext(LayoutContext);

  const signupAndLogin = useCallback(async (formData) => {
    try {
      await login(formData?.email, formData?.password);
    } catch (error: any) {
      genericError();
      return Promise.reject();
    }
  }, [login, genericError]);

  const signupAndLoginInvitation = useCallback(async (formData) => {
    try {
      await usersClient.signupWithEmailAndPasswordInvitation(formData);
      await login(formData?.email, formData?.password);
    } catch (error: any) {
      genericError();
      return Promise.reject();
    }
  }, [login, genericError]);

  return {
    signupAndLogin,
    signupAndLoginInvitation,
  };
};
