import React, { FC } from 'react';
import { Link } from '@mui/material';
import { truncate } from 'lodash';


export const downloadFile = (file: any) => window.open(file, '_blank');

const FileList: FC<any> = (
  {
    files,
  },
) => {
  return (
    <>
      {files.map((file: any) => (
        <Link
          key={file.id}
          fontWeight={300}
          fontSize={13}
          display="block"
          mt={1}
          sx={{
            wordBreak: 'break-word',
            color: 'black',
            cursor: 'pointer',
          }}
          onClick={() => downloadFile(file.file)}
        >
          {truncate(
            file.file?.split('/')?.reverse()?.[0],
            { length: 300 })
          }
        </Link>
      ))}
    </>
  );
};

export default FileList;
