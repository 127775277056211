import React, { FC, useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import { Box, Paper, Typography } from '@mui/material';

import { cacheKeys } from 'config';
import { LocalizationContext } from 'contexts';
import { documentsClient } from 'clients/documents/documentsClient';
import { ContentHistoryRecord } from './ContentHistoryRecord';


export const ContentHistory: FC<any> = ({ cla, section, article, block }) => {

  const { dictionary } = useContext(LocalizationContext);

  const [type = undefined, params = {}, getter = undefined, currentGetter = undefined] = useMemo(() => {
    if (cla) {
      return ['cla', { slug: cla.id }, documentsClient.getDocumentHistory, () => documentsClient.getDocument({
        slug: cla.id,
        flat: true,
      }, true, false)];
    } else if (section) {
      return ['section', { id: section.id }, documentsClient.getSectionHistory, () => documentsClient.getSection(section.id)];
    } else if (article) {
      return ['article', { id: article.id }, documentsClient.getArticleHistory, () => documentsClient.getArticle(article.id)];
    } else if (block) {
      return ['block', { id: block.id }, documentsClient.getBlockHistory, () => documentsClient.getBlock(block.id)];
    }
    return [];
  }, [cla, section, article, block]);

  const { data: { data: currentRecord = {} } = {} } = useQuery(
    [cacheKeys.history.getHistory, 'currentRecord', type, params],
    () => currentGetter && currentGetter(),
    { enabled: !!currentGetter },
  );

  const { data: { data: { results: records = [] } = {} } = {} } = useQuery(
    [cacheKeys.history.getHistory, type, params],
    () => getter && getter(params),
    { enabled: !!currentRecord && !!getter },
  );

  return (
    <Box p={2} component={Paper} sx={{ minWidth: 600 }}>
      <Typography variant="h6" align="center" sx={{ mb: 2 }}>
        {dictionary.editableContent.viewHistory}
      </Typography>
      <Box sx={{ maxHeight: '60vh', overflow: 'auto' }}>
        {records.map((record: any, index: number) => (
          <>
            <ContentHistoryRecord
              key={index}
              {...record}
              currentRecord={currentRecord}
              records={records}
              cla={cla}
              index={index}
            />
          </>
        ))}
      </Box>
    </Box>
  );
};
