import React, { FC, useContext, useRef } from 'react';
import { Box, Checkbox } from '@mui/material';
import classnames from 'classnames';

import { isEmpty } from 'lodash';
import { DocumentPrintContext } from 'contexts/DocumentPrintContext/DocumentPrintContext';
import { BlockVariant, DocumentItemType } from 'clients/documents/documentsClient.types';
import { ListItem } from 'modules/Document/List/ListItem';
import { getHeaderVariant } from 'modules/Document/Article/helpers';
import { getAddOrDeleteEntityColorForHistory, getModifiedEntityColorForHistory } from 'modules/Document/helpers';
import { useNotes } from 'shared/hooks/useNotes';
import FileButton from 'shared/components/form/FileButton/FileButton';
import { QuestionAnswerButton } from 'shared/components/form/QuestionAnswerButton/QuestionAnswerButton';

import { ContentBlock } from './ContentBlock';
import { Explanation } from './Explanation';
import { SpreadSheet } from './SpreadSheet/SpreadSheet';


export const Block: FC<any> = ({
  parent,
  block,
  type,
  titleVariant,
  blockStyle = {},
  listValue,
  siblings,
  showComments,
}) => {
  // todo idea. Move set padding bottom for the each element and avoid top + bottom

  const ref = useRef<HTMLElement>();

  if (block.variant === BlockVariant.Spreadsheet) {
    return (
      <Box
        ref={ref}
        className={
          classnames(
            {
              'is-full-renvoi': block.is_full_renvoi,
            },
          )
        }
      >
        <SpreadSheet
          block={block}
          style={{
            marginLeft: !!type ? '-15px' : undefined,
            ...blockStyle,
          }}
          parent={parent}
          siblings={siblings}
        />
      </Box>
    );
  } else if ([BlockVariant.Explanation, BlockVariant.Recommendation].includes(block.variant)) {
    const newOrDeletedColor = getAddOrDeleteEntityColorForHistory(block.id, 'block');

    return (
      <Explanation
        ref={ref}
        className={
          classnames(
            {
              'is-full-renvoi': block.is_full_renvoi,
            },
          )
        }
        style={{
          marginLeft: !!type ? '-30px' : undefined,
          border: newOrDeletedColor ? `8px solid ${newOrDeletedColor}` : undefined,
          ...blockStyle,
        }}
      >
        <ContentBlock
          parent={parent}
          titleVariant={getHeaderVariant(3)}
          block={block}
          siblings={siblings}
          isExplanationBlock
          showComments={showComments}
        />
      </Explanation>
    );
  }

  return (
    <ListItem
      ref={ref}
      type={block.display_list_formatting ? type : ''}
      style={blockStyle}
      value={listValue}
      displayListFormatting={block.display_list_formatting}
      showMarker={block.title || block.text_content}
    >
      <ContentBlock
        parent={parent}
        block={block}
        titleVariant={titleVariant || block.title_variant}
        siblings={siblings}
        showComments={showComments}
      />
    </ListItem>
  );
};

export const BlockWrapper: FC<any> = (props) => {
  const { isPrintMode, isPrintPreview, itemsToPrint, toggleItemToPrint } = useContext(DocumentPrintContext);
  const { groupByBlock } = useNotes();
  const modifiedColorOther = getModifiedEntityColorForHistory(props.block.id, 'block', 'other');

  return (
    <Box
      position="relative"
      className={
        classnames({
          'is-renvoi': props.block.is_renvoi,
          'is-full-renvoi': props.block.is_full_renvoi,
          'document-child-item': props.allowPrintSelection,
        })
      }
      id={props.block.slug}
      sx={{
        border: !!modifiedColorOther ? `4px dashed ${modifiedColorOther}`: undefined,
        background: [BlockVariant.Explanation, BlockVariant.Recommendation, BlockVariant.Spreadsheet].includes(props.block.variant) ? undefined : getAddOrDeleteEntityColorForHistory(props.block.id, 'block'),
      }}
    >
      {(props.allowPrintSelection && isPrintMode && !isPrintPreview) && (
        <span data-slug={props.block.slug}>
          <Checkbox
            key={`${itemsToPrint[props.block.slug]}`}
            defaultChecked={itemsToPrint[props.block.slug]}
            size="small"
            className="print-selection-checkbox"
            sx={{ ml: -2, mt: -0.5 }}
            onChange={event => toggleItemToPrint(props.block.slug, event.target.checked)}
          />
        </span>
      )}
      {!props.block.title && props.showComments && (
        <FileButton
          block={props.block}
          sx={
            props.block.has_questions ? (
              {
                position: 'absolute',
                top: 0,
                left: '-48px',
              }
            ) : (
              {
                position: 'absolute',
                top: 0,
                right: '-20px',
              }
            )
          }
        />
      )}
      <Block {...props}/>
      {props.showComments && (props.block.has_questions || !isEmpty(groupByBlock[props.block.id])) && !props.block.title && (
        <QuestionAnswerButton
          item={props.block}
          itemType={DocumentItemType.Block}
          itemQuestions={props.block.questions}
          sx={{
            position: 'absolute',
            top: 0,
            right: '-20px',
          }}
        />
      )}
    </Box>
  );
};
