import { warnOnMissingTranslations } from 'helpers/translationHelpers';
import { dictionary as dictionaryType } from './en';

export const nl: Partial<typeof dictionaryType> = {
  home: 'Home',
  loading: 'Bezig met laden',
  results: 'Resultaten',
  myProfile: 'Mijn profiel',
  myAccount: 'Mijn account',
  subscriptions: 'Abonnementen',
  login: 'Log In',
  logOut: 'Log uit',
  signUp: 'Inschrijven',
  fullName: 'Voor- en achternaam',
  name: 'Naam',
  surname: 'Achternaam',
  email: 'E-mail',
  phone: 'Telefoon',
  password: 'Wachtwoord',
  thePasswordMustBeAtLeast8CharactersLong: 'Het wachtwoord moet minimaal 8 tekens lang zijn',
  repeatPassword: 'Herhaal wachtwoord',
  userWithThisEmailAlreadyExists: 'Gebruiker met dit e-mailadres bestaat al',
  accountAlreadyExists: 'Er is al een account geregistreerd met dit e-mailadres.',
  please: 'Alsjeblieft',
  orUseAnotherEmail: 'of gebruik een ander e-mailadres.',
  passwordsDoNotMatch: 'Wachtwoorden komen niet overeen',
  signUpWithGoogle: 'Aanmelden met Google',
  signUpWithMicrosoft: 'Aanmelden bij Microsoft',
  loginWithGoogle: 'Inloggen met Google',
  loginWithMicrosoft: 'Inloggen met Microsoft',
  lastUpdated: 'Laatst bijgewerkt',
  publishedDate: 'Datum Gepubliceerd',
  publishDate: 'Publiceer Datum',
  tableOfContents: 'Inhoudsopgave',
  insertText: 'Tekst invoegen',
  share: 'Deel',

  auth: {
    or: 'of',
    dontHaveAccount: 'Heb je geen account?',
    alreadyHaveAccount: 'Heb je al een account?',
    buttonSignIn: 'Inloggen',
    buttonCreateAccount: 'Account aanmaken',

    validation: {
      emailOrPasswordWrong: 'E-mail of wachtwoord onjuist',
    },
  },

  impersonate: {
    noToken: 'Token is niet aanwezig',
    invalidToken: 'Token is ongeldig of verlopen',
  },

  somethingWentWrong: 'Er is iets fout gegaan!',
  oneOrMoreFieldsAreIncorrect: 'Een of meer velden zijn onjuist.',
  edit: 'Bewerk',
  open: 'Open',
  delete: 'Verwijder',
  save: 'Opslaan',
  saveAndClose: 'Opslaan en sluiten',
  view: 'Weergave',
  date: 'Datum',
  dueDate: 'Datum akkoord',
  dates: 'Datums',
  time: 'Tijd',
  duration: 'Duur',
  comingSoon: 'Komt binnenkort',
  toggleLighDarkMode: 'Schakelen tussen licht-/donkermodus',
  previous: 'Vorig',
  next: 'Volgende',

  hour: 'Uur',
  hours: 'Uren',
  minutes: 'Minuten',

  resetPassword: 'Wachtwoord opnieuw instellen',
  reset: 'Resetten',
  passwordResetEmailSent: 'Wachtwoord reset e-mail verzonden',

  notFound: 'Niet gevonden',

  progress: 'Voortgang',
  complete: 'Compleet',
  downloads: 'Downloads',

  join: 'Meedoen',

  darkMode: 'Donkere modus',
  lightMode: 'Lichte modus',

  switchToDarkMode: 'Overschakelen naar donkere modus',
  switchToLightMode: 'Overschakelen naar de lichte modus',

  draft: 'Ontwerp',
  in_review: 'In beoordeling',
  approved: 'Goedgekeurd',

  download: 'Downloaden',

  search: 'Zoek',
  notifications: 'Meldingen',
  seeNotifications: 'Zie meldingen',

  hello: 'Hallo',
  assignee: 'Gemachtigde',
  status: 'Toestand',

  cancel: 'Annuleren',
  all: 'Alle',
  submit: 'Bevestig',

  description: 'Omschrijving',
  attachments: 'Bijlagen',

  resetFilters: 'Filters resetten',
  type: 'Type',

  no: 'Nee',
  yes: 'Ja',

  none: 'Geen',

  noResults: 'Er zijn geen resultaten die overeenkomen met je selectie',

  changesSavedSuccessfully: 'Wijzigingen succesvol opgeslagen!',

  export: 'Exporteren als',
  toggleFilters: 'Schakel filters in',
  section: 'Sectie',
  partial: 'Gedeeltelijk',
  full: 'Vol',

  add: 'Toevoegen',

  confirmation: {
    defaultTitle: 'Weet je het zeker?',
    defaultContent: 'Deze actie is onomkeerbaar.',
    cannotEditTitle: 'Kan item niet bewerken',
    cannotEditDuplicateContent: 'Dit item is een duplicaat, om dit item te bewerken moet je het originele item bewerken.',
    cannotPublishTitle: 'Kan item niet publiceren',
    cannotPublishDuplicateContent: 'Dit item is een duplicaat, om dit item te publiceren moet u het originele item publiceren.',
    cannotDeleteOriginalTitle: 'Kan item niet verwijderen',
    cannotDeleteOriginalContent: 'Dit item heeft duplicaten, u kunt het pas verwijderen als u alle duplicaten hebt verwijderd.',
    no: 'Nee',
    yes: 'Ja',
    ok: 'Ok',
  },

  welcome: {
    title: 'Dankjewel voor het aanmelden.',
    subtitle: 'Je ontvangt binnenkort een bevestigingsmail.',
    buttonStart: 'Start',
  },

  company: {
    accountManagerLabel: 'Account Manager',
    primaryContactLabel: 'Primair contact',
    selectCompanyLabel: 'Bekijk projecten',
    title: 'Bedrijf',

    create: {
      title: 'Bedrijf maken',
      buttonCreate: 'Bedrijf aanmaken',
    },

    edit: {
      addUserTitle: (company: string) => `Gebruiker toevoegen aan ${company}`,
      buttonEdit: 'Bedrijf bewerken',
      buttonDelete: 'Bedrijf verwijderen',
      buttonAddUser: 'Voeg gebruiker toe',
      deleteConfirmation: 'Weet je zeker dat je dit bedrijf wilt verwijderen?',
      editTheCompanyName: 'Bewerk de bedrijfsnaam',
      placeholderCompanyName: 'Bedrijfsnaam',
      feedbackCompanyNameUpdated: 'De bedrijfsnaam is succesvol gewijzigd.',
    },
  },

  companies: {
    title: 'bedrijven',
  },

  user: {
    title: 'Gebruiker',
    typeCustomerLabel: 'Klant',
    typeAdminLabel: 'Beheerder',

    create: {
      title: 'Gebruiker maken',
      buttonCreate: 'Gebruiker maken',
    },

    edit: {
      buttonEdit: 'Bewerk gebruiker',
      buttonDelete: 'Verwijder gebruiker',
      deleteConfirmation: 'Weet je zeker dat je deze gebruiker wilt verwijderen?',
      deleteOwnAccountConfirmation: 'Weet je zeker dat je je eigen account wilt verwijderen?',
    },

    companyLabel: 'Bedrijf',
    jobTitleLabel: 'Functietitel',
    adminLabel: 'FunctieBeheerder',
  },

  users: {
    title: 'Gebruikers',
  },

  document: {
    form: {
      fieldSearch: 'Zoekopdracht',
      noMatchingArticles: 'Er zijn geen artikelen die overeenkomen met deze zoekopdracht',
      typeToSearch: 'Type om te zoeken',
    },
    files: 'Bestanden',
    footnotesTitle: 'Voetnoten',
    duplicateTag: 'duplicaat van',
  },

  faq: {
    faq: 'Veelgestelde vragen',
    linkedArticles: 'Gelinkte artikelen',
    linkedBlocks: 'Gelinkte blokken',
    searchArticles: 'Zoek Artikelen',
    searchBlocks: 'Zoekblokken',
    frequentlyAskedQuestions: 'Veel Gestelde Vragen',
    relatedBlocks: 'Gerelateerde Blokken',
    relatedArticles: 'Gerelateerde Artikelen',
    addQA: 'FAQ toevoegen',
    editQA: 'FAQ bewerken',
    linkedItemValidation: 'Je moet minimaal 1 gelinkt item opgeven',
    empty: 'Nul vragen gevonden',
  },

  notes: {
    notes: 'Opmerkingen',
    empty: 'Er zijn geen notities',
    title: 'Titel',
    text: 'Tekst',

    modal: {
      addTitle: 'Opmerking toevoegen',
      editTitle: 'Opmerking bewerken',

      notifications: {
        created: 'De notitie is succesvol aangemaakt.',
        edited: 'De notitie is met succes bewerkt.',
        deleted: 'De notitie is succesvol verwijderd.',
        error: 'Er ging iets mis tijdens de aanvraag.',
      },
    },
  },

  filesOverview: {
    title: 'Overzicht bestanden',
    empty: 'Er zijn geen bestanden',
  },

  printDialog: {
    title: 'Exporteren als',
    form: {
      entireDocument: 'Gehele document',
      version: 'Versie',
      buttonLabels: {
        printFull: 'Print',
        printPartial: 'Print selection',
        docxFull: 'Exporteer',
        docxPartial: 'Exporteer selectie',
      },
    },
    format: {
      print: 'Afdrukken / PDF',
    },
  },

  team: {
    title: 'Teamleden',
    detailsLabel: 'Details',
    jobTitleLabel: 'Functietitel',
    openQueriesLabel: 'Query\'s openen',
    notificationsLabel: 'E-mail notificaties',
    unknownLabel: 'Onbekende functietitel',
    buttonInvite: 'Teamleden uitnodigen',
  },

  invite: {
    dialog: {
      title: 'Teamleden uitnodigen',
      placeholderEmail: 'E-mail, door komma\'s gescheiden',
      buttonInvite: 'Uitnodiging sturen',
      invitationSentInfo: (email: string) => `Uitnodiging voor ${email} verzonden`,
      userType: 'Gebruikerstype',
    },

    title: 'Teamuitnodiging',
    subtitle: (company?: string) => company ? `Je bent uitgenodigd om lid te worden van het team van ${company}` : 'Je bent uitgenodigd om lid te worden van een team',
    buttonAcceptInvitation: 'Account aanmaken',
  },

  menu: {
    home: 'Home',
    documents: 'Documenten',
    help: 'Helpen',
    faq: 'FAQ en opmerkingen',
    dashboard: 'Dashboard',
    companies: 'Bedrijven',
    users: 'Gebruikers',
    team: 'Team',
    manual: 'Handleiding',
  },

  filters: {
    search: 'Zoekopdracht',
    label: 'Filters',
    fieldStatus: 'Toestand',
    fieldDateFrom: 'Datum vanaf',
    fieldDateTo: 'Datum tot',
    fieldImportantOnly: 'Alleen A-bepalingen',
    buttonSort: 'Sorteer',
    buttonFilter: 'Filter',

    transaction: {
      fieldScope: 'Domein',
    },
  },

  dialogs: {
    defaultTitle: 'Weet je zeker dat je dit wilt doen?',
    defaultContent: 'Deze actie is onomkeerbaar.',
    no: 'Nee',
    yes: 'Ja',
    cancel: 'Annuleren',
    close: 'Sluit',
    buttonCancel: 'Annuleren',
  },

  forms: {
    fieldEmail: 'E-mail',
    fieldPassword: 'Wachtwoord',
    fieldPhone: 'Telefoon',
    fieldCountry: 'Land',
    fieldCompany: 'Bedrijf',
    fieldTitle: 'Titel',
    fieldSubtitle: 'Subtitel',
    fieldDescription: 'Omschrijving',
    fieldAuthGroup: 'Gebruikersgroep',
    fieldTooltip: 'Knopinfo',
    fieldQuestion: 'Vraag',
    fieldAnswer: 'Antwoorden',
    copyUrlToShare: 'URL kopiëren om te delen',
    colorPickerInvalidError: 'Moet een geldige hexadecimale kleur zijn die begint met "#"',

    signup: {
      fieldRepeatPassword: 'Herhaal wachtwoord',
    },

    company: {
      fieldName: 'Naam',
      fieldAccountManager: 'Account Manager',
      fieldPrimaryContact: 'Primair contact',
      fieldPreferredLanguage: 'Voorkeurstaal',
    },

    user: {
      fieldFirstName: 'Naam',
      fieldLastName: 'Achternaam',
      fieldType: 'Type',
      fieldJobTitle: 'Functietitel',
      fieldAdmin: 'Beheerder',
      fieldPicture: 'Upload foto',

      fieldEmailDisabledHelper: 'Neem contact op met de ondersteuning om het e-mailadres te wijzigen.',
    },

    validations: {
      required: 'Dit veld is verplicht',
      titleOrDescriptionRequired: 'Voer de titel of beschrijving in',
      invalidDate: 'Datum is ongeldig',
      invalidEmail: 'Email is ongeldig',
      invalidPhone: 'Telefoonnummer is ongeldig',
      invalidPassword: 'Wachtwoord is ongeldig. Er is minimaal 1 nummer nodig.',
      invalidFileSize: (max: string) => `Bestand is te groot. Maximaal is ${max}`,
      invalidFileType: 'Bestandstype is niet geldig',
      minLength: (length: number | string) => `Moet minimaal ${length} tekens hebben`,
      maxLength: (length: number | string) => `Mag niet meer dan ${length} tekens bevatten`,

      memberInvitationAllEmailsValid: 'Een of meer e-mails zijn ongeldig.',
      companyContactRequired: 'Bedrijf vereist een accountmanager en primaire contactpersoon',
    },
    delete: 'Verwijderen',
    save: 'Opslaan',
    attach: 'Bijvoegen',
    upload: 'Uploaden',
    saveChanges: 'Wijzigingen opslaan',
  },

  routes: {
    home: 'Home',
    login: 'Log in',
    signup: 'Inschrijven',
    documents: 'Documenten',
    document: 'Document',
    publicDocument: 'Document',
    documentPrintPreview: 'Document',
    team: 'Team',
    myAccount: 'Mijn account',
    invite: 'Uitnodiging',
    resetPassword: 'Reset wachtwoord',
  },

  editableContent: {
    title: 'Titel',
    copyLink: 'Kopieer link',
    scrollOppositeWindow: 'Scrollen naar in het Tegenovergestelde Venster',
    linkCopiedSuccessfully: 'Link gekopieerd naar klembord',
    order: 'Volgorde',
    numbering: 'Tellen',
    body: 'Body',
    type: 'Type',
    sibling: 'Zuster',
    child: 'Kind',
    variant: 'Variant',
    descendants: 'Afstammelingen',
    childrenFormat: 'Formaat kinderen',
    text: 'Text',
    html: 'HTML',
    explanation: 'Uitleg',
    recommendation: 'Aanbeveling',
    spreadsheet: 'Spreadsheet',
    none: 'Geen',
    dot: 'Punt',
    dash: 'Streepje',
    arabicNumbers: 'Cijfers',
    romanNumerals: 'Romeinse cijfers',
    alphabetLowercase: 'Kleine letters',
    alphabetUppercase: 'Hoofdletters',
    add: 'Toevoegen',
    addCla: 'CAO toevoegen',
    addSection: 'Sectie toevoegen',
    addArticle: 'Artikel toevoegen',
    addContent: 'Content toevoegen',
    addSiblingSectionOrChildArticle: 'Voeg een sectie of artikel over een kind toe',
    addTooltip: 'Knopinfo toevoegen',
    editTooltip: 'Knopinfo bewerken',
    addDuplicate: (title: string) => `Duplicaat Toevoegen: ${title}`,
    addDuplicateButton: 'Duplicaat Toevoegen',
    edit: 'Bewerk',
    editCla: 'CAO bewerken',
    editSection: 'Bewerk sectie',
    editArticle: 'Artikel bewerken',
    editContent: 'Inhoud bewerken',
    delete: 'Verwijderen',
    deleteCla: 'CAO verwijderen',
    deleteSection: 'Sectie verwijderen',
    deleteArticle: 'Artikel verwijderen',
    deleteContent: 'Inhoud verwijderen',
    removeTooltip: 'Knopinfo verwijderen',
    sort: 'Sorteer',
    nest: 'Spring in',
    unNest: 'Spring uit',
    files: 'Bestanden',
    saveChanges: 'Wijzigingen opslaan',
    viewHistory: 'Bekijk geschiedenis',
    isImportant: 'A-bepalingen', //FIXME: Is specific to metalektro
    renvoi: 'Gedeeltelijke renvooi',
    renvoi_helptext: 'In deze modus kan door middel van \'doorstrepen\' worden aangegeven welk gedeelte is gerenvooieerd.',
    full_renvoi: 'Volledig renvooieren',
    full_renvoi_helptext: 'In deze modus worden de titels en teksten van het artikel inclusief alle \'kind-artikelen\' gerenvooieerd.',
    renvoi_disclaimer: 'Als op een bovenliggend artikel de modus \'Volledig renvooieren\' is ingeschakeld is de modus \'gedeeltelijke renvooi\' niet mogelijk.',
    sectionTitlesColor: 'Titels kleur',
    is_toc_include_children: 'Kinderen opnemen in de Inhoudsopgave',
    is_toc_include_children_helptext: 'Indien aangevinkt worden alle titels van de kinderen in de inhoudsopgave getoond.',
    displayListFormatting: 'Lijst formaat',
    showMore: 'Meer opties',
    remove: 'Verwijderen',
    publish: 'Publiceren',
    unknown: 'Onbekend',
    questionAnswer: 'FAQ toevoegen',
    note: 'Opmerking toevoegen',
    editInDifferentLanguage: 'Bewerk inhoud in een andere taal',
    errorUnsavedChangesOnTranslationsSwitch: 'Dit veld is gewijzigd, sla het formulier op voordat u andere vertalingen bewerkt',
  },

  editableTable: {
    columnActions: 'Kolom Acties',
    table: 'Tabel',
    noLabel: 'Geen Label',
    writeMode: 'Schrijfmodus',
    addColumnBefore: 'Kolom invoegen voor',
    addColumnAfter: 'Kolom invoegen na',
    duplicateColumn: 'Dupliceer kolom',
    deleteColumn: 'Kolom verwijderen',
    sortRows: 'Rijen sorteren',
    sortColumns: 'Kolommen sorteren',
    addRowBefore: 'Rij invoegen voor',
    addRowAfter: 'Rij invoegen na',
    deleteRow: 'Verwijder rij',
    duplicateRow: 'Dupliceer rij',
  },

  renvoiMode: {
    normal: 'Geen renvooi',
    strikethrough: 'Renvooi doorhalen',
    removed: 'Renvooi weglaten',
  },

  documentStatuses: {
    read: 'Gepubliceerd',
    write: 'Ontwerp',
    review: 'Beoordeling',
  },

  languages: {
    language: 'Taal',
    english: 'Engels',
    dutch: 'Nederlands',
    switchToEnglish: 'Switch to English',
    switchToDutch: 'Overschakelen naar Nederlands',
  },

  documentShare: {
    modal: {
      title: 'Openbaar Documenten delen',
      mode: 'Modus',
      modes: {
        normal: 'Normaal',
        draft: 'Ontwerp',
      },
      link: 'URL-link',
      textCopied: 'URL-link gekopieerd naar het klembord',
      copyLink: 'Kopieer link',
    },
  },

  publicDocument: {
    error: {
      title: 'You are not allowed to view this draft document',
      description: 'Seems that your link is expired or invalid. Ask your supervisor to share the new link again or log in and generate link by yourself.',
    },
  },

  authGroups: {
    moderator: 'Moderator',
    customer: 'Klant',
    admin: 'Beheerder',
    controller: 'Controleur',
  },
};


export const dictionary = {
  ...dictionaryType,
  ...nl,
};


warnOnMissingTranslations(nl, 'nl');
