import React, { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import { routes } from 'config';
import { LayoutContext } from 'contexts/LayoutContext/LayoutContext';
import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';
import { useInvitation } from 'shared/hooks/useInvitation';

import { useSignup } from 'shared/hooks/useSignup';
import { useQueryState } from 'shared/hooks/useQueryState';
import { LoggedOutActionsLayout } from 'shared/components/layout/LoggedOutActionsLayout/LoggedOutActionsLayout';
import { UserSignupForm } from './partials/UserSignupForm';

export const Invite = () => {

  const navigate = useNavigate();
  const { genericError } = useContext(LayoutContext);
  const { dictionary } = useContext(LocalizationContext);
  const [id] = useQueryState('id');
  const { status, invite } = useInvitation({ id: id || '' }, {
    enabled: !!id,
  });

  const { signupAndLoginInvitation } = useSignup();

  const onSubmit = useCallback(async (data: any) => {
    try {
      await signupAndLoginInvitation(data);
      navigate(routes.home);
    } catch(error: any) {
      console.error(error);
      genericError();
    }
  }, [genericError, signupAndLoginInvitation, navigate]);

  return (
    <LoggedOutActionsLayout>
      <Typography variant="h4" align="center">{dictionary.invite.title}</Typography>
      <Typography variant="body2" align="center">{dictionary.invite.subtitle(invite?.company?.name)}</Typography>
      <Box pt={4}>
        {status === 'success' && (
          <UserSignupForm
            disabledFields={['company']}
            defaultValues={{ userInvitationId: id, email: invite?.email, authGroupId: invite?.authGroupId }}
            onSubmitRequest={onSubmit}
            onSubmitButtonText={dictionary.invite.buttonAcceptInvitation}
          />
        )}
      </Box>
    </LoggedOutActionsLayout>
  );
};
