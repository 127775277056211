import { MaybeNull } from 'types';
import { AuthGroup } from 'clients/groups/groupClient.types';

export type UserSignupWithEmailAndPasswordApi = {
    company?: string;
    email: string;
    first_name: string;
    job_title?: string;
    last_name: string;
    password: string;
    phone?: string;
    repeated_password: string;
    user_invitation_id?: string;
    auth_group_id?: number;
};

export type UserSignupWithEmailAndPassword = {
    company?: MaybeNull<string>;
    email: string;
    firstName: string;
    jobTitle?: MaybeNull<string>;
    lastName: string;
    password: string;
    repeatPassword: string;
    userInvitationId?: MaybeNull<string>;
    authGroupId: number;
};

export type UserCrudApi = {
    company_id?: number;
    email: string;
    first_name: string;
    job_title?: string;
    last_name: string;
    phone?: MaybeNull<string>;
    profile_picture?: File;
    type?: UserType;
};

export type UserCreateApi = UserCrudApi;

export type UserEditApi = UserCrudApi & {
    id: number;
}

export type UserCrud = {
    firstName: string;
    lastName: string;
    phone?: MaybeNull<string>;
    jobTitle: string;
    email: string;
    company?: MaybeNull<any>;
    type?: UserType;
    profilePicture?: File | string;
};

export type UserCreate = UserCrud;

export type UserEdit = UserCrud & {
    id: number;
}

export type SimpleUserApi = {
    id: number;
    first_name: string;
    last_name: string;
    job_title: string;
    email: string;
    type: UserType;
    profile_picture: MaybeNull<string>;
}

export type UserApi = SimpleUserApi & {
    firebase_uid: string;
    phone: string;
    company: MaybeNull<any>;
    auth_group: AuthGroup;
}

export type SimpleUser = {
    id: number;
    displayName: string;
    firstName: string;
    lastName: string;
    jobTitle: string;
    email: string;
    type: UserType;
    profilePicture?: string;
}

export type User = SimpleUser & {
    firebaseUid: string;
    phone: MaybeNull<string>;
    company: MaybeNull<any>;
    authGroup: AuthGroup;
}

export type UserRowType = User & {
    editable: boolean;
    isCurrentUser: boolean;
}

export enum UserType {
    Customer = 'Customer',
    Admin = 'Admin',
}

export type GetUsersParams = Partial<{
    type: string;
    search: string;
    company: string | number;
    limit: number;
    offset: number;
}>;

export type UserInviteApi = {
    id: string;
    email: string;
    company?: any;
    auth_group_id?: number
}

export type UserInvite = {
    id: string;
    email: string;
    company?: any;
    authGroupId?: number;
}
