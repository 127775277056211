import React, { FC, createContext, useState, useEffect, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import enLocale from 'date-fns/locale/en-GB';
import nlLocale from 'date-fns/locale/nl';

import { parseQuery } from 'helpers/query';
import { dictionary as en } from './languages/en';
import { dictionary as nl } from './languages/nl';
import { ContextProps, LocaleItem, SupportedLanguage } from './types';

const LANGUAGES: LocaleItem = {
  nl: 'nl',
  en: 'en',
};

const localeLocalStorageKey = 'claLanguage';

//@ts-ignore
const browserLocale = window?.navigator?.userLanguage || window?.navigator?.language;

const defaultContext: ContextProps = {
  language: browserLocale && browserLocale.slice(0, 2) === LANGUAGES.nl ? LANGUAGES.nl : LANGUAGES.en,
  dictionary: nl,
  setLanguage: () => null,
  locale: nlLocale,
  localizePrice: () => '',
  availableLanguages: Object.values(LANGUAGES),
};


const getDefaultLanguage = (): SupportedLanguage => {
  const lang = parseQuery(window.location.search).language as SupportedLanguage | undefined
    || localStorage.getItem(localeLocalStorageKey) as SupportedLanguage
    || defaultContext.language;
  if ([LANGUAGES.nl, LANGUAGES.en].includes(lang)) {
    return lang;
  }
  return LANGUAGES.nl;
};


export const LocalizationContext = createContext(defaultContext);


export const LocalizationContextProvider: FC = ({ children }) => {
  const defaultLanguage = getDefaultLanguage();
  const [language, setLanguage] = useState<SupportedLanguage>(defaultLanguage as SupportedLanguage);

  const queryClient = useQueryClient();

  const dictionaries = { en, nl };
  const dictionary = dictionaries[language as SupportedLanguage];

  // todo what is that? seems to be unused
  const localizePrice = useCallback((price: number | string) => {
    if (!price) {
      return price;
    }
    const stringPrice = typeof price === 'string' ? parseFloat(price) : price;
    switch (language) {
      case LANGUAGES.nl:
        return stringPrice.toFixed(2).replace('.', ',');
      default:
        return stringPrice.toFixed(2);
    }
  }, [language]);


  useEffect(() => {
    localStorage.setItem(localeLocalStorageKey, language);
    queryClient.invalidateQueries();
  }, [language, queryClient]);

  // why do we have defaults then?
  return (
    <LocalizationContext.Provider
      value={{
        language,
        dictionary,
        setLanguage,
        localizePrice,
        // todo why do we have 2 default value logic pieces. See the defaultContext
        locale: language === 'en' ? enLocale : nlLocale,
        availableLanguages: defaultContext.availableLanguages,
      }}
    >
      {children}
    </LocalizationContext.Provider>
  );
};
