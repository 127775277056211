import React, { FC, Fragment, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Badge, Grid, IconButton, Tooltip, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  Add,
  Clear,
  Sort,
  Edit,
  FormatIndentIncrease,
  FormatIndentDecrease,
  HistoryToggleOff, Link, ContentCopy, Publish, ChevronRight,
  FilePresent,
  QuestionAnswerOutlined,
} from '@mui/icons-material';

import TagIcon from '@mui/icons-material/Tag';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import { useBoundStore } from 'store';
import { isEmpty } from 'lodash';
import { cacheKeys, routes } from 'config';
import { DialogContext, LayoutContext, LocalizationContext, UserContext } from 'contexts';
import { Dialog } from 'contexts/DialogContext/types';
import { SectionContext } from 'contexts/SectionContext/SectionContext';
import { isDateValid } from 'helpers/date';
import { preventEnterKey } from 'helpers/events';
import { cleanHtmlEditorText, trimHtmlEol } from 'helpers/string';
import { documentsClient } from 'clients/documents/documentsClient';
import { BlockVariant, DocumentItemType } from 'clients/documents/documentsClient.types';
import { useQueryState } from 'shared/hooks/useQueryState';
import { useOutsideClick } from 'shared/hooks/useOutsideClick';
import { ContentDescendants } from 'shared/components/ContentDescendants/ContentDecendants';
import { deleteItemWithDescendents } from './helpers/requests';


const useTooltipStyles = makeStyles(() => ({
  tooltipPlacementLeft: {
    marginRight: '40px !important',
  },
}));

const defaultNewSpreadSheet = {
  style: new Array(4).fill('').map((_, i) => ({ label: `Column ${i + 1}` })),
  values: new Array(5).fill('').map((_, i) => new Array(4).fill('').map((_, i) => ({ label: '' }))),
};


export type EditableContentProps = {
  cla?: any;
  section?: any;
  article?: any;
  articleNumber?: any;
  block?: any;
  field?: string;
  previousSibling?: any;
  parent?: any;
  siblings?: any[];
  canAdd?: boolean;
  canEdit?: boolean;
  canDelete?: boolean;
  canViewHistory?: boolean;
  canAddFile?: boolean,
  canAddFAQ?: boolean,
  canAddNote?: boolean,
  disableEnterKey?: boolean;
  canScrollOppositeWindow?: boolean
  setIsHovered?: any;
}

export const EditableContentWrapped: FC<EditableContentProps> = ({
  cla,
  section,
  article,
  block,
  articleNumber,
  children,
  parent,
  siblings = [],
  field = 'title',
  canAdd,
  canEdit,
  canDelete,
  canAddFile,
  canAddFAQ,
  canAddNote,
  canViewHistory,
  canScrollOppositeWindow,
  disableEnterKey,
  setIsHovered,
}) => {

  const navigate = useNavigate();
  const tooltipClass = useTooltipStyles();
  const { setLayoutTempState, setSharedPopupOpen, genericError, setSnackbarState } = useContext(LayoutContext);
  const { asyncConfirmation, openDialog } = useContext(DialogContext);
  const { language, dictionary } = useContext(LocalizationContext);
  const { sectionId: parentSectionId } = useContext(SectionContext);
  const { userPermissions } = useContext(UserContext);
  const queryClient = useQueryClient();
  const ref = useRef<any>();
  const [focused, setFocused] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [claSlug] = useQueryState('slug');

  const theme = useTheme();

  const itemHasDuplicates = useMemo(() => {
    return (block || article)?.has_duplicates;
  }, [article, block]);

  const itemIsDuplicate = useMemo(() => {
    return (block || article)?.original;
  }, [article, block]);

  const itemIsChildOfDuplicate = useMemo(() => {
    return (block || article)?.is_child_of_duplicate;
  }, [article, block]);

  const filteredSiblings = useMemo(() => {
    return siblings.filter(({ id }) => id !== article?.id && id !== block?.id);
  }, [siblings, article, block]);

  const previousSibling = useMemo(() => {
    const previousIndex = ((block || article)?.index || 0) - 1;
    if (previousIndex >= 0) {
      return siblings && siblings?.[previousIndex];
    }
  }, [block, article, siblings]);

  const onFocus = useCallback(() => {
    ref.current.focus();
    setFocused(true);
  }, []);

  const onSave = useCallback(async (e = undefined) => {
    setIsSaving(true);
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    try {
      const html = ref.current.innerHTML;
      if (article?.id && cleanHtmlEditorText(html)) {
        await documentsClient.patchArticle(article?.id, { [`${field}_draft_${language}`]: html });
      } else if (block && field !== 'title') {
        if (cleanHtmlEditorText(html)) {
          await documentsClient.patchBlock(block?.id, { [`${field}_draft_${language}`]: html });
        } else {
          const placeholder = `<span>${dictionary.insertText}</span>`;
          await documentsClient.patchBlock(block?.id, { [`${field}_draft_${language}`]: placeholder });
        }
      } else if (section) {
        await documentsClient.patchSection(section?.id, { [`${field}_draft_${language}`]: html });
      } else if (cla) {
        await documentsClient.patchDocument(cla?.id, { [`${field}_draft_${language}`]: html });
        queryClient.invalidateQueries(cacheKeys.documents.getDocuments);
      }
      await queryClient.invalidateQueries(cacheKeys.documents.getDraftDocument);
      queryClient.invalidateQueries(cacheKeys.documents.getSearchDropdownData);
      queryClient.invalidateQueries(cacheKeys.history.getHistory);
    } catch (e) {
      genericError();
      console.error(e);
    }
    setFocused(false);
    setIsSaving(false);
    setIsDirty(false);
  }, [genericError, queryClient, language, field, article, block, section, cla, dictionary]);

  const onBlur = useCallback(async () => {
    setFocused(false);
  }, []);

  const onTabPress = useCallback(async () => {
    if (isDirty && !isSaving) {
      await onSave();
    }
    setFocused(false);
  }, [onSave, isDirty, isSaving]);

  const deleteLabel = useMemo(() => {
    let label = dictionary.editableContent.delete;
    if (cla) {
      label = dictionary.editableContent.deleteCla;
    }
    if (section) {
      label = dictionary.editableContent.deleteSection;
    }
    if (article) {
      label = dictionary.editableContent.deleteArticle;
    }
    if (block) {
      label = dictionary.editableContent.deleteContent;
    }
    return label;
  }, [dictionary, cla, section, article, block]);

  const onDelete = useCallback(async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (itemHasDuplicates || itemIsChildOfDuplicate) {
      await asyncConfirmation({
        title: itemHasDuplicates ? dictionary.confirmation.cannotDeleteOriginalTitle : dictionary.confirmation.cannotEditTitle,
        content: itemHasDuplicates ? dictionary.confirmation.cannotDeleteOriginalContent : dictionary.confirmation.cannotEditDuplicateContent,
        confirmLabel: dictionary.confirmation.ok,
        cancelLabel: '',
      });
      return;
    }

    setIsDeleting(true);
    ref.current.blur();

    const item = cla || article || block || section;

    const userConfirmed = await asyncConfirmation({
      title: dictionary.confirmation.defaultTitle,
      content: (
        <React.Fragment>
          {dictionary.confirmation.defaultContent}
          <ContentDescendants item={item} articleNumber={articleNumber}/>
        </React.Fragment>
      ),
    });
    if (userConfirmed) {
      setFocused(true);
      try {
        if (article?.id) {
          await deleteItemWithDescendents(article, DocumentItemType.Article);
        } else if (block?.id) {
          await deleteItemWithDescendents(block, DocumentItemType.Block);
        } else if (section?.id) {
          await deleteItemWithDescendents(section, DocumentItemType.Section);
        } else if (cla?.id) {
          await deleteItemWithDescendents(cla, DocumentItemType.Cla);
          navigate(routes.documents);
          await queryClient.invalidateQueries(cacheKeys.documents.getDocuments);
        }
      } catch (e) {
        genericError();
        console.error(e);
      }
      await queryClient.invalidateQueries(cacheKeys.documents.getDraftDocument);
      queryClient.invalidateQueries(cacheKeys.documents.getSearchDropdownData);
    }
    setFocused(false);
    setIsDeleting(false);
  }, [genericError, dictionary, navigate, cla, section, article, block, articleNumber, asyncConfirmation, queryClient, itemHasDuplicates, itemIsChildOfDuplicate]);

  const onFilesClick = useCallback(async (e) => {
    setSharedPopupOpen(true);
    setLayoutTempState({
      contentAttachments: true,
      cla,
      section,
      article,
      block,
      onSave: async () => {
      },
    });
  }, [cla, section, article, block, setSharedPopupOpen, setLayoutTempState]);

  const onSort = useCallback(async () => {
    if (itemIsDuplicate || itemIsChildOfDuplicate) {
      await asyncConfirmation({
        title: dictionary.confirmation.cannotEditTitle,
        content: dictionary.confirmation.cannotEditDuplicateContent,
        confirmLabel: dictionary.confirmation.ok,
        cancelLabel: '',
      });
      return;
    }

    setSharedPopupOpen(true);
    setLayoutTempState({
      sortContent: true,
      items: siblings,
      onSave: async (items: any) => {
        if (block?.id) {
          await documentsClient.patchBlocks(items.map(({ id }: any, order: number) => ({
            id,
            order_draft: order + 1,
          })));
        }
        if (article?.id) {
          await documentsClient.patchArticles(items.map(({ id }: any, order: number) => ({
            id,
            order_draft: order + 1,
          })));
        }
        if (section?.id) {
          await documentsClient.patchSections(items.map(({ id }: any, order: number) => ({
            id,
            order_draft: order + 1,
          })));
        }
        await queryClient.invalidateQueries(cacheKeys.documents.getDraftDocument);
        queryClient.invalidateQueries(cacheKeys.documents.getSearchDropdownData);
      },
    });
  }, [block, article, setSharedPopupOpen, setLayoutTempState, siblings, asyncConfirmation, dictionary, section?.id, queryClient, itemIsDuplicate, itemIsChildOfDuplicate]);

  const onPublish = useCallback(async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (itemIsChildOfDuplicate || itemIsDuplicate) {
      await asyncConfirmation({
        title: dictionary.confirmation.cannotPublishTitle,
        content: dictionary.confirmation.cannotPublishDuplicateContent,
        confirmLabel: dictionary.confirmation.ok,
        cancelLabel: '',
      });
      return;
    }

    if (claSlug) {
      setIsSaving(true);
      try {
        const userConfirmed = await asyncConfirmation({
          title: dictionary.confirmation.defaultTitle,
          content: dictionary.confirmation.defaultContent,
          confirmLabel: dictionary.yes,
        });
        if (userConfirmed) {
          await documentsClient.publishDocument(claSlug, {
            partial_publish: true,
            recursive_block_publish: false,
            recursive_article_publish: false,
            ...section && {
              sections: [section.id],
            },
            ...article && {
              articles: [article.id],
            },
            ...block && {
              blocks: [block.id],
            },
            ...cla && {
              publish_cla_metadata: true,
            },
          });
          queryClient.invalidateQueries(cacheKeys.documents.getDocument);
          queryClient.invalidateQueries(cacheKeys.history.getHistory);
          queryClient.invalidateQueries(cacheKeys.documents.getSearchDropdownData);
        }
      } catch (e) {
        console.error(e);
        genericError();
      }
      setIsSaving(false);
    }
  }, [dictionary, asyncConfirmation, claSlug, cla, section, article, block, genericError, queryClient, itemIsDuplicate, itemIsChildOfDuplicate]);

  const onQuestionAnswer = useCallback(async (event) => {
    if (itemIsDuplicate || itemIsChildOfDuplicate) {
      await asyncConfirmation({
        title: dictionary.confirmation.cannotEditTitle,
        content: dictionary.confirmation.cannotEditDuplicateContent,
        confirmLabel: dictionary.confirmation.ok,
        cancelLabel: '',
      });
      return;
    }
    openDialog(Dialog.QuestionAnswerDialog, {
      itemType: block ? DocumentItemType.Block : DocumentItemType.Article,
      linkedItem: block ? block : article,
    });
  }, [article, asyncConfirmation, block, dictionary, itemIsChildOfDuplicate, itemIsDuplicate, openDialog]);

  const addLabel = useMemo(() => {
    let label = dictionary.editableContent.add;
    if (cla) {
      label = dictionary.editableContent.addSection;
    }
    if (section) {
      label = dictionary.editableContent.addSiblingSectionOrChildArticle;
    }
    if (article) {
      label = dictionary.editableContent.addArticle;
    }
    if (block) {
      label = dictionary.editableContent.addContent;
    }
    return label;
  }, [dictionary, cla, section, article, block]);

  const onAdd = useCallback(async () => {
    if (itemIsChildOfDuplicate || itemIsDuplicate) {
      await asyncConfirmation({
        title: dictionary.confirmation.cannotEditTitle,
        content: dictionary.confirmation.cannotEditDuplicateContent,
        confirmLabel: dictionary.confirmation.ok,
        cancelLabel: '',
      });
      return;
    }

    setSharedPopupOpen(true);
    setLayoutTempState({
      parentSectionId,
      title: addLabel,
      contentForm: true,
      articleNumber,
      isAddingFromSection: !!section,
      isAddingFromArticle: !!article,
      isAddingFromBlock: !!block,
      fields: [
        'title',
        'description',
        'variant',
        'is_important',
        'is_renvoi',
        'is_full_renvoi',
        ...(articleNumber || '').split('.').length < 3 ? ['type'] : [],
        (block?.section_id && !block?.article_id && !block?.block_id) ? 'is_toc_include_children' : null,
      ],
      ...section && {
        fields: ['title', 'description', 'type'],
      },
      ...cla && {
        fields: ['title', 'description', 'titles_color'],
      },
      onSave: async (data: any) => {
        if (data?.original_draft) {
          if (block) {
            await documentsClient.createBlock({
              cla: claSlug,
              original_draft: data?.original_draft,
              is_important_draft: data?.is_important,
              is_renvoi_draft: data?.is_renvoi,
              is_full_renvoi_draft: data?.is_full_renvoi,
              is_toc_include_children_draft: data?.is_toc_include_children,
              display_list_formatting_draft: data?.display_list_formatting,
              [`title_draft_${language}`]: data?.title,
              [`text_content_draft_${language}`]: data?.description,
              ...block?.block_id && {
                block_draft: data?.type === 'sibling' ? block?.block_id : block?.id,
                article_draft: null,
                section_draft: null,
              },
              ...block?.article_id && {
                ...data?.type === 'sibling' ? {
                  article_draft: block?.article_id,
                  block_draft: null,
                  section_draft: null,
                } : {
                  block_draft: block?.id,
                  article_draft: null,
                  section_draft: null,
                },
              },
              ...block?.section_id && {
                ...data?.type === 'sibling' ? {
                  section_draft: block?.section_id,
                  block_draft: null,
                  article_draft: null,
                } : {
                  block_draft: block?.id,
                  article_draft: null,
                  section_draft: null,
                },
              },
              variant_draft: data?.variant || BlockVariant.HtmlField,
              order_draft: siblings?.length + 1 || 0,
              ...data.variant === BlockVariant.Spreadsheet && {
                [`title_draft_${language}`]: data.title,
                [`json_content_draft_${language}`]: defaultNewSpreadSheet,
              },
            });
          } else {
            if (section) {
              await documentsClient.createArticle({
                cla: claSlug,
                [`title_draft_${language}`]: trimHtmlEol(data?.title),
                order_draft: 1,
                section_draft: section?.id,
                list_formatting_draft: '',
                original_draft: data?.original_draft,
                is_renvoi_draft: data?.is_renvoi,
                is_full_renvoi_draft: data?.is_full_renvoi,
              });
            } else {
              await documentsClient.createArticle({
                cla: claSlug,
                [`title_draft_${language}`]: trimHtmlEol(data?.title),
                parent_draft: data?.type === 'sibling' ? article?.parent_id : article?.id,
                section_draft: null,
                ...(article?.section_id && data?.type === 'sibling') && {
                  parent_draft: null,
                  section_draft: article?.section_id,
                },
                list_formatting_draft: '',
                order_draft: siblings?.length + 1 || 0,
                original_draft: data?.original_draft,
                is_renvoi_draft: data?.is_renvoi,
                is_full_renvoi_draft: data?.is_full_renvoi,
              });
            }
          }
        } else if (cla) {
          const { data: createdSection } = await documentsClient.createSection({
            [`title_draft_${language}`]: data?.title,
            cla: claSlug,
            titles_color_draft: data?.titles_color,
            order_draft: cla?.section_set?.length + 1,
          });
          if (!!data?.description || data.variant === BlockVariant.Spreadsheet) {
            await documentsClient.createBlock({
              cla: claSlug,
              variant_draft: data?.variant || BlockVariant.HtmlField,
              section_draft: createdSection?.id,
              display_list_formatting_draft: data?.display_list_formatting,
              order_draft: 1,
              ...data.variant === BlockVariant.Spreadsheet ? {
                [`title_draft_${language}`]: data.description,
                [`json_content_draft_${language}`]: defaultNewSpreadSheet,
              } : {
                [`text_content_draft_${language}`]: data.description,
              },
            });
          }
        } else if (section) {
          if (data?.type === 'child') {
            const { data: createdArticle } = await documentsClient.createArticle({
              [`title_draft_${language}`]: trimHtmlEol(data?.title),
              cla: claSlug,
              order_draft: 1,
              section_draft: section?.id,
              list_formatting_draft: '',
            });
            if (!!data?.description || data.variant === BlockVariant.Spreadsheet) {
              await documentsClient.createBlock({
                cla: claSlug,
                variant_draft: data?.variant || BlockVariant.HtmlField,
                article_draft: createdArticle?.id,
                display_list_formatting_draft: data?.display_list_formatting,
                order_draft: 1,
                ...data.variant === BlockVariant.Spreadsheet ? {
                  [`title_draft_${language}`]: data.description,
                  [`json_content_draft_${language}`]: defaultNewSpreadSheet,
                } : {
                  [`text_content_draft_${language}`]: data.description,
                },
              });
            }
          } else {
            const { data: createdSection } = await documentsClient.createSection({
              [`title_draft_${language}`]: data?.title,
              titles_color_draft: data?.titles_color,
              cla: claSlug,
              order_draft: siblings?.length + 1 || 0,
            });
            if (!!data?.description || data.variant === BlockVariant.Spreadsheet) {
              await documentsClient.createBlock({
                cla: claSlug,
                variant_draft: data?.variant || BlockVariant.HtmlField,
                section_draft: createdSection?.id,
                order_draft: 1,
                ...data.variant === BlockVariant.Spreadsheet ? {
                  [`title_draft_${language}`]: data.description,
                  [`json_content_draft_${language}`]: defaultNewSpreadSheet,
                } : {
                  [`text_content_draft_${language}`]: data.description,
                },
              });
            }
          }
        } else if (article) {
          const { data: createdArticle } = await documentsClient.createArticle({
            cla: claSlug,
            [`title_draft_${language}`]: trimHtmlEol(data?.title),
            parent_draft: data?.type === 'sibling' ? article?.parent_id : article?.id,
            section_draft: null,
            ...(article?.section_id && data?.type === 'sibling') && {
              parent_draft: null,
              section_draft: article?.section_id,
            },
            list_formatting_draft: '',
            order_draft: siblings?.length + 1 || 0,
            is_renvoi_draft: data?.is_renvoi,
            is_full_renvoi_draft: data?.is_full_renvoi,
          });
          if (!!data?.description || data.variant === BlockVariant.Spreadsheet) {
            await documentsClient.createBlock({
              cla: claSlug,
              variant_draft: data?.variant || BlockVariant.HtmlField,
              is_important_draft: data?.is_important,
              is_renvoi_draft: data?.is_renvoi,
              is_full_renvoi_draft: data?.is_full_renvoi,
              article_draft: createdArticle?.id,
              order_draft: 1,
              ...data.variant === BlockVariant.Spreadsheet ? {
                [`title_draft_${language}`]: data.description,
                [`json_content_draft_${language}`]: defaultNewSpreadSheet,
              } : {
                [`text_content_draft_${language}`]: data.description,
              },
            });
          }
        } else if (block) {
          await documentsClient.createBlock({
            cla: claSlug,
            is_important_draft: data?.is_important,
            is_renvoi_draft: data?.is_renvoi,
            is_full_renvoi_draft: data?.is_full_renvoi,
            is_toc_include_children_draft: data?.is_toc_include_children,
            display_list_formatting_draft: data?.display_list_formatting,
            [`title_draft_${language}`]: data?.title,
            [`text_content_draft_${language}`]: data?.description,
            ...block?.block_id && {
              block_draft: data?.type === 'sibling' ? block?.block_id : block?.id,
              article_draft: null,
              section_draft: null,
            },
            ...block?.article_id && {
              ...data?.type === 'sibling' ? {
                article_draft: block?.article_id,
                block_draft: null,
                section_draft: null,
              } : {
                block_draft: block?.id,
                article_draft: null,
                section_draft: null,
              },
            },
            ...block?.section_id && {
              ...data?.type === 'sibling' ? {
                section_draft: block?.section_id,
                block_draft: null,
                article_draft: null,
              } : {
                block_draft: block?.id,
                article_draft: null,
                section_draft: null,
              },
            },
            variant_draft: data?.variant || BlockVariant.HtmlField,
            order_draft: siblings?.length + 1 || 0,
            ...data.variant === BlockVariant.Spreadsheet && {
              [`title_draft_${language}`]: data.title,
              [`json_content_draft_${language}`]: defaultNewSpreadSheet,
            },
          });
          if (data?.type === 'child' && !block.list_formatting) {
            await documentsClient.patchBlock(block?.id, {
              list_formatting_draft: 'dot',
            });
          }
        }
        await queryClient.invalidateQueries(cacheKeys.documents.getDraftDocument);
        queryClient.invalidateQueries(cacheKeys.documents.getSearchDropdownData);
      },
    });
  }, [setSharedPopupOpen, setLayoutTempState, asyncConfirmation, parentSectionId, addLabel, section, article, block, articleNumber, cla, queryClient, claSlug, language, siblings?.length, dictionary, itemIsDuplicate, itemIsChildOfDuplicate]);

  const allowNesting = useMemo(() => {
    if (article?.hasGrandChildren || (article?.isChild && article?.hasChildren)) {
      return false;
    }
    return previousSibling;
  }, [article, previousSibling]);

  const onNest = useCallback(async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (itemIsChildOfDuplicate || itemIsDuplicate) {
      await asyncConfirmation({
        title: dictionary.confirmation.cannotEditTitle,
        content: dictionary.confirmation.cannotEditDuplicateContent,
        confirmLabel: dictionary.confirmation.ok,
        cancelLabel: '',
      });
      return;
    }

    if (previousSibling) {
      setIsSaving(true);
      try {
        //FIXME: Find out the type of previous sybling before making api call to it
        if (!previousSibling?.list_formatting) {
          try {
            await documentsClient.patchBlock(previousSibling?.id, {
              list_formatting_draft: 'dot',
            });
          } catch (e) {
            console.error(e);
            genericError();
          }
        }
        if (block && previousSibling?.id) {
          await documentsClient.patchBlock(block?.id, {
            block_draft: previousSibling?.id,
            section_draft: null,
            article_draft: null,
          });
        } else if (article && previousSibling?.id) {
          await documentsClient.patchArticle(article?.id, {
            parent_draft: previousSibling?.id,
            section_draft: null,
          });
        }
        await queryClient.invalidateQueries(cacheKeys.documents.getDraftDocument);
        queryClient.invalidateQueries(cacheKeys.documents.getSearchDropdownData);
        queryClient.invalidateQueries(cacheKeys.history.getHistory);
      } catch (e) {
        console.error(e);
        genericError();
      }
      setIsSaving(false);
    }
  }, [previousSibling, queryClient, block, article, genericError, dictionary, asyncConfirmation, itemIsDuplicate, itemIsChildOfDuplicate]);

  const onShowMore = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowMore(true);
  }, []);

  const onUnNest = useCallback(async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (itemIsChildOfDuplicate || itemIsDuplicate) {
      await asyncConfirmation({
        title: dictionary.confirmation.cannotEditTitle,
        content: dictionary.confirmation.cannotEditDuplicateContent,
        confirmLabel: dictionary.confirmation.ok,
        cancelLabel: '',
      });
      return;
    }

    if (parent) {
      setIsSaving(true);
      if (block && parent?.block_id) {
        await documentsClient.patchBlock(block?.id, {
          block_draft: parent?.block_id,
          section_draft: null,
          article_draft: null,
        });
      }
      if (block && parent?.article_id) {
        await documentsClient.patchBlock(block?.id, {
          article_draft: parent?.article_id,
          section_draft: null,
          block_draft: null,
        });
      }
      if (block && parent?.section_id) {
        await documentsClient.patchBlock(block?.id, {
          section_draft: parent?.section_id,
          block_draft: null,
          article_draft: null,
        });
      }
      if (article) {
        await documentsClient.patchArticle(article?.id, {
          ...parent?.parent_id && {
            parent_draft: parent?.parent_id,
            section_draft: null,
          },
          ...parent?.section_id && {
            section_draft: parent?.section_id,
            parent_draft: null,
          },
        });
      }
      await queryClient.invalidateQueries(cacheKeys.documents.getDraftDocument);
      queryClient.invalidateQueries(cacheKeys.documents.getSearchDropdownData);
      queryClient.invalidateQueries(cacheKeys.history.getHistory);
      setIsSaving(false);
    }
  }, [parent, queryClient, block, article, dictionary, asyncConfirmation, itemIsDuplicate, itemIsChildOfDuplicate]);

  const editLabel = useMemo(() => {
    let label = dictionary.editableContent.edit;
    if (cla) {
      label = dictionary.editableContent.editCla;
    }
    if (section) {
      label = dictionary.editableContent.editSection;
    }
    if (article) {
      label = dictionary.editableContent.editArticle;
    }
    if (block) {
      label = dictionary.editableContent.editContent;
    }
    return label;
  }, [dictionary, cla, section, article, block]);

  const onEdit = useCallback(async () => {
    if (itemIsChildOfDuplicate || itemIsDuplicate) {
      await asyncConfirmation({
        title: dictionary.confirmation.cannotEditTitle,
        content: dictionary.confirmation.cannotEditDuplicateContent,
        confirmLabel: dictionary.confirmation.ok,
        cancelLabel: '',
      });
      return;
    }

    setSharedPopupOpen(true);
    setLayoutTempState({
      title: editLabel,
      contentForm: true,
      articleNumber,
      article,
      block,
      section,
      cla,
      // TODO FIX ME for the multiple variables instead of ternary expressions
      ...cla && {
        fields: ['title', 'description', 'published_at'],
      },
      ...section && {
        fields: ['title', 'titles_color'],
        ...(section?.blocks || []).length === 0 && {
          fields: [
            'title', 'description', 'variant', 'titles_color',
          ],
        },
        section: {
          ...section,
          variant: BlockVariant.Text,
        },
      },
      ...article && {
        fields: [
          'title', 'description',
          'variant', 'is_important',
          'is_renvoi', 'is_full_renvoi',
          'list_formatting',
        ],
      },
      ...block && {
        fields: [
          'title',
          'description',
          'variant',
          'list_formatting',
          'is_important',
          'is_renvoi',
          'is_full_renvoi',
          (block?.section_id && !block?.article_id && !block?.block_id) ? 'is_toc_include_children' : null,
        ],
      },
      ...(block && block.variant === BlockVariant.Spreadsheet) && {
        fields: ['title', 'description', 'is_important', 'is_renvoi', 'is_full_renvoi'],
        block: {
          ...block,
          variant: BlockVariant.Spreadsheet,
        },
      },
      onSave: async (data: any) => {
        if (article) {
          await documentsClient.patchArticle(article?.id, {
            [`title_draft_${data.editLanguage}`]: trimHtmlEol(data?.title),
            list_formatting_draft: data?.list_formatting,
            is_important_draft: data?.is_important,
            is_renvoi_draft: data?.is_renvoi,
            is_full_renvoi_draft: data?.is_full_renvoi,
          });
          if (data.description) {
            await documentsClient.createBlock({
              cla: claSlug,
              [`text_content_draft_${data.editLanguage}`]: data.description,
              variant_draft: data?.variant || BlockVariant.HtmlField,
              is_important_draft: data?.is_important,
              is_renvoi_draft: data?.is_renvoi,
              is_full_renvoi_draft: data?.is_full_renvoi,
              display_list_formatting_draft: data?.display_list_formatting,
              article_draft: article?.id,
              order_draft: 1,
              // todo nasty in multilang...
              ...data.variant === BlockVariant.Spreadsheet ? {
                [`title_draft_${data.editLanguage}`]: data.description,
                [`json_content_draft_${data.editLanguage}`]: defaultNewSpreadSheet,
              } : {
                [`text_content_draft_${data.editLanguage}`]: data.description,
              },
            });
          }
        } else if (block) {
          await documentsClient.patchBlock(block?.id, {
            [`title_draft_${data.editLanguage}`]: data?.title,
            [`text_content_draft_${data.editLanguage}`]: data?.description,
            variant_draft: data?.variant,
            is_important_draft: data?.is_important,
            is_renvoi_draft: data?.is_renvoi,
            is_full_renvoi_draft: data?.is_full_renvoi,
            is_toc_include_children_draft: data?.is_toc_include_children,
            list_formatting_draft: data?.list_formatting,
            display_list_formatting_draft: data?.display_list_formatting,
            // todo nasty in multilang...
            ...(block.variant !== BlockVariant.Spreadsheet && data.variant === BlockVariant.Spreadsheet && !block?.json_content) && {
              [`title_draft_${data.editLanguage}`]: data.description,
              [`json_content_draft_${data.editLanguage}`]: defaultNewSpreadSheet,
            },
          });
        } else if (section) {
          await documentsClient.patchSection(section?.id, {
            [`title_draft_${data.editLanguage}`]: data?.title,
            titles_color_draft: data?.titles_color,
          });
          if ((section?.blocks || []).length === 0) {
            await documentsClient.createBlock({
              cla: claSlug,
              variant_draft: data?.variant || BlockVariant.HtmlField,
              section_draft: section?.id,
              display_list_formatting_draft: data?.display_list_formatting,
              order_draft: 1,
              ...data.variant === BlockVariant.Spreadsheet ? {
                [`title_draft_${data.editLanguage}`]: data.description,
                [`json_content_draft_${data.editLanguage}`]: defaultNewSpreadSheet,
              } : {
                [`text_content_draft_${data.editLanguage}`]: data.description,
              },
            });
          }
        } else if (cla && claSlug) {
          await documentsClient.patchDocument(claSlug, {
            [`title_draft_${data.editLanguage}`]: data?.title,
            [`subtitle_draft_${data.editLanguage}`]: data?.description,
            published_at_draft: data?.published_at && isDateValid(data?.published_at.toString()) ? data?.published_at : data?.published_at?.toISOString()?.substr(0, 10),
          });
        }
        await queryClient.invalidateQueries(cacheKeys.documents.getDraftDocument);
        queryClient.invalidateQueries(cacheKeys.documents.getSearchDropdownData);
        queryClient.invalidateQueries(cacheKeys.history.getHistory);
      },
    });
  }, [editLabel, asyncConfirmation, dictionary, cla, section, claSlug, article, block, articleNumber, setSharedPopupOpen, setLayoutTempState, queryClient, itemIsDuplicate, itemIsChildOfDuplicate]);

  const onViewHistory = useCallback(() => {
    setSharedPopupOpen(true);
    setLayoutTempState({
      contentHistory: true,
      cla,
      section,
      article,
      block,
    });
  }, [cla, section, article, block, setSharedPopupOpen, setLayoutTempState]);

  const onCopyLink = useCallback(async () => {
    if (navigator) {
      try {
        await navigator.clipboard.writeText('#' + (cla || section || article || block)?.slug);
        setSnackbarState({
          open: true,
          message: dictionary.editableContent.linkCopiedSuccessfully,
          severity: 'success',
          autoHideDuration: 2000,
        });
      } catch (e) {
        console.error(e);
        genericError();
      }
    }
  }, [dictionary, cla, section, article, block, genericError, setSnackbarState]);

  const onScrollOppositeWindow = useCallback(async () => {
    const slug = (section || article || block)?.slug;

    const elements = document.querySelectorAll(
      `#${slug.replace(/\./g, '\\.')}`,
    );

    elements.forEach(el => {
      // if (!isEmpty(block) && !block.is_before_article && !block.section_id) {
      if (
        !isEmpty(block)
        && !['ol', 'ul'].includes(el.parentElement?.tagName?.toLowerCase() || '')
      ) {
        el = el.parentElement as Element;
      }

      el.scrollIntoView({
        block: 'start',
      });
      (el as HTMLElement).style.outline = `4px solid ${theme.palette.primary.main}`;
      setTimeout(() => {
        (el as HTMLElement).style.outline = 'initial';
      }, 10000);
    });
  }, [section, article, block, theme.palette.primary.main]);

  useOutsideClick(ref, async () => {
    if (isDirty && !isSaving) {
      await onSave();
    }
    setTimeout(() => {
      setFocused(false);
      setIsHovered(false);
    });
  });

  const isGrandchildArticle = useMemo(() => {
    return articleNumber?.split('.')?.length >= 3;
  }, [articleNumber]);

  // if the main menu is empty force expand it
  const isForceExpandMenu = !canEdit && !canAdd && !canDelete;

  return (
    <>
      <Tooltip
        classes={tooltipClass}
        open={focused}
        title={
          <Grid container>
            {canAdd && (
              <Tooltip title={addLabel}>
                <IconButton onMouseDown={onAdd} size="small" disabled={isSaving || isDeleting}>
                  <Add style={{ opacity: (isSaving || isDeleting) ? 0.5 : 1 }}/>
                </IconButton>
              </Tooltip>
            )}
            {canEdit && (
              <Tooltip title={editLabel}>
                <IconButton onMouseDown={onEdit} size="small" disabled={isSaving || isDeleting}>
                  <Edit style={{ opacity: (isSaving || isDeleting) ? 0.5 : 1 }}/>
                </IconButton>
              </Tooltip>
            )}
            {canDelete && (
              <Tooltip title={deleteLabel}>
                <IconButton onMouseDown={onDelete} disabled={(isDeleting || isSaving)} size="small">
                  <Clear style={{ opacity: (isDeleting || isSaving) ? 0.5 : 1 }}/>
                </IconButton>
              </Tooltip>
            )}
            {(canEdit && (filteredSiblings || [])?.length > 0) && (
              <Tooltip title={dictionary.editableContent.sort}>
                <IconButton onMouseDown={onSort} size="small" disabled={isSaving || isDeleting}>
                  <Sort style={{ opacity: (isSaving || isDeleting) ? 0.5 : 1 }}/>
                </IconButton>
              </Tooltip>
            )}
            {(!showMore && !isForceExpandMenu) ? (
              <Tooltip title={dictionary.editableContent.showMore}>
                <IconButton onMouseDown={onShowMore} size="small">
                  <ChevronRight/>
                </IconButton>
              </Tooltip>
            ) : (
              <Fragment>
                {(canEdit && allowNesting && !isGrandchildArticle) && (
                  <Tooltip title={dictionary.editableContent.nest}>
                    <IconButton onMouseDown={onNest} size="small" disabled={isSaving || isDeleting}>
                      <FormatIndentIncrease style={{ opacity: (isSaving || isDeleting) ? 0.5 : 1 }}/>
                    </IconButton>
                  </Tooltip>
                )}
                {(canEdit && parent) && (
                  <Tooltip title={dictionary.editableContent.unNest}>
                    <IconButton onMouseDown={onUnNest} size="small" disabled={isSaving || isDeleting}>
                      <FormatIndentDecrease style={{ opacity: (isSaving || isDeleting) ? 0.5 : 1 }}/>
                    </IconButton>
                  </Tooltip>
                )}
                {canViewHistory && (
                  <Tooltip title={dictionary.editableContent.viewHistory}>
                    <IconButton onMouseDown={onViewHistory} size="small">
                      <HistoryToggleOff/>
                    </IconButton>
                  </Tooltip>
                )}
                {!cla && (
                  <Tooltip title={dictionary.editableContent.copyLink}>
                    <IconButton onMouseDown={onCopyLink} size="small" disabled={isSaving || isDeleting}>
                      <Badge
                        badgeContent={<Link style={{
                          marginBottom: 14,
                          marginRight: 12,
                          width: 16,
                          height: 16,
                          opacity: (isDeleting || isSaving) ? 0.5 : 1,
                        }}/>}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                      >
                        <ContentCopy style={{ opacity: (isDeleting || isSaving) ? 0.5 : 1 }}/>
                      </Badge>
                    </IconButton>
                  </Tooltip>
                )}
                {canScrollOppositeWindow && !cla && (
                  <Tooltip title={dictionary.editableContent.scrollOppositeWindow}>
                    <IconButton onMouseDown={onScrollOppositeWindow} size="small" disabled={isSaving || isDeleting}>
                      <TagIcon/>
                    </IconButton>
                  </Tooltip>
                )}
                {canEdit && !!userPermissions?.publish_cla && (
                  <Tooltip title={dictionary.editableContent.publish}>
                    <IconButton onMouseDown={onPublish} size="small" disabled={isSaving || isDeleting}>
                      <Publish style={{ opacity: (isSaving || isDeleting) ? 0.5 : 1 }}/>
                    </IconButton>
                  </Tooltip>
                )}
                {canAddFile && !cla && (
                  <Tooltip title={dictionary.editableContent.files}>
                    <IconButton onMouseDown={onFilesClick} size="small">
                      <FilePresent/>
                    </IconButton>
                  </Tooltip>
                )}
                {canAddFAQ && (block || article) && (
                  <Tooltip title={dictionary.editableContent.questionAnswer}>
                    <IconButton onMouseDown={onQuestionAnswer} size="small" disabled={isSaving || isDeleting}>
                      <QuestionAnswerOutlined style={{ opacity: (isSaving || isDeleting) ? 0.5 : 1 }}/>
                    </IconButton>
                  </Tooltip>
                )}
                {canAddNote && (block || article) && (
                  <Tooltip title={dictionary.editableContent.note}>
                    <IconButton
                      onMouseDown={() => {
                        if (block) {
                          openDialog(Dialog.NoteDialog, {
                            key: block?.id,
                            item: block,
                            itemType: 'block',
                          });
                        }
                        if (article) {
                          openDialog(Dialog.NoteDialog, {
                            key: article?.id,
                            item: article,
                            itemType: 'article',
                          });
                        }
                      }}
                      size="small"
                      disabled={isSaving || isDeleting}
                    >
                      <StickyNote2Icon style={{ opacity: (isSaving || isDeleting) ? 0.5 : 1 }}/>
                    </IconButton>
                  </Tooltip>
                )}
              </Fragment>
            )}
          </Grid>
        }
      >
        <span
          onMouseLeave={() => !focused && setIsHovered(false)}
          suppressContentEditableWarning={true}
          ref={ref}
          style={{
            outline: 'none',
            ...focused && {
              background: 'rgba(255,183,0,0.10)',
              boxShadow: '0 0 30px 20px rgba(255,183,0,0.10)',
            },
          }}
          {...canEdit ? {
            // https://targa.youtrack.cloud/agiles/141-2/current?issue=CLA-130
            // contentEditable: (itemIsChildOfDuplicate || itemIsDuplicate) ? false : true,
            contentEditable: false,
            tabIndex: 0,
            onFocus,
            onBlur,
            onInput: () => setIsDirty(true),
            onDoubleClick: () => {
              setFocused(true);
              onEdit();
            },
          } : {
            onClick: () => setFocused(true),
          }}
          onKeyDown={e => {
            if (e.key === 'Tab') {
              onTabPress();
            }
          }}
          {...disableEnterKey && {
            onKeyPress: (event) => preventEnterKey(event),
          }}
        >
          {children}
        </span>
      </Tooltip>
    </>
  );
};

export const EditableContentWithPermissions: FC<EditableContentProps> = ({
  setIsHovered,
  ...props
}) => {

  const { userPermissions } = useContext(UserContext);

  const writeMode = useBoundStore((state) => state.writeMode);
  const draftPreviewMode = useBoundStore((state) => state.draftPreviewMode);
  const reviewMode = useBoundStore((state) => state.reviewMode);


  const hasPermission = useCallback((
    type: string,
    props: any,
    permission_getter = (type: string, item: string) => `${type}_${item}`,
  ) => {
    return ['cla', 'section', 'article', 'block'].every(item => {
      if (props?.[item]) {
        return userPermissions?.[permission_getter(type, item)];
      }
      return true;
    });
  }, [userPermissions]);

  const canEditPermission = useMemo(() => hasPermission('change', props), [hasPermission, props]);
  const canDeletePermission = useMemo(() => hasPermission('delete', props), [hasPermission, props]);
  const canAddPermission = useMemo(() => hasPermission('add', props), [hasPermission, props]);
  const canViewHistoryPermission = useMemo(() => {
    return hasPermission(
      'view_historical',
      props,
      (type: string, item: string) => `${type}${item}`,
    );
  }, [hasPermission, props]);

  if (reviewMode) {
    return (
      <EditableContentWrapped
        canEdit={false}
        canDelete={false}
        canAdd={false}
        canAddFile={false}
        canAddFAQ={false}
        canAddNote={false}
        canViewHistory={canViewHistoryPermission}
        setIsHovered={setIsHovered}
        canScrollOppositeWindow={true}
        {...props}
      />
    );
  }

  const canAddFilePermission = userPermissions?.add_file;
  const canAddFAQPermission = userPermissions?.add_questionanswer;
  const canAddNotePermission = userPermissions?.add_note;

  const getPropOrPermission = (prop: any, perm: any) => prop === undefined ? perm : prop;
  const canEdit = getPropOrPermission(props.canEdit, canEditPermission);
  const canDelete = getPropOrPermission(props.canDelete, canDeletePermission);
  const canAdd = getPropOrPermission(props.canAdd, canAddPermission);
  const canAddFile = getPropOrPermission(props.canAddFile, canAddFilePermission);
  const canAddFAQ = getPropOrPermission(props.canAddFAQ, canAddFAQPermission);
  const canAddNote = getPropOrPermission(props.canAddNote, canAddNotePermission);

  if (
    !writeMode
    || draftPreviewMode
    || (!canEdit && !canDelete && !canAdd && !canAddFile && !canAddFAQ && !canViewHistoryPermission)
  ) {
    return <span>{props.children}</span>;
  }

  return (
    <EditableContentWrapped
      canEdit={canEdit}
      canDelete={canDelete}
      canAdd={canAdd}
      canAddFile={canAddFile}
      canAddFAQ={canAddFAQ}
      canAddNote={canAddNote}
      canViewHistory={canViewHistoryPermission}
      setIsHovered={setIsHovered}
      {...props}
    />
  );
};

export const EditableContent: FC<EditableContentProps> = (props) => {

  const [isHovered, setIsHovered] = useState(false);

  if (!isHovered) {
    return <span onMouseEnter={() => setIsHovered(true)}>{props.children}</span>;
  }

  return <EditableContentWithPermissions {...props} setIsHovered={setIsHovered}/>;

};
