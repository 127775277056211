import React, { FC, useContext, useState } from 'react';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

import { LayoutContext, LocalizationContext, UserContext } from 'contexts';


export const Header: FC<any> = ({
  col,
  columnIndex,
  rowSpan,
  colSpan,
  onAddColumn,
  onDeleteColumn,
  onHeaderChange,
  onSort,
}) => {

  const { userPermissions } = useContext(UserContext);
  const { writeMode } = useContext(LayoutContext);
  const { dictionary } = useContext(LocalizationContext);
  const [isHovered, setIsHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>();

  return (
    <th
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        position: 'relative',
        minWidth: writeMode ? 100 : 24,
        width: col.width,
        backgroundColor: '#273e71',
        color: 'white',
        padding: 10,
        border: '1px #fff solid',
      }}
      rowSpan={rowSpan}
      colSpan={colSpan}
    >
      <span
        suppressContentEditableWarning={true}
        contentEditable={writeMode && !!userPermissions?.change_block}
        onBlur={e => !!userPermissions?.change_block && onHeaderChange && onHeaderChange(columnIndex, e.currentTarget.innerText)}
        style={{
          ...(!col?.label && writeMode) && {
            color: 'rgba(255, 255, 255, 0.5)',
          },
        }}
      >
        {col.label || (writeMode && <i>{dictionary.editableTable.noLabel}</i>)}
      </span>
      {(writeMode && onAddColumn && onDeleteColumn && !!userPermissions?.change_block) && (
        <div
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{ position: 'absolute', right: 3, top: 3, opacity: isHovered ? 1 : 0 }}
        >
          <Tooltip title={dictionary.editableTable.columnActions}>
            <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
              <MoreVert style={{ fill: '#fff' }}/>
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => { setAnchorEl(null); setIsHovered(false); }}
          >
            {onAddColumn && [
              <MenuItem key="add-column-before" onClick={() => { onAddColumn(columnIndex); setAnchorEl(null); }}>{dictionary.editableTable.addColumnBefore}</MenuItem>,
              <MenuItem key="add-column-after" onClick={() => { onAddColumn(columnIndex + 1); setAnchorEl(null); }}>{dictionary.editableTable.addColumnAfter}</MenuItem>,
              <MenuItem key="duplicate-column" onClick={() => { onAddColumn(columnIndex, true); setAnchorEl(null); }}>{dictionary.editableTable.duplicateColumn}</MenuItem>,
            ]}
            {onDeleteColumn && (
              <MenuItem onClick={() => { onDeleteColumn(columnIndex); setAnchorEl(null); }}>{dictionary.editableTable.deleteColumn}</MenuItem>
            )}
            {onSort && [
              <MenuItem key="sort-rows" onClick={() => { onSort(columnIndex, 'rows'); setAnchorEl(null); }}>{dictionary.editableTable.sortRows}</MenuItem>,
              <MenuItem key="sort-columns" onClick={() => { onSort(columnIndex, 'columns'); setAnchorEl(null); }}>{dictionary.editableTable.sortColumns}</MenuItem>,
            ]}
          </Menu>
        </div>
      )}
    </th>
  );
};
