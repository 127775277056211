import React, { FC, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Grid,
  InputAdornment,
  Tooltip, LinearProgress, Button,
} from '@mui/material';
import { ContentPaste, Share } from '@mui/icons-material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { LayoutContext, LocalizationContext } from 'contexts';
import { documentsClient } from 'clients/documents/documentsClient';
import { DocumentMode, DocumentShareButtonProps, DocumentShareModalFormProps } from './types';


const makeDocumentShareUrl = (slug: string, mode: DocumentMode, draftSecret: string | undefined) => {
  const params: any = {
    slug,
  };

  if (draftSecret) {
    params['draft-secret'] = draftSecret;
  }

  const searchParams = new URLSearchParams(params);
  return window.location.origin + '/document/public?' + searchParams.toString();
};


const DocumentShareModalForm: FC<DocumentShareModalFormProps> = ({ slug }) => {
  const { dictionary } = useContext(LocalizationContext);
  const { setSnackbarState } = useContext(LayoutContext);

  const [url, setUrl] = useState<string>();
  const [draftSecret, setDraftSecret] = useState<string | undefined>();
  const [draftSecretLoading, setDraftSecretLoading] = useState<boolean>(false);

  const {
    control,
    watch,
  } = useForm({
    defaultValues: {
      mode: 'normal',
    },
  });

  const mode = watch('mode');

  const setFocus = (e: any) => e.target.select();
  const onCopyButtonClick = () => {
    navigator.clipboard.writeText(url || '');

    setSnackbarState({
      open: true,
      message: dictionary.documentShare.modal.textCopied,
      severity: 'success',
      autoHideDuration: 5000,
    });
  };

  useEffect(() => {
    if (slug) {
      if (mode === 'draft' && !draftSecret) {
        setDraftSecretLoading(true);
        documentsClient.getDocumentDraftSecret(slug)
          .then(({ data: { draft_secret } }) => setDraftSecret(draft_secret))
          .then(() => setDraftSecretLoading(false))
          .catch(() => setDraftSecretLoading(false));

      } else if (mode === 'draft' && draftSecret) {
        const url = makeDocumentShareUrl(
          slug,
          mode as DocumentMode,
          draftSecret,
        );
        setUrl(url);
      } else {
        const url = makeDocumentShareUrl(
          slug,
          mode as DocumentMode,
          undefined,
        );
        setUrl(url);
      }
    }

  }, [mode, slug, draftSecret]);

  return (
    <form>
      <Grid container>
        <Grid item xs={12}>
          <FormLabel>
            {dictionary.documentShare.modal.mode}
          </FormLabel>
          <Controller
            name="mode"
            control={control}
            render={({ field }) => (
              <RadioGroup
                row
                {...field}
              >
                <FormControlLabel
                  value="normal"
                  control={<Radio/>}
                  label={dictionary.documentShare.modal.modes.normal}
                />
                <FormControlLabel
                  value="draft"
                  control={<Radio/>}
                  label={dictionary.documentShare.modal.modes.draft}
                />
              </RadioGroup>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={dictionary.documentShare.modal.link}
            variant="filled"
            fullWidth
            autoFocus
            value={url}
            onFocus={setFocus}
            disabled={draftSecretLoading}
            multiline
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                >
                  <Tooltip
                    title={dictionary.forms.copyUrlToShare}
                  >
                    <IconButton
                      onClick={onCopyButtonClick}
                      disabled={draftSecretLoading}
                    >
                      <ContentPaste/>
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
              readOnly: true,
            }}
          />
          {draftSecretLoading && (
            <LinearProgress />
          )}
        </Grid>
        <Grid
          container
          xs={12}
          pt={2}
        >
          <Grid item ml="auto"/>
          <Grid item>
            <Button
              variant="contained"
              onClick={onCopyButtonClick}
            >
              {dictionary.documentShare.modal.copyLink}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export const DocumentShareButton: FC<DocumentShareButtonProps> = ({ slug }) => {
  const { dictionary } = useContext(LocalizationContext);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const onButtonClick = () => {
    setOpen(true);
  };

  return (
    <>
      <IconButton
        size="small"
        onClick={onButtonClick}
      >
        <Share/>
      </IconButton>
      <Dialog
        onClose={handleClose}
        open={open}
      >
        <DialogTitle>
          {dictionary.documentShare.modal.title}
        </DialogTitle>
        <DialogContent>
          <DocumentShareModalForm
            slug={slug}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

