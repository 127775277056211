import { stripHtml } from './html';

export const trimHtmlEol = (string: string) => {
  if(!string) {
    return string;
  }
  return string.replace(/(<br \/>)*$/, '');
};

export const cleanHtmlEditorText = (string: string) => {
  if (!stripHtml(string).trim()) {
    return '';
  }

  return string;
};

export const replaceNewlineCharacters = (string: string) => {
  if(!string) {
    return string;
  }

  return string
    .replace(/<br>/g, '<br />')
    .replace(/<p><\/p>/g, '<br />');
};

export const removeLineBreaks = (string: string) => {
  if(!string) {
    return string;
  }
  return string
    .replaceAll(/\n/g, '')
    .replaceAll('<br>', '')
    .replaceAll('<br/>', '')
    .replaceAll('<br />', '')
    .replaceAll('<p>', '')
    .replaceAll('<p/>', '')
    .replaceAll('</p>', '');
};
