import { StateCreator } from 'zustand';
import { DocumentTemplateSliceState, FaqOrNotesTab } from './types';
import { useBoundStore } from './index';

export const createDocumentTemplateSlice: StateCreator<DocumentTemplateSliceState> = (set) => ({
  showFilters: false,
  showDocumentSidebar: false,
  showFilesOverview: false,
  showRightContainer: false,
  faqOrNotesTab: FaqOrNotesTab.FAQ,
});


export const toggleShowFilters = () => {
  useBoundStore.setState((state) => ({
    showFilters: !state.showFilters,
  }));
};

export const toggleShowDocumentSidebar = () => {
  useBoundStore.setState((state) => ({
    showDocumentSidebar: !state.showDocumentSidebar,
  }));
};

export const setShowFilesOverview = (showFilesOverview: boolean) => {
  useBoundStore.setState((state) => ({
    showFilesOverview: showFilesOverview,
  }));
};

export const setShowRightContainer = (showRightContainer: boolean) => {
  useBoundStore.setState((state) => ({
    showRightContainer: showRightContainer,
  }));
};

export const setFaqOrNotesTab = (faqOrNotesTab: FaqOrNotesTab) => {
  useBoundStore.setState((state) => ({
    faqOrNotesTab: faqOrNotesTab,
  }));
};
