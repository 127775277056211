import React, { FC, useContext } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { isEmpty } from 'lodash';

import { useBoundStore } from 'store';
import classnames from 'classnames';
import { LayoutContext } from 'contexts';
import { DocumentItemType } from 'clients/documents/documentsClient.types';
import { ListWithListValue } from 'modules/Document/List/List';
import { TransformedText } from 'modules/Document/TransformedText/TransformedText';
import { getHeaderVariant } from 'modules/Document/Article/helpers';
import { getModifiedEntityColorForHistory } from 'modules/Document/helpers';
import { useNotes } from 'shared/hooks/useNotes';
import FileButton from 'shared/components/form/FileButton/FileButton';
import { EditableContent } from 'shared/components/form/EditableContent/EditableContent';
import { QuestionAnswerButton } from 'shared/components/form/QuestionAnswerButton/QuestionAnswerButton';

import { SubBlockWrapper as SubBlock } from './SubBlock';

export const ContentSubBlock: FC<any> = ({
  parent,
  subBlock,
  titleVariant,
  siblings,
  isExplanationBlock,
  showComments,
}) => {
  const { isMobile } = useContext(LayoutContext);
  const { groupByBlock } = useNotes();

  const highlightedTitle = useBoundStore((state) => (
    state.documentSearchHighlightedMap[subBlock.slug]?.highlightedTitle
  ));
  const highlightedText = useBoundStore((state) => (
    state.documentSearchHighlightedMap[subBlock.slug]?.highlightedText
  ));

  return (
    <Box
      mt={subBlock.isTableHeader ? 3 : undefined}
      className={
        classnames(
          {
            'is-full-renvoi': subBlock.is_full_renvoi,
          },
        )
      }
    >
      <Box
        id={subBlock.slug}
        style={{ position: 'relative', top: isMobile ? '-320px' : '-200px' }}
      />
      <Typography
        variant={titleVariant || getHeaderVariant(3)}
        sx={{
          wordBreak: 'break-word',
          breakInside: 'avoid',
          marginTop: !isExplanationBlock && subBlock.title ? 2 : undefined,
          marginBottom: subBlock.title ? 1 : undefined,
          display: subBlock.title ? 'inline-flex' : 'inherit',
          background: getModifiedEntityColorForHistory(subBlock.id, 'block', 'title'),
        }}
      >
        <EditableContent
          parent={parent}
          block={subBlock}
          siblings={siblings}
          disableEnterKey
        >
          <TransformedText
            text={highlightedTitle || subBlock.title}
            is_renvoi={subBlock.is_renvoi}
            is_full_renvoi={subBlock.is_full_renvoi}
            is_parent_full_renvoi={subBlock.is_parent_full_renvoi}
          />
        </EditableContent>
        {(subBlock.has_questions || !isEmpty(groupByBlock[subBlock.id])) && !!subBlock.title && (
          <QuestionAnswerButton
            item={subBlock}
            itemType={DocumentItemType.Block}
            itemQuestions={subBlock.questions}
            sx={{
              display: 'inline-block',
              ml: 2,
            }}
          />
        )}
        {!!subBlock.title && showComments && (
          <FileButton
            block={subBlock}
          />
        )}
      </Typography>
      <Typography
        sx={{
          wordBreak: 'break-word',
          breakInside: 'avoid',
          background: getModifiedEntityColorForHistory(subBlock.id, 'block', 'text_content'),
        }}
      >
        <EditableContent parent={parent} block={subBlock} field="text_content" siblings={siblings} disableEnterKey>
          <TransformedText
            text={highlightedText || subBlock.text_content}
            is_renvoi={subBlock.is_renvoi}
            is_full_renvoi={subBlock.is_full_renvoi}
            is_parent_full_renvoi={subBlock.is_parent_full_renvoi}
          />
        </EditableContent>
      </Typography>

      {!isEmpty(subBlock.sub_block) && (
        <Grid container>
          <Grid item xs={12}>
            <ListWithListValue
              type={subBlock.list_formatting}
              blocks={subBlock.sub_block}
              render={(childSubBlock, listValue) => (
                <SubBlock
                  parent={subBlock}
                  siblings={subBlock.sub_block}
                  subBlock={childSubBlock}
                  key={childSubBlock.slug}
                  type={subBlock.list_formatting}
                  listValue={listValue}
                  blockStyle={{ display: childSubBlock.hasContent ? undefined : 'none' }}
                  showComments={showComments}
                />
              )}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
