import React, { useContext } from 'react';
import { MenuItem, TextField } from '@mui/material';

import { LocalizationContext } from 'contexts';

export const LanguageSwitch = () => {

  const { language, setLanguage, availableLanguages } = useContext(LocalizationContext);

  return (
    <TextField
      size="small"
      style={{ background: 'none' }}
      InputProps={{
        style: { background: 'none' },
      }}
      value={language}
      onChange={(e) => setLanguage(e.target.value)}
      select
    >
      {availableLanguages.map(language => (
        <MenuItem
          key={language}
          value={language}
        >
          {language.toUpperCase()}
        </MenuItem>
      ))}
    </TextField>
  );
};
