import React, { FC, useMemo, useContext, useRef, ChangeEvent } from 'react';
import {
  Box, Checkbox,
  Grid, Stack,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';

import classnames from 'classnames';
import { useBoundStore } from 'store';
import { DocumentPublishContext, LayoutContext } from 'contexts';
import { DocumentPrintContext } from 'contexts/DocumentPrintContext/DocumentPrintContext';
import { BlockVariant, DocumentItemType } from 'clients/documents/documentsClient.types';
import { BlockWrapper as Block } from 'modules/Document/Block/Block';
import { getBlockMd, getBlockSm } from 'modules/Document/Block/helpers';
import { ListWithListValue } from 'modules/Document/List/List';
import { TransformedText } from 'modules/Document/TransformedText/TransformedText';
import { DuplicateTag } from 'modules/Document/DuplicateTag/DuplicateTag';
import { getAddOrDeleteEntityColorForHistory, getModifiedEntityColorForHistory } from 'modules/Document/helpers';
import { useNotes } from 'shared/hooks/useNotes';
import FileButton from 'shared/components/form/FileButton/FileButton';
import { EditableContent } from 'shared/components/form/EditableContent/EditableContent';
import { QuestionAnswerButton } from 'shared/components/form/QuestionAnswerButton/QuestionAnswerButton';


import { getArticleMd, getArticleSm, getHeaderVariant, getArticleNumbering, hasBlocksWithContent } from './helpers';


type Props = {
  article: any,
  parent?: any,
  siblings?: any,
  level: number,
  position: number,
  parentArticleNumber?: string,
  showComments?: boolean;
};

export const Article: FC<Props> = ({
  article,
  parent,
  siblings,
  level,
  position,
  parentArticleNumber = '',
  showComments = true,
}) => {

  const ref = useRef<HTMLElement>();
  const { isMobile, documentMode } = useContext(LayoutContext);
  const { addArticle, removeArticle, partialPublish } = useContext(DocumentPublishContext);
  const { isPrintMode, isPrintPreview, itemsToPrint, toggleItemToPrint } = useContext(DocumentPrintContext);
  const { groupByArticle } = useNotes();

  const highlightedTitle = useBoundStore((state) => (
    state.documentSearchHighlightedMap[article.slug]?.highlightedTitle
  ));

  const showDraftVersion = useBoundStore((state) => state.showDraftVersion);

  const articleNumber = useMemo(() => {
    return getArticleNumbering(parentArticleNumber, position, level);
  }, [parentArticleNumber, position, level]);

  const onPublishCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      addArticle(article.id);
    } else {
      removeArticle(article.id);
    }
  };

  const onPrintCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    toggleItemToPrint(article.slug, event.target.checked);
  };

  const headerMarginBottom = level === 3 ? 0.5 : 1;

  const newOrDeletedColor = getAddOrDeleteEntityColorForHistory(article.id, 'article');
  const modifiedColorTitle = getModifiedEntityColorForHistory(article.id, 'article', 'title');
  const modifiedColorOther = getModifiedEntityColorForHistory(article.id, 'article', 'other');

  return (
    <Box
      py={1}
      ref={ref}
      id={article.slug}
      className={
        classnames(
          'document-child-item',
          {
            'is-renvoi': article.is_renvoi,
            'is-full-renvoi': article.is_full_renvoi,
          },
        )
      }
      sx={{
        background: newOrDeletedColor,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box style={{ position: 'relative', top: isMobile ? '-320px' : '-200px' }}/>
          <Stack
            direction="row"
          >
            {/*<Typography*/}
            {/*  variant={getHeaderVariant(level)}*/}
            {/*  sx={{ marginBottom: headerMarginBottom }}*/}
            {/*>*/}

            {/*</Typography>*/}

            <Typography
              variant={getHeaderVariant(level)}
              sx={{
                wordBreak: 'break-word',
                marginBottom: headerMarginBottom,
                breakInside: 'avoid',
                display: 'inline-flex',
                alignItems: 'baseline',
                background: modifiedColorTitle,
                border: `4px dashed ${modifiedColorOther}`,
              }}
            >
              <span
                style={{
                  marginRight: '8px',
                  color: article.titles_color,
                  wordBreak: 'normal',
                  // alignSelf: 'baseline',
                }}
              >
                {articleNumber} {' '}
              </span>
              {(!showDraftVersion && partialPublish) && (
                <Checkbox
                  size="small"
                  sx={{ ml: -2, mt: -0.5 }}
                  onChange={onPublishCheckboxChange}
                />
              )}
              {(isPrintMode && !isPrintPreview) && (
                <span data-slug={article.slug}>
                  <Checkbox
                    key={`${itemsToPrint[article.slug]}`}
                    defaultChecked={itemsToPrint[article.slug]}
                    size="small"
                    className="print-selection-checkbox"
                    sx={{ ml: -2, mt: -0.5 }}
                    onChange={onPrintCheckboxChange}
                  />
                </span>
              )}
              <EditableContent
                article={article}
                parent={parent}
                siblings={siblings}
                articleNumber={articleNumber}
                disableEnterKey
              >
                <span
                  style={{
                    color: article.titles_color,
                  }}
                >
                  <TransformedText
                    text={highlightedTitle || article.title}
                    is_renvoi={article.is_full_renvoi}
                    is_full_renvoi={article.is_full_renvoi}
                    is_parent_full_renvoi={article.is_parent_full_renvoi}
                  />
                </span>
              </EditableContent>
              {showComments && (article.has_questions || !isEmpty(groupByArticle[article.id])) && (
                <QuestionAnswerButton
                  item={article}
                  itemType={DocumentItemType.Article}
                  itemQuestions={article.questions}
                  sx={{ display: 'inline-block', ml: 2 }}
                />
              )}
              {showComments && (
                <FileButton
                  article={article}
                />
              )}
              {(article.original || article.is_child_of_duplicate) && (
                <DuplicateTag original={article.original ? article.original : article}/>
              )}
            </Typography>
          </Stack>

          {(level === 1 && hasBlocksWithContent(article.blocks)) && (
            <Grid container spacing={2} sx={{ mt: 0, mb: 2 }}>
              <Grid item xs={12}>
                {article.blocks.map((block: any) => (
                  block.hasContent && (
                    <Grid
                      item
                      xs={12}
                      key={block.slug}
                    >
                      <Block
                        key={block.slug}
                        block={block}
                        type={article.list_formatting}
                        siblings={article.blocks}
                        allowPrintSelection
                        showComments={showComments}
                      />
                    </Grid>
                  )))}
              </Grid>
            </Grid>
          )}

          {(level === 1 && !isEmpty(article.article_set)) && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {article.article_set.map((childArticle: any, index: number) => (
                  childArticle.title && (
                    <Article
                      article={childArticle}
                      siblings={article.article_set}
                      parent={article}
                      key={childArticle.slug}
                      level={level + 1}
                      parentArticleNumber={articleNumber}
                      position={index}
                      showComments={showComments}
                    />
                  )))}
              </Grid>
            </Grid>
          )}

          {(level === 2 && !isEmpty(article.blocks)) && (
            <ListWithListValue
              type={article.list_formatting}
              style={{ paddingLeft: article.list_formatting ? 14 : undefined }}
              blocks={article.blocks}
              twoRows
              render={(block, listValue) => (
                block.hasContent && (
                  <Grid
                    item
                    xs={12}
                    sm={block.hasTable ? undefined : getBlockSm(article.blocks, listValue)}
                    md={block.hasTable ? undefined : getBlockMd(article.blocks, listValue)}
                    key={block.slug}
                  >
                    <Block
                      key={block.slug}
                      block={block}
                      type={article.list_formatting}
                      siblings={article.blocks}
                      listValue={listValue}
                      blockStyle={{
                        marginBottom: 2,
                        marginLeft: article.list_formatting ? 0 : undefined,
                      }}
                      allowPrintSelection
                      showComments={showComments}
                    />
                  </Grid>
                )
              )}
            />
          )}

          {(level === 2 && !isEmpty(article.article_set)) && (
            <Grid container spacing={2}>
              {article.article_set.map((childArticle: any, index: number) => (
                childArticle.title && (
                  <Grid
                    item
                    xs={12}
                    sm={childArticle.hasTable ? undefined : getArticleSm(article.article_set, index, documentMode)}
                    md={childArticle.hasTable ? undefined : getArticleMd(article.article_set, index, documentMode)}
                    key={childArticle.slug}
                    className={
                      classnames(
                        {
                          'is-full-renvoi': childArticle.is_full_renvoi,
                        },
                      )
                    }
                  >
                    <Article
                      parent={article}
                      siblings={article.article_set}
                      article={childArticle}
                      level={level + 1}
                      parentArticleNumber={articleNumber}
                      position={index}
                      showComments={showComments}
                    />
                  </Grid>
                )))}
            </Grid>
          )}
          {(level === 3 && !isEmpty(article.blocks)) && (
            <ListWithListValue
              type={article.list_formatting}
              style={{ paddingLeft: article.list_formatting ? 14 : undefined }}
              blocks={article.blocks}
              render={(block, listValue) => (
                <Block
                  siblings={article.blocks}
                  key={block.slug}
                  block={block}
                  type={article.list_formatting}
                  listValue={listValue}
                  showComments={showComments}
                  blockStyle={{
                    display: block.hasContent ? undefined : 'none',
                    marginLeft: article.list_formatting ? (
                      [BlockVariant.Explanation, BlockVariant.Recommendation].includes(block.variant) ? 10 : (
                        block.display_list_formatting ? 0 : '-15px'
                      )
                    ) : undefined,
                  }}
                />
              )}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
