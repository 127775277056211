import React, { useMemo, useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Box, CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { muiTheme } from 'shared/styles/muiTheme';
import {
  LayoutContextProvider,
  UserContextProvider,
  LocalizationContextProvider,
  LocalizationContext,
  LayoutContext,
  DialogContextProvider,
  CompanyContextProvider,
} from 'contexts';
import { Root } from 'views/Root';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 5 * 60 * 1000,
      staleTime: 5 * 60 * 1000,
    },
  },
});

const AppWithTheme = () => {

  const { locale } = useContext(LocalizationContext);
  const { mode } = useContext(LayoutContext);

  // de seems to be German
  const theme = useMemo(() => muiTheme(mode, locale === 'de' ? 'deDE' : 'enUS'), [mode, locale]);

  return (
    <ThemeProvider theme={theme}>
      <Box position="absolute" bottom={0} right={0} style={{ opacity: 0 }}>
        <svg width={0} height={0}>
          <linearGradient id="primaryGradient" x1={1} y1={0} x2={1} y2={1}>
            <stop offset={0} stopColor={theme.palette.secondary.main}/>
            <stop offset={1} stopColor={theme.palette.primary.main}/>
          </linearGradient>
        </svg>
      </Box>
      <CssBaseline/>
      <DialogContextProvider>
        <Root/>
      </DialogContextProvider>
    </ThemeProvider>
  );
};

const App = () => {

  return (
    <Router>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <QueryClientProvider client={queryClient}>
          <LocalizationContextProvider>
            <UserContextProvider>
              <CompanyContextProvider>
                <LayoutContextProvider>
                  <AppWithTheme/>
                </LayoutContextProvider>
              </CompanyContextProvider>
            </UserContextProvider>
          </LocalizationContextProvider>
        </QueryClientProvider>
      </LocalizationProvider>
    </Router>
  );
};

export default App;
