import React, { Fragment, useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppBar,
  Avatar,
  Drawer,
  IconButton,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Toolbar,
  Typography,
  Menu,
  Grid, useTheme, Box, useMediaQuery, TextField, Tooltip,
} from '@mui/material';
import {
  Menu as Hamburger,
  Lock,
  Logout,
  Search, Person,
} from '@mui/icons-material';

import { routes, featureFlags } from 'config';
import { UserContext, LayoutContext, LocalizationContext } from 'contexts';
import { useNavSections } from 'shared/hooks/useNavSections';
import { Logo } from 'shared/components/layout/Logo/Logo';
import { LanguageSwitch } from 'shared/components/form/LanguageSwitch/LanguageSwitch';

const navSearch = false;

export const UserMenu = () => {

  const navigate = useNavigate();
  const { logout, isLoggedIn } = useContext(UserContext);
  const { dictionary } = useContext(LocalizationContext);
  const { isLightMode, toggleMode, ToggleModeIcon } = useContext(LayoutContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const { user } = useContext(UserContext);
  const { displayName = undefined, profilePicture = undefined } = user || {};

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <IconButton onClick={handleClick}>
        <Avatar alt={displayName} src={profilePicture} sx={{ color: 'white' }}/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          elevation: 5,
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {isLoggedIn ? [(
          <MenuItem key="my-account" onClick={() => [navigate(routes.myAccount), handleClose()]} sx={{ p: 3 }}>
            <ListItemIcon><Person/></ListItemIcon>
            <ListItemText><Typography>{dictionary.myAccount}</Typography></ListItemText>
          </MenuItem>), (
          <MenuItem key="logout" onClick={() => [logout(), handleClose()]} sx={{ p: 3 }}>
            <ListItemIcon><Logout/></ListItemIcon>
            <ListItemText><Typography>{dictionary.logOut}</Typography></ListItemText>
          </MenuItem>
        )] : (
          <MenuItem onClick={() => [navigate(routes.login), handleClose()]} sx={{ p: 3 }}>
            <ListItemIcon><Lock/></ListItemIcon>
            <ListItemText><Typography>{dictionary.login}</Typography></ListItemText>
          </MenuItem>
        )}
        {featureFlags.darkMode && (
          <MenuItem onClick={() => [toggleMode(), handleClose()]} sx={{ p: 3 }}>
            <ListItemIcon><ToggleModeIcon/></ListItemIcon>
            <ListItemText><Typography>{isLightMode ? dictionary.switchToDarkMode : dictionary.switchToLightMode}</Typography></ListItemText>
          </MenuItem>
        )}
      </Menu>
    </Fragment>
  );
};


export const Nav = (
  {
    showUserMenu = true,
    logoRoute=routes.home,
  }: {
    showUserMenu?: boolean,
    logoRoute?: string | null
  },
) => {

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [menuOpen, setMenuOpen] = useState(false);
  const { isLoggedIn } = useContext(UserContext);
  const { dictionary } = useContext(LocalizationContext);
  const [showSearch, setShowSearch] = useState(false);

  const { navSections } = useNavSections();

  const closeMenu = useCallback(() => {
    setMenuOpen(false);
  }, []);

  const navigateLogo = () => logoRoute && navigate(logoRoute);

  return (
    <Fragment>
      <Drawer open={menuOpen} onClose={closeMenu} className="no-print">
        <Grid container direction="column" sx={{ minWidth: 200, minHeight: '100%' }}>
          <Typography
            variant="h5"
            onClick={() => [navigateLogo(), closeMenu()]}
            sx={theme => ({
              padding: theme.spacing(4),
              cursor: 'pointer',
            })}
          >
            <Logo onClick={navigateLogo}/>
          </Typography>
          {navSections.map(({ title, link, Icon, external }, i: number) => (
            <MenuItem
              key={i}
              onClick={() => {
                if (external) {
                  window.open(link, '_blank')?.focus();
                } else {
                  navigate(link);
                }
                setMenuOpen(false);
              }}
              sx={{ p: 3 }}
            >
              <ListItemIcon><Icon/></ListItemIcon>
              <ListItemText><Typography>{title}</Typography></ListItemText>
            </MenuItem>
          ))}
        </Grid>
      </Drawer>
      <AppBar
        position="sticky"
        elevation={0}
        className="no-print"
      >
        <Toolbar>
          <Grid container alignItems="center" direction="row" spacing={1}>
            {isLoggedIn && (
              <Grid item>
                <IconButton onClick={() => setMenuOpen(prevState => !prevState)} edge="start">
                  <Hamburger sx={{ color: 'primary.main' }}/>
                </IconButton>
              </Grid>
            )}
            {!isMobile && (
              <Grid item>
                <Logo onClick={navigateLogo} style={{ marginTop: -5 }}/>
              </Grid>
            )}
            <Box ml="auto"/>
            {navSearch && (
              <Fragment>
                <Grid item>
                  {showSearch && (
                    <TextField
                      onBlur={() => setShowSearch(false)}
                      variant="outlined"
                      placeholder={dictionary.search}
                      size="small"
                      sx={{
                        maxWidth: isMobile ? 'auto' : 400,
                        width: isMobile ? 'calc(100vw - 160px)' : 'calc(100vw - 400px)',
                      }}
                      autoFocus
                    />
                  )}
                </Grid>
                <Grid item>
                  <Tooltip title={dictionary.search}>
                    <IconButton
                      aria-label={dictionary.search}
                      style={{ pointerEvents: showSearch ? 'none' : 'all' }}
                      onClick={() => {
                        if (!showSearch) setShowSearch(true);
                      }}
                    >
                      <Search style={{ color: '#fff', marginLeft: showSearch ? (isMobile ? -66 : -72) : 0 }}/>
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Fragment>
            )}
            <Grid item>
              <LanguageSwitch/>
            </Grid>
            <Grid item sx={{ mr: -2 }}>
              {showUserMenu && (
                <UserMenu/>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Fragment>
  );
};
