import { isEmpty } from 'lodash';
import { PaginatedResults } from 'types/Query';
import { sanitizeHtml, sanitizeHtmlAndHideTable, stripHtml } from 'helpers/html';
import { hasContent, blockHasTables } from 'modules/Document/Block/helpers';

import { SearchOriginalResult } from './documentsClient.types';

export const mapDocument = (document: any, writeMode = false) => {
  return {
    _raw: document,
    id: document.id,
    title: document.title,
    stripped_title: stripHtml(document.title),
    subtitle: document.subtitle,
    published_at: document.published_at,
    blocks: (
      document.blocks?.map(
        (block: any, index: number, parent: any) => mapBlock(block, index, parent, undefined),
      ) || []
    ),
    section_set: document.section_set?.map((section: any, i: number) => mapSection(section, writeMode)) || [],
  };
};

export const mapSection = (section: any, writeMode = false) => ({
  _raw: section,
  id: section.id,
  slug: section.slug,
  order: section.slug,
  title: section.title,
  stripped_title: stripHtml(section.title),
  titles_color: section.titles_color,
  files_count: section.files_count,
  blocks: (
    section.blocks?.map(
      (block: any, index: number, parent: any) => mapBlock(block, index, parent, section),
    ) || []
  ),
  article_set: (
    section.article_set?.map(
      (article: any, i: number) => mapArticle(article, i, writeMode, undefined, section),
    ) || []
  ),
});

export const mapArticle = (
  article: any,
  index: number,
  writeMode = false,
  parentArticle: any,
  section: any,
) => {
  let writeModeMeta = {};
  if (writeMode) {
    const hasChildren = article.article_set.length > 0;
    const hasGrandChildren = hasChildren && article.article_set.some((article: any) => article.article_set.length > 0);
    writeModeMeta = {
      isChild: !!article.parent_id,
      hasChildren,
      hasGrandChildren,
    };
  }

  let mappedArticle = {
    index,
    _raw: article,
    id: article?.id,
    article_set: undefined,
    blocks: undefined,
    is_important: article.is_important,
    is_renvoi: article.is_renvoi,
    is_full_renvoi: article.is_full_renvoi,
    is_parent_full_renvoi: parentArticle?.is_full_renvoi || parentArticle?.is_parent_full_renvoi,
    list_formatting: article.list_formatting,
    order: article.order,
    numbering: article.numbering,
    parent_id: article.parent_id,
    section_id: article.section_id,
    slug: article.slug,
    subtitle: article.subtitle,
    title: sanitizeHtml(article.title),
    stripped_title: stripHtml(article.title),
    section_title: parentArticle?.original?.section_title || parentArticle?.section_title,
    valid_from: article.valid_from,
    valid_to: article.valid_to,
    hasTable: article.blocks?.some(blockHasTables),
    original: article.original ? mapOriginalArticle(article.original) : article.original,
    has_duplicates: article.has_duplicates,
    is_child_of_duplicate: !!parentArticle?.original || parentArticle?.is_child_of_duplicate,
    has_questions: !isEmpty(article.questions),
    questions: article.questions?.map(mapQuestion),
    titles_color: section?.titles_color,
    files_count: article.files_count,
    ...writeModeMeta,
  };

  mappedArticle = {
    ...mappedArticle,
    blocks: article.blocks?.map((block: any, index: number) => mapBlock(block, index, mappedArticle, undefined)) || [],
    article_set: article.article_set?.map((article: any, index: number) => (
      mapArticle(article, index, writeMode, mappedArticle, undefined)) || [],
    ),
  };

  return mappedArticle;
};

export const mapOriginalArticle = (originalArticle: any) => ({
  title: originalArticle.title,
  stripped_title: stripHtml(originalArticle.title),
  section_title: originalArticle.section_title,
  slug: originalArticle.slug,
  id: originalArticle.id,
  numbering: originalArticle.numbering,
});

const TABLE_LABEL = 'Tabel:';

export const mapBlock = (block: any, index: number, parent: any, section: any) => {
  let mappedBlock = {
    _raw: block,
    index,
    id: block.id,
    article_id: block.article_id,
    block_id: block.block_id,
    cla_id: block.cla_id,
    is_before_article: block.is_before_article,
    is_important: block.is_important,
    is_renvoi: block.is_renvoi,
    is_full_renvoi: block.is_full_renvoi,
    is_toc_include_children: block.is_toc_include_children,
    is_parent_full_renvoi: parent?.is_full_renvoi || parent?.is_parent_full_renvoi,
    json_content: block.json_content,
    list_formatting: block.list_formatting,
    display_list_formatting: block.display_list_formatting,
    order: block.order,
    section_id: block.section_id,
    slug: block.slug,
    sub_block: undefined,
    text_content: sanitizeHtml(block.text_content),
    title: sanitizeHtml(block.title),
    stripped_title: stripHtml(block.title),
    valid_from: block.valid_from,
    valid_to: block.valid_to,
    variant: block.variant,
    hasContent: hasContent(block),
    hasTable: block.sub_block?.some(blockHasTables),
    isTableHeader: block?.title?.startsWith(TABLE_LABEL) || block?.text_content?.startsWith(TABLE_LABEL),
    original: block.original ? mapOriginalBlock(block.original) : block.original,
    has_duplicates: block.has_duplicates,
    is_child_of_duplicate: !!parent?.original || parent?.is_child_of_duplicate,
    has_questions: !isEmpty(block.questions),
    titles_color: section?.titles_color,
    files_count: block?.files_count,
    questions: block.questions?.map(mapQuestion),
  };

  mappedBlock.sub_block = block.sub_block?.map((sub_block: any, index: number) => mapSubBlock(sub_block, mappedBlock, index)) || [];

  return mappedBlock;
};

export const mapOriginalBlock = (originalBlock: any) => ({
  title: stripHtml(originalBlock.title),
  section_title: originalBlock.section_title,
  slug: originalBlock.slug,
  id: originalBlock.id,
});

export const mapSubBlock = (subBlock: any, parentBlock: any, index: number) => {
  let mappedSubBlock = {
    index,
    id: subBlock.id,
    article_id: subBlock.article_id,
    block_id: subBlock.block_id,
    cla_id: subBlock.cla_id,
    is_before_article: subBlock.is_before_article,
    is_important: subBlock.is_important,
    is_renvoi: subBlock.is_renvoi,
    is_full_renvoi: subBlock.is_full_renvoi,
    is_toc_include_children: subBlock.is_toc_include_children,
    is_parent_full_renvoi: parentBlock?.is_full_renvoi || parentBlock?.is_parent_full_renvoi,
    json_content: subBlock.json_content,
    list_formatting: subBlock.list_formatting,
    display_list_formatting: subBlock.display_list_formatting,
    order: subBlock.order,
    section_id: subBlock.section_id,
    slug: subBlock.slug,
    sub_block: undefined,
    text_content: sanitizeHtmlAndHideTable(subBlock.text_content),
    title: sanitizeHtml(subBlock.title),
    stripped_title: stripHtml(subBlock.title),
    valid_from: subBlock.valid_from,
    valid_to: subBlock.valid_to,
    variant: subBlock.variant,
    hasContent: hasContent(subBlock),
    isTableHeader: subBlock?.title?.startsWith(TABLE_LABEL) || subBlock?.text_content?.startsWith(TABLE_LABEL),
    original: subBlock.original ? mapOriginalBlock(subBlock.original) : subBlock.original,
    has_duplicates: subBlock.has_duplicates,
    is_child_of_duplicate: !!parentBlock.original || parentBlock.is_child_of_duplicate,
    has_questions: !isEmpty(subBlock.questions),
    files_count: subBlock.files_count,
    questions: subBlock.questions?.map(mapQuestion),
  };

  mappedSubBlock.sub_block = subBlock.sub_block?.map((childSubBlock: any, index: number) => mapSubBlock(childSubBlock, mappedSubBlock, index)) || [];

  return mappedSubBlock;
};

export const mapQuestion = (question: any) => ({
  id: question.id,
  question: question.question,
  answer: question.answer,
  linked_articles: question.linked_articles?.map(mapLinkedItem),
  linked_blocks: question.linked_blocks?.map(mapLinkedItem),
  file_set: question.file_set,
});

export const mapQuestionApi = (question: any) => ({
  id: question.id,
  ...question,
  linked_articles: question.linked_articles?.map(mapLinkedItemApi),
  linked_blocks: question.linked_blocks?.map(mapLinkedItemApi),
  file_set: question.file_set,
});

export const mapLinkedItem = (linkedItem: any) => ({
  id: linkedItem.id,
  slug: linkedItem.slug,
  title: linkedItem.title,
  stripped_title: stripHtml(linkedItem.title),
  title_draft: linkedItem.title_draft,
  text_content: linkedItem.text_content,
  text_content_draft: linkedItem.text_content_draft,
  stripped_text_content: stripHtml(linkedItem.text_content),
  stripped_text_content_draft: stripHtml(linkedItem.text_content_draft),
  stripped_title_draft: stripHtml(linkedItem.title_draft),
  numbering: linkedItem.numbering,
  numbering_draft: linkedItem.numbering_draft,
});

export const mapLinkedItemApi = (linkedItem: any) => linkedItem.id;

export const mapSearchResults = (results: any) => ({
  highlighted_results: results.highlighted_results?.map(mapHighlightedResults),
});

export const mapSearchOriginalResults = (results: PaginatedResults<SearchOriginalResult>) => ({
  results: results.results?.map((result) => ({
    stripped_title: stripHtml(result.title_draft),
    ...result,
  })),
});

export const mapHighlightedResults = (highlightedResult: any) => ({
  id: highlightedResult.id,
  slug: highlightedResult.slug,
  type: highlightedResult.type,
  highlighted_text: sanitizeHtml(highlightedResult.highlighted_text),
});
