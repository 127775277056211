import React, { FC, useContext } from 'react';
import { Dialog } from '@mui/material';

import { LayoutContext } from 'contexts/LayoutContext/LayoutContext';
import { ContentSort } from 'shared/components/form/ContentSort/ContentSort';
import { ContentForm } from 'shared/components/form/ContentForm/ContentForm';
import { ContentAttachments } from 'shared/components/form/ContentAttachments/ContentAttachments';
import { ContentHistory } from 'shared/components/layout/ContentHistory/ContentHistory';


export const SharedLayoutComponent: FC = () => {

  const {
    sharedPopupOpen,
    setSharedPopupOpen,
    layoutTempState,
    Snackbar,
  } = useContext(LayoutContext);

  return (
    <div>
      {Snackbar}
      <Dialog open={sharedPopupOpen} onBackdropClick={() => setSharedPopupOpen(false)} maxWidth="lg">
        {(layoutTempState?.sortContent) && (
          <ContentSort {...layoutTempState}/>
        )}
        {(layoutTempState?.contentForm) && (
          <ContentForm {...layoutTempState}/>
        )}
        {(layoutTempState?.contentHistory) && (
          <ContentHistory {...layoutTempState}/>
        )}
        {(layoutTempState?.contentAttachments) && (
          <ContentAttachments {...layoutTempState}/>
        )}
      </Dialog>
    </div>
  );
};
