import * as locales from '@mui/material/locale';
import { createTheme, ThemeOptions, responsiveFontSizes } from '@mui/material/styles';
import type {} from '@mui/x-data-grid/themeAugmentation';
import { deepmerge } from '@mui/utils';
import { common, grey } from '@mui/material/colors';


declare module '@mui/material' {
  interface PaperPropsVariantOverrides {
    contained: true;
  }
}

export const primary = '#10285f';
export const primaryLight = '#fafafa';
export const primaryDark = '#10285f';
export const secondary = '#10285f';
export const textColor = 'black';

export const primaryBrandBlue = '#273e71';
export const primaryBrandOrange = '#ff7000';
export const secondaryBrandBlue = '#037da3';
export const secondaryBrandOrange = '#ff9900';
export const secondaryBrandGrey = '#ededed';


export const themePalette = {
  palette: {
    primary: {
      main: primary,
      light: primaryLight,
      dark: primaryDark,
      contrastText: primaryLight,
    },
    secondary: {
      main: secondary,
      light: secondary,
      dark: secondary,
      contrastText: primaryLight,
    },
    warning: {
      light: '#ffa900',
      main: '#ffa900',
      dark: '#ffa900',
      contrastText: '#000',
    },
    error: {
      light: '#FF2600',
      main: '#FF2600',
      dark: '#FF2600',
      contrastText: '#000',
    },
    background: {
      default: 'rgba(0,0,0,0)',
      paper: '#eeeeee',
    },
  },
};

export const defaultTheme = responsiveFontSizes(createTheme({}));

export const baseTheme: ThemeOptions = {
  palette: {
    ...themePalette.palette,
  },
  typography: {
    fontSize: 12,
    fontFamily: 'Roboto',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightBold: 500,
    ...['h1', 'h2'].reduce((prev, next) => {
      prev[next] = { fontWeight: 300, fontFamily: 'Ubuntu' };
      return prev;
    }, {} as any),
    ...['h3', 'h4'].reduce((prev, next) => {
      prev[next] = { fontWeight: 300, fontFamily: 'Ubuntu' };
      return prev;
    }, {} as any),
    ...['h5', 'h6'].reduce((prev, next) => {
      prev[next] = { fontWeight: 300, fontFamily: 'Ubuntu' };
      return prev;
    }, {} as any),
    ...['body1', 'body2', 'subtitle1', 'subtitle2', 'default', 'button', 'overline'].reduce((prev, next) => {
      prev[next] = { fontWeight: 300 };
      return prev;
    }, {} as any),
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: false,
        disableElevation: true,
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 0,
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          minHeight: 'calc(100vh - 65px)',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        fullWidth: true,
        InputLabelProps: {
          shrink: true,
        },
      },
      styleOverrides: {
        root: {
          borderRadius: 0,
          '& fieldset': {
            borderRadius: 0,
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 9,
        },
        thumb: {
          width: 16,
          height: 16,
        },
        switchBase: {
          padding: 11,
          '&.Mui-checked + .MuiSwitch-track': {
            opacity: 1,
          },
        },
        track: {
          borderRadius: 10,
          opacity: 1,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          [defaultTheme.breakpoints.up('sm')]: {
            minHeight: 100,
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          border: 0,
          backgroundColor: 'transparent',
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
        variant: 'outlined',
        square: true,
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 12,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          paddingTop: defaultTheme.spacing(1),
          paddingBottom: defaultTheme.spacing(1),
          fontWeight: 500,
        },
        body: {
          paddingTop: defaultTheme.spacing(3),
          paddingBottom: defaultTheme.spacing(3),
        },
        root: {
          '&:first-child': {
            paddingLeft: defaultTheme.spacing(4),
          },
          '&:last-child': {
            paddingRight: defaultTheme.spacing(4),
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none',
        },
        columnHeaders: {
          borderBottom: 'none',
          borderRadius: defaultTheme.shape.borderRadius,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          [defaultTheme.breakpoints.up('sm')]: {
            paddingLeft: defaultTheme.spacing(4),
            paddingRight: defaultTheme.spacing(4),
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          [defaultTheme.breakpoints.up('sm')]: {
            paddingLeft: defaultTheme.spacing(4),
            paddingRight: defaultTheme.spacing(4),
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          [defaultTheme.breakpoints.up('sm')]: {
            paddingLeft: defaultTheme.spacing(3),
            paddingRight: defaultTheme.spacing(3),
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          maxWidth: 500,
          fontSize: 14,
          fontWeight: 300,
          padding: defaultTheme.spacing(2),
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        outlined: {
          padding: defaultTheme.spacing(1),
          paddingRight: defaultTheme.spacing(0.5),
          paddingLeft: defaultTheme.spacing(0.5),
          fontWeight: 500,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: 'center',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          border: 'none',
          '&:before': {
            display: 'none',
          },
          '&.Mui-expanded': {
            margin: 'auto',
          },
        },
      },
    },
  },
};

export const lightTheme = (locale = 'enUS') => responsiveFontSizes(createTheme(deepmerge(baseTheme, {
  palette: {
    mode: 'light',
    background: {
      default: '#fafafa',
      paper: grey['100'],
    },
    invert: {
      main: common.white,
    },
  },
  typography: {
    ...['h1', 'h2'].reduce((prev, next) => {
      prev[next] = { color: primaryBrandBlue };
      return prev;
    }, {} as any),
    ...['h3'].reduce((prev, next) => {
      prev[next] = { color: primaryBrandOrange };
      return prev;
    }, {} as any),
    ...['h4'].reduce((prev, next) => {
      prev[next] = { color: secondaryBrandBlue };
      return prev;
    }, {} as any),
    ...['h5', 'h6'].reduce((prev, next) => {
      prev[next] = { color: primaryBrandBlue };
      return prev;
    }, {} as any),
    ...['body1', 'body2', 'subtitle1', 'subtitle2', 'default', 'button', 'overline'].reduce((prev, next) => {
      prev[next] = { color: textColor };
      return prev;
    }, {} as any),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#fafafa',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        color: 'primary',
      },
      variants: [{
        props: {
          variant: 'contained',
          color: 'primary',
        },
        style: {
          '.MuiSvgIcon-root': {
            fill: common.white,
          },
        },
      }],
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: secondary,
          fill: secondary,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: primaryBrandBlue,
          color: `${secondaryBrandGrey} !important`,
        },
        fallback: {
          fill: secondaryBrandGrey,
        },
      },
    },
    MuiBadge: {
      variants: [{
        props: {
          color: 'secondary',
        },
        style: {
          '.MuiBadge-badge': {
            backgroundColor: primaryBrandOrange,
            fontWeight: 500,
            lineHeight: '0px',
            height: '15px',
            minWidth: '15px',
            padding: 0,
            margin: 0,
            fontSize: '9px',
            borderRadius: '79px',
          },
          '.MuiBadge-anchorOriginTopRightRectangular': {
            top: '2px',
          },
          '.MuiBadge-anchorOriginTopLeftRectangular': {
            top: '2px',
            left: '-2px',
          },
          '.MuiBadge-outlined': {
            lineHeight: '0px',
            height: '15px',
            minWidth: '15px',
            padding: 0,
            margin: 0,
            fontSize: '9px',
            borderRadius: '79px',

            color: primaryBrandOrange,
            fontWeight: 'bold',
            backgroundColor: 'white',
            border: '2px solid #ff7000',
          },
        },
      }],
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label.Mui-focused ': {
            color: `${secondary} !important`,
          },
          '& .Mui-focused fieldset': {
            borderColor: `${secondary} !important`,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: common.white,
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: common.white,
          },
        },
        notchedOutline: {
          borderColor: common.white,
        },
      },
      variants: [{
        props: {
          color: 'secondary',
        },
        style: {
          backgroundColor: common.white,
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: common.white,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: common.black,
          },
        },
      }],
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          '&.Mui-checked': {
            color: common.white,
          },
        },
        track: {
          backgroundColor: grey['400'],
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: secondaryBrandGrey,
          border: '1px solid #ececec',
        },
        elevation5: {
          boxShadow: '0 0 100px 0 rgba(0,0,0,0.2)',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          border: 0,
          background: secondaryBrandGrey,
          color: secondary,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0 0 30px 0 rgba(0,0,0,0.05)',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: secondary,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          '&:before': {
            color: common.white,
            boxShadow: '0 0 10px 0 rgba(0,0,0,0.2)',
          },
        },
        tooltip: {
          background: common.white,
          color: primary,
          boxShadow: '0 0 10px 0 rgba(0,0,0,0.2)',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        outlined: {
          background: common.white,
          '& svg': {
            filter: 'none',
            color: secondary,
            fill: secondary,
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        outlinedInfo: {
          borderColor: `${primary}50`,
        },
        outlinedError: {
          borderColor: '#FF2600',
          color: primary,
          '& svg': {
            color: '#FF2600 !important',
            fill: '#FF2600 !important',
          },
        },
        outlinedWarning: {
          borderColor: '#ffa900',
          color: primary,
          '& svg': {
            color: '#ffa900 !important',
            fill: '#ffa900 !important',
          },
        },
      },
    },
    MuiSlider: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundImage: 'none',
          background: 'white',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: '#fafafa',
          color: common.black,
        },
      },
    },
  },

}),
// @ts-ignore
locales[locale]));


const darkPaper = '#2c2e33';
export const darkTheme = (locale = 'enUS') => responsiveFontSizes(createTheme(deepmerge(baseTheme, {
  palette: {
    mode: 'dark',
    primary: {
      main: '#ececec',
      light: '#ececec',
      contrastText: '#111',
    },
    secondary: {
      main: primaryBrandOrange,
      light: primaryBrandOrange,
      contrastText: '#111',
    },
    background: {
      paper: darkPaper,
    },
    invert: {
      main: common.black,
    },
  },
  typography: {
    ...['h1', 'h2'].reduce((prev, next) => {
      prev[next] = { color: primaryBrandOrange };
      return prev;
    }, {} as any),
    ...['h3', 'h4'].reduce((prev, next) => {
      prev[next] = { color: primaryBrandOrange };
      return prev;
    }, {} as any),
    ...['h5', 'h6'].reduce((prev, next) => {
      prev[next] = { color: common.white };
      return prev;
    }, {} as any),
    ...['body1', 'body2', 'subtitle1', 'subtitle2', 'default', 'button', 'overline'].reduce((prev, next) => {
      prev[next] = { color: common.white };
      return prev;
    }, {} as any),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#131313',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        color: 'primary',
      },
      variants: [{
        props: {
          variant: 'contained',
          color: 'primary',
        },
        style: {
          '.MuiSvgIcon-root': {
            fill: '#111',
          },
          '&:hover': {
            backgroundColor: '#ececec',
          },
        },
      }],
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: secondaryBrandGrey,
          fill: secondaryBrandGrey,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: secondaryBrandGrey,
          color: `${primaryDark} !important`,
        },
        fallback: {
          fill: primaryDark,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label.Mui-focused ': {
            color: `${secondary} !important`,
          },
          '& .Mui-focused fieldset': {
            borderColor: `${secondary} !important`,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: common.black,
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: common.black,
          },
        },
        notchedOutline: {
          borderColor: common.black,
        },
      },
      variants: [{
        props: {
          color: 'secondary',
        },
        style: {
          backgroundColor: darkPaper,
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: darkPaper,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: common.white,
          },
        },
      }],
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: grey['900'],
          '&.Mui-checked': {
            color: grey['900'],
          },
        },
        track: {
          backgroundColor: grey['700'],
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: '#181818',
          border: 'none',
        },
        elevation5: {
          boxShadow: '0 0 100px 0 rgba(0,0,0,0.2)',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          border: 0,
          background: '#2c2e33',
          color: secondaryBrandGrey,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0 0 30px 0 rgba(0,0,0,0.05)',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: secondary,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          '&:before': {
            color: '#333333',
            boxShadow: '0 0 10px 0 rgba(0,0,0,0.2)',
          },
        },
        tooltip: {
          backgroundColor: '#333333',
          color: '#ececec',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        outlined: {
          background: primaryDark,
          fontWeight: 500,
          '& svg': {
            filter: 'none',
            color: secondary,
            fill: secondary,
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        outlinedInfo: {
          borderColor: `${primary}50`,
        },
        outlinedError: {
          borderColor: '#FF2600',
          color: primary,
          '& svg': {
            color: '#FF2600 !important',
            fill: '#FF2600 !important',
          },
        },
        outlinedWarning: {
          borderColor: '#ffa900',
          color: primary,
          '& svg': {
            color: '#ffa900 !important',
            fill: '#ffa900 !important',
          },
        },
      },
    },
    MuiSlider: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundImage: 'none',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#131313',
          color: common.black,
        },
      },
    },

  },
})),
// @ts-ignore
locales[locale]);

export const muiTheme = (mode: 'light' | 'dark', locale = 'enUS') => mode === 'light' ? lightTheme(locale) : darkTheme(locale);
