import { isEmpty } from 'lodash';

import { DocumentItemType } from 'clients/documents/documentsClient.types';
import { documentsClient } from 'clients/documents/documentsClient';


export const getItemDescendents = (item: any, itemType: DocumentItemType) => {
  const children = [];

  if (item.id) {
    children.push([{
      type: itemType,
      id: item.id,
    }]);
  }

  if (item.original) {
    return children.flat(2);
  }

  if (!isEmpty(item.blocks)) {
    children.push(...item.blocks.map((block: any) => getItemDescendents(block, DocumentItemType.Block)));
  }
  if (!isEmpty(item.sub_block)) {
    children.push(...item.sub_block.map((block: any) => getItemDescendents(block, DocumentItemType.Block)));
  }
  if (!isEmpty(item.article_set)) {
    children.push(...item.article_set.map((article: any) => getItemDescendents(article, DocumentItemType.Article)));
  }
  if (!isEmpty(item.section_set)) {
    children.push(...item.section_set.map((section: any) => getItemDescendents(section, DocumentItemType.Section)));
  }
  return children.flat(10);
};

export const sortDocumentItems = (items: Array<{id: number, type: DocumentItemType}> = []) => {
  const sortingMap = {
    [DocumentItemType.Cla]: 1,
    [DocumentItemType.Section]: 2,
    [DocumentItemType.Article]: 3,
    [DocumentItemType.Block]: 4,
  };
  return items.sort((a, b) => sortingMap[b.type] - sortingMap[a.type]);
};

export const deleteItemWithDescendents = async (item: any, itemType: DocumentItemType) => {
  const itemsToDelete = getItemDescendents(item, itemType);
  const sortedItems = sortDocumentItems(itemsToDelete);
  const requests = sortedItems.map(({ type, id }) => {
    if (type === DocumentItemType.Cla) {
      return documentsClient.deleteDocument(id);
    }
    if (type === DocumentItemType.Section) {
      return documentsClient.deleteSection(id);
    }
    if (type === DocumentItemType.Article) {
      return documentsClient.deleteArticle(id);
    }
    if (type === DocumentItemType.Block) {
      return documentsClient.deleteBlock(id);
    }
    return undefined;
  });

  return Promise.all(requests);
};
