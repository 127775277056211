import React, { FC, useCallback, useContext } from 'react';
import { FilePresent } from '@mui/icons-material';
import { Badge, Box } from '@mui/material';
import { LayoutContext } from 'contexts';
import { DocumentPrintContext } from 'contexts/DocumentPrintContext/DocumentPrintContext';

const FileButton: FC<any> = ({
  section, article, block, sx,
}) => {
  const { setSharedPopupOpen, setLayoutTempState } = useContext(LayoutContext);
  const { isPrintMode, isPrintPreview } = useContext(DocumentPrintContext);

  const onClick = useCallback(async () => {
    setSharedPopupOpen(true);
    setLayoutTempState({
      contentAttachments: true,
      section,
      article,
      block,
    });
  }, [section, article, block, setSharedPopupOpen, setLayoutTempState]);

  const entity = section || article || block;
  
  if (isPrintMode || isPrintPreview) {
    return <></>;
  }

  if (entity.files_count) {
    return (
      <Box
        sx={{
          display: 'inline-block',
          marginLeft: 2,
          ...sx,
        }}
        className="no-print"
      >
        <Box position="relative">
          <Badge
            color="secondary"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            badgeContent={entity.files_count}
            sx={{ cursor: 'pointer', wordBreak: 'keep-all' }}
            onClick={onClick}
          >
            <FilePresent/>
          </Badge>
        </Box>
      </Box>
    );
  } else {
    return <></>;
  }
};

export default FileButton;
