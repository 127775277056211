import React, { FC } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Grid, Paper, Typography } from '@mui/material';

export const AsyncConfirmationDialog: FC<any> = ({
  open = false,
  onClose = () => null,
  onConfirm = () => null,
  title,
  content,
  confirmLabel,
  cancelLabel,
}) => (
  <Dialog open={open} onClose={onClose}>
    <Paper>
      <DialogContent>
        <Typography variant="body1" align="center" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body2" align="center" gutterBottom color="error">
          {content}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between">
          {cancelLabel && (
            <Grid item>
              <Button type="button" variant="text" onClick={onClose}>{cancelLabel}</Button>
            </Grid>
          )}
          {confirmLabel && (
            <Grid item>
              <Button type="button" color="primary" onClick={onConfirm}>{confirmLabel}</Button>
            </Grid>
          )}
        </Grid>
      </DialogActions>
    </Paper>
  </Dialog>
);
