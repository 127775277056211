import { useContext, useMemo } from 'react';
import {
  Home,
  InsertDriveFile,
  People,
  Info,
} from '@mui/icons-material';

import { routes } from 'config';
import { LocalizationContext, UserContext } from 'contexts';

export const useNavSections = () => {
  const { userPermissions } = useContext(UserContext);
  const { dictionary } = useContext(LocalizationContext);

  const sections = useMemo(() => [
    {
      title: dictionary.menu.home,
      link: routes.home,
      Icon: Home,
      enabled: true,
      showInNav: true,
      showInHome: false,
    },
    {
      title: dictionary.menu.documents,
      link: routes.documents,
      Icon: InsertDriveFile,
      enabled: !!userPermissions?.view_cla,
      showInNav: !!userPermissions?.view_cla,
      showInHome: !!userPermissions?.view_cla,
    },
    {
      title: dictionary.menu.team,
      link: routes.team,
      Icon: People,
      enabled: !!userPermissions?.view_user,
      showInNav: !!userPermissions?.view_user,
      showInHome: !!userPermissions?.view_user,
    },
    {
      title: dictionary.menu.manual,
      link: `${process.env.REACT_APP_CLA_API_ROOT}/manual`,
      Icon: Info,
      enabled: true,
      showInNav: true,
      showInHome: true,
      external: true,
    },
  ].filter(({ enabled }) => enabled), [dictionary, userPermissions]);

  const navSections = useMemo(() => sections.filter(({ showInNav }) => showInNav), [sections]);
  const homeSections = useMemo(() => sections.filter(({ showInHome }) => showInHome), [sections]);

  return {
    sections,
    navSections,
    homeSections,
  };
};
