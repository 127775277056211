import { useQuery } from 'react-query';
import { cacheKeys } from 'config';
import { groupClient } from 'clients/groups/groupClient';

type Options = {
  enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useAuthGroups = (options: Partial<Options> = defaultOptions) => {

  const { data: { data: { results: groups = [] } = {} } = {}, status, error } = useQuery(
    cacheKeys.groups.getAuthGroups,
    groupClient.getAuthGroups,
    {
      enabled: options.enabled,
    },
  );

  return {
    status,
    error,
    groups,
  };
};
