import React, { FC } from 'react';


import { getEntityColor, menuIndicatorBorder } from 'modules/Document/SectionMenu/helpers';
import { SectionAccordionMenu } from 'modules/Document/SectionMenu/SectionAccordionMenu';
import { useQueryState } from 'shared/hooks/useQueryState';
import { ArticleMenu } from './ArticleMenu';
import { BlockMenu } from './BlockMenu';


type Props = {
  document: any;
  onNavigate?: () => void;
};

export const SectionMenu: FC<Props> = ({
  document,
  onNavigate = () => {
  },
}) => {
  const [sectionId] = useQueryState('section-id');

  const sections = document?.section_set.filter((section: any) => {
    if (!sectionId) {
      return true;
    }
    return section.id.toString() === sectionId.toString();
  });

  return (
    <div
      style={{
        border: '2px solid #fafafa',
      }}
    >
      {sections?.map((section: any) => (
        <SectionAccordionMenu
          key={section.slug}
          title={section.stripped_title}
          titleColor={getEntityColor(section)}
          accordionDetailsStyle={{
            padding: 0,
            marginLeft: 2,
            borderLeft: menuIndicatorBorder,
          }}
          details={(
            <>
              {section.blocks.map((block: any) => (
                (block.is_before_article && block.title) && (
                  <BlockMenu
                    block={block}
                    onNavigate={onNavigate}
                    key={block.slug}
                  />
                )
              ))}
              {section.article_set.map((article: any, index: number) => (
                article.title && (
                  <ArticleMenu
                    article={article}
                    position={index}
                    onNavigate={onNavigate}
                    key={article.slug}
                  />
                )))}
              {section.blocks.map((block: any, index: number) => (
                (!block.is_before_article && block.title) && (
                  <BlockMenu
                    block={block}
                    onNavigate={onNavigate}
                    key={block.slug}
                  />
                )
              ))}
            </>
          )}
        />
      ))}
    </div>
  );
};
