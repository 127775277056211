import { StateCreator } from 'zustand';
import { DocumentSliceState, HistoryData } from 'store/types';
import { useBoundStore } from 'store/index';

export const createDocumentSlice: StateCreator<DocumentSliceState> = () => ({
  historyData: {
    new_blocks: [],
    deleted_blocks: [],
    modified_blocks: {
      title: [],
      text_content: [],
      json_content: [],
      other: [],
    },
    new_articles: [],
    deleted_articles: [],
    modified_articles: {
      title: [],
      other: [],
    },
    new_sections: [],
    deleted_sections: [],
    modified_sections: {
      title: [],
      other: [],
    },
  },
});

export const setHistoryData = (historyData: HistoryData) => {
  useBoundStore.setState((state) => ({
    historyData,
  }));
};
