import { useContext, useEffect } from 'react';
import { FetchQueryOptions, useQuery, useQueryClient } from 'react-query';

import { cacheKeys } from 'config';
import { UserContext } from 'contexts';
import { documentsClient } from 'clients/documents/documentsClient';

export type Params = {
  slug?: string;
  importantOnly?: boolean;
};

type Options = {
  enabled: boolean;
};

type PrefetchOptions = FetchQueryOptions & {
  enabled: boolean;
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

const defaultPrefetchOptions: Partial<PrefetchOptions> = {
  enabled: true,
};


export const usePrefetchDocument = (params: Params = {}, options: Partial<PrefetchOptions> = defaultPrefetchOptions) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    const { enabled, ...prefetchOptions } = options;

    if (enabled) {
      queryClient.prefetchQuery(
        [cacheKeys.documents.getDocument, params],
        () => documentsClient.getDocument(params),
        prefetchOptions,
      );
    }
  }, [options, queryClient, params]);
};

export const useDocument = (params: Params = {}, options: Partial<Options> = defaultOptions) => {

  const { userPermissions } = useContext(UserContext);

  const { data: { data: document } = {}, status, error } = useQuery(
    [cacheKeys.documents.getDocument, params, false],
    () => documentsClient.getDocument(params, false),
    {
      enabled: options.enabled && !!userPermissions?.view_cla,
    },
  );

  const { data: { data: draftDocument } = {}, status: draftStatus, error: draftError } = useQuery(
    [cacheKeys.documents.getDraftDocument, params, true],
    () => documentsClient.getDocument(params, true),
    {
      enabled: !!document && !!userPermissions?.change_cla,
    },
  );

  return {
    status,
    error,
    document,
    draftDocument,
    draftStatus,
    draftError,
  };
};

type DocumentHistoryParams = {
  slug?: string,
}

export const useDocumentHistoryV2 = (params: DocumentHistoryParams = {}, options: Partial<Options> = defaultOptions) => {
  const { data: { data } = {}, status, error, refetch } = useQuery(
    [cacheKeys.documents.getDocumentHistoryV2, params, false],
    () => documentsClient.getDocumentHistoryV2(params),
    options,
  );

  return {
    data,
    status,
    error,
    refetch,
  };
};
