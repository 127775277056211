import React, { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Paper,
  TextField,
  Typography,
} from '@mui/material';

import { cacheKeys } from 'config';
import { LayoutContext, LocalizationContext } from 'contexts';
import { documentsClient } from 'clients/documents/documentsClient';
import AttachmentEditableList from 'shared/components/form/AttachmentEditableList/AttachmentEditableList';


export const ContentAttachments: FC<any> = ({ cla, section, article, block }) => {
  const queryClient = useQueryClient();
  const { isMobile, genericError, changesSaved, setSharedPopupOpen, readMode } = useContext(LayoutContext);
  const { dictionary } = useContext(LocalizationContext);
  const [isSaving, setIsSaving] = useState(false);

  const readOnly = readMode;

  const [id, cacheKey, clientMethod] = useMemo(() => {
    if (section) {
      return [section.id, cacheKeys.section.getSection, () => documentsClient.getSection(section.id)];
    }
    if (article) {
      return [article.id, cacheKeys.article.getArticle, () => documentsClient.getArticle(article.id)];
    }
    return [block.id, cacheKeys.block.getBlock, () => documentsClient.getBlock(block.id)];
  }, [section, article, block]);

  const { data: { data = null } = {} } = useQuery(
    [cacheKey, id],
    clientMethod,
    { enabled: true },
  );

  // fixme Doesn't clean file name after submitting the file
  const onDelete = useCallback(async (id) => {
    setIsSaving(true);

    const fetch = async () => {
      await documentsClient.deleteFile(id);
      queryClient.invalidateQueries(cacheKey);
      queryClient.invalidateQueries(cacheKeys.documents.getDocument);
      queryClient.invalidateQueries(cacheKeys.documents.getDraftDocument);
    };
    await fetch();

    setIsSaving(false);

  }, [cacheKey, queryClient]);

  const methods = useForm({
    defaultValues: {
      file: null,
    },
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = useCallback(async ({ file }) => {
    setIsSaving(true);
    try {
      const mappedData = {
        file,
        cla: cla?.id,
        section: section?.id,
        article: article?.id,
        block: block?.id,
      };
      await documentsClient.createFile(mappedData);
      changesSaved();
      reset();
      queryClient.invalidateQueries(cacheKey);
      queryClient.invalidateQueries(cacheKeys.documents.getDocument);
      queryClient.invalidateQueries(cacheKeys.documents.getDraftDocument);
    } catch (e) {
      console.error(e);
      genericError();
    }
    setIsSaving(false);
  }, [cla, section, article, block, cacheKey, queryClient, reset, genericError, changesSaved]);

  return (
    <Box component={Paper} sx={{ p: 2, minWidth: isMobile ? 'calc(100vw - 100)' : 500 }}>
      <Grid container direction="column" spacing={2}>
        <Grid item xs={12}><Typography variant="h5">{dictionary.document.files}</Typography></Grid>
        <AttachmentEditableList
          files={data?.file_set}
          allowDelete={!readOnly}
          onDelete={onDelete}
        />
        <Grid item xs={12}>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                {!readOnly && (
                  <Grid item xs={12}>
                    <Controller
                      name="file"
                      rules={{ required: dictionary.forms.validations.required }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          style={{ border: '1px solid #999' }}
                          type="file"
                          value={undefined}
                          onChange={e => {
                            // @ts-ignore
                            field.onChange(e?.target?.files?.[0]);
                          }}
                        />
                      )}
                    />
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  sx={{ textAlign: 'right' }}
                  mt={1}
                >
                  <Grid
                    container
                    spacing={1}
                  >
                    <Grid item ml="auto"/>
                    <Grid item>
                      <Button
                        size="small"
                        disabled={isSaving}
                        onClick={() => setSharedPopupOpen(false)}
                      >
                        {dictionary.dialogs.close}
                      </Button>
                    </Grid>
                    {!readOnly && (
                      <Grid item>
                        <Button size="small" type="submit" disabled={isSaving}>
                          {dictionary.submit}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                  {isSaving && (
                    <LinearProgress/>
                  )}
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Grid>
      </Grid>
    </Box>
  );
};
