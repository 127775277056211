import React, { FC, useContext, useState } from 'react';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

import { LayoutContext, LocalizationContext, UserContext } from 'contexts';


export const Row: FC<any> = ({ children, onAddRow, onDeleteRow, rowIndex }) => {

  const { userPermissions } = useContext(UserContext);
  const { isLightMode, writeMode } = useContext(LayoutContext);
  const { dictionary } = useContext(LocalizationContext);
  const [isHovered, setIsHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>();

  return (
    <tr
      key={rowIndex}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        minHeight: 40,
        position: 'relative',
        borderRight: `2px ${isLightMode ? '#fafafa' : undefined} solid`,
        borderLeft: `2px ${isLightMode ? '#fafafa' : undefined} solid`,
      }}
    >
      {children}
      {(writeMode && !!userPermissions?.change_block) && (
        <td
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{ width: 24, opacity: isHovered ? 1 : 0 }}
        >
          <Tooltip title="Row Actions">
            <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
              <MoreVert/>
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => { setAnchorEl(null); setIsHovered(false); }}
          >
            <MenuItem onClick={() => { onAddRow(rowIndex); setAnchorEl(null); }}>{dictionary.editableTable.addRowBefore}</MenuItem>
            <MenuItem onClick={() => { onAddRow(rowIndex + 1); setAnchorEl(null); }}>{dictionary.editableTable.addRowAfter}</MenuItem>
            <MenuItem onClick={() => { onAddRow(rowIndex, true); setAnchorEl(null); }}>{dictionary.editableTable.duplicateRow}</MenuItem>
            <MenuItem onClick={() => { onDeleteRow(rowIndex); setAnchorEl(null); }}>{dictionary.editableTable.deleteRow}</MenuItem>
          </Menu>
        </td>
      )}
    </tr>
  );
};
