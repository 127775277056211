import { DocumentMode } from 'types/Document';
import { RenvoiMode } from 'clients/documents/documentsClient.types';

export type DocumentTemplateSliceState = {
  showFilters: boolean,
  showDocumentSidebar: boolean,
  showFilesOverview: boolean,
  showRightContainer: boolean,
  faqOrNotesTab: FaqOrNotesTab,
}

export type DocumentModeSliceState = {
  mode: DocumentMode;
  draftPreviewMode: boolean;
  writeMode: boolean;
  readMode: boolean;
  reviewMode: boolean;
  showDraftVersion: boolean;
}

export type DocumentSearchSliceState = {
  renvoiMode: RenvoiMode,
  documentSearchResults: any,
  documentSearchHighlightedMap: any,
  documentSearchArticleResults: any,
  documentSearchHighlightedResults: any,
  documentSearchCurrentHighlightedId: string | undefined | null
}

export type HistoryData = {
  new_blocks: number[],
  deleted_blocks: number[],
  modified_blocks: {
    title: number[]
    text_content: number[]
    json_content: number[]
    other: number[]
  },
  new_articles: number[],
  deleted_articles: number[],
  modified_articles: {
    title: number[]
    other: number[]
  },
  new_sections: number[],
  deleted_sections: number[],
  modified_sections: {
    title: number[]
    other: number[]
  },
}

export type DocumentSliceState = {
  historyData: HistoryData
}

export enum FaqOrNotesTab {
  FAQ = 'FAQ',
  NOTES = 'NOTES'
}
