import { create } from 'zustand';
import { createDocumentSlice } from 'store/Document';
import { createDocumentTemplateSlice } from './DocumentTemplate';
import { createDocumentModeSlice } from './DocumentMode';
import {
  DocumentModeSliceState,
  DocumentTemplateSliceState,
  DocumentSearchSliceState,
  DocumentSliceState,
} from './types';
import { createDocumentSearchSlice } from './DocumentSearch';

export const useBoundStore = create<
  DocumentTemplateSliceState
  & DocumentSliceState
  & DocumentModeSliceState
  & DocumentSearchSliceState
>()((...a) => ({
  ...createDocumentTemplateSlice(...a),
  ...createDocumentModeSlice(...a),
  ...createDocumentSearchSlice(...a),
  ...createDocumentSlice(...a),
}));
