const styles = `
body {
    font-family: 'Ubuntu', 'Roboto', 'serif';
    line-height: 1.5;
    font-size: 12pt;
    font-weight: 300;
}

h1 {
    font-weight: 300 !important;
    font-size: 26pt;
}

h2 {
    font-weight: 300 !important;
    font-size: 24pt;
}

h3 {
    font-weight: 300 !important;
    font-size: 22pt;
}

h4 {
    font-weight: 300 !important;
    font-size: 20pt;
}

h5 {
    font-weight: 300 !important;
    font-size: 18pt;
}

h6 {
    font-weight: 300 !important;
    font-size: 16pt;
}

h3.MuiTypography-h3 {
    display: inline-block;
    color: rgb(255,112,0);
}

h4.MuiTypography-h4 {
    display: inline-block;
    color: rgb(255,112,0);
}

h5.MuiTypography-h5 {
    display: inline-block;
    color: rgb(39,62,113);
}

h6.MuiTypography-h6 {
    display: inline-block;
    color: rgb(39,62,113);
}

ol .MuiTypography-h5 {
    display: unset;
}

ul .MuiTypography-h5 {
    display: unset;
}

h6.MuiTypography-h6 {
    display: inline-block;
}

ol .MuiTypography-h6 {
    display: unset;
}

ul .MuiTypography-h6 {
    display: unset;
}

.no-print {
    display: none !important;
}

strong {
    font-weight: 700 !important;
}

h1, h2, h3, h4, h5, h6 {
    margin: 8px 0;
}

h1 span span p, h2 span span p, h3 span span p, h4 span span p, h5 span span p, h6 span span p {
    margin: 8px 0;
    display: inline;
}

.reference-container {
    display: inline;
}
.reference-container div {
    display: inline;
}

.explanation-block {
  text-decoration: green wavy underline;
}

.explanation-block {
    background: #ededed;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 8px;
    border-radius: 12px;
    border: 3px solid #ededed;
}

tr {
    color: white;
}

table {
  font-size: 9pt !important;
}

table a {
  color: white;
}

br {
    display: none;
}

/* empty tags cleanup */
p:has(> span:empty) {
    display: none;
}

div:has(> span table) {
    margin-right: 12px;
}

h3:has(> span:empty),
h3:has(> span span:empty),
h4:has(> span:empty),
h4:has(> span span:empty),
h5:has(> span:empty),
h5:has(> span span:empty),
h6:has(> span:empty),
h6:has(> span span:empty) {
    display: none;
}

br {
    display: none;
}

.renvoi-mode-normal .is-renvoi s,
.renvoi-mode-normal.is-renvoi s,
.renvoi-mode-normal .is-full-renvoi,
.renvoi-mode-normal.is-full-renvoi,
.renvoi-mode-normal .is-full-renvoi s,
.renvoi-mode-normal.is-full-renvoi s {
    text-decoration: none;
}

.renvoi-mode-removed .is-renvoi s,
.renvoi-mode-removed.is-renvoi s,
.renvoi-mode-removed .is-full-renvoi,
.renvoi-mode-removed.is-full-renvoi {
    display: none;
}

/* don't ask me, ask chrome developers https://bugzilla.mozilla.org/show_bug.cgi?id=1344545 */
.renvoi-mode-strikethrough.is-full-renvoi,
.renvoi-mode-strikethrough .is-full-renvoi,
.renvoi-mode-strikethrough .is-full-renvoi h1,
.renvoi-mode-strikethrough .is-full-renvoi h2,
.renvoi-mode-strikethrough .is-full-renvoi h3,
.renvoi-mode-strikethrough .is-full-renvoi h4,
.renvoi-mode-strikethrough .is-full-renvoi h5,
.renvoi-mode-strikethrough .is-full-renvoi h6 {
    text-decoration: line-through;
}

ul.block-list div.block-item, ol.block-list div.block-item {
    padding-left: 10px
}

ul.block-list div.non-list-item, ol.block-list div.non-list-item {
    margin: 0 0 0 -20px;
}

#documentPublishedAt, #documentTitle {
    display: block !important;
    text-align: center !important;
    color: rgb(39,62,113);
}

ol, ul {
  margin-left: 0 !important;
  padding-left: 0 !important;
}

hr {
  border: 1px solid rgb(39,62,113);
}

// .hidden {
//     display: none !important;
// }
`;

export default styles;
