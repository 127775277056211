import { saveAs } from 'file-saver';
import docStyles from './docStyles';


const replaceTagName = (
  element: Element,
  tagFromStartRegex: RegExp,
  tagFromClosingRegex: RegExp,
  tagToStart: string,
  tagToClosing: string,
) => {
  element.outerHTML = element.outerHTML
    .replace(tagFromStartRegex, tagToStart)
    .replace(tagFromClosingRegex, tagToClosing);
};

const replaceChildrenTagToTag = (
  elements: HTMLCollectionOf<Element> | NodeListOf<Element>,
  tagFrom: string,
  tagFromStartRegex: RegExp,
  tagFromClosingRegex: RegExp,
  tagToStart: string,
  tagToClosing: string,
) => {
  Array.from(elements).forEach(element => {
    const elements = element.querySelectorAll(tagFrom);

    Array.from(elements).forEach(element => {
      replaceTagName(
        element,
        tagFromStartRegex,
        tagFromClosingRegex,
        tagToStart,
        tagToClosing,
      );
    });
  });
};

const maxWidth = 600;

const scaleTable = (table: HTMLTableElement) => {
  const container = document.createElement('div');
  container.style.position = 'absolute';
  container.style.visibility = 'hidden';
  container.style.width = 'auto';
  container.appendChild(table.cloneNode(true));
  document.body.appendChild(container);

  const originalWidth = container.offsetWidth;

  if (originalWidth > maxWidth) {
    table.style.maxWidth = `${450}px`;
    table.style.position = 'absolute';
    table.style.left = '0';
    const tableCells = table.querySelectorAll('td, th');
    tableCells.forEach(cell => {
      const divInsideTd = cell.querySelector('div') as HTMLElement;
      if (divInsideTd) {
        // const scaleFactor = maxWidth / originalWidth;
        // const fontSizeNumber = 9 * scaleFactor;
        // divInsideTd.style.fontSize = `${fontSizeNumber}pt`;
      }
      (cell as HTMLElement).style.maxWidth = '50px';
      (cell as HTMLElement).style.padding = '2px';
    });
  }


  document.body.removeChild(container);
};

const scaleAllTables = (document: Document) => {
  const tables = document.querySelectorAll('table');
  tables.forEach((table, index) => {
    scaleTable(table as HTMLTableElement);
  });
};

export const downloadDocx = () => {
  const header = `
    <html>
      <head>
        <meta charset="utf-8">
        <style>
          ${docStyles}
        </style>
      </head>
      <body>
  `;

  const footer = '</body></html>';

  const hiddenItems = window.document.querySelectorAll('.hidden');
  hiddenItems.forEach(el => el.remove());

  const documentElementHtml = window.document.getElementById('document')?.outerHTML.toString();

  const normalizedDocumentElementHtml = documentElementHtml
    ?.replaceAll('<del>', '<s>')
    .replaceAll('</del>', '</s>')
    .replaceAll('<p></p>', '')
    .replaceAll('<br>', '');

  const fakeHtml = header + normalizedDocumentElementHtml + footer;

  // replace p with span make it be inline.
  const parser = new DOMParser();
  const fakeDocument = parser.parseFromString(fakeHtml, 'text/html');

  scaleAllTables(fakeDocument);

  ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].map(tagName => (
    replaceChildrenTagToTag(
      fakeDocument.getElementsByTagName(tagName),
      'p',
      /<p/g,
      /<\/p>/g,
      '<span',
      '</span>',
    )
  ));

  replaceChildrenTagToTag(
    fakeDocument.getElementsByClassName('reference-container'),
    'div',
    /<div/g,
    /<\/div>/g,
    '<span',
    '</span>',
  );

  // replace the container div itself
  Array.from(fakeDocument.getElementsByClassName('reference-container')).forEach(element => {
    replaceTagName(
      element,
      /<div/g,
      /<\/div>/g,
      '<span',
      '</span>',
    );
  });

  // fix to do not display multiple lines
  replaceChildrenTagToTag(
    fakeDocument.querySelectorAll('div:has(> .reference-container)'),
    'p',
    /<p/g,
    /<\/p>/g,
    '<span',
    '</span>',
  );

  // distinguish the explanations by adding hr to the start and the end
  Array.from(
    fakeDocument.querySelectorAll('.explanation-block'),
  ).forEach(ref => ref.outerHTML = '<br/><hr/>' + ref.outerHTML + '<hr/><br/>');

  const blob = new Blob(['\ufeff', fakeDocument.documentElement.outerHTML], {
    type: 'text/html',
  });

  saveAs(blob, 'cla.html');
};
