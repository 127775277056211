import React, { FC } from 'react';
import { Box } from '@mui/system';

import classnames from 'classnames';
import { isEmpty } from 'lodash';
import { BlockVariant, DocumentItemType } from 'clients/documents/documentsClient.types';
import { ListItem } from 'modules/Document/List/ListItem';
import { getHeaderVariant } from 'modules/Document/Article/helpers';
import { getAddOrDeleteEntityColorForHistory, getModifiedEntityColorForHistory } from 'modules/Document/helpers';
import { useNotes } from 'shared/hooks/useNotes';
import FileButton from 'shared/components/form/FileButton/FileButton';
import { QuestionAnswerButton } from 'shared/components/form/QuestionAnswerButton/QuestionAnswerButton';

import { Explanation } from './Explanation';
import { ContentSubBlock } from './ContentSubBlock';
import { SpreadSheet } from './SpreadSheet/SpreadSheet';


export const SubBlock: FC<any> = ({ parent, subBlock, type, blockStyle, listValue, siblings, showComments }) => {

  if (subBlock.variant === BlockVariant.Spreadsheet) {
    // if not json data hide?
    return (
      <SpreadSheet
        block={subBlock}
        parent={parent}
        siblings={siblings}
        style={{ marginLeft: !!type ? '-15px' : undefined ,...blockStyle }}
      />
    );
  } else if ([BlockVariant.Explanation, BlockVariant.Recommendation].includes(subBlock.variant)) {
    const newOrDeletedColor = getAddOrDeleteEntityColorForHistory(subBlock.id, 'block');

    return (
      <Explanation
        style={{
          marginLeft: !!type ? '-15px' : undefined,
          border: newOrDeletedColor ? `8px solid ${newOrDeletedColor}` : undefined,
          ...blockStyle,
        }}
      >
        <ContentSubBlock
          parent={parent}
          titleVariant={getHeaderVariant(3)}
          subBlock={subBlock}
          siblings={siblings}
          isExplanationBlock
          showComments={showComments}
        />
      </Explanation>
    );
  }

  return (
    <ListItem
      type={subBlock.display_list_formatting ? type : ''}
      style={blockStyle}
      value={listValue}
      displayListFormatting={subBlock.display_list_formatting}
      showMarker={subBlock.title || subBlock.text_content}
    >
      <ContentSubBlock
        parent={parent}
        subBlock={subBlock}
        siblings={siblings}
        showComments={showComments}
      />
    </ListItem>
  );
};

export const SubBlockWrapper: FC<any> = (props) => {
  const { groupByBlock } = useNotes();
  const modifiedColorOther = getModifiedEntityColorForHistory(props.subBlock.id, 'block', 'other');

  return (
    <Box
      className={
        classnames(
          {
            'is-full-renvoi': props.subBlock.is_full_renvoi,
            'is-renvoi': props.subBlock.is_renvoi,
          },
        )
      }
      sx={{
        border: !!modifiedColorOther ? `4px dashed ${modifiedColorOther}`: undefined,
        background: [BlockVariant.Explanation, BlockVariant.Recommendation, BlockVariant.Spreadsheet].includes(props.subBlock.variant) ? undefined : getAddOrDeleteEntityColorForHistory(props.subBlock.id, 'block'),
      }}
      position="relative"
    >
      {!props.subBlock.title && props.showComments && (
        <FileButton
          block={props.subBlock}
          sx={
            props.subBlock.has_questions ? (
              {
                position: 'absolute',
                top: 0,
                left: props.parent.list_formatting ? '-63px' : '-48px',
              }
            ) : (
              {
                position: 'absolute',
                top: 0,
                right: '-20px',
              }
            )
          }
        />
      )}
      <SubBlock {...props}/>
      {(props.subBlock.has_questions || !isEmpty(groupByBlock[props.subBlock.id])) && props.showComments && !props.subBlock.title && (
        <QuestionAnswerButton
          item={props.subBlock}
          itemType={DocumentItemType.Block}
          itemQuestions={props.subBlock.questions}
          sx={{
            position: 'absolute',
            top: 0,
            right: -20,
          }}
        />
      )}
    </Box>
  );
};
