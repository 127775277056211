import React, { Fragment, FC, useContext, useState, useCallback, useMemo } from 'react';
import { useDebounce } from 'react-use';
import {
  Box,
  Typography,
  IconButton,
  InputAdornment,
  TextField,
  Link,
  Button,
} from '@mui/material';
import { Delete, Edit, AddCircle, Search } from '@mui/icons-material';

import { cloneDeep, isEmpty, pullAt, truncate } from 'lodash';
import { DialogContext, LayoutContext, LocalizationContext } from 'contexts';
import { Dialog } from 'contexts/DialogContext/types';
import { useQueryState } from 'shared/hooks/useQueryState';
import { useQuestionSearch } from 'shared/hooks/useQuestionSearch';
import { useQuestionCrud } from 'shared/hooks/useQuestionCrud';
import MultiAccordion from 'shared/components/MultiAccordion/MultiAccordion';
import FileList from 'shared/components/FileList/FileList';


export const FAQ: FC<any> = ({
  onCountChange = () => {
  },
  params={
    limit: 1000000000000,
  },
}) => {
  const { dictionary } = useContext(LocalizationContext);
  const [slug = ''] = useQueryState('slug');
  const { layoutTempState, writeMode, readMode, setLayoutTempState } = useContext(LayoutContext);
  const { openDialog, asyncConfirmation } = useContext(DialogContext);
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');
  const { deleteQuestion } = useQuestionCrud();

  useDebounce(() => {
    setDebouncedSearchQuery(searchQuery);
  },
  500,
  [searchQuery],
  );
  const showGeneralQuestions = useMemo(() => {
    return debouncedSearchQuery.length > 2 || isEmpty(layoutTempState?.selectedFAQ);
  }, [debouncedSearchQuery.length, layoutTempState]);

  const { results = [] } = useQuestionSearch(
    {
      cla_id: slug,
      search: debouncedSearchQuery,
      ...params,
    },
    { enabled: showGeneralQuestions },
  );

  const questions = useMemo(() => {
    let allQuestions = (showGeneralQuestions ? results : layoutTempState?.selectedFAQ) || [];

    if (readMode) {
      allQuestions = allQuestions.filter((question: any) => {
        const hasPublishedArticles = question.linked_articles.some((article: any) => !!article.title);
        const hasPublishedBlocks = question.linked_blocks.some((block: any) => !!block.title || !!block.text_content);
        return hasPublishedArticles || hasPublishedBlocks;
      });

      allQuestions = allQuestions.map((question: any) => ({
        ...question,
        linked_articles: question.linked_articles.filter((article: any) => !!article.title),
        linked_blocks: question.linked_blocks.filter((block: any) => !!block.title || !!block.text_content),
      }));
    }

    onCountChange(allQuestions.length);

    return allQuestions;
  }, [showGeneralQuestions, results, layoutTempState?.selectedFAQ, readMode, onCountChange]);


  const onEdit = useCallback((index: number, event) => {
    event.stopPropagation();
    openDialog(Dialog.QuestionAnswerDialog, { question: questions[index], index });
  }, [openDialog, questions]);

  const onDelete = useCallback(async (index: number, event) => {
    event.stopPropagation();
    const userConfirmed = await asyncConfirmation({
      title: dictionary.confirmation.defaultTitle,
      content: dictionary.confirmation.defaultContent,
    });

    if (!userConfirmed) {
      return;
    }

    await deleteQuestion.mutateAsync({
      cla_id: slug,
      id: questions[index].id,
    });

    let selectedFAQ = cloneDeep(layoutTempState?.selectedFAQ || []);
    pullAt(selectedFAQ, [index]);

    setLayoutTempState({
      ...layoutTempState,
      selectedFAQ: selectedFAQ,
    });

  }, [asyncConfirmation, deleteQuestion, dictionary, layoutTempState, questions, setLayoutTempState, slug]);

  return (
    <>
      <Box
        mb={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <TextField
          sx={{ my: 2 }}
          size="small"
          label={dictionary.search}
          variant="standard"
          InputProps={{
            endAdornment: <InputAdornment position="end"><Search/></InputAdornment>,
          }}
          onChange={(event) => setSearchQuery(event.target.value)}
        />
        {writeMode && (
          <Button
            sx={{
              'ml': '12px',
            }}
            variant="contained"
            endIcon={<AddCircle/>}
            onClick={(event) => onEdit(-1, event)}
          >
            {dictionary.add}
          </Button>
        )}
      </Box>
      <Box maxHeight="calc(100vh - 440px)" overflow="auto">
        <MultiAccordion
          data={questions}
          emptyLabel={dictionary.faq.empty}
          renderSummary={(question: any, index: number, expandIndex: number) => (
            <>
              <Typography fontWeight={500}>{question.question}</Typography>
              <Box ml="auto"/>
              {expandIndex === index && writeMode && (
                <IconButton onClick={(event) => onEdit(index, event)}>
                  <Edit/>
                </IconButton>
              )}
              {expandIndex === index && writeMode && (
                <IconButton onClick={(event) => onDelete(index, event)}>
                  <Delete/>
                </IconButton>
              )}
            </>
          )}
          renderDetails={(question: any, index: number) => (
            <>
              <Typography
                variant="body1" sx={{ wordBreak: 'break-word' }}
                dangerouslySetInnerHTML={{ __html: question.answer }}
              />

              {!isEmpty(question.linked_articles) && (
                <Fragment>
                  <Typography mt={3} fontWeight={400}>{dictionary.faq.relatedArticles}:</Typography>
                  <Box mt={1}>
                    {question.linked_articles.map((linked_article: any) => (
                      <Link
                        key={linked_article.id}
                        href={`#${linked_article.slug}`}
                        fontWeight={300}
                        fontSize={13}
                        display="block"
                        mt={1}
                        sx={{ wordBreak: 'break-word' }}
                      >
                        {writeMode ? linked_article.numbering_draft : linked_article.numbering} {writeMode ? linked_article.stripped_title_draft || linked_article.stripped_title : linked_article.stripped_title}
                      </Link>
                    ))}
                  </Box>
                </Fragment>
              )}

              {!isEmpty(question.linked_blocks) && (
                <Fragment>
                  <Typography mt={3} fontWeight={400}>{dictionary.faq.relatedBlocks}:</Typography>
                  <Box mt={1}>
                    {question.linked_blocks.map((linked_block: any) => (
                      <Link
                        key={linked_block.id}
                        href={`#${linked_block.slug}`}
                        fontWeight={300}
                        fontSize={13}
                        display="block"
                        mt={1}
                        sx={{ wordBreak: 'break-word' }}
                      >
                        {truncate(
                          writeMode ? linked_block.stripped_title_draft
                            || linked_block.stripped_title
                            || linked_block.stripped_text_content_draft : linked_block.stripped_title || linked_block.stripped_text_content,
                          { length: 300 },
                        )}
                      </Link>
                    ))}
                  </Box>
                </Fragment>
              )}

              {!isEmpty(question.file_set) && (
                <>
                  <Typography mt={3} fontWeight={400}>
                    {dictionary.document.files}:
                  </Typography>
                  <Box mt={1}>
                    <FileList
                      files={question.file_set}
                    />
                  </Box>
                </>
              )}
            </>
          )}
        />
      </Box>
    </>
  );
};
