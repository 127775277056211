import { useQuery } from 'react-query';

import { cacheKeys } from 'config';
import { documentsClient } from 'clients/documents/documentsClient';

export type Params = {
  slug?: string;
  importantOnly?: boolean;
  draft_secret?: string;
};

type Options = {
  enabled: boolean;
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};


export const usePublicDocument = (params: Params = {}, options: Partial<Options> = defaultOptions) => {

  const { data: { data: document } = {}, status, error } = useQuery(
    [cacheKeys.documents.getDocument, params],
    () => documentsClient.getPublicDocument(params),
    {
      enabled: options.enabled,
    },
  );

  return {
    status,
    error,
    document,
  };
};
