import React, { createContext, FC, useCallback, useState } from 'react';

const defaultContext: any = {
  sectionsToPublish: [],
  setSectionsToPublish: () => null,
  addSection: () => null,
  removeSection: () => null,
  articlesToPublish: [],
  setArticlesToPublish: () => null,
  addArticle: () => null,
  removeArticle: () => null,
  blocksToPublish: [],
  setBlocksToPublish: () => null,
  partialPublish: false,
  setPartialPublish: () => null,
  reset: () => null,
};

export const DocumentPublishContext = createContext(defaultContext);

const addItem = (id: number) => (prevState: number[]) => {
  const index = prevState.indexOf(id);
  if (index < 0) {
    return [...prevState, id];
  }
  return prevState;
};

const removeItem = (id: number) => (prevState: number[]) => {
  const index = prevState.indexOf(id);
  if (index > -1) {
    const newState = [...prevState];
    newState.splice(index, 1);
    return newState;
  }
  return prevState;
};

export const DocumentPublishContextProvider: FC<any> = ({ children }) => {
  const [partialPublish, setPartialPublish] = useState(false);
  const [sectionsToPublish, setSectionsToPublish] = useState<number[]>([]);
  const [articlesToPublish, setArticlesToPublish] = useState<number[]>([]);
  const [blocksToPublish, setBlocksToPublish] = useState<number[]>([]);

  const addSection = useCallback((sectionId: number) => {
    setSectionsToPublish(addItem(sectionId));
  }, []);

  const removeSection = useCallback((sectionId: number) => {
    setSectionsToPublish(removeItem(sectionId));
  }, []);

  const addArticle = useCallback((articleId: number) => {
    setArticlesToPublish(addItem(articleId));
  }, []);

  const removeArticle = useCallback((articleId: number) => {
    setArticlesToPublish(removeItem(articleId));
  }, []);

  const reset = useCallback(() => {
    setSectionsToPublish([]);
    setArticlesToPublish([]);
    setBlocksToPublish([]);
  }, []);

  return (
    <DocumentPublishContext.Provider
      value={{
        ...defaultContext,
        sectionsToPublish,
        setSectionsToPublish,
        addSection,
        removeSection,
        articlesToPublish,
        setArticlesToPublish,
        addArticle,
        removeArticle,
        blocksToPublish,
        setBlocksToPublish,
        partialPublish,
        setPartialPublish,
        reset,
      }}
    >
      {children}
    </DocumentPublishContext.Provider>
  );
};
