import { useQuery } from 'react-query';

import { cacheKeys } from 'config';
import { documentsClient } from 'clients/documents/documentsClient';


export type Params = {
  slug?: string;
  search?: string;
  importantOnly?: boolean;
  draft?: boolean;
};

type Options = {
  enabled: boolean;
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useDocumentSearch = (params: Params = {}, options: Partial<Options> = defaultOptions) => {
  const { data: { data: searchResults } = {}, status, error } = useQuery(
    [cacheKeys.documents.searchDocument, params],
    () => documentsClient.searchDocument(params),
    {
      enabled: options.enabled,
    },
  );

  return {
    status,
    error,
    searchResults,
  };
};
