import React, { FC, useContext } from 'react';
import {
  Avatar,
  Box,
  Chip,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@mui/material';
import { ClearOutlined, FilePresent } from '@mui/icons-material';
import { format } from 'date-fns';
import { LocalizationContext } from 'contexts';
import { AttachmentEditableListProps } from 'shared/components/form/AttachmentEditableList/types';
import { downloadFile } from 'shared/components/FileList/FileList';

const AttachmentEditableList: FC<AttachmentEditableListProps> = (
  {
    files,
    onDelete,
    allowDelete,
  },
) => {
  const { locale } = useContext(LocalizationContext);

  return (
    <>
      {files?.map(({ id, file, created_at, created_by, isNew }: any) => {
        const fileName = file?.name || file?.split('/')?.reverse()?.[0] || '';

        return (
          <Grid item xs={12}>
            <Box
              p={1}
              border="1px solid #999"
              sx={{
                background: 'white',
              }}
            >
              <Grid container alignItems="center" spacing={1} wrap="nowrap">
                <Grid item>
                  <IconButton onClick={() => downloadFile(file)}>
                    <FilePresent/>
                  </IconButton>
                </Grid>
                <Grid item>
                  <Link
                    sx={{
                      cursor: 'pointer',
                    }}
                    onClick={() => downloadFile(file)}
                  >
                    <Typography
                      sx={{
                        wordBreak: 'break-all',
                      }}
                    >
                      {fileName}
                    </Typography>
                  </Link>
                </Grid>
                <Box ml="auto"/>
                {isNew && (
                  <Chip
                    label="new"
                    color="primary"
                    size="small"
                    sx={{
                      right: '-9px',
                      position: 'relative',
                    }}
                  />
                )}
                {created_at && (
                  <Grid item>
                    <Typography variant="subtitle2">
                      {format(new Date(created_at), 'EEE, dd MMM yy HH:mm', { locale })}
                    </Typography>
                  </Grid>
                )}
                {created_by && (
                  <Grid item>
                    <Tooltip title={`${created_by.first_name} ${created_by.last_name}`}>
                      <Avatar src={created_by.profile_picture} sx={{ mr: -0.5, ml: 1, width: 26, height: 26 }}/>
                    </Tooltip>
                  </Grid>
                )}
                {allowDelete && (
                  <Grid item>
                    <IconButton onClick={() => onDelete(id, file)}>
                      <ClearOutlined/>
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
        );
      })}
    </>
  );
};

export default AttachmentEditableList;
