import React, { FC, Fragment, useCallback, useContext, useEffect, useMemo } from 'react';
import StickyBox from 'react-sticky-box';
import {
  Grid,
  Box,
  Hidden,
  Paper,
  IconButton,
  Tooltip,
  Typography,
  TextField,
  MenuItem,
  Alert,
  AlertTitle,
} from '@mui/material';
import { FilterAlt, Fullscreen, ViewCompact } from '@mui/icons-material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { find } from 'lodash';
import { useBoundStore } from 'store';
import { toggleShowDocumentSidebar, toggleShowFilters } from 'store/DocumentTemplate';
import { setRenvoiMode } from 'store/DocumentSearch';
import { primaryLight } from 'shared/styles/muiTheme';
import {
  DialogContext,
  DocumentPublishContextProvider,
  LayoutContext,
  LocalizationContext,
} from 'contexts';
import { Dialog } from 'contexts/DialogContext/types';
import { DocumentPrintContextProvider } from 'contexts/DocumentPrintContext/DocumentPrintContext';

import { RenvoiMode } from 'clients/documents/documentsClient.types';
import { SectionMenu } from 'modules/Document/SectionMenu/SectionMenu';
import { MobileDocumentNav } from 'modules/Document/SectionMenu/MobileDocumentNav';
import { SearchResultsNav } from 'modules/Document/SearchResultsNav/SearchResultsNav';
import { SearchInput } from 'modules/Document/SearchInput/SearchInput';
import { RenvoiModeDropDown } from 'modules/Document/RenvoiModeDropdown/RenvoiModeDropDown';
import { ImportantOnlyDropDown } from 'modules/Document/ImportantOnlyDropDown/ImportantOnlyDropDown';
import { useLazyHashInitScrollEffect } from 'shared/hooks/useLazyHashInitScrollEffect';

import { useQueryState } from 'shared/hooks/useQueryState';
import { usePublicDocument } from 'shared/hooks/usePublicDocument';
import { useOnLeavePage } from 'shared/hooks/useOnLeavePage';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';
import { RenderDocumentContainer, RenderedDocument } from 'views/LoggedIn/Document/components/RenderedDocument';


const showSectionList = false;

type DocumentProps = {
  mode?: boolean;
  isPrintMode?: boolean;
  isPrintPreview?: boolean;
};

export const DocumentWithContexts: FC<DocumentProps> = ({ isPrintMode, isPrintPreview }) => {

  const {
    genericError,
    mode,
    reviewMode,
    readMode,
    isLightMode,
    isMobile,
  } = useContext(LayoutContext);

  const [slug] = useQueryState('slug');
  const [draftSecret] = useQueryState('draft-secret');
  const [sectionId, setSectionId] = useQueryState('section-id');
  const [hideNav] = useQueryState('hide-nav');

  const { openDialog } = useContext(DialogContext);
  const { dictionary } = useContext(LocalizationContext);

  const [importantOnly] = useQueryState('important-only');

  const showFilters = useBoundStore((state) => state.showFilters);
  const showDocumentSidebar = useBoundStore((state) => state.showDocumentSidebar);
  const renvoiMode = useBoundStore((state) => state.renvoiMode);

  const { document, error, status } = usePublicDocument({
    slug,
    'draft_secret': draftSecret,
    importantOnly: !!importantOnly,
  });

  const sections = useMemo(() => {
    const section = find(document?.section_set, { 'id': parseInt(sectionId as string) });
    if (!reviewMode && sectionId !== undefined && document?.section_set && section) {
      setSectionId(section.id);
      return [section];
    }
    return document?.section_set;
  }, [reviewMode, sectionId, setSectionId, document]);

  useLazyHashInitScrollEffect();

  // in Published Mode should be renvoi in the normal mode
  useEffect(() => {
    if (!isPrintMode && !isPrintPreview) {
      setRenvoiMode(RenvoiMode.Normal);
    }
    // eslint-disable-next-line
  }, []);

  const onPrintStart = useCallback(() => {
    openDialog(Dialog.PrintDocument);
  }, [openDialog]);


  if (status === 'error') {
    return (
      <Alert
        severity="error"
        sx={{
          m: 4,
        }}
      >
        <AlertTitle>
          {draftSecret ? dictionary.publicDocument.error.title : '404'}
        </AlertTitle>
        {draftSecret ? dictionary.publicDocument.error.description : 'Not found'}
      </Alert>
    );
  }

  if (error) {
    genericError();
    return null;
  }

  if (status !== 'success') {
    return <BodyLoading/>;
  }

  return (
    <Box bgcolor={mode === 'light' ? '#ececec' : '#111'}>
      {!isPrintMode && (
        <StickyBox
          key={isMobile}
          offsetTop={hideNav ? 0 : (isMobile ? 56 : 100)}
          style={{ zIndex: 100 }}
          className="no-print"
        >
          <Paper sx={{
            p: 2,
            background: primaryLight,
          }}>
            <Grid container alignItems="center" wrap={!isMobile ? 'nowrap' : undefined} spacing={2}>
              <Fragment>
                <Grid item>
                  <IconButton onClick={toggleShowDocumentSidebar}>
                    {showDocumentSidebar ? <Fullscreen/> : <ViewCompact/>}
                  </IconButton>
                </Grid>
                <Grid item>
                  <Tooltip title={dictionary.export}>
                    <IconButton onClick={onPrintStart} edge="start">
                      <CloudDownloadIcon/>
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title={dictionary.toggleFilters}>
                    <IconButton onClick={toggleShowFilters} edge="start">
                      <FilterAlt/>
                    </IconButton>
                  </Tooltip>
                </Grid>
                {showFilters && (
                  <Fragment>
                    {(!reviewMode && document) && (
                      <Grid item xs={12} sm="auto">
                        <TextField
                          label={dictionary.section}
                          size="small"
                          sx={{ width: 200 }}
                          defaultValue={sectionId}
                          onChange={e => setSectionId(e.target.value === 'all' ? null : e.target.value.toString())}
                          select
                        >
                          <MenuItem value="all">
                            {dictionary.all}
                          </MenuItem>
                          {document?.section_set?.map((section: any) => (
                            <MenuItem
                              key={section.slug}
                              value={section.id}
                            >
                              {section?.stripped_title}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <SearchInput document={document}/>
                    </Grid>
                    <Grid item xs={12} sm="auto">
                      <SearchResultsNav/>
                    </Grid>
                    <Grid item xs={12} sm="auto" textAlign="right" sx={{ ml: -2 }}>
                      <ImportantOnlyDropDown/>
                    </Grid>
                    {!!draftSecret && (
                      <Grid item xs={12} sm="auto" textAlign="right" sx={{ ml: -2 }}>
                        <RenvoiModeDropDown/>
                      </Grid>
                    )}
                  </Fragment>
                )}
              </Fragment>
            </Grid>
          </Paper>
        </StickyBox>
      )}
      <Box
        p={isPrintMode ? undefined : 2}
        pt={isPrintMode ? undefined : 4}
        className={`renvoi-mode-${renvoiMode} print-document-container`}
        id="document"
      >
        <Box
          sx={{
            paddingLeft: isPrintMode ? 0 : undefined,
            paddingRight: isPrintMode ? 0 : undefined,
          }}
        >
          <Grid
            container
            alignItems="top"
            wrap="nowrap"
            spacing={isPrintMode ? undefined : 1}
            display="flex"
            justifyContent="center"
          >
            {(showDocumentSidebar && !reviewMode && !isPrintMode) && (
              <Fragment>
                <Hidden mdDown>
                  <Grid
                    item
                    className="no-print"
                    sx={{
                      mr: 2,
                      maxWidth: '480px',
                      minWidth: '300px',
                    }}
                  >
                    <StickyBox offsetTop={190} style={{ zIndex: 50 }}>
                      <Box textAlign="center" sx={{ mb: 2 }}>
                        <Typography variant="h6">{dictionary.tableOfContents}</Typography>
                      </Box>
                      <Box maxHeight="calc(100vh - 280px)" overflow="auto">
                        <SectionMenu document={document}/>
                      </Box>
                    </StickyBox>
                  </Grid>
                </Hidden>
                <Hidden mdUp>
                  <MobileDocumentNav document={document}/>
                </Hidden>
              </Fragment>
            )}
            {((reviewMode || readMode) && document) && (
              <RenderDocumentContainer
                reviewMode={reviewMode}
                isPrintMode={isPrintMode}
                showDocumentSidebar={showDocumentSidebar}
                showRightContainer={false}
                id="document-published"
              >
                <RenderedDocument
                  document={document}
                  sections={sections}
                  reviewMode={reviewMode}
                  showSectionList={showSectionList}
                  isLightMode={isLightMode}
                  isPrintPreview={isPrintPreview}
                  showComments={false}
                />
              </RenderDocumentContainer>
            )}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export const Document: FC<DocumentProps> = (props) => {
  return (
    <DocumentPublishContextProvider>
      <DocumentPrintContextProvider isPrintMode={!!props.isPrintMode} isPrintPreview={!!props.isPrintPreview}>
        <DocumentWithContexts {...props}/>
      </DocumentPrintContextProvider>
    </DocumentPublishContextProvider>
  );
};

export const DocumentPublicView: FC = () => {
  const { layoutTempState, setLayoutTempState, setShowFAQ } = useContext(LayoutContext);

  const onLeavePage = useCallback(() => {
    setLayoutTempState({
      ...layoutTempState,
      itemsToPrint: {},
      selectedFAQ: [],
      FAQItem: undefined,
      FAQItemType: undefined,
    });
    setShowFAQ(false);
  }, [layoutTempState, setLayoutTempState, setShowFAQ]);

  useOnLeavePage(onLeavePage);

  return (
    <Document/>
  );
};
