import React, { FC } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Link, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { menuIndicatorBorder } from 'modules/Document/SectionMenu/helpers';

type SectionAccordionMenuProps = {
  title: string | JSX.Element,
  titleColor?: string,
  details: any,
  accordionDetailsStyle?: any
  titleBold?: boolean,
}

type SectionAccordionMenuItemProps = {
  title: string | JSX.Element,
  href?: string,
  onNavigate?: () => void,
  sx?: any,
  bold?: boolean,
}

const hoverStyle = {
  '&:hover': {
    backgroundColor: '#f1f1f1',
  },
};

export const SectionAccordionMenu: FC<SectionAccordionMenuProps> = ({
  title,
  titleColor,
  titleBold = true,
  details,
  accordionDetailsStyle,
}) => {
  return (
    <Accordion
      sx={{
        background: '#fafafa',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore/>}
        sx={{
          ...hoverStyle,
        }}
      >
        <Typography
          fontWeight={titleBold ? 500 : 100}
          color={titleColor}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          pb: 0,
          pt: 0,
          pr: 0,
          ...accordionDetailsStyle,
        }}
      >
        {details}
      </AccordionDetails>
    </Accordion>
  );
};

export const SectionAccordionMenuItem: FC<SectionAccordionMenuItemProps> = ({
  href,
  onNavigate,
  title,
  sx,
  bold = true,
}) => {
  return (
    <Link
      href={href}
      fontWeight={bold ? 500 : 100}
      underline="none"
      display="block"
      sx={{
        borderLeft: menuIndicatorBorder,
        p: 2,
        pr: 2,
        ...hoverStyle,
        ...sx,
      }}
      onClick={onNavigate}
    >
      {title}
    </Link>
  );
};


