import React, { FC, useContext } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { primaryBrandOrange } from 'shared/styles/muiTheme';
import { useBoundStore } from 'store';
import { setFaqOrNotesTab } from 'store/DocumentTemplate';
import { FaqOrNotesTab } from 'store/types';
import { LayoutContext, LocalizationContext } from 'contexts';
import { FAQ } from 'modules/Document/FAQ/FAQ';
import Notes from 'modules/Document/Notes/Notes';
import { useNotes } from 'shared/hooks/useNotes';

const TabTitleWithFakeBadge: FC<any> = ({
  text,
  counter,
  extraBadgeSx,
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      {text}
      <Box
        sx={{
          background: primaryBrandOrange,
          padding: '2px 6px',
          marginLeft: '6px',
          color: 'white',
          borderRadius: '50px',
          fontWeight: 500,
          ...extraBadgeSx,
        }}
      >
        {counter}
      </Box>
    </Box>
  );
};

const FAQAndNotesContainer = () => {
  const { dictionary } = useContext(LocalizationContext);
  const faqOrNotesTab = useBoundStore(
    (state) => state.faqOrNotesTab,
  );

  const [faqCount, setFaqCount] = React.useState(0);

  const { layoutTempState } = useContext(LayoutContext);
  const { notes: allNotes, groupByBlock, groupByArticle } = useNotes();

  let notes: any;
  switch (layoutTempState?.FAQItemType) {
    case 'block':
      notes = groupByBlock[layoutTempState?.FAQItem?.id] || [];
      break;
    case 'article':
      notes = groupByArticle[layoutTempState?.FAQItem?.id] || [];
      break;
    default:
      notes = [];
      break;
  }

  if (!layoutTempState?.FAQItemType && !layoutTempState?.FAQItemType) {
    notes = allNotes;
  }

  const notesCount = notes.length || 0;

  const handleChange = (event: React.SyntheticEvent, newValue: FaqOrNotesTab) => {
    setFaqOrNotesTab(newValue);
  };

  return (
    <TabContext
      value={FaqOrNotesTab[faqOrNotesTab]}
      key={layoutTempState?.FAQItem?.id}
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList
          onChange={handleChange}
          key={layoutTempState?.FAQItem?.id}
        >
          <Tab
            label={(
              <TabTitleWithFakeBadge
                text={dictionary.faq.frequentlyAskedQuestions}
                counter={faqCount}
              />
            )}
            value={FaqOrNotesTab[FaqOrNotesTab.FAQ]}
          />
          <Tab
            label={(
              <TabTitleWithFakeBadge
                text={dictionary.notes.notes}
                counter={notesCount}
                extraBadgeSx={{
                  color: primaryBrandOrange,
                  fontWeight: 'bold',
                  backgroundColor: 'white',
                  border: '2px solid #ff7000',
                  // height: '19px',
                  // minWidth: '19px',
                  padding: '0px 4px',
                }}
              />
            )}
            value={FaqOrNotesTab[FaqOrNotesTab.NOTES]}
          />
        </TabList>
      </Box>
      <TabPanel
        value={FaqOrNotesTab[FaqOrNotesTab.FAQ]}
        key={layoutTempState?.FAQItem?.id}
      >
        <FAQ
          key={layoutTempState?.FAQItem?.id}
          onCountChange={setFaqCount}
        />
      </TabPanel>
      <TabPanel
        value={FaqOrNotesTab[FaqOrNotesTab.NOTES]}
      >
        <Notes
          notes={notes}
          disableAddButton={!layoutTempState?.FAQItem}
        />
      </TabPanel>
    </TabContext>
  );
};

export default FAQAndNotesContainer;
