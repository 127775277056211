import React, { createContext, FC } from 'react';

import { ContextProps, ContextProviderProps } from './types';

const defaultContext: ContextProps = {
  sectionId: undefined,
  sectionTitle: '',
};
export const SectionContext = createContext(defaultContext);

export const SectionContextProvider: FC<ContextProviderProps> = ({ sectionId, sectionTitle, children }) => {
  return (
    <SectionContext.Provider
      value={{
        ...defaultContext,
        sectionId,
        sectionTitle,
      }}
    >
      {children}
    </SectionContext.Provider>
  );
};
