import React, { FC, useContext } from 'react';
import { Box, Link, Typography } from '@mui/material';
import { LocalizationContext } from 'contexts';

interface Props {
    original: {
      title: string,
      stripped_title: string,
      slug: string,
      section_title: string,
      numbering: string,
    };
};

export const DuplicateTag: FC<Props> = ({ original }) => {
  const { dictionary } = useContext(LocalizationContext);

  return (
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: 'baseline',
        ml: 3,
      }}
    >
      <Typography
        fontWeight={400}
        fontSize={13}
        sx={{
          color: '#777',
        }}
      >
        {dictionary.document.duplicateTag}
      </Typography>
      <Link
        href={`#${original.slug}`}
        ml="3px"
        fontWeight={400}
        fontSize={13}
      >
        {original.section_title} - {original.numbering} {original.stripped_title}
      </Link>
    </Box>
  );
};
