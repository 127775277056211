import React from 'react';

import { ReferenceTooltip } from 'modules/Document/TransformedText/ReferenceTooltip';

import { findLinkEntities } from './helpers';

export const Link = (props: any) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <ReferenceTooltip
      title={url}
    >
      {props.children}
    </ReferenceTooltip>
  );
};

export const tooltipDecorator = {
  strategy: findLinkEntities,
  component: Link,
};
