import React, { FC, useContext, useState } from 'react';
import { useDebounce } from 'react-use';
import { Autocomplete, TextField } from '@mui/material';

import { LocalizationContext } from 'contexts';
import { useOriginalItemSearchResults } from 'shared/hooks/useOriginalItemSearchResults';
import { useQueryState } from 'shared/hooks/useQueryState';

export const ArticleSearchAutocomplete: FC<any> = ({ label, TextFieldProps, ...props }) => {
  const { dictionary } = useContext(LocalizationContext);
  const [ slug = '' ] = useQueryState('slug');
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');
  const { results } = useOriginalItemSearchResults({ cla_slug: slug, search: debouncedSearchQuery, article: true }, { enabled: debouncedSearchQuery.length > 2 });

  useDebounce(() => {
    setDebouncedSearchQuery(searchQuery);
  },
  500,
  [searchQuery],
  );

  return (
    <Autocomplete
      options={results}
      renderInput={props => (
        <TextField
          {...props}
          label={label || dictionary.faq.searchArticles}
          InputLabelProps={{ shrink: true }}
          {...TextFieldProps}
          onChange={(event) => setSearchQuery(event.target.value)}
        />
      )}
      getOptionLabel={(article: any) => `${article.numbering_draft} ${article.stripped_title_draft || article.stripped_title}`}
      {...props}
      onChange={(e, value) => props.onChange(value)}
    />
  );
};
