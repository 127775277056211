import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Grid, Typography, Box, Paper, useTheme } from '@mui/material';

import { LayoutContext } from 'contexts';
import { useNavSections } from 'shared/hooks/useNavSections';

export const Home = () => {

  const { homeSections } = useNavSections();
  const { isLightMode } = useContext(LayoutContext);
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Container maxWidth="xl" sx={{ p: 3 }}>
      <Grid container sx={{ minHeight: 'calc(100vh - 150px)' }} direction="row" spacing={4} alignItems="center"
        justifyContent="center">
        {homeSections.map(({ title, link, Icon, external }, i) => (
          <Grid item xs={12} sm={4} key={i}>
            <Box
              component={Paper}
              onClick={() => {
                if (external) {
                  window.open(link, '_blank')?.focus();
                } else {
                  navigate(link);
                }
              }}
              sx={{
                p: 4,
                textAlign: 'center',
                cursor: 'pointer',
                '&:hover': {
                  background: 'none',
                  bgcolor: isLightMode ? '#ececec' : '#111111',
                },
              }}
            >
              <Icon sx={{ width: 45, height: 45 }}/>
              <Box mt={2}/>
              <Typography
                variant="h4"
                color={isLightMode ? theme.palette.primary.main : 'white'}
              >
                {title}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
