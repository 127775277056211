import React, { useCallback, useContext, useState, useMemo, FC, useEffect } from 'react';
import {
  Box,
  InputAdornment,
  Paper,
  TextField,
  Link,
  Typography,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { debounce } from 'lodash';

import { setDocumentSearchArticleResults, setDocumentSearchResults } from 'store/DocumentSearch';
import { useBoundStore } from 'store';
import { primaryBrandOrange } from 'shared/styles/muiTheme';
import {
  LocalizationContext,
  LayoutContext,
} from 'contexts';
import { DocumentMode } from 'types/Document';
import { stripHtml } from 'helpers/html';
import { getArticleResults } from 'modules/Document/Article/helpers';
import { useQueryState } from 'shared/hooks/useQueryState';
import { useDocumentSearch } from 'shared/hooks/useDocumentSearch';
import { useDocumentSearchDropdownData } from 'shared/hooks/useDocumentSearchDropdownData';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';


export const SearchInput: FC<any> = ({ document }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { genericError, isLightMode, documentMode } = useContext(LayoutContext);
  const { dictionary } = useContext(LocalizationContext);
  const [search, setSearch] = useQueryState('search');
  const [importantOnly] = useQueryState('important-only');
  const [slug] = useQueryState('slug');
  const documentSearchArticleResults = useBoundStore((state) => state.documentSearchArticleResults);

  const { data: dropDownData, error: dropDownDataError } = useDocumentSearchDropdownData({
    slug,
    draft: documentMode === DocumentMode.Write,
  });

  const { searchResults, error: searchError, status } = useDocumentSearch({
    slug,
    search,
    importantOnly: !!importantOnly,
    draft: documentMode === DocumentMode.Write,
  }, { enabled: !!search });

  const onSearch = useCallback(({ target: { value } }) => (
    setSearch(value.trim() === '' ? undefined : value)
  ), [setSearch]);

  useEffect(() => {
    setDocumentSearchResults(searchResults);
    if (!searchResults) {
      setDocumentSearchArticleResults([]);
    }
  }, [searchResults]);

  useEffect(() => {
    if (document && searchResults && dropDownData) {
      setDocumentSearchArticleResults(
        getArticleResults(document, dropDownData, searchResults),
      );
    }
  }, [searchResults, document, dropDownData]);

  useEffect(() => {
    if (searchError || dropDownDataError) {
      genericError();
    }
  }, [genericError, searchError, dropDownDataError]);

  const debouncedSearch = useMemo(() => {
    return debounce(onSearch, 800);
  }, [onSearch]);

  return (
    <Box position="relative">
      <TextField
        label={dictionary.document.form.fieldSearch}
        defaultValue={search}
        size="small"
        onBlur={() => {
          setTimeout(() => setIsDropdownOpen(false), 300);
        }}
        onFocus={() => setIsDropdownOpen(true)}
        onChange={(event) => {
          setIsDropdownOpen(true);
          debouncedSearch(event);
        }}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Search/>
            </InputAdornment>
          ),
        }}
      />
      {isDropdownOpen && (
        <Paper
          sx={{
            position: 'absolute',
            width: '100%',
            height: '200px',
            overflowY: 'auto',
            zIndex: 100,
          }}
          variant="outlined" square
        >
          {documentSearchArticleResults?.map((result: any) => (
            <Box key={result.slug}>
              <Box>
                <Link
                  display="flex"
                  underline="none"
                  mb="10px"
                  color={isLightMode ? 'black' : 'white'}
                  href={`#${result.slug}`}
                >
                  {/* todo add section coloring */}
                  <Typography
                    color={primaryBrandOrange}
                  >
                    {stripHtml(result.section.title)}
                  </Typography>
                  <Typography ml="2px">
                    - {result.numbering} {stripHtml(result.title)}
                  </Typography>
                </Link>
              </Box>
              <Box mb="15px" paddingLeft="20px">
                {result.subArticles?.map((subArticleResult: any) => (
                  <Link
                    key={subArticleResult.slug}
                    display="block"
                    underline="none"
                    mb="10px"
                    color={isLightMode ? 'black' : 'white'}
                    href={`#${subArticleResult.slug}`}
                  >
                    {subArticleResult.numbering} {stripHtml(subArticleResult.title)}
                  </Link>
                ))}
              </Box>
            </Box>
          ))}
          {(!documentSearchArticleResults?.length && status === 'loading') && (
            <BodyLoading height="100%"/>
          )}
          {(!documentSearchArticleResults?.length && status !== 'loading') && (
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {search && <Typography>{dictionary.document.form.noMatchingArticles}</Typography>}
              {!search && <Typography>{dictionary.document.form.typeToSearch}</Typography>}
            </Box>
          )}
        </Paper>
      )}
    </Box>
  );
};
