import React, { createContext, FC, useCallback, useContext } from 'react';

import { LayoutContext } from 'contexts/LayoutContext/LayoutContext';
import { checkAllRelatedPrintCheckboxes } from 'modules/Document/helpers';
import { ContextProps, ContextProviderProps } from './types';

const defaultContext: ContextProps = {
  isPrintMode: false,
  isPrintPreview: false,
  itemsToPrint: {},
  toggleItemToPrint: () => null,
  reset: () => null,
};

export const DocumentPrintContext = createContext(defaultContext);

export const DocumentPrintContextProvider: FC<ContextProviderProps> = ({ isPrintMode, isPrintPreview, children }) => {
  const { layoutTempState, setLayoutTempState } = useContext(LayoutContext);

  const toggleItemToPrint = useCallback((itemSlug: string, shouldPrint: boolean) => {
    const itemsToUpdate = checkAllRelatedPrintCheckboxes(itemSlug, shouldPrint);

    setLayoutTempState({
      ...layoutTempState,
      shouldPrintAll: shouldPrint ? layoutTempState?.shouldPrintAll : false,
      itemsToPrint: {
        ...(layoutTempState?.itemsToPrint || {}),
        ...itemsToUpdate,
      },
    });
  }, [layoutTempState, setLayoutTempState]);

  const reset = useCallback(() => {
    setLayoutTempState({
      ...layoutTempState,
      itemsToPrint: {},
    });
  }, [layoutTempState, setLayoutTempState]);

  return (
    <DocumentPrintContext.Provider
      value={{
        ...defaultContext,
        isPrintMode,
        isPrintPreview,
        itemsToPrint: layoutTempState?.itemsToPrint || {},
        toggleItemToPrint,
        reset,
      }}
    >
      {children}
    </DocumentPrintContext.Provider>
  );
};
