export const isDateValid = (dateString: string) => {
  const regex = /^\d{4}-\d{2}-\d{2}$/;

  if (dateString.match(regex) === null) {
    return false;
  }

  const date = new Date(dateString);

  const timestamp = date.getTime();

  if (Number.isNaN(timestamp)) {
    return false;
  }

  return date.toISOString().startsWith(dateString);
};