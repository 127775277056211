import { request } from 'clients/baseClient';
const groupsApiBaseUrl = process.env.REACT_APP_CLA_API_URL;

const getAuthGroups = (): any => {
  return request({
    options: {
      url: `${groupsApiBaseUrl}/groups/`,
      method: 'GET',
    },
  }).then((data: any) => ({
    ...data,
  }));
};

export const groupClient = {
  getAuthGroups,
};
