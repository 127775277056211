import { useQuery } from 'react-query';
import { groupBy } from 'lodash';
import { cacheKeys } from 'config';
import { documentsClient } from 'clients/documents/documentsClient';
import { useQueryState } from 'shared/hooks/useQueryState';

type Options = {
  enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export type Params = {};

export const useNotes = (
  params: Params = {
    limit: 1000000000000,
  },
  filterByCurrentCla?: boolean,
  options: Partial<Options> = defaultOptions,
) => {
  const [slug = ''] = useQueryState('slug');

  const cacheParams = filterByCurrentCla ? { ...params, cla_id: slug } : params;

  const { data: { data } = {}, status, error } = useQuery(
    [cacheKeys.documents.getNotes, cacheParams],
    () => documentsClient.getNotes(params),
    {
      enabled: options.enabled,
    },
  );

  const results = data?.results;

  const groupById = groupBy(results, 'id');
  const groupByBlock = groupBy(results, 'block');
  const groupByArticle = groupBy(results, 'article');

  return {
    notes: results,
    groupById,
    groupByBlock,
    groupByArticle,
    status,
    error,
  };
};
