import React, { useContext } from 'react';
import { Button, Card, Container, Grid, Typography } from '@mui/material';
import { AddCircle } from '@mui/icons-material';

import { DialogContext, LocalizationContext } from 'contexts';
import { Dialog } from 'contexts/DialogContext/types';
import { useBreadcrumbs } from 'shared/hooks/useBreadcrumbs';
import { Breadcrumbs } from 'shared/components/layout/Breadcrumbs/Breadcrumbs';
import { TeamHeader } from 'views/LoggedIn/Team/partials/TeamHeader';
import { TeamList } from 'views/LoggedIn/Team/TeamList';

export const Team = () => {

  const breadcrumbs = useBreadcrumbs('team');
  const { dictionary } = useContext(LocalizationContext);
  const { openDialog } = useContext(DialogContext);

  return (
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ my: 2 }}>
          <Typography variant="h4">Team</Typography>
        </Grid>
        <Grid item xs={12}>
          <Breadcrumbs list={breadcrumbs} />
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ minHeight: '100%' }}>
            <TeamHeader>
              {({ filters }) => (
                <TeamList
                  filters={filters}
                />
              )}
            </TeamHeader>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ textAlign: 'center' }}
          pb={2}
        >
          <Button
            size="large"
            variant="contained"
            onClick={() => openDialog(Dialog.InviteTeamMember)}
            endIcon={<AddCircle style={{ color: '#FAFAFA' }}/>}
          >
            {dictionary.team.buttonInvite}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
