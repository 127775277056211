import React, { FC, useContext } from 'react';
import { EditorState } from 'draft-js';
import { noop } from 'lodash';

import { LocalizationContext } from 'contexts';
import { InfoIcon } from 'shared/components/layout/CustomIcons/InfoIcon';

type Props = {
  editorState: EditorState,
  onChange?: (editorState: EditorState) => void,
  onClick?: () => void;
  disabled?: boolean;
};

export const TooltipToolbarRemoveOption: FC<Partial<Props>> = ({ editorState, onChange = noop, onClick = noop, disabled = false }) => {
  const { dictionary } = useContext(LocalizationContext);

  return (
    <div onClick={disabled ? () => {} : onClick}>
      <div className={`rdw-option-wrapper ${disabled && 'rdw-option-disabled'}`} title={dictionary.editableContent.removeTooltip}>
        <InfoIcon
          color="black"
          style={{
            width: 16,
            height: 16,
          }}
        /> -
      </div>
    </div>
  );
};
