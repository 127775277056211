import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Typography, Box, IconButton, TextField, InputAdornment, CircularProgress } from '@mui/material';
import { Link, Search } from '@mui/icons-material';
import { primaryBrandOrange } from 'shared/styles/muiTheme';
import { debounce, isEmpty } from 'lodash';
import { LocalizationContext } from 'contexts';
import { documentsClient } from 'clients/documents/documentsClient';
import {
  FilesOverviewContainerProps,
  FlattenEntityItem,
  SmartFileListProps,
} from 'modules/Document/FilesOverviewContainer/types';
import { getArticleNumbering } from 'modules/Document/Article/helpers';
import { useQueryState } from 'shared/hooks/useQueryState';
import MultiAccordion from 'shared/components/MultiAccordion/MultiAccordion';
import FileList from 'shared/components/FileList/FileList';


const SmartFileList: FC<SmartFileListProps> = ({
  item,
}) => {
  const [files, setFiles] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { dictionary } = useContext(LocalizationContext);

  useEffect(() => {
    Promise.all([
      documentsClient.getFiles(
        {
          [item.type]: item.id,
        },
      ),
      !isEmpty(item.descendantBlocks) ? (
        documentsClient.getFiles(
          {
            block__in: item.descendantBlocks?.map(block => block.id).join(','),
          },
        )
      ) : undefined,
    ]).then(([responseEntity, responseBlocks]) => {
      if (!responseBlocks) {
        setFiles(responseEntity.data.results);
      } else {
        setFiles([
          ...responseEntity.data.results,
          ...responseBlocks.data.results,
        ]);
      }
    }).finally(() => {
      setIsLoading(false);
    });
  }, [item.id, item.type, item.descendantBlocks]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          width: '100%',
        }}
      >
        <CircularProgress/>
      </Box>
    );
  }

  if (isEmpty(files) && !isLoading) {
    return (
      <span>{dictionary.filesOverview.empty}</span>
    );
  }

  return (
    <FileList
      files={files}
    />
  );
};

const makeFlat = (
  entity: any,
  descendantBlocks: any[],
  addFilesCount: (value: number) => void,
) => {
  descendantBlocks.push(entity);
  addFilesCount(entity.files_count);

  entity.blocks?.forEach((block: any) => {
    makeFlat(block, descendantBlocks, addFilesCount);
  });

  entity.sub_block?.forEach((block: any) => {
    makeFlat(block, descendantBlocks, addFilesCount);
  });
};

export const getDescendantsAndFilesCount = (entity: any, type: 'block' | 'article' | 'section') => {
  const descendantBlocks: any[] = [];
  let filesCount: number = entity.files_count || 0;
  const addFilesCount = (value: number) => {
    filesCount += value;
  };

  if (type === 'section') {
    entity.blocks?.filter((block: any) => (!block.is_before_article || (block.is_before_article && !block.title)))?.forEach((entity: any) => makeFlat(entity, descendantBlocks, addFilesCount));
    entity.sub_block?.filter((block: any) => (!block.is_before_article || (block.is_before_article && !block.title)))?.forEach((block: any) => makeFlat(block, descendantBlocks, addFilesCount));
  } else {
    entity.blocks?.forEach((block: any) => makeFlat(block, descendantBlocks, addFilesCount));
    entity.sub_block?.forEach((block: any) => makeFlat(block, descendantBlocks, addFilesCount));
  }

  return {
    descendantBlocks,
    filesCount,
    id: entity.id,
    type,
  };
};

export const FilesOverviewContainer: FC<FilesOverviewContainerProps> = (
  {
    document,
  },
) => {
  const [sectionId] = useQueryState('section-id');
  const { dictionary } = useContext(LocalizationContext);

  const [search, setSearch] = useState<string | undefined>();

  const debouncedSearch = useMemo(() => {
    return debounce(setSearch, 600);
  }, [setSearch]);

  const flattenEntityList = useMemo<FlattenEntityItem[]>(() => {
    const newFlattenEntityList: FlattenEntityItem[] = [];

    const sections = document?.section_set.filter((section: any) => {
      if (!sectionId) {
        return true;
      }
      return section.id.toString() === sectionId.toString();
    });

    sections?.forEach((section: any) => {
      const level = 0;
      newFlattenEntityList.push({
        title: section.stripped_title,
        slug: section.slug,
        level,
        color: section.titles_color,
        ...getDescendantsAndFilesCount(section, 'section'),
      });


      section.blocks?.filter((block: any) => (
        block.is_before_article && block.title
      )).forEach((block: any) => {
        const level = 1;
        newFlattenEntityList.push({
          title: block.stripped_title,
          slug: block.slug,
          level,
          color: section.titles_color,
          ...getDescendantsAndFilesCount(block, 'block'),
        });
      });

      const pushArticle = (
        article: any,
        articleNumber: string,
        level: number,
      ) => {
        newFlattenEntityList.push({
          title: `${articleNumber} ${article.stripped_title}`,
          slug: article.slug,
          color: section.titles_color,
          level,
          ...getDescendantsAndFilesCount(article, 'article'),
        });
      };

      const pushArticles = (
        articles: any,
        level: number = 1,
        parentArticleNumber?: string,
      ) => {
        articles?.forEach((article: any, indexArticle: number) => {
          const articleNumber = getArticleNumbering(
            parentArticleNumber,
            indexArticle,
            level,
          );
          pushArticle(
            article,
            articleNumber,
            level + 1,
          );

          if ((level + 1) <= 3) {
            pushArticles(article.article_set, level + 1, articleNumber);
          }

        });
      };

      pushArticles(section.article_set);

      section.blocks?.filter((block: any) => (
        !block.is_before_article && block.title
      )).forEach((block: any) => {
        const level = 1;
        newFlattenEntityList.push({
          title: block.stripped_title,
          slug: block.slug,
          level,
          color: section.titles_color,
          ...getDescendantsAndFilesCount(block, 'block'),
        });
      });
    });

    if (search) {
      return newFlattenEntityList.filter((item: FlattenEntityItem) => (
        item.title?.toLowerCase().includes(search.toLowerCase())
      )).filter(item => item.filesCount > 0);
    } else {
      return newFlattenEntityList.filter(item => item.filesCount > 0);
    }
  }, [document?.section_set, sectionId, search]);

  return (
    <>
      <Box textAlign="center" sx={{ mb: 2, mt: 2 }}>
        <Typography variant="h6">
          {dictionary.filesOverview.title}
        </Typography>

        <TextField
          sx={{ my: 1 }}
          size="small"
          label={dictionary.search}
          variant="standard"
          InputProps={{
            endAdornment: <InputAdornment position="end"><Search/></InputAdornment>,
          }}
          onChange={(event) => {
            debouncedSearch(event.target.value);
          }}
        />
      </Box>
      <Box maxHeight="calc(100vh - 340px)" overflow="auto">
        <MultiAccordion
          data={flattenEntityList}
          emptyLabel={dictionary.filesOverview.empty}
          defaultExpandIndex={-1}
          renderSummary={(item: any, index: number, expandIndex: number) => (
            <>
              <Typography
                fontWeight={item.level < 3 ? 500 : 300}
                sx={{
                  color: [0, 1].includes(item.level) ? (item.color || primaryBrandOrange) : 'inherit',
                  fontSize: item.level === 0 ? '1rem' : 'inherit',
                  paddingLeft: item.level > 0 ? '12px' : 'inherit',
                  borderLeft: item.level > 0 ? `3px solid ${item.color || primaryBrandOrange}` : 'inherit',
                }}
              >
                {item.title}
              </Typography>
              <Box ml="auto"/>
              <span
                style={{
                  marginLeft: '6px',
                }}
              >
                {item.filesCount}
              </span>
              <IconButton
                onClick={e => {
                  e.preventDefault();
                  (
                    item.level === 0 ? (
                      window.document.querySelectorAll(`h3#${item.slug}`)[0]
                    ) : (
                      window.document.getElementById(item.slug)
                    )
                  )?.scrollIntoView({
                    behavior: 'auto',
                    block: 'center',
                    inline: 'center',
                  });
                }}
              >
                <Link/>
              </IconButton>
            </>
          )}
          renderDetails={(item: any, index: number, expandIndex: number) => {
            if (index !== expandIndex) {
              return <></>;
            }

            return (
              <SmartFileList
                item={item}
              />
            );
          }}
        />
      </Box>
    </>
  );
};
