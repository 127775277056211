import { useCallback, useContext } from 'react';
import { UserContext } from 'contexts';


export const useSignin = () => {

  const { login: userLogin } = useContext(UserContext);

  const signIn = useCallback(async (email, password) => {
    try {
      const result = await userLogin(email, password);
      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }, [userLogin]);

  return {
    signIn,
  };
};
