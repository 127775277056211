import React, { forwardRef, FC } from 'react';
import {
  Box,
} from '@mui/material';

import { secondaryBrandGrey } from 'shared/styles/muiTheme';

export const Explanation: FC<any> = forwardRef(({ children, className, ...props }, ref) => {

  return (
    <Box
      ref={ref}
      className={`${className} explanation-block`}
      sx={{
        background: secondaryBrandGrey,
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 3,
        paddingRight: 3,
        borderRadius: '3px',
        marginTop: 1,
        marginBottom: 1,
        marginLeft: '4px',
      }}
      {...props}
    >
      {children}
    </Box>
  );
});
