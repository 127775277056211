import React, { FC, useContext } from 'react';
import { Typography } from '@mui/material';

import { LocalizationContext } from 'contexts';
import { EditableContent } from 'shared/components/form/EditableContent/EditableContent';

import { Header } from './Header';


export const SheetRenderer: FC<any> = ({
  block,
  parent,
  siblings,
  columns,
  className,
  onAddColumn,
  onDeleteColumn,
  onHeaderChange,
  onSort,
  children,
}) => {

  const { dictionary } = useContext(LocalizationContext);

  return (
    <table
      className={className}
      style={{
        tableLayout: 'auto',
        width: '100%',
        marginBottom: '20px',
        borderCollapse: 'collapse',
      }}
    >
      <thead>
        <tr>
          <th
            colSpan={columns.length}
            style={{
              backgroundColor: '#273e71',
              padding: 10,
              border: '1px #fafafa solid',
            }}
          >
            <Typography align="center" variant="body1" sx={{ color: '#fff', fontWeight: 'bold' }}>
              <EditableContent block={block} parent={parent} siblings={siblings} disableEnterKey>
                <span dangerouslySetInnerHTML={{ __html: block?.title || dictionary.editableTable.table }}/>
              </EditableContent>
            </Typography>
          </th>
        </tr>
        <tr>
          {
            columns.map((col: any, index: number) => (
              <Header
                columnIndex={index}
                key={index}
                col={col}
                onAddColumn={onAddColumn}
                onDeleteColumn={onDeleteColumn}
                onHeaderChange={onHeaderChange}
                onSort={onSort}
              />
            ))
          }
        </tr>
      </thead>
      <tbody>
        {children}
      </tbody>
    </table>
  );
};
