import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  LinearProgress,
  MenuItem,
  Paper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/lab';

import { primaryBrandOrange } from 'shared/styles/muiTheme';
import { isEmpty } from 'lodash';
import { LayoutContext, LocalizationContext } from 'contexts';
import { stripHtml } from 'helpers/html';
import { cleanHtmlEditorText, removeLineBreaks, replaceNewlineCharacters } from 'helpers/string';
import { BlockVariant, DocumentItemType } from 'clients/documents/documentsClient.types';
import { documentsClient } from 'clients/documents/documentsClient';
import { useQueryState } from 'shared/hooks/useQueryState';
import { HTMLEditor } from 'shared/components/form/HTMLEditor/HTMLEditor';
import { DuplicateButton } from 'shared/components/form/DuplicateButton/DuplicateButton';
import { ContentDescendants } from 'shared/components/ContentDescendants/ContentDecendants';

import { ColorPicker, ColorPickerController } from 'shared/components/form/ColorPicker';
import {
  fetchDocumentEntity,
  getDocumentEntityFieldValuesInLanguageParser,
  FieldLabelWithTooltip,
  shouldUseHtmlEditor,
} from './helpers';
import { DocumentEntityType } from './types';


// why do we accept 3 different parameters like block, article, section
//  where only one will not be null but else do
//  if we can have one entity parameter
export const ContentForm: FC<any> = ({
  title,
  cla,
  article,
  block,
  section,
  onSave,
  isAddingFromArticle,
  isAddingFromSection,
  isAddingFromBlock,
  articleNumber,
  fields = ['title', 'description', 'variant', 'list_formatting'],
}) => {

  const { setSharedPopupOpen } = useContext(LayoutContext);

  const { language, dictionary, availableLanguages } = useContext(LocalizationContext);
  const [editLanguage, setEditLanguage] = useState(language);
  // id must equal the key
  const submitButtonsAttributes = useMemo(() => (
    {
      save: {
        id: 'save',
        label: dictionary.save,
        isClosePopup: true,
      },
      saveAndClose: {
        id: 'saveAndClose',
        label: dictionary.saveAndClose,
        isClosePopup: false,
      },
    }
  ), [dictionary]);

  const [selectedDuplicate, setSelectedDuplicate] = useState<any>();

  const [documentSlug] = useQueryState<string>('slug');

  // equals is edit mode. maybe you can add new var for more readability
  const entityType: DocumentEntityType = (
    (cla && 'cla') || (article && 'article') || (block && 'block') || (section && 'section')
  );
  const entity = cla || article || block || section;

  const methods = useForm({
    defaultValues: {
      type: 'sibling',
      display_list_formatting: true,
      variant: BlockVariant.HtmlField,
      titles_color: primaryBrandOrange,
      ...entity,
    },
  });

  const {
    formState,
    formState: { isDirty, dirtyFields, errors },
    watch,
    reset,
    setValue,
    setError,
    handleSubmit,
  } = methods;

  // Enforced by the lib architecture
  // resets the state. Needed to make a form pristine
  // after submit for the translations business logic
  // https://react-hook-form.com/api/useform/reset/
  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({}, { keepValues: true });
    }
  }, [formState, reset]);

  useEffect(() => {
    if (!entityType) {
      return;
    }

    // todo extra fetch if language changes
    const fieldValuesInLanguageParser = getDocumentEntityFieldValuesInLanguageParser(entityType);
    const updateFields = (data: any) => {
      const fieldValuesInLanguage = fieldValuesInLanguageParser(editLanguage, data);

      for (const fieldName in fieldValuesInLanguage) {
        // @ts-ignore
        setValue(fieldName, fieldValuesInLanguage[fieldName]);
      }
    };

    if (entityType === 'cla') {
      documentsClient.getDocument({ slug: documentSlug, flat: true }, true, false)
        .then((resp: any) => {
          updateFields(resp.data);
        });
    } else {
      fetchDocumentEntity(entityType, entity.id)
        .then((resp: any) => {
          updateFields(resp.data);
        });
    }
  }, [cla, fields, article, block, section, entity, entityType, documentSlug, setValue, editLanguage]);

  const [isSaving, setIsSaving] = useState(false);

  const variant = watch('variant');
  const titleFieldValue = watch('title');
  const descriptionFieldValue = watch('description');
  const type = watch('type');
  const is_renvoi = watch('is_renvoi');
  const is_full_renvoi = watch('is_full_renvoi');

  const isAddingArticle = useMemo(() => {
    return isAddingFromArticle || (isAddingFromSection && type === 'child');
  }, [isAddingFromArticle, isAddingFromSection, type]);

  const onSelectDuplicate = useCallback((item: any) => {
    setSelectedDuplicate(item);
    setValue('original_draft', item.id);
    setValue('title', item.title);
    setValue('description', item.body);

    if (isAddingFromBlock) {
      setValue('description', item.body);
      setValue('list_formatting_draft', item.list_formatting_draft);
      setValue('variant_draft', item.variant_draft);
      setValue('is_important_draft', item.is_important_draft);
      setValue('is_renvoi_draft', item.is_renvoi_draft);
      setValue('is_full_renvoi_draft', item.is_full_renvoi_draft);
    }
  }, [isAddingFromBlock, setValue]);

  const onSubmit = useCallback(async (data, e) => {
    setIsSaving(true);
    const title = removeLineBreaks(cleanHtmlEditorText(data.title));
    onSave && await onSave({
      ...data,
      title,
      description: replaceNewlineCharacters(cleanHtmlEditorText(data.description)),
      editLanguage,
    });
    setIsSaving(false);

    const submitId = e.nativeEvent.submitter.id;
    // @ts-ignore
    const isClosePopup = submitButtonsAttributes[submitId].isClosePopup;
    setSharedPopupOpen(isClosePopup);
  }, [onSave, editLanguage, setSharedPopupOpen, submitButtonsAttributes]);

  const useHTMLEditor = useMemo(() => {
    return shouldUseHtmlEditor(variant, isAddingArticle, titleFieldValue);
  }, [isAddingArticle, variant, titleFieldValue]);

  const RenderField = useHTMLEditor ? HTMLEditor : TextField;

  const item = cla || article || block || section;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box component={Paper} sx={{ p: 3, maxWidth: 720 }}>
          <Grid container spacing={2}>
            {title && (
              <>
                <Grid item xs={12} display="flex" justifyContent="space-between">
                  <Typography variant="h6" align="center">
                    {!!selectedDuplicate ? dictionary.editableContent.addDuplicate(stripHtml(selectedDuplicate?.title)) : title}
                  </Typography>
                  {((isAddingArticle || isAddingFromBlock) && !selectedDuplicate) && (
                    <DuplicateButton onSelect={onSelectDuplicate}
                      itemType={isAddingArticle ? DocumentItemType.Article : DocumentItemType.Block}/>
                  )}
                  {/* is edit mode ? */}
                  {entityType && (
                    <ToggleButtonGroup
                      disabled={isSaving}
                      color="primary"
                      exclusive
                      value={editLanguage}
                      onChange={(e: any) => {
                        setEditLanguage(e.target.value);
                      }}
                      aria-label={dictionary.editableContent.editInDifferentLanguage}
                    >
                      {availableLanguages.map(language => (
                        <ToggleButton
                          value={language}
                          onClick={
                            (e) => {
                              if (isDirty) {
                                e.preventDefault();
                                e.stopPropagation();

                                Object.keys(dirtyFields).forEach(fieldName => {
                                  setError(
                                    fieldName,
                                    {
                                      type: 'error',
                                      message: dictionary.editableContent.errorUnsavedChangesOnTranslationsSwitch,
                                    },
                                  );
                                });
                              }
                            }
                          }
                        >
                          {language}
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                  )}
                </Grid>
              </>
            )}
            {fields.includes('title') && (
              <Grid item xs={12}>
                <Controller
                  name="title"
                  rules={{
                    validate: value => {
                      const strippedValue = stripHtml(value).trim();
                      if (!strippedValue && (isAddingFromSection || isAddingFromArticle)) {
                        return dictionary.forms.validations.required;
                      } else if (!strippedValue && !stripHtml(descriptionFieldValue).trim()) {
                        return dictionary.forms.validations.titleOrDescriptionRequired;
                      }
                      return true;
                    },
                  }}
                  render={({ field }) => (
                    <RenderField
                      autoFocus
                      size="small"
                      {...field}
                      label={dictionary.editableContent.title}
                      error={!!errors.title}
                      helperText={errors.title?.message}
                      disabled={!!selectedDuplicate}
                    />
                  )}
                />
              </Grid>
            )}
            {fields.includes('description') && (
              <Grid item xs={12}>
                <Controller
                  name="description"
                  rules={{
                    validate: value => {
                      const strippedValue = stripHtml(value).trim();

                      if (!strippedValue && !stripHtml(titleFieldValue).trim()) {
                        return dictionary.forms.validations.titleOrDescriptionRequired;
                      }

                      return true;
                    },
                  }}
                  render={({ field }) => (
                    <RenderField
                      {...field}
                      label={dictionary.editableContent.body}
                      {...!useHTMLEditor ? {
                        multiline: true,
                        minRows: 4,
                      } : {
                        wrapperStyle: {
                          minHeight: 200,
                        },
                      }}
                      error={!!errors.description}
                      helperText={errors.description?.message}
                      disabled={!!selectedDuplicate}
                    />
                  )}
                />
              </Grid>
            )}
            {fields.includes('published_at') && (
              <Grid item xs={12}>
                <Controller
                  name="published_at"
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      inputFormat="dd/MM/yyyy"
                      label={dictionary.publishDate}
                      renderInput={props => <TextField size="small" {...props}/>}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item sx={{ flexGrow: 1, ...!fields.includes('type') && { display: 'none' } }}>
              <Controller
                name="type"
                render={({ field }) => (
                  <TextField
                    {...field}
                    size="small"
                    label={dictionary.editableContent.type}
                    select
                    disabled={!!selectedDuplicate}
                  >
                    {[
                      ['sibling', dictionary.editableContent.sibling],
                      ['child', dictionary.editableContent.child],
                    ].map(([value, label]) => (
                      <MenuItem key={value} value={value}>{label}</MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            {fields.includes('variant') && (
              <Grid item sx={{ flexGrow: 1 }}>
                <Controller
                  name="variant"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      label={dictionary.editableContent.variant}
                      disabled={!!selectedDuplicate}
                      select
                    >
                      {[
                        [BlockVariant.Text, dictionary.editableContent.text],
                        [BlockVariant.HtmlField, dictionary.editableContent.html],
                        [BlockVariant.Explanation, dictionary.editableContent.explanation],
                        [BlockVariant.Recommendation, dictionary.editableContent.recommendation],
                        [BlockVariant.Spreadsheet, dictionary.editableContent.spreadsheet],
                      ].map(([value, label]) => (
                        <MenuItem key={value} value={value}>{label}</MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
            )}
            {fields.includes('list_formatting') && (
              <Grid item sx={{ flexGrow: 1 }}>
                <Controller
                  name="list_formatting"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      label={dictionary.editableContent.childrenFormat}
                      select
                      disabled={!!selectedDuplicate}
                    >
                      {[
                        ['', dictionary.editableContent.none],
                        ['dot', dictionary.editableContent.dot],
                        ['dash', dictionary.editableContent.dash],
                        ['arabic_numbers', dictionary.editableContent.arabicNumbers],
                        ['roman_numerals', dictionary.editableContent.romanNumerals],
                        ['alphabet', dictionary.editableContent.alphabetLowercase],
                        ['alphabet_capital', dictionary.editableContent.alphabetUppercase],
                      ].map(([value, label]) => (
                        <MenuItem key={value} value={value}>{label}</MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
            )}
            <Grid item ml="auto"/>
            {(
              ((entityType === 'block' && (entity.block_id || entity.article_id))
                || isAddingFromBlock)
            ) && (
              <Grid item>
                <Controller
                  name="display_list_formatting"
                  render={({ field }) => (
                    <FormControlLabel
                      {...field}
                      checked={field.value}
                      defaultChecked={field.value}
                      labelPlacement="start"
                      control={<Checkbox size="small" disabled={!!selectedDuplicate}/>}
                      label={dictionary.editableContent.displayListFormatting}
                    />
                  )}
                />
              </Grid>
            )}
            {fields.includes('is_important') && (
              <Grid item>
                <Controller
                  name="is_important"
                  render={({ field }) => (
                    <FormControlLabel
                      {...field}
                      checked={field.value}
                      defaultChecked={field.value}
                      labelPlacement="start"
                      control={<Checkbox size="small" disabled={!!selectedDuplicate}/>}
                      label={dictionary.editableContent.isImportant}
                    />
                  )}
                />
              </Grid>
            )}
            {fields.includes('is_renvoi') && (
              <Grid item>
                <Controller
                  name="is_renvoi"
                  render={({ field }) => (
                    <FormControlLabel
                      {...field}
                      checked={!!item?.is_parent_full_renvoi ? false : field.value}
                      disabled={!!is_full_renvoi || !!item?.is_parent_full_renvoi}
                      defaultChecked={field.value}
                      labelPlacement="start"
                      control={<Checkbox size="small" disabled={!!selectedDuplicate}/>}
                      label={(
                        <FieldLabelWithTooltip
                          label={dictionary.editableContent.renvoi}
                          tooltipTitle={dictionary.editableContent.renvoi_helptext}
                          tooltipDisclaimer={dictionary.editableContent.renvoi_disclaimer}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            )}
            {fields.includes('is_full_renvoi') && (
              <Grid item>
                <Controller
                  name="is_full_renvoi"
                  render={({ field }) => (
                    <FormControlLabel
                      {...field}
                      checked={field.value || !!item?.is_parent_full_renvoi}
                      disabled={!!is_renvoi || !!item?.is_parent_full_renvoi}
                      defaultChecked={field.value}
                      labelPlacement="start"
                      control={<Checkbox size="small" disabled={!!selectedDuplicate}/>}
                      label={(
                        <FieldLabelWithTooltip
                          label={dictionary.editableContent.full_renvoi}
                          tooltipTitle={dictionary.editableContent.full_renvoi_helptext}
                          tooltipDisclaimer={dictionary.editableContent.renvoi_disclaimer}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            )}
            {fields.includes('is_toc_include_children') && (
              <Grid item>
                <Controller
                  name="is_toc_include_children"
                  render={({ field }) => (
                    <FormControlLabel
                      {...field}
                      checked={field.value}
                      defaultChecked={field.value}
                      labelPlacement="start"
                      control={(
                        <Checkbox size="small" disabled={!!selectedDuplicate}/>
                      )}
                      label={(
                        <FieldLabelWithTooltip
                          label={dictionary.editableContent.is_toc_include_children}
                          tooltipTitle={dictionary.editableContent.is_toc_include_children_helptext}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid
              item
              container
              justifyContent="flex-end"
              spacing={2}
              sx={{
                paddingTop: '8px',
              }}
            >
              {fields.includes('titles_color') && (
                <>
                  <Grid
                    item
                    style={{
                      paddingTop: '4px',
                    }}
                  >
                    <ColorPickerController
                      name="titles_color"
                      // @ts-ignore
                      render={({ field, ...props }) => (
                        <ColorPicker
                          label={dictionary.editableContent.sectionTitlesColor}
                          error={!!errors.titles_color}
                          helperText={errors.titles_color?.message}
                          {...field}
                          {...props}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item ml="auto"/>
                </>
              )}
              <Grid item>
                <Button
                  type="submit"
                  id={submitButtonsAttributes.saveAndClose.id}
                  disabled={isSaving}
                >
                  {submitButtonsAttributes.saveAndClose.label}
                </Button>
              </Grid>
              {!isEmpty(item) && (
                <Grid item>
                  <Button
                    type="submit"
                    id={submitButtonsAttributes.save.id}
                    disabled={isSaving}
                  >
                    {submitButtonsAttributes.save.label}
                  </Button>
                </Grid>
              )}
            </Grid>

          </Grid>
          <ContentDescendants item={item} articleNumber={articleNumber} showDelete/>
          {/* todo make me smooth */}
          {isSaving && (
            <LinearProgress/>
          )}
        </Box>
      </form>
    </FormProvider>
  );
};
