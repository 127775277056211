import { StateCreator } from 'zustand';
import { RenvoiMode, SearchResultType } from 'clients/documents/documentsClient.types';
import { DocumentSearchSliceState } from './types';
import { useBoundStore } from './index';

export const createDocumentSearchSlice: StateCreator<DocumentSearchSliceState> = (set) => ({
  renvoiMode: RenvoiMode.Strikethrough,
  documentSearchResults: [],
  documentSearchArticleResults: [],
  documentSearchHighlightedResults: [],
  documentSearchHighlightedMap: [],
  documentSearchCurrentHighlightedId: null,
});

export const setRenvoiMode = (renvoiMode: RenvoiMode) => {
  useBoundStore.setState((state) => ({
    renvoiMode: renvoiMode,
  }));
};

export const setDocumentSearchResults = (documentSearchResults: any) => {
  if (documentSearchResults?.highlighted_results?.length) {
    const documentSearchHighlightedMap = documentSearchResults.highlighted_results.reduce((accumulator: any, result: any) => ({
      ...accumulator,
      [result.slug]: {
        highlightedTitle: [
          SearchResultType.ArticleTitle,
          SearchResultType.BlockTitle,
        ].includes(result.type) ? result.highlighted_text : undefined,
        highlightedText: result.type === SearchResultType.BlockTextContent ? result.highlighted_text : undefined,
      },
    }), {});

    useBoundStore.setState((state) => ({
      documentSearchResults,
      documentSearchHighlightedResults: documentSearchResults?.highlighted_results,
      documentSearchHighlightedMap,
    }));
  } else {
    useBoundStore.setState((state) => ({
      documentSearchHighlightedMap: {},
      documentSearchResults: [],
      documentSearchHighlightedResults: [],
    }));
  }
};

export const setDocumentSearchArticleResults = (documentSearchArticleResults: any) => {
  useBoundStore.setState((state) => ({
    documentSearchArticleResults,
  }));
};

export const setDocumentSearchCurrentHighlightedId = (documentSearchCurrentHighlightedId: string | null) => {
  useBoundStore.setState((state) => ({
    documentSearchCurrentHighlightedId,
  }));
};
