import { useQuery } from 'react-query';
import { cacheKeys } from 'config';
import { documentsClient } from 'clients/documents/documentsClient';

import { SearchOriginalItemsParams } from 'clients/documents/documentsClient.types';

type Options = {
  enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useOriginalItemSearchResults = (params: SearchOriginalItemsParams, options: Partial<Options> = defaultOptions) => {
  const { data: { data } = {}, status, error } = useQuery(
    [cacheKeys.documents.searchOriginalItems, params],
    () => documentsClient.searchOriginalItems(params),
    {
      enabled: options.enabled,
    },
  );

  return {
    status,
    error,
    count: data?.count || 0,
    results: data?.results || [],
  };
};
