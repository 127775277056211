import React, { FC, useContext } from 'react';
import { BounceLoader } from 'react-spinners';
import { Grid, useTheme } from '@mui/material';

import { LayoutContext } from 'contexts/LayoutContext/LayoutContext';


export const BodyLoading: FC<any> = ({ size = 100, height = 'calc(100vh - 150px)' }) => {

  const theme = useTheme();
  const { isLightMode } = useContext(LayoutContext);

  return (
    <Grid container direction="column" alignItems="center" justifyContent="center" style={{ height }}>
      <BounceLoader color={isLightMode ? theme.palette.primary.main : '#ececec'} size={size}/>
    </Grid>
  );
};
