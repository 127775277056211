import React, { FC, useMemo } from 'react';
import { getArticleNumbering } from 'modules/Document/Article/helpers';
import { SectionAccordionMenu, SectionAccordionMenuItem } from 'modules/Document/SectionMenu/SectionAccordionMenu';

type Props = {
  article: any;
  position: number;
  onNavigate?: () => void;
}

const ArticleNumber: FC<any> = ({ number }) => (
  <span
    style={{
      paddingRight: '12px',
    }}
  >
    {number}
  </span>
);

export const ArticleMenu: FC<Props> = ({
  article,
  position,
  onNavigate = () => {
  },
}) => {

  const articleNumber = useMemo(() => {
    return getArticleNumbering(undefined, position, 1);
  }, [position]);

  return (
    <SectionAccordionMenu
      title={(
        <>
          <ArticleNumber
            number={articleNumber}
          />
          {article.stripped_title}
        </>
      )}
      titleBold={article.is_important}
      details={(
        <>
          {article.article_set.map((subArticle: any, index: number) => (
            subArticle.title && (
              <SectionAccordionMenuItem
                href={`#${subArticle.slug}`}
                key={subArticle.slug}
                title={(
                  <>
                    <ArticleNumber
                      number={getArticleNumbering(articleNumber, index, 2)}
                    />
                    {subArticle.stripped_title}
                  </>
                )}
                onNavigate={onNavigate}
                bold={subArticle.is_important}
              />
            )))}
        </>
      )}
    />
  );
};
