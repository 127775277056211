import { useState, FC, useEffect, useCallback, forwardRef, useContext, useRef, ReactNode, Fragment } from 'react';
import { Editor, EditorProps } from 'react-draft-wysiwyg';
import { Box, Typography } from '@mui/material';
import {
  EditorState,
  ContentState,
  convertToRaw,
  RichUtils,
  SelectionState,
  ContentBlock,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { LayoutContext } from 'contexts';

import { TooltipToolbarAddOption } from 'shared/components/form/HTMLEditor/TooltipToolbarAddOption';
import { TooltipToolbarRemoveOption } from 'shared/components/form/HTMLEditor/TooltipToolbarRemoveOption';
import { TooltipToolbarEditOption } from 'shared/components/form/HTMLEditor/TooltipToolbarEditOption';
import { TooltipEditorInput } from 'shared/components/form/HTMLEditor/TooltipEditorInput';
import { tooltipDecorator } from './TooltipDecoratorComponent';
import { preProcessHtmlForTooltips, postProcessEditorHtmlForTooltips } from './helpers';

export type HTMLEditorProps = {
  label?: ReactNode;
  value?: string;
  onChange?: (value: string) => void;
  error?: boolean;
  helperText?: string;
  tooltipsEnabled?: boolean;
  disabled?: boolean;
};

type SelectionInfo = {
  anchorKey: string;
  anchorOffset: number;
  focusKey: string;
  focusOffset: number;
  isBackward: boolean;
} | null;

export const HTMLEditor: FC<EditorProps & HTMLEditorProps> = forwardRef(({
  value: _value,
  onChange,
  label,
  error,
  helperText,
  tooltipsEnabled = true,
  disabled,
  ...props
}) => {
  const ref: any = useRef();
  const { mode } = useContext(LayoutContext);
  const [editorState, _setEditorState] = useState(EditorState.createEmpty());

  const [savedSelection, setSavedSelection] = useState<SelectionInfo>(null);

  const [showTooltipEditor, setShowTooltipEditor] = useState(false);
  const [currentEditTooltipText, setCurrentEditTooltipText] = useState('');

  const [isLink, setIsLink] = useState(false);
  const [hasLinksInSelection, setHasLinkInSelection] = useState(false);

  const setEditorState = useCallback((newEditorState: EditorState) => {
    _setEditorState(newEditorState);
    setIsLink(isSelectionLink(newEditorState));
    setHasLinkInSelection(checkHasLinksInSelection(newEditorState));
  }, []);

  useEffect(() => {
    const processedValue = preProcessHtmlForTooltips(_value || '');
    const contentBlock = htmlToDraft(processedValue);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    setEditorState(editorState);

    // console.log('#', _value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_value]);

  const focusEditor = useCallback(() => {
    setTimeout(() => {
      ref.current.focusEditor();
    }, 300);
  }, []);

  const onBlur = useCallback((e: any, state: EditorState) => {
    if (onChange) {
      const html = draftToHtml(convertToRaw(state.getCurrentContent()));
      const processedHtml = postProcessEditorHtmlForTooltips(html);
      onChange(processedHtml);
    }
  }, [onChange]);

  const updateTooltip = useCallback((value: string, editorState: EditorState) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'LINK',
      'MUTABLE',
      { url: value },
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });

    setEditorState(RichUtils.toggleLink(
      newEditorState,
      newEditorState.getSelection(),
      entityKey,
    ));
    focusEditor();
  }, [focusEditor, setEditorState]);

  const onAddTooltipClick = useCallback(() => {
    // todo if not a link already disable option
    const selection = editorState.getSelection();

    if (!selection.isCollapsed()) {
      updateTooltip('TODO', editorState);
    }
  }, [editorState, updateTooltip]);

  // Function to save the current selection state
  const saveSelection = (editorState: EditorState) => {
    const selectionState = editorState.getSelection();
    const selectionInfo = {
      anchorKey: selectionState.getAnchorKey(),
      anchorOffset: selectionState.getAnchorOffset(),
      focusKey: selectionState.getFocusKey(),
      focusOffset: selectionState.getFocusOffset(),
      isBackward: selectionState.getIsBackward(),
    };
    setSavedSelection(selectionInfo);
  };

  // Function to restore the saved selection state
  const restoreSelection = useCallback(() => {
    if (savedSelection &&
      savedSelection.anchorKey &&
      savedSelection.focusKey) {
      try {
        const contentState = editorState.getCurrentContent();
        const blocks = contentState.getBlocksAsArray();

        // Find the closest valid blocks
        let anchorBlock = blocks.find(block => block.getKey() === savedSelection.anchorKey) || blocks[0];
        let focusBlock = blocks.find(block => block.getKey() === savedSelection.focusKey) || blocks[blocks.length - 1];

        // Ensure offsets are within block bounds
        const anchorOffset = Math.min(savedSelection.anchorOffset, anchorBlock.getLength());
        const focusOffset = Math.min(savedSelection.focusOffset, focusBlock.getLength());

        let selectionState = SelectionState.createEmpty(anchorBlock.getKey());

        selectionState = selectionState.merge({
          anchorKey: anchorBlock.getKey(),
          anchorOffset: anchorOffset,
          focusKey: focusBlock.getKey(),
          focusOffset: focusOffset,
          isBackward: savedSelection.isBackward,
        });

        const newEditorState = EditorState.forceSelection(editorState, selectionState);
        setEditorState(newEditorState);
        console.info('Selection restored');
        return selectionState;
      } catch (error) {
        console.error('Error restoring selection:', error);
      }
    } else {
      console.warn('No valid selection to restore');
    }
    return null;
  }, [editorState, savedSelection, setEditorState]);

  const expandSelectionToLink = useCallback(() => {
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    const startKey = selection.getStartKey();
    const startOffset = selection.getStartOffset();
    // @ts-ignore
    // const endKey = selection.getEndKey();
    const endOffset = selection.getEndOffset();

    // Get the block where the selection starts
    const block = contentState.getBlockForKey(startKey);

    // Get the entity key at the start of the selection
    const startEntityKey = block.getEntityAt(startOffset);

    // Initialize new start and end offsets
    let newStartOffset = startOffset;
    let newEndOffset = endOffset;

    // If there's no link entity at the selection start, return the current editorState
    if (!startEntityKey) {
      return {
        newEditorState: editorState,
        anchorOffset: newStartOffset,
        focusOffset: newEndOffset,
      };
    }

    // Find the start of the link
    for (let i = startOffset; i >= 0; i--) {
      if (block.getEntityAt(i) !== startEntityKey) {
        newStartOffset = i + 1;
        break;
      }
      if (i === 0) {
        newStartOffset = 0;
        break;
      }
    }

    // Find the end of the link
    for (let i = endOffset; i < block.getLength(); i++) {
      if (block.getEntityAt(i) !== startEntityKey) {
        newEndOffset = i;
        break;
      }
      if (i === block.getLength() - 1) {
        newEndOffset = block.getLength();
        break;
      }
    }

    // Create a new selection state with the expanded range
    const newSelection = selection.merge({
      anchorOffset: newStartOffset,
      focusOffset: newEndOffset,
    }) as SelectionState;

    const newEditorState = EditorState.forceSelection(editorState, newSelection);

    setEditorState(newEditorState);

    return {
      newEditorState,
      anchorOffset: newStartOffset,
      focusOffset: newEndOffset,
    };
  }, [editorState, setEditorState]);

  const onEditTooltipClick = useCallback(() => {
    const { newEditorState } = expandSelectionToLink();
    if (newEditorState.getSelection().isCollapsed()) {
      return;
    }

    saveSelection(newEditorState);

    const contentState = newEditorState.getCurrentContent();
    const startKey = newEditorState.getSelection().getStartKey();
    const startOffset = newEditorState.getSelection().getStartOffset();
    const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
    const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

    let tooltipText = '';
    if (linkKey) {
      const linkInstance = contentState.getEntity(linkKey);
      tooltipText = linkInstance.getData().url;
    }

    setShowTooltipEditor(true);
    setCurrentEditTooltipText(tooltipText);
  }, [expandSelectionToLink]);

  const onEditTooltipSaveClick = useCallback((value) => {
    const selection = restoreSelection();
    if (!selection) {
      console.warn('Failed to restore selection');
      return;
    }

    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'LINK',
      'MUTABLE',
      { url: value },
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });

    const updatedEditorState = RichUtils.toggleLink(
      newEditorState,
      selection,
      entityKey,
    );

    setEditorState(updatedEditorState);
    setShowTooltipEditor(false);
    setCurrentEditTooltipText('');
    focusEditor();
  }, [restoreSelection, editorState, setEditorState, focusEditor]);

  const onRemoveTooltipClick = useCallback(() => {
    const { newEditorState } = expandSelectionToLink();
    const selection = newEditorState.getSelection();

    if (!selection.isCollapsed()) {
      focusEditor();
      setEditorState(RichUtils.toggleLink(editorState, selection, null));
    }
  }, [editorState, expandSelectionToLink, focusEditor, setEditorState]);

  const isSelectionLink = (editorState: EditorState) => {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const startKey = selection.getStartKey();
    const startOffset = selection.getStartOffset();
    const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
    const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

    return linkKey !== null && contentState.getEntity(linkKey).getType() === 'LINK';
  };

  const checkHasLinksInSelection = (editorState: EditorState) => {
    const contentState: ContentState = editorState.getCurrentContent();
    const selection: SelectionState = editorState.getSelection();
    const startKey: string = selection.getStartKey();
    const endKey: string = selection.getEndKey();
    const startOffset: number = selection.getStartOffset();
    const endOffset: number = selection.getEndOffset();

    const isCollapsed: boolean = selection.isCollapsed();

    const blockMap = contentState.getBlockMap();
    let currentKey = startKey;

    while (currentKey && (currentKey === startKey || currentKey <= endKey)) {
      const contentBlock: ContentBlock | undefined = blockMap.get(currentKey);
      if (!contentBlock) break;

      const blockText: string = contentBlock.getText();
      const blockStart: number = currentKey === startKey ? startOffset : 0;
      const blockEnd: number = currentKey === endKey ? endOffset : blockText.length;

      for (let i = blockStart; i < blockEnd; i++) {
        const entityKey = contentBlock.getEntityAt(i);
        if (entityKey !== null) {
          const entity = contentState.getEntity(entityKey);
          if (entity.getType() === 'LINK') {
            // If there's a selection or the caret is inside a link, return true
            if (!isCollapsed || (isCollapsed && i === startOffset)) {
              return true;
            }
          }
        }
      }

      currentKey = contentState.getKeyAfter(currentKey);
    }

    return false;
  };

  return (
    <Fragment>
      <Box p={2} sx={(theme) => ({
        background: mode === 'light' ? '#fafafa' : '#000',
        border: error ? `1px solid ${theme.palette.error.main}` : undefined,
      })}>
        {label && (
          <Typography
            variant="body1"
            sx={(theme) => ({
              fontSize: '10px',
              marginTop: '-28px',
              marginBottom: '8px',
              color: error ? theme.palette.error.main : undefined,
              // background: mode === 'light' ? '#fafafa' : '#000',
              width: 'max-content',
              paddingTop: '2px',

            })}
          >
            {label}
          </Typography>
        )}
        <Editor
          ref={ref}
          stripPastedStyles={true}
          {...props}
          editorState={editorState}
          readOnly={disabled}
          wrapperStyle={{
            width: '100%',
            ...props.wrapperStyle,
          }}
          editorStyle={{
            height: '250px',
            overflow: 'auto',
            background: showTooltipEditor ? 'repeating-linear-gradient(45deg, #ededed, #ededed 5px, transparent 5px, transparent 10px)' : 'inherit',
          }}
          toolbar={{
            options: ['inline', 'link'],
            inline: {
              options: ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript'],
            },
            link: {
              linkCallback: (props: any) => {
                focusEditor();
                return { ...props };
              },
            },
            ...props.toolbar,
          }}
          onEditorStateChange={setEditorState}
          //@ts-ignore
          onBlur={onBlur}
          toolbarCustomButtons={(tooltipsEnabled ? [
            <TooltipToolbarAddOption onClick={onAddTooltipClick} disabled={hasLinksInSelection}/>,
            <TooltipToolbarRemoveOption onClick={onRemoveTooltipClick} disabled={!isLink}/>,
            <TooltipToolbarEditOption onClick={onEditTooltipClick} disabled={!isLink}/>,
          ] : [])}
          customDecorators={tooltipsEnabled ? [tooltipDecorator] : []}
        />
        {error &&
          <Typography variant="body1" sx={theme => ({ color: theme.palette.error.main })}>{helperText}</Typography>}
      </Box>

      {showTooltipEditor && (
        <TooltipEditorInput
          key={currentEditTooltipText}
          value={currentEditTooltipText}
          onChange={onEditTooltipSaveClick}
          onClose={() => {
            setShowTooltipEditor(false);
            setCurrentEditTooltipText('');
          }}
        />
      )}
    </Fragment>
  );

});
