import { AxiosResponse } from 'axios';

import { PaginatedResults } from 'types/Query';
import { getFormDataFromObject } from 'helpers/form';
import { request } from 'clients/baseClient';

import {
  mapDocument,
  mapQuestion,
  mapQuestionApi,
  mapSearchOriginalResults,
  mapSearchResults,
} from './documentsClient.formatter';
import {
  CreateNoteData, GetFilesParams,
  MappedQuestionResult,
  MappedSearchOriginalResult, NotesParams,
  SearchOriginalItemsParams,
  SearchOriginalResult,
  SearchQuestionResult,
  SearchQuestionsParams, UpdateNoteData,
} from './documentsClient.types';


const DocumentsApiBaseUrl = process.env.REACT_APP_CLA_API_URL;

const getDocuments = (params: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/cla/`,
      method: 'GET',
      params,
    },
  });
};

const getDocument = (params: any, writeMode = false, map = true): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/cla/${params.slug}/`,
      method: 'GET',
      params: {
        is_important: params?.importantOnly ? true : undefined,
        flat: params?.flat ? true : undefined,
        draft: writeMode,
      },
    },
  }).then((data: AxiosResponse) => ({
    ...data,
    data: map ? mapDocument(data.data, writeMode) : data.data,
  }));
};

const getPublicDocument = (params: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/public-cla/${params.slug}/`,
      method: 'GET',
      params: {
        draft_secret: params.draft_secret,
        is_important: params?.importantOnly ? true : undefined,
      },
    },
  }).then((data: AxiosResponse) => ({
    ...data,
    data: mapDocument(data.data, false),
  }));
};

const getDocumentDraftSecret = (slug: string): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/cla/${slug}/draft_secret`,
      method: 'GET',
    },
  });
};

const getDocumentHistoryV2 = (params: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/cla/${params.slug}/history_v2/`,
      method: 'GET',
    },
  });
};

const getDocumentHistory = (params: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/cla-history/`,
      method: 'GET',
      params,
    },
  });
};


const searchDocument = (params: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/search`,
      method: 'GET',
      params: {
        query: params.search,
        draft: params.draft,
        is_important: params.importantOnly ? true : undefined,
        cla_slug: params.slug,
      },
    },
  }).then((data: AxiosResponse<any>) => ({
    ...data,
    data: mapSearchResults(data.data),
  }));
};

const searchOriginalItems = (params: SearchOriginalItemsParams): Promise<AxiosResponse<PaginatedResults<MappedSearchOriginalResult>>> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/search-originals`,
      method: 'GET',
      params,
    },
  }).then((data: AxiosResponse<PaginatedResults<SearchOriginalResult>>) => ({
    ...data,
    data: {
      ...data.data,
      ...mapSearchOriginalResults(data.data),
    },
  }));
};

const searchQuestions = (params: SearchQuestionsParams): Promise<AxiosResponse<PaginatedResults<MappedQuestionResult>>> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/question-answer`,
      method: 'GET',
      params,
    },
  }).then((data: AxiosResponse<PaginatedResults<SearchQuestionResult>>) => ({
    ...data,
    data: {
      ...data.data,
      results: data.data.results.map(mapQuestion),
    },
  }));
};

const deleteQuestion = (data: any): Promise<AxiosResponse> => {
  const { cla_id, id } = data;
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/question-answer/${id}/`,
      method: 'PATCH',
      params: {
        cla_id,
      },
      data: {
        deleted_at: new Date(),
      },
    },
  });
};

const createQuestion = (data: any): Promise<AxiosResponse> => {
  const { cla_id, ...payload } = data;

  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/question-answer/`,
      params: {
        cla_id,
      },
      method: 'POST',
      data: mapQuestionApi(payload),
    },
  });
};

const patchQuestion = (data: any): Promise<AxiosResponse> => {
  const { cla_id, ...payload } = data;

  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/question-answer/${data.id}/`,
      params: {
        cla_id,
      },
      method: 'PATCH',
      data: mapQuestionApi(payload),
    },
  });
};

const getQuestion = (id: number, cla_id: string): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/question-answer/${id}/`,
      params: {
        cla_id,
      },
      method: 'GET',
    },
  });
};


const getSearchDropdownData = (params: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/search-dropdown`,
      method: 'GET',
      params: {
        query: params.search,
        draft: params.draft,
        is_important: params.importantOnly ? true : undefined,
        cla_slug: params.slug,
      },
    },
  });
};

const getArticle = (id: number | string): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/article/${id}/`,
      method: 'GET',
    },
  });
};

const getArticleHistory = (params: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/article-history/`,
      method: 'GET',
      params,
    },
  });
};

const createArticle = (data: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/article/`,
      method: 'POST',
      data,
    },
  });
};

const patchArticle = (id: number | string, data: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/article/${id}/`,
      method: 'PATCH',
      data,
    },
  });
};

const patchArticles = (data: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/articles/`,
      method: 'PATCH',
      data,
    },
  });
};

const deleteArticle = (id: number | string): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/article/${id}/`,
      method: 'PATCH',
      data: {
        deleted_at_draft: new Date(),
      },
    },
  });
};

const getBlock = (id: number | string): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/block/${id}/`,
      method: 'GET',
    },
  });
};

const getBlockHistory = (params: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/block-history/`,
      method: 'GET',
      params,
    },
  });
};

const createBlock = (data: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/block/`,
      method: 'POST',
      data,
    },
  });
};

const patchBlock = (id: number | string, data: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/block/${id}/`,
      method: 'PATCH',
      data,
    },
  });
};

const patchBlocks = (data: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/blocks/`,
      method: 'PATCH',
      data,
    },
  });
};

const deleteBlock = (id: number | string): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/block/${id}/`,
      method: 'PATCH',
      data: {
        deleted_at_draft: new Date(),
      },
    },
  });
};

const getSection = (id: number | string): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/section/${id}/`,
      method: 'GET',
    },
  });
};

const getSectionHistory = (params: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/section-history/`,
      method: 'GET',
      params,
    },
  });
};

const createSection = (data: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/section/`,
      method: 'POST',
      data,
    },
  });
};

const patchSection = (id: number | string, data: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/section/${id}/`,
      method: 'PATCH',
      data,
    },
  });
};

const patchSections = (data: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/sections/`,
      method: 'PATCH',
      data,
    },
  });
};

const deleteSection = (id: number | string): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/section/${id}/`,
      method: 'PATCH',
      data: {
        deleted_at_draft: new Date(),
      },
    },
  });
};

const createDocument = (data: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/cla/`,
      method: 'POST',
      data,
    },
  });
};

const patchDocument = (id: number | string, data: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/cla/${id}/`,
      method: 'PATCH',
      data,
    },
  });
};

const publishDocument = (slug: number | string, data: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/cla/${slug}/publish/`,
      method: 'POST',
      data,
    },
  });
};

const deleteDocument = (id: number | string): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/cla/${id}/`,
      method: 'DELETE',
    },
  });
};

const createFile = (data: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/file/`,
      method: 'POST',
      data: getFormDataFromObject(data),
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  });
};

const deleteFile = (id: number | string): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/file/${id}/`,
      method: 'DELETE',
    },
  });
};

const getFiles = (params?: GetFilesParams): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/file/`,
      method: 'GET',
      params,
    },
  });
};

const getNotes = (params: NotesParams): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/note/`,
      method: 'GET',
      params,
    },
  });
};

const createNote = (data: CreateNoteData): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/note/`,
      method: 'POST',
      data: data,
    },
  });
};

const patchNote = (id: number, data: UpdateNoteData): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/note/${id}/`,
      method: 'PATCH',
      data,
    },
  });
};

const deleteNote = (id: number): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/note/${id}/`,
      method: 'DELETE',
    },
  });
};


export const documentsClient = {
  getDocuments,
  getDocument,
  getPublicDocument,
  getDocumentDraftSecret,
  getDocumentHistory,
  getDocumentHistoryV2,
  searchDocument,
  searchOriginalItems,
  getSearchDropdownData,
  getArticle,
  getArticleHistory,
  createArticle,
  patchArticle,
  patchArticles,
  deleteArticle,
  getBlock,
  getBlockHistory,
  createBlock,
  patchBlock,
  patchBlocks,
  deleteBlock,
  getSection,
  getSectionHistory,
  createSection,
  patchSection,
  patchSections,
  deleteSection,
  createDocument,
  patchDocument,
  publishDocument,
  deleteDocument,
  createFile,
  deleteFile,
  getFiles,
  searchQuestions,
  getQuestion,
  createQuestion,
  deleteQuestion,
  patchQuestion,
  getNotes,
  createNote,
  patchNote,
  deleteNote,
};
