import React, { FC, ReactElement } from 'react';
import { Button, ButtonProps, Typography } from '@mui/material';
import { isString } from 'lodash';

import { Dropdown, DropdownProps } from './Dropdown';


export type ButtonDropdownProps = Omit<DropdownProps, 'children'> & {
  label: string | ReactElement;
  dropDownStyle?: React.CSSProperties;
  buttonProps?: Omit<ButtonProps, 'endIcon'> & {
    endIcon?: (open: boolean) => React.ReactNode;
  };
}

export const ButtonDropdown: FC<ButtonDropdownProps> = ({ label, content, placement, buttonProps, dropDownStyle }) => (
  <Dropdown
    content={content}
    placement={placement}
    style={dropDownStyle}
  >
    {({ ref, handleToggleDropdownMenu, openDropdownMenu }) => (
      <Button
        {...buttonProps}
        ref={ref}
        aria-label="options menu"
        aria-haspopup="menu"
        onClick={handleToggleDropdownMenu}
        endIcon={buttonProps?.endIcon && buttonProps.endIcon(openDropdownMenu)}
      >
        {isString(label) ? (
          <Typography fontSize={14} sx={{ color: 'inherit' }}>{label}</Typography>
        ) : (
          label
        )}
      </Button>
    )}
  </Dropdown>
);
