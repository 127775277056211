import { useCallback, useContext, useMemo } from 'react';
import { routeBreadcrumbs, routes, Routes } from 'config';
import { LocalizationContext } from 'contexts';

type Breadcrumb = {
  text: string;
  link: string;
};

type Params = {
  text?: Record<string, any>;
  routes?: Record<string, any[]>;
}

export const useBreadcrumbs = (page: Routes, params?: Params): Breadcrumb[] => {
  const { dictionary } = useContext(LocalizationContext);

  const getText = useCallback((name: Routes) => {
    const text = dictionary.routes[name];
    return text;
  }, [dictionary]);

  const getLink = useCallback((name: Routes) => {
    const route = routes[name];
    return route;
  }, []);

  const breadcrumbs: Breadcrumb[] = useMemo(() => {
    return routeBreadcrumbs[page].map(crumb => ({
      text: getText(crumb),
      link: getLink(crumb),
    }));
  }, [page, getText, getLink]);

  return breadcrumbs;
};
