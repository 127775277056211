import React, { FC, useCallback, useState } from 'react';
import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';
import { isEmpty } from 'lodash';
import { MultiAccordionItemProps, MultiAccordionProps } from 'shared/components/MultiAccordion/types';


const MultiAccordionItem: FC<MultiAccordionItemProps> = ({
  index,
  expandIndex,
  onExpand,
  summary,
  details,
}) => {
  return (
    <Accordion
      key={index}
      expanded={expandIndex === index}
      onChange={() => onExpand(index)}
      sx={{
        '&.MuiAccordion-root': {
          border: '1px solid rgba(0, 0, 0, 0.15)',
          marginBottom: '5px',
          background: 'white',
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore/>}
        sx={{
          '.MuiAccordionSummary-content': {
            display: 'flex',
            alignItems: 'center',
          },
        }}
      >
        {summary}
      </AccordionSummary>
      <AccordionDetails
        sx={{ padding: 0, mx: 2, paddingBottom: 2 }}
      >
        {details}
      </AccordionDetails>
    </Accordion>
  );
};

const MultiAccordion: FC<MultiAccordionProps> = ({
  data,
  emptyLabel,
  renderSummary,
  renderDetails,
  defaultExpandIndex = 0,
}) => {
  const [expandIndex, setExpandIndex] = useState(defaultExpandIndex);

  const onExpand = useCallback((index: number) => {
    if (index === expandIndex) {
      setExpandIndex(-1);
    } else {
      setExpandIndex(index);
    }
  }, [expandIndex]);

  return (
    <>
      {isEmpty(data) ? (
        <Box
          m={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {emptyLabel}
        </Box>
      ) : (
        <>
          {data?.map((itemData: any, index: number) => (
            <MultiAccordionItem
              key={index}
              index={index}
              expandIndex={expandIndex}
              onExpand={onExpand}
              summary={renderSummary(itemData, index, expandIndex)}
              details={renderDetails(itemData, index, expandIndex)}
            />
          ))}
        </>
      )}
    </>
  );
};

export default MultiAccordion;
