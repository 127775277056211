import React, { FC } from 'react';

import { stripHtml } from 'helpers/html';
import { getEntityColor } from 'modules/Document/SectionMenu/helpers';
import { SectionAccordionMenu, SectionAccordionMenuItem } from 'modules/Document/SectionMenu/SectionAccordionMenu';


type Props = {
  block: any;
  onNavigate?: () => void;
}

export const BlockMenu: FC<Props> = ({
  block,
  onNavigate = () => {
  },
}) => {
  if (!block.is_toc_include_children || block.sub_block.length === 1) {
    return (
      <SectionAccordionMenuItem
        href={`#${block.slug}`}
        key={block.slug}
        title={stripHtml(block.title)}
        onNavigate={onNavigate}
        sx={{
          color: `${getEntityColor(block)} !important`,
          borderLeft: null,
        }}
      />
    );
  }

  return (
    <SectionAccordionMenu
      title={stripHtml(block.title)}
      titleColor={getEntityColor(block)}
      details={(
        <>
          {block.sub_block.map((subBlock: any, index: number) => (
            subBlock.title && (
              <SectionAccordionMenuItem
                href={`#${subBlock.slug}`}
                key={subBlock.slug}
                title={stripHtml(subBlock.title)}
                onNavigate={onNavigate}
              />
            )
          ))}
        </>
      )}
    />
  );
};
