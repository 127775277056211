import React, { FC, useMemo } from 'react';

import { Grid } from '@mui/material';
import classNames from 'classnames';
import { getListStyle, getListNumberingType } from 'helpers/numbering';
import {
  ListFormattingType,
  OrderedListFormattingType,
  UnorderedListFormattingType,
} from 'clients/documents/documentsClient.types';
import { ListTypeProps, ListProps, ListWithListValueProps } from 'modules/Document/List/types';


const Ul: FC<ListTypeProps> = ({ children, style, ...props }) => <ul {...props}
  style={{ paddingLeft: '15px', ...style }}>{children}</ul>;
const Ol: FC<ListTypeProps> = ({ children, style, ...props }) => <ol {...props} style={{
  paddingLeft: '15px',
  margin: 0, ...style,
}}>{children}</ol>;
const Div: FC<ListTypeProps> = ({ children, ...props }) => <div {...props}>{children}</div>;

export const List: FC<ListProps> = ({ type, className, children, style = {} }) => {
  const Element = useMemo(() => {
    if ([ListFormattingType.Dot, ListFormattingType.Dash].includes(type)) {
      return Ul;
    } else if ([
      ListFormattingType.Alphabet,
      ListFormattingType.AlphabetCapital,
      ListFormattingType.ArabicNumbers,
      ListFormattingType.RomanNumerals,
      ListFormattingType.RomanNumeralsCapital,
    ].includes(type)) {
      return Ol;
    }

    return Div;
  }, [type]);

  const numberingType = useMemo(() => getListNumberingType(type as OrderedListFormattingType), [type]);

  return (
    <Element
      type={numberingType}
      className={classNames('block-list')}
      style={{ ...style, listStyleType: getListStyle(type as UnorderedListFormattingType) }}
    >
      {children}
    </Element>
  );
};


export const ListWithListValue: FC<ListWithListValueProps> = ({
  type,
  className,
  style = {},
  render,
  blocks,
  twoRows = false,
}) => {
  let listValue = 0;
  const list = (
    <>
      {blocks?.map((block: any, index: number) => {
        listValue = block.display_list_formatting ? listValue + 1 : listValue;
        return render(block, listValue, index);
      })}
    </>
  );
  return (
    <List
      type={type}
      className={className}
      style={style}
    >
      {twoRows ? (
        <Grid container spacing={2}>
          {list}
        </Grid>
      ) : (
        list
      )}
    </List>
  );
};
