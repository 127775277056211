import React, { useCallback, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { routes } from 'config';
import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';
import { emailRegex } from 'helpers/validation';
import { useSignin } from 'shared/hooks/useSignin';

import { LoggedOutActionsLayout } from 'shared/components/layout/LoggedOutActionsLayout/LoggedOutActionsLayout';

export const Login = () => {

  const { dictionary } = useContext(LocalizationContext);
  const { control, setError, handleSubmit, formState: { isSubmitting, errors } } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const { signIn } = useSignin();

  const onSubmit = useCallback (async ({ email, password }) => {
    try {
      await signIn(email, password);
    } catch (error: any) {
      if(['auth/invalid-email','auth/user-not-found', 'auth/wrong-password'].includes(error.error)) {
        setError('email', { type: 'error', message: dictionary.auth.validation.emailOrPasswordWrong });
        setError('password', { type: 'error', message: dictionary.auth.validation.emailOrPasswordWrong });
      } else {
        setError('email', { type: 'error', message: dictionary.somethingWentWrong });
      }
    }
  }, [signIn, setError, dictionary]);

  return (
    <LoggedOutActionsLayout>
      <form onSubmit={handleSubmit(onSubmit)} style={{ margin: 0 }}>
        <Box sx={{ maxWidth: 400 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h3" align="center">{dictionary.login}</Typography><br/>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: dictionary.forms.validations.required,
                  pattern: {
                    value: emailRegex,
                    message: dictionary.forms.validations.invalidEmail,
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    label={dictionary.forms.fieldEmail}
                    name="email"
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="password"
                control={control}
                rules={{ required: dictionary.forms.validations.required }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    label={dictionary.forms.fieldPassword}
                    name="password"
                    type="password"
                    error={!!errors.password}
                    helperText={errors.password?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'center', mt: 2 }}>
              <LoadingButton type="submit" loading={isSubmitting} size="large" variant="contained" color="secondary">
                {dictionary.login}
              </LoadingButton>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center', mt: 2 }}>
            <Typography variant="subtitle2" align="center">
              <Link to={routes.resetPassword} color="inherit">{dictionary.resetPassword}</Link>
            </Typography>
          </Grid>
        </Box>
      </form>
    </LoggedOutActionsLayout>
  );
};
