import { OrderedListTypeAttribute } from 'types/html';
import {
  ListFormattingType,
  UnorderedListFormattingType,
  OrderedListFormattingType,
} from 'clients/documents/documentsClient.types';

export const getListStyle = (type: UnorderedListFormattingType) => {
  const map = {
    [ListFormattingType.Dash]: '"-\\  "',
    [ListFormattingType.Dot]: 'disc',
  };

  return map[type];
};

export const getListNumberingType = (type: OrderedListFormattingType): OrderedListTypeAttribute => {
  const map = {
    [ListFormattingType.Alphabet]: 'a' as OrderedListTypeAttribute,
    [ListFormattingType.AlphabetCapital]: 'A' as OrderedListTypeAttribute,
    [ListFormattingType.ArabicNumbers]: '1' as OrderedListTypeAttribute,
    [ListFormattingType.RomanNumerals]: 'I' as OrderedListTypeAttribute,
    [ListFormattingType.RomanNumeralsCapital]: 'I' as OrderedListTypeAttribute,
  };

  return map[type];
};
