import { StateCreator } from 'zustand';
import { DocumentMode } from 'types/Document';
import { DocumentModeSliceState } from './types';
import { useBoundStore } from './index';

export const createDocumentModeSlice: StateCreator<DocumentModeSliceState> = (set) => ({
  mode: DocumentMode.Read,
  draftPreviewMode: false,
  writeMode: false,
  readMode: false,
  reviewMode: false,
  showDraftVersion: false,
});

const getShowDraftVersion = (mode: DocumentMode, draftPreviewMode: boolean) => mode === DocumentMode.Write || draftPreviewMode;


export const setMode = (mode: DocumentMode) => {
  useBoundStore.setState((state) => ({
    mode,
    writeMode: mode === DocumentMode.Write,
    readMode: mode === DocumentMode.Read,
    reviewMode: mode === DocumentMode.Review,
    showDraftVersion: getShowDraftVersion(mode, state.draftPreviewMode),
  }));
};

export const setDraftPreviewMode = (draftPreviewMode: boolean) => {
  useBoundStore.setState((state) => ({
    draftPreviewMode,
    showDraftVersion: getShowDraftVersion(state.mode, draftPreviewMode),
  }));
};

