import React, { FC, useContext } from 'react';
import {
  Box,
  Grid,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';

import { useBoundStore } from 'store';
import classnames from 'classnames';
import { LayoutContext } from 'contexts';
import { DocumentItemType } from 'clients/documents/documentsClient.types';
import { ListWithListValue } from 'modules/Document/List/List';
import { TransformedText } from 'modules/Document/TransformedText/TransformedText';
import { getHeaderVariant } from 'modules/Document/Article/helpers';
import { DuplicateTag } from 'modules/Document/DuplicateTag/DuplicateTag';
import { getModifiedEntityColorForHistory } from 'modules/Document/helpers';
import { useNotes } from 'shared/hooks/useNotes';
import FileButton from 'shared/components/form/FileButton/FileButton';
import { EditableContent } from 'shared/components/form/EditableContent/EditableContent';
import { QuestionAnswerButton } from 'shared/components/form/QuestionAnswerButton/QuestionAnswerButton';

import { SubBlockWrapper as SubBlock } from './SubBlock';


export const ContentBlock: FC<any> = ({ parent, block, titleVariant, siblings, isExplanationBlock, showComments }) => {
  const { isMobile } = useContext(LayoutContext);
  const { groupByBlock } = useNotes();

  const highlightedTitle = useBoundStore((state) => (
    state.documentSearchHighlightedMap[block.slug]?.highlightedTitle
  ));
  const highlightedText = useBoundStore((state) => (
    state.documentSearchHighlightedMap[block.slug]?.highlightedText
  ));

  return (
    <Grid
      container
      className={
        classnames(
          {
            'is-full-renvoi': block.is_full_renvoi,
          },
        )
      }
    >
      <Grid item xs={12}>
        <Box style={{ position: 'relative', top: isMobile ? '-320px' : '-200px' }}/>
        <Typography
          variant={titleVariant || getHeaderVariant(2)}
          style={{
            breakInside: 'avoid',
            marginTop: !isExplanationBlock && block.title ? '16px' : undefined,
            alignItems: block.title ? 'center' : undefined,
            display: block.title ? 'inline-flex' : undefined,
            background: getModifiedEntityColorForHistory(block.id, 'block', 'title'),
          }}
        >
          <EditableContent block={block} siblings={siblings} parent={parent} disableEnterKey>
            <span
              style={{
                color: block.titles_color,
              }}
            >
              <TransformedText
                text={highlightedTitle || block.title}
                is_renvoi={block.is_renvoi}
                is_full_renvoi={block.is_full_renvoi}
                is_parent_full_renvoi={block.is_parent_full_renvoi}
              />
              {(block.has_questions || !isEmpty(groupByBlock[block.id])) && !!block.title && (
                <QuestionAnswerButton
                  item={block}
                  itemType={DocumentItemType.Block}
                  itemQuestions={block.questions}
                  sx={{
                    display: 'inline-block',
                    ml: 2,
                  }}
                />
              )}
              {!!block.title && showComments && (
                <FileButton
                  block={block}
                />
              )}
            </span>
          </EditableContent>
          {block.original && (
            <DuplicateTag original={block.original}/>
          )}
        </Typography>
        <Typography
          sx={{
            wordBreak: 'break-word',
            breakInside: 'avoid',
            background: getModifiedEntityColorForHistory(block.id, 'block', 'text_content'),
          }}>
          <EditableContent block={block} field="text_content" siblings={siblings} parent={parent} disableEnterKey>
            <TransformedText
              text={highlightedText || block.text_content}
              is_renvoi={block.is_renvoi}
              is_full_renvoi={block.is_full_renvoi}
              is_parent_full_renvoi={block.is_parent_full_renvoi}
            />
          </EditableContent>
        </Typography>

        {!isEmpty(block.sub_block) && (
          <Grid container>
            <Grid item xs={12}>
              <ListWithListValue
                type={block.list_formatting}
                blocks={block.sub_block}
                render={(sub_block, listValue) => (
                  <SubBlock
                    parent={block}
                    siblings={block.sub_block}
                    key={sub_block.slug}
                    subBlock={sub_block}
                    type={block.list_formatting}
                    listValue={listValue}
                    blockStyle={{ display: sub_block.hasContent ? undefined : 'none' }}
                    showComments={showComments}
                  />
                )}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
