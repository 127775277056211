import React, { useContext } from 'react';

import { LayoutContext } from 'contexts';
import { ReactComponent as SvgLogoDark } from 'shared/img/logo.dark.svg';
import { ReactComponent as SvgLogoLight } from 'shared/img/logo.light.svg';


export const Logo: typeof SvgLogoLight | typeof SvgLogoDark = (props) => {
  const { isLightMode } = useContext(LayoutContext);

  if (isLightMode) {
    return <SvgLogoLight {...props} style={{ width: 140, height: 'auto', cursor: 'pointer', ...props.style }}/>;
  }
  return <SvgLogoDark {...props} style={{ width: 140, height: 'auto', cursor: 'pointer', ...props.style }}/>;
};
